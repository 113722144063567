function getLocaleCode (userProfile) {
  switch (userProfile.userLanguage) {
    case "en":
      return ("en-GB");
    case "es":
      return ("es-ES");
    case "fr":
      return ("fr-FR")
    case "zh":
      return ("zh-CN")
    default:
      return ("en-GB");
  }
}

export const dateFormatterTimeOnly = (timestamp, userProfile) => {
  const selectedTimestamp = new Date(timestamp);
  return (selectedTimestamp.toLocaleString(getLocaleCode(userProfile), { timeZone: userProfile.userTimezone.value, timeStyle: "short" }) + " " + userProfile.userTimezone.abbrev)
};

export const dateFormatterTimeOnly12Hour = (timestamp, userProfile) => {
  const selectedTimestamp = new Date(timestamp);
  return (selectedTimestamp.toLocaleString(getLocaleCode(userProfile), { timeZone: userProfile.userTimezone.value, timeStyle: "short", hour12: true }) + " " + userProfile.userTimezone.abbrev)
};

export const dateFormatterTimeAndDay = (timestamp, userProfile) => {
  const selectedTimestamp = new Date(timestamp);
  return (selectedTimestamp.toLocaleString(getLocaleCode(userProfile), { timeZone: userProfile.userTimezone.value, weekday: "short" }) + " " + selectedTimestamp.toLocaleString('en-UK', { timeZone: userProfile.userTimezone.value, timeStyle: "short" }) + " " + userProfile.userTimezone.abbrev)
};

export const dateFormatterTimeAndFullDay = (timestamp, userProfile) => {
  const selectedTimestamp = new Date(timestamp);
  return (selectedTimestamp.toLocaleString(getLocaleCode(userProfile), { timeZone: userProfile.userTimezone.value, weekday: "long" }) + " " + selectedTimestamp.toLocaleString('en-UK', { timeZone: userProfile.userTimezone.value, timeStyle: "short" }) + " " + userProfile.userTimezone.abbrev)
};