import React, { Component } from "react";
import Modal from "react-modal";
import { Trans, withTranslation } from 'react-i18next';
import { requestPoints } from "../helpers/requestPoints.js";
import AgendaRow from "./agendaElements/AgendaRow"
import styles from "./Agenda.module.scss";

class Agenda extends Component {
    constructor(props) {
        super(props);
        //Set default message
        this.state = {
            requestsMade: [],
            requestsReceived: [],
            combinedConfirmedMeetings: [],
            showDay1: false,
            showDay2: true,
            showDay3: false,
        };
    }

    agendaModalOnAfterOpen = () => {
        requestPoints("other", "viewAgenda");
        this.reloadData();
    }

    // Get sent and received meeting requests
    componentDidMount () {
        this.reloadData();
    }

    // Update overall agenda content on content changed
    componentDidUpdate (prevProps, prevState) {
        // Only trigger update of confirmed meetings if requestsMade or requestsReceived has updatedAt
        if (prevState.requestsMade !== this.state.requestsMade || prevState.requestsReceived !== this.state.requestsReceived) {
            this.updateCombinedConfirmedMeetingsArray();
        }
    }

    reloadData = () => {
        // Reload user profile in case of updates to say table allocations
        this.props.reloadUserProfile();

        // Now load meetings
        fetch("/api/getUserMeetings")
            .then((res) => res.json())
            .then((data) =>
                this.setState({
                    requestsMade: data.meetingRequestsMade,
                    requestsReceived: data.meetingRequestsReceived,
                })
            );
    };

    // Function for sorting meeting records
    compare (a, b) {
        let comparison = 0;
        let timestampA = new Date();
        let timestampB = new Date();

        // Get timestamps of disparate items to compare together
        if (a.meetingSlotID != null) {
            timestampA = new Date(a.meetingSlotID.meetingSlotTimestamp);
        } else if (a.agendaItemTimestamp != null) {
            timestampA = a.agendaItemTimestamp;
        }
        if (b.meetingSlotID != null) {
            timestampB = new Date(b.meetingSlotID.meetingSlotTimestamp);
        } else if (b.agendaItemTimestamp != null) {
            timestampB = b.agendaItemTimestamp;
        }

        if (timestampA > timestampB) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    }

    updateCombinedConfirmedMeetingsArray = () => {
        console.log("Re-evaluate combined array");
        const confirmedRequestsMadeArray = this.state.requestsMade.filter(
            (request) => request.meetingStatus === "accepted"
        );
        const confirmedRequestsReceivedArray = this.state.requestsReceived.filter(
            (request) => request.meetingStatus === "accepted"
        );
        // For the requests received array where we are requestee, make the requestor the requestee to
        // allow the records to be combined to show the other party, regardless of who initiated
        confirmedRequestsReceivedArray.forEach(function (request) {
            request.meetingRequesteeUser = request.meetingRequestorUser;
            // Also amend the key to avoid the key clash of the same meeting ever occuring twice
            // if user invites themself!
            //request._id = "2" + request._id;
        });

        //console.log("A" + confirmedRequestsReceivedArray);
        const combinedRequestsArray = confirmedRequestsMadeArray.concat(
            confirmedRequestsReceivedArray
        );
        //console.log(combinedRequestsArray);
        this.setState({ combinedConfirmedMeetings: combinedRequestsArray.sort(this.compare) });
    };

    switchFromAgendaToSessionSelection = () => {
        this.props.setShowSessionSelectionModal(true);
    }

    clearAllDays = () => {
        this.setState({ showDay1: false, showDay2: false });
    }

    showDay1 = () => {
        this.clearAllDays();
        this.setState({ showDay1: true });
    };

    showDay2 = () => {
        this.clearAllDays();
        this.setState({ showDay2: true });
    };

    render () {
        const enAgendaContent = [
            {
                agendaItemTimestamp: new Date("2021-09-07T15:00:00.000+01:00"),
                agendaItemTitle: this.props.t("agenda_opening_remarks"),
                agendaItemLocation: "/plenary",
                agendaItemLocationText: this.props.t("shared_plenary"),
                agendaItemPresenters: [
                    "Denise Lee, Environmental Policy Researcher and Student, University of Toronto",
                    "Alok Sharma, President of the 26th United Nations Climate Change Conference",
                    "John Pettigrew, Chief Executive, National Grid",
                    "Joseph Tsongo, Co-ordinator of the Amani-Institute ASBL",
                    "Chris Stark, Chief Executive, Climate Change Committee",
                ],
            },
            {
                agendaItemTimestamp: new Date("2021-09-07T15:30:00.000+01:00"),
                agendaItemTitle: "",
                agendaItemLocation: "",
                agendaItemLocationText: "",
                agendaItemPresenters: [],
                agendaItemSessionSelector: this.props.userProfile && this.props.userProfile.breakoutSession1Selection,
                agendaItemType: "breakout",
            },
            {
                agendaItemTimestamp: new Date("2021-09-07T16:30:00.000+01:00"),
                agendaItemTitle: "",
                agendaItemLocation: "",
                agendaItemLocationText: "",
                agendaItemPresenters: [],
                agendaItemSessionSelector: this.props.userProfile && this.props.userProfile.breakoutSession2Selection,
                agendaItemType: "breakout",
            },
            {
                agendaItemTimestamp: new Date("2021-09-07T17:30:00.000+01:00"),
                agendaItemTitle: "",
                agendaItemLocation: "",
                agendaItemLocationText: "",
                agendaItemPresenters: [],
                agendaItemSessionSelector: this.props.userProfile && this.props.userProfile.breakoutSession3Selection,
                agendaItemType: "roundtables",
            },
            {
                agendaItemTimestamp: new Date("2021-09-07T18:30:00.000+01:00"),
                agendaItemTitle: this.props.t("agenda_plenary_qa_panel"),
                agendaItemLocation: "/plenary",
                agendaItemLocationText: this.props.t("shared_plenary"),
                agendaItemPresenters: ["Eva Saha, Conference MC",
                    "Dr. Nick Wayth, Energy Institute",
                    "Carlotta Giacché, Nord/LB and My Meaningful Money",
                    "Dr. Alaa Al Khourdajie, IPCC Working Group III",
                    "Sam Johnson Transport for London",
                    "Caritta Seppa, Tespack",
                    "Natalie Edwards, National Grid",
                ],
            },
            {
                agendaItemTimestamp: new Date("2021-09-07T19:10:00.000+01:00"),
                agendaItemTitle: this.props.t("agenda_make_your_pledge"),
                agendaItemLocation: "/networking",
                agendaItemLocationText: this.props.t("networking_pledge_wall"),
                agendaItemPresenters: ["What’s your pledge to go #onestepgreener?"],
            },
            // Day 2
            {
                agendaItemTimestamp: new Date("2021-09-08T06:00:00.000+01:00"),
                agendaItemTitle: this.props.t("agenda_opening_remarks"),
                agendaItemLocation: "/plenary",
                agendaItemLocationText: this.props.t("shared_plenary"),
                agendaItemPresenters: [
                    "Denise Lee, Environmental Policy Researcher and Student, University of Toronto",
                    "Alok Sharma, President of the 26th United Nations Climate Change Conference",
                    "John Pettigrew, Chief Executive, National Grid",
                    "Joseph Tsongo, Co-ordinator of the Amani-Institute ASBL",
                    "Chris Stark, Chief Executive, Climate Change Committee",
                ],
            },
            {
                agendaItemTimestamp: new Date("2021-09-08T06:30:00.000+01:00"),
                agendaItemTitle: "",
                agendaItemLocation: "",
                agendaItemLocationText: "",
                agendaItemPresenters: [],
                agendaItemSessionSelector: this.props.userProfile && this.props.userProfile.breakoutSession4Selection,
                agendaItemType: "breakout",
            },
            {
                agendaItemTimestamp: new Date("2021-09-08T07:30:00.000+01:00"),
                agendaItemTitle: "",
                agendaItemLocation: "",
                agendaItemLocationText: "",
                agendaItemPresenters: [],
                agendaItemSessionSelector: this.props.userProfile && this.props.userProfile.breakoutSession5Selection,
                agendaItemType: "breakout",
            },
            {
                agendaItemTimestamp: new Date("2021-09-08T08:30:00.000+01:00"),
                agendaItemTitle: "",
                agendaItemLocation: "",
                agendaItemLocationText: "",
                agendaItemPresenters: [],
                agendaItemSessionSelector: this.props.userProfile && this.props.userProfile.breakoutSession6Selection,
                agendaItemType: "roundtables",
            },
            {
                agendaItemTimestamp: new Date("2021-09-08T09:30:00.000+01:00"),
                agendaItemTitle: this.props.t("agenda_plenary_qa_panel"),
                agendaItemLocation: "/plenary",
                agendaItemLocationText: this.props.t("shared_plenary"),
                agendaItemPresenters: [
                    "Eva Saha, Conference MC",
                    "Dr.Nick Wayth, Energy Institute",
                    "Polly Osborne, Burns & McDonnell",
                    "Amy Weltvreden, National Grid Electricity System Operator",
                    "Dr. Alaa Al Khourdajie, IPCC Working Group III",
                    "Dr. Alex Wohnas, Australian Energy Market Operator (AEMO)",
                ],
            },
            {
                agendaItemTimestamp: new Date("2021-09-08T10:10:00.000+01:00"),
                agendaItemTitle: this.props.t("agenda_make_your_pledge"),
                agendaItemLocation: "/networking",
                agendaItemLocationText: this.props.t("networking_pledge_wall"),
                agendaItemPresenters: ["What’s your pledge to go #onestepgreener?"],
            },
        ];

        // Combine meetings with fixed items
        const agendaContentWithMeetings = enAgendaContent.concat(this.state.combinedConfirmedMeetings);
        // Sort combined array
        const agendaContentWithMeetingsSorted = agendaContentWithMeetings.sort(this.compare);

        // Get user timezone offset to work out start and end points of their calendar days
        var userTimezoneOffset = 0;
        if (this.props.userProfile !== null) {
            userTimezoneOffset = this.props.userProfile.userTimezone.offset;
        }

        // Work out start and end time points for local user's days
        var day1StartTimestamp = new Date("2021-09-02T00:00:00.000+00:00");
        day1StartTimestamp.setHours(day1StartTimestamp.getHours() - userTimezoneOffset);

        const day1EndTimestamp = new Date("2021-09-07T23:59:59.000+00:00");
        day1EndTimestamp.setHours(day1EndTimestamp.getHours() - userTimezoneOffset);

        const agendaContentWithMeetingsSortedAndFilteredDay1 = agendaContentWithMeetingsSorted.filter((agendaItem) =>
            (agendaItem.agendaItemTimestamp && agendaItem.agendaItemTimestamp > day1StartTimestamp && agendaItem.agendaItemTimestamp < day1EndTimestamp) ||
            (agendaItem.meetingSlotID && new Date(agendaItem.meetingSlotID.meetingSlotTimestamp) > day1StartTimestamp && new Date(agendaItem.meetingSlotID.meetingSlotTimestamp) < day1EndTimestamp))

        var day2StartTimestamp = new Date("2021-09-08T00:00:00.000+00:00");
        day2StartTimestamp.setHours(day2StartTimestamp.getHours() - userTimezoneOffset);

        const day2EndTimestamp = new Date("2021-09-15T23:59:59.000+00:00");
        day2EndTimestamp.setHours(day2EndTimestamp.getHours() - userTimezoneOffset);

        const agendaContentWithMeetingsSortedAndFilteredDay2 = agendaContentWithMeetingsSorted.filter((agendaItem) =>
            (agendaItem.agendaItemTimestamp && agendaItem.agendaItemTimestamp > day2StartTimestamp && agendaItem.agendaItemTimestamp < day2EndTimestamp) ||
            (agendaItem.meetingSlotID && new Date(agendaItem.meetingSlotID.meetingSlotTimestamp) > day2StartTimestamp && new Date(agendaItem.meetingSlotID.meetingSlotTimestamp) < day2EndTimestamp))


        const agendaContentDay1 = agendaContentWithMeetingsSortedAndFilteredDay1.map((agendaItem, index) => {
            return (
                <AgendaRow
                    key={index}
                    agendaItemDetails={agendaItem}
                    userProfile={this.props.userProfile}
                    setShowAgendaModal={this.props.setShowAgendaModal}
                    switchFromAgendaToSessionSelection={this.switchFromAgendaToSessionSelection}
                />
            )
        }
        );

        const agendaContentDay2 = agendaContentWithMeetingsSortedAndFilteredDay2.map((agendaItem, index) => {
            return (
                <AgendaRow
                    key={index}
                    agendaItemDetails={agendaItem}
                    userProfile={this.props.userProfile}
                    setShowAgendaModal={this.props.setShowAgendaModal}
                    switchFromAgendaToSessionSelection={this.switchFromAgendaToSessionSelection}
                />
            )
        }
        );

        return (
            <div>
                <Modal
                    onAfterOpen={this.agendaModalOnAfterOpen}
                    ariaHideApp={false}
                    isOpen={this.props.showAgendaModal}
                    onRequestClose={() => this.props.setShowAgendaModal(false)}
                    contentLabel="Agenda Modal"
                    overlayClassName={styles.modalOverlay}
                    className={styles.modalContent}
                    style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
                >
                    <div className={styles.modalFlexboxColumn}>
                        <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
                            <h1 className={styles.modalPageTitle}><Trans>nav_agenda</Trans></h1>
                            <button
                                className={styles.modalClose}
                                variant="success"
                                onClick={() => this.props.setShowAgendaModal(false)}
                                aria-label="Close"
                            >
                                <Trans>shared_close</Trans>
                                <img
                                    className={styles.modalCloseIcon}
                                    src="/icons/close-no-circle.png"
                                    alt="Close"
                                />
                            </button>
                        </div>


                        {this.props.userProfile ?
                            <div className={styles.daySelectionPanel}>
                                {this.state.showDay1 ? (
                                    <img
                                        src={window.$videoPath + "agendaDayButtons/" + this.props.userProfile.userLanguage + "/day1On.png"}
                                        onClick={this.showDay1}
                                        className={styles.daySelectionImage}
                                        alt="Day 1 Selected"
                                    />
                                ) : (
                                    <img
                                        src={window.$videoPath + "agendaDayButtons/" + this.props.userProfile.userLanguage + "/day1Off.png"}
                                        onClick={this.showDay1}
                                        className={styles.daySelectionImage}
                                        alt="Day 1 Not Selected"
                                    />
                                )}
                                {this.state.showDay2 ? (
                                    <img
                                        src={window.$videoPath + "agendaDayButtons/" + this.props.userProfile.userLanguage + "/day2On.png"}
                                        onClick={this.showDay2}
                                        className={styles.daySelectionImage}
                                        alt="Day 2 Selected"
                                    />
                                ) : (
                                    <img
                                        src={window.$videoPath + "agendaDayButtons/" + this.props.userProfile.userLanguage + "/day2Off.png"}
                                        onClick={this.showDay2}
                                        className={styles.daySelectionImage}
                                        alt="Day 2 Not Selected"
                                    />
                                )}
                            </div>
                            : null}

                        <div className={styles.scrollableArea}>
                            {this.state.showDay1 && this.props.userProfile ?
                                <div>
                                    <div className={styles.agendaTableContainer}>
                                        <table className={styles.agendaTable}>
                                            <tbody>
                                                {this.props.userProfile && agendaContentDay1}

                                            </tbody>
                                        </table>
                                    </div>
                                </div> : null}

                            {this.state.showDay2 ?
                                <div>
                                    <div className={styles.agendaTableContainer}>
                                        <table className={styles.agendaTable}>
                                            <tbody>
                                                {this.props.userProfile && agendaContentDay2}
                                            </tbody>
                                        </table>
                                    </div>
                                </div> : null}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(Agenda);