import React, { Component } from "react";
import ReactTooltip from 'react-tooltip';
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import UserSessionBookingOptions1 from "./userSessionBookingOptions/UserSessionBookingOptions1"
import styles from "./UserSessions.module.scss";

export default class UserSessionsBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {

            breakoutSession1Selection: 0,
            breakoutSession2Selection: 0,
            breakoutSession3Selection: 0,
            breakoutSession4Selection: 0,
            breakoutSession5Selection: 0,
            breakoutSession6Selection: 0,

            session1Disabled: true,
            session2Disabled: true,
            session3Disabled: true,
            session4Disabled: true,
            session5Disabled: true,
            session6Disabled: true,
            session7Disabled: true,
            session8Disabled: true,
            session9Disabled: true,
            session10Disabled: true,
            session11Disabled: true,
            session12Disabled: true,
            session13Disabled: true,

            sessionSelectionChanged: 0,

            page0Active: true,
            page1Active: false,
            page1Complete: false,
            page2Active: false,
            page2Complete: false,
            page3Active: false,
            page3Complete: false,
            page4Active: false,
            page4Complete: false,
            page5Active: false,
            page5Complete: false,
            page6Active: false,
            page6Complete: false,
            page7Active: false,
        };

        this.handleChange = this.handleChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);

        this.profileScrollRef = React.createRef();
    }

    componentDidMount () {
        // All roundtable sessions disabled by default
        this.disableAllSessions();
        // Get user session selections
        this.retrieveUserDetails();
    }

    disableAllSessions () {
        this.setState({
            session1Disabled: true,
            session2Disabled: true,
            session3Disabled: true,
            session4Disabled: true,
            session5Disabled: true,
            session6Disabled: true,
            session7Disabled: true,
            session8Disabled: true,
            session9Disabled: true,
            session10Disabled: true,
            session11Disabled: true,
            session12Disabled: true,
            session13Disabled: true,
            sessionSelectionChanged: 0,
        })
    }

    // Called when we move to this page
    checkSlotAvailability = (slot) => {
        console.log("Checking session availability for slot" + slot);

        // Do a fetch with slot number as a parameter which returns session availability for that slot
        fetch("/api/getSessionAvailability?slot=" + slot)
            .then((res) => res.json())
            .then((data) => {
                this.processSlotAvailability(data);
            });
    }

    // Called in response to slot availability info being loaded
    processSlotAvailability (data) {
        console.log("Received slot availability");
        console.log(data);
        this.disableAllSessions();
        const arrayOfSpaces = data.arrayOfSpaces;
        console.log(arrayOfSpaces);
        // Iterate through each session's availability
        var session;
        for (session = 1; session < 20; session++) {
            var sessionAvailability = arrayOfSpaces.find(key => key.session === session);
            if (sessionAvailability && sessionAvailability.availability === "available") {
                switch (session) {
                    case 1:
                        this.setState({ session1Disabled: false })
                        break;
                    case 2:
                        this.setState({ session2Disabled: false })
                        break;
                    case 3:
                        this.setState({ session3Disabled: false })
                        break;
                    case 4:
                        this.setState({ session4Disabled: false })
                        break;
                    case 5:
                        this.setState({ session5Disabled: false })
                        break;
                    case 6:
                        this.setState({ session6Disabled: false })
                        break;
                }
            }
        }
    }

    resetDialogueStatus () {
        this.setState({
            page0Active: true,
            page1Active: false,
            page1Complete: false,
            page2Active: false,
            page2Complete: false,
            page3Active: false,
            page3Complete: false,
            page4Active: false,
            page4Complete: false,
            page5Active: false,
            page5Complete: false,
            page6Active: false,
            page6Complete: false,
            page7Active: false,
        });
    }

    retrieveUserDetails = () => {
        fetch("/api/getUserProfile")
            .then((res) => {
                if (!res.ok) {
                    throw new Error(res.status);
                } else {
                    return res.json();
                }
            })
            .then((data) => this.loadResponseIntoState(data))
            .catch((error) => {
                console.log("Unable to load user profile");
            });
    };

    loadResponseIntoState (data) {
        this.setState({
            breakoutSession1Selection: data.user.breakoutSession1Selection,
            breakoutSession2Selection: data.user.breakoutSession2Selection,
            breakoutSession3Selection: data.user.breakoutSession3Selection,
            breakoutSession4Selection: data.user.breakoutSession4Selection,
            breakoutSession5Selection: data.user.breakoutSession5Selection,
            breakoutSession6Selection: data.user.breakoutSession6Selection,
        });
        this.resetDialogueStatus();
    }

    handleChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value,
        });
    };

    handleBreakoutSession1SelectionChange = (value) => {
        this.setState({
            breakoutSession1Selection: value, sessionSelectionChanged: 1,
        });
    };


    saveFormData = () => {
        // Only save if data has been change, otherwise will test against availability for a session we are already in!
        if (this.state.sessionSelectionChanged != 0) {
            // Determine which session to update
            const changedSession = {};
            switch (this.state.sessionSelectionChanged) {
                case 1:
                    changedSession["breakoutSession1Selection"] = this.state.breakoutSession1Selection;
                    break;
                case 2:
                    changedSession["breakoutSession2Selection"] = this.state.breakoutSession2Selection;
                    break;
                case 3:
                    changedSession["breakoutSession3Selection"] = this.state.breakoutSession3Selection;
                    break;
                case 4:
                    changedSession["breakoutSession4Selection"] = this.state.breakoutSession4Selection;
                    break;
                case 5:
                    changedSession["breakoutSession5Selection"] = this.state.breakoutSession5Selection;
                    break;
                case 6:
                    changedSession["breakoutSession6Selection"] = this.state.breakoutSession6Selection;
                    break;
            }
            fetch("/api/updateUserSessionBookings", {
                method: "POST",
                body: JSON.stringify(changedSession),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.status === "success") {
                        console.log("Saved successfully");
                    } else {
                        const error = new Error("Error saving session choice");
                        throw error;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    alert("This session is fully booked, please check the later time slots for availability");
                    this.closeWithoutSave();
                });
        } else { console.log("No session selection change") }
    };


    saveAndExit = () => {
        this.saveFormData();
        this.props.closeSessionBookingModal();
        this.resetDialogueStatus();
    };

    closeWithoutSave = () => {
        this.props.closeSessionBookingModal();
        this.resetDialogueStatus();
    }

    nextPage0 = () => {
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page0Active: false, page1Active: true });

        this.checkSlotAvailability(1);
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage1 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page1Active: false, page0Active: true });
        if (
            this.state.breakoutSession1Selection &&
            this.state.breakoutSession1Selection > 0
        ) {
            this.setState({ page1Complete: true });
        } else {
            this.setState({ page1Complete: false });
        }
    };

    nextPage1 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page1Active: false, page7Active: true });
        if (
            this.state.breakoutSession1Selection &&
            this.state.breakoutSession1Selection > 0
        ) {
            this.setState({ page1Complete: true });
        } else {
            this.setState({ page1Complete: false });
        }

        this.checkSlotAvailability(2);
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage2 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page2Active: false, page1Active: true });
        if (
            this.state.breakoutSession2Selection &&
            this.state.breakoutSession2Selection > 0
        ) {
            this.setState({ page2Complete: true });
        } else {
            this.setState({ page2Complete: false });
        }

        this.checkSlotAvailability(1);
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage2 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page2Active: false, page3Active: true });
        if (
            this.state.breakoutSession2Selection &&
            this.state.breakoutSession2Selection > 0
        ) {
            this.setState({ page2Complete: true });
        } else {
            this.setState({ page2Complete: false });
        }

        this.checkSlotAvailability(3);
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage3 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page3Active: false, page2Active: true });
        if (
            this.state.breakoutSession3Selection &&
            this.state.breakoutSession3Selection > 0
        ) {
            this.setState({ page3Complete: true });
        } else {
            this.setState({ page3Complete: false });
        }

        this.checkSlotAvailability(2);
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage3 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page3Active: false, page4Active: true });
        if (
            this.state.breakoutSession3Selection &&
            this.state.breakoutSession3Selection > 0
        ) {
            this.setState({ page3Complete: true });
        } else {
            this.setState({ page3Complete: false });
        }

        this.checkSlotAvailability(4);
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage4 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page4Active: false, page3Active: true });
        if (
            this.state.breakoutSession4Selection &&
            this.state.breakoutSession4Selection > 0
        ) {
            this.setState({ page4Complete: true });
        } else {
            this.setState({ page4Complete: false });
        }

        this.checkSlotAvailability(3);
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage4 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page4Active: false, page5Active: true });
        if (
            this.state.breakoutSession4Selection &&
            this.state.breakoutSession4Selection > 0
        ) {
            this.setState({ page4Complete: true });
        } else {
            this.setState({ page4Complete: false });
        }

        this.checkSlotAvailability(5);
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage5 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page5Active: false, page4Active: true });
        if (
            this.state.breakoutSession5Selection &&
            this.state.breakoutSession5Selection > 0
        ) {
            this.setState({ page5Complete: true });
        } else {
            this.setState({ page5Complete: false });
        }

        this.checkSlotAvailability(4);
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage5 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page5Active: false, page6Active: true });
        if (
            this.state.breakoutSession5Selection &&
            this.state.breakoutSession5Selection > 0
        ) {
            this.setState({ page5Complete: true });
        } else {
            this.setState({ page5Complete: false });
        }

        this.checkSlotAvailability(6);
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage6 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page6Active: false, page5Active: true });
        if (
            this.state.breakoutSession6Selection &&
            this.state.breakoutSession6Selection
        ) {
            this.setState({ page6Complete: true });
        } else {
            this.setState({ page6Complete: false });
        }

        this.checkSlotAvailability(5);
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage6 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page6Active: false, page7Active: true });
        if (
            this.state.breakoutSession6Selection &&
            this.state.breakoutSession6Selection
        ) {
            this.setState({ page6Complete: true });
        } else {
            this.setState({ page6Complete: false });
        }
    };

    previousPage7 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page7Active: false, page6Active: true });

        this.checkSlotAvailability(6);
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };



    closeAndResetProfileModal = () => {
        this.setState({ page1Complete: false });
    };

    render () {
        const StatusIcon1 = () => {
            if (this.state.page1Active) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_1_on.png"}
                        alt="Page 1 visible"
                    />
                );
            } else if (this.state.page1Complete) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 1 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_1_off.png"}
                        alt="Page 1 not visible"
                    />
                );
            }
        };

        const StatusIcon2 = () => {
            if (this.state.page2Active) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_2_on.png"}
                        alt="Page 2 visible"
                    />
                );
            } else if (this.state.page2Complete) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 2 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_2_off.png"}
                        alt="Page 2 not visible"
                    />
                );
            }
        };

        const StatusIcon3 = () => {
            if (this.state.page3Active) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_3_on.png"}
                        alt="Page 3 visible"
                    />
                );
            } else if (this.state.page3Complete) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 3 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_3_off.png"}
                        alt="Page 3 not visible"
                    />
                );
            }
        };

        const StatusIcon4 = () => {
            if (this.state.page4Active) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_4_on.png"}
                        alt="Page 4 visible"
                    />
                );
            } else if (this.state.page4Complete) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 4 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_4_off.png"}
                        alt="Page 4 not visible"
                    />
                );
            }
        };

        const StatusIcon5 = () => {
            if (this.state.page5Active) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_5_on.png"}
                        alt="Page 5 visible"
                    />
                );
            } else if (this.state.page5Complete) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 5 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_5_off.png"}
                        alt="Page 5 not visible"
                    />
                );
            }
        };

        const StatusIcon6 = () => {
            if (this.state.page6Active) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_6_on.png"}
                        alt="Page 6 visible"
                    />
                );
            } else if (this.state.page6Complete) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 6 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_6_off.png"}
                        alt="Page 4 not visible"
                    />
                );
            }
        };

        const StatusIcon7 = () => {
            if (
                this.state.page7Active &&
                this.state.page1Complete &&
                this.state.page2Complete &&
                this.state.page3Complete &&
                this.state.page4Complete &&
                this.state.page5Complete &&
                this.state.page6Complete
            ) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 7 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle_grey.png"}
                        alt="Page 7 incomplete"
                    />
                );
            }
        };

        return (
            <div>

                <Modal
                    onAfterOpen={this.retrieveUserDetails}
                    isOpen={this.props.showSessionBookingModal}
                    ariaHideApp={false}
                    contentLabel="User Profile"
                    contentRef={(contentDiv) => (this.profileScrollRef = contentDiv)}
                    overlayClassName={styles.modalOverlay}
                    className={styles.modalContent}
                    style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
                >
                    <ReactTooltip className={styles.toolTip} style={{ zIndex: 5000000000 }} html={true} id={"sessionInfo"} border={true} effect={"float"} />

                    <div className={styles.modalFlexboxColumn}>
                        <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
                            <h1 className={styles.modalPageTitle}>Select Roundtables</h1>
                        </div>

                        <div className={styles.scrollableArea} ref={(contentDiv) => (this.profileScrollRef = contentDiv)}>

                            <div className={styles.pageNavColumn}>
                                <StatusIcon1 />
                                <StatusIcon2 />
                                <StatusIcon3 />
                                {/**<StatusIcon4 />
                                <StatusIcon5 />
                                <StatusIcon6 />
                                <StatusIcon7 />*/}
                            </div>

                            <div className={styles.sessionSelectionArea}>
                                <br />
                                {/*Page 0 */}
                                {this.state.page0Active ? (
                                    <React.Fragment>
                                        <p className={styles.smallSubheading} >
                                            Choose which Roundtables you would like to attend
                                        </p>
                                        <br /><br />
                                        <div className={styles.buttonArea}>
                                            <Button
                                                onClick={this.nextPage0}
                                                className={`${"btn btn-success"} ${styles.bottomRightButtons}`}
                                            >
                                                Next
                                            </Button>
                                            <Button
                                                onClick={this.closeWithoutSave}
                                                className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                                            >
                                                Close
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                ) : null}

                                {/*Page 1 */}
                                {this.state.page1Active ? (
                                    <div className={styles.pageContentPanel}>
                                        <p className={styles.subheading}>Event Date</p>

                                        <br />
                                        <UserSessionBookingOptions1
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession1SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession1Selection}
                                        />
                                        <br />
                                        <br />
                                        <br />
                                        <div className={styles.buttonArea}>
                                            <Button
                                                onClick={this.nextPage1}
                                                className={`${"btn btn-success"} ${styles.bottomRightButtons}`}
                                            >
                                                Next
                                            </Button>

                                            <Button
                                                onClick={this.saveAndExit}
                                                className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                                            >
                                                Save & exit
                                            </Button>
                                            <Button
                                                onClick={this.closeWithoutSave}
                                                className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                                            >
                                                Close without saving
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}



                                {/*Page 7*/}
                                {this.state.page7Active ? (
                                    <div className={styles.pageContentPanel}>
                                        <p className={styles.smallSubheading}>Roundtable selection complete</p>
                                        <br /><br /><br />
                                        <div className={styles.buttonArea}>
                                            <Button
                                                onClick={this.saveAndExit}
                                                className={`${"btn btn-success"} ${styles.bottomRightButtons}`}
                                            >
                                                Exit
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}