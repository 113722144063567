import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import styles from "./LobbyMobile.module.scss";

export default class PostersMobile extends Component {

  render () {
    return (
      <div className={styles.mobileListView} >
        <div className={styles.mobileTitleArea}>
          <h1><Trans>nav_lobby</Trans></h1>
        </div>

        <h3 className={styles.mobileLobbyInstructionText}>{/**Select from the following:*/}</h3>

        <div className={styles.lobbyIconGrid}>

          <div
            className={styles.lobbyIconGridElement}
            onClick={() => this.props.setShowAgendaModal(true)}>
            <img
              className={styles.lobbyIcon}
              src={window.$videoPath + "mobileAssets/lobbyIcons/agenda.png"}
              alt="LobbyIcons"
            ></img>
            <h4 className={styles.lobbyIconText}><Trans>nav_agenda</Trans></h4>
          </div>

          <Link to="/plenary">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/plenary.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}><Trans>shared_plenary</Trans></h4>
            </div>
          </Link>

          <Link to="/breakouts">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/breakouts.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}><Trans>shared_breakouts</Trans></h4>
            </div>
          </Link>

          <Link to="/presentersGallery">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/speakers.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}><Trans>nav_speakers</Trans></h4>
            </div>
          </Link>

          <Link to="/gallery">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/attendees.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}><Trans>nav_attendees</Trans></h4>
            </div>
          </Link>

          <Link to="/networking">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/networking.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}><Trans>shared_networking_lounge</Trans></h4>
            </div>
          </Link>

          <Link to="/exhibition">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/exhibition.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}><Trans>shared_exhibition</Trans></h4>
            </div>
          </Link>

          <Link to="/jobSearch">
            <div
              className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/greenJobs.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}><Trans>nav_green_jobs</Trans></h4>
            </div>
          </Link>

          <Link to="/onDemand">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/onDemand.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}><Trans>nav_gyecc_tv</Trans></h4>
            </div>
          </Link>

          <Link to="/meetings">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/meetings.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}><Trans>nav_my_meetings</Trans></h4>
            </div>
          </Link>

          <Link to="/posters">
            <div className={styles.lobbyIconGridElement}>
              <img
                className={styles.lobbyIcon}
                src={window.$videoPath + "mobileAssets/lobbyIcons/posters.png"}
                alt="LobbyIcons"
              ></img>
              <h4 className={styles.lobbyIconText}><Trans>shared_poster_gallery</Trans></h4>
            </div>
          </Link>

          <div
            className={styles.lobbyIconGridElement}
            onClick={() => this.props.setShowProfileModal(true)}>
            <img
              className={styles.lobbyIcon}
              src={window.$videoPath + "mobileAssets/lobbyIcons/profile.png"}
              alt="LobbyIcons"
            ></img>
            <h4 className={styles.lobbyIconText}><Trans>shared_my_profile</Trans></h4>
          </div>

          <div
            className={styles.lobbyIconGridElement}
            onClick={() => this.props.setShowSessionSelectionModal(true)}>
            <img
              className={styles.lobbyIcon}
              src={window.$videoPath + "mobileAssets/lobbyIcons/plan.png"}
              alt="LobbyIcons"
            ></img>
            <h4 className={styles.lobbyIconText}><Trans>my_day_plan_my_day</Trans></h4>
          </div>

          <div
            className={styles.lobbyIconGridElement}
            onClick={() => this.props.setShowLeaderboardModal(true)}>
            <img
              className={styles.lobbyIcon}
              src={window.$videoPath + "mobileAssets/lobbyIcons/leaderboard.png"}
              alt="LobbyIcons"
            ></img>
            <h4 className={styles.lobbyIconText}><Trans>leaderboard_title</Trans></h4>
          </div>

          <div
            className={styles.lobbyIconGridElement}
            onClick={() => this.props.setShowHelpModal(true)}>
            <img
              className={styles.lobbyIcon}
              src={window.$videoPath + "mobileAssets/lobbyIcons/help.png"}
              alt="LobbyIcons"
            ></img>
            <h4 className={styles.lobbyIconText}><Trans>shared_help</Trans></h4>
          </div>

        </div>
      </div>
    );
  }
}
