import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import dompurify from "dompurify";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { Image, Transformation } from "cloudinary-react";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./JobSearch.module.scss";

class JobSearch extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      galleryContent: [],
      searchString: "",

      // Show job detail
      showJobModal: false,
      selectedJob: null,

      // Search modal control
      showAdvancedSearchDiv: false,

      // Search parameters
      locationRegion: [],
      contractType: [],
      contractLength: [],
      seniorityLevel: [],
      salaryBracket: [],
    };
  }

  componentDidMount () {
    // Hide corner menu
    this.props.setCornerMenuStatus(3);
    // Load database content
    this.loadCurrentDatabaseContent();
    // On load, set default search settings
    this.resetAdvancedSearch();
  }

  componentWillUnmount () {
    // Set corner menu to displayed again
    this.props.setCornerMenuStatus(1);
  }

  componentDidUpdate () {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 3) {
      this.props.setCornerMenuStatus(3);
    }
  }

  loadCurrentDatabaseContent () {
    fetch("/api/getJobSearch")
      .then((res) => res.json())
      .then((data) => this.setState({ galleryContent: data }));
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleLocationRegionChange = (value) => {
    this.setState({
      locationRegion: value,
    });
  };


  handleContractTypeChange = (value) => {
    this.setState({
      contractType: value,
    });
  };

  handleContractLengthChange = (value) => {
    this.setState({
      contractLength: value,
    });
  };

  handleSeniorityLevelChange = (value) => {
    this.setState({
      seniorityLevel: value,
    });
  };

  handleSalaryBracketChange = (value) => {
    this.setState({
      salaryBracket: value,
    });
  };

  resetAdvancedSearch = () => {
    this.setState({ locationRegion: ["jobs_apac", "jobs_europe", "jobs_south_america", "jobs_uk", "jobs_us_canada", "shared_other"], contractType: ["jobs_full_time", "jobs_part_time"], contractLength: ["jobs_permanent", "jobs_contract", "jobs_temporary", "shared_internship", "shared_other"], seniorityLevel: ["shared_internship", "jobs_entry_level", "jobs_associate", "jobs_mid_senior", "jobs_director", "jobs_executive", "shared_other"], salaryBracket: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], });
  }

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  handleCloseJobModal = () => {
    this.setState({ showJobModal: false });
  };

  viewJob = (job) => {
    this.setState({
      selectedJob: job,
      showJobModal: true,
    });
    requestPoints("viewJob", job._id);
  }

  render () {
    const jobSearchGalleryFiltered = this.state.galleryContent.filter(
      (jobSearch) =>
        (jobSearch.company
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
          jobSearch.jobTitle
            .toLowerCase()
            .includes(this.state.searchString.toLowerCase()) ||
          jobSearch.jobDetail
            .toLowerCase()
            .includes(this.state.searchString.toLowerCase())) &&
        this.state.locationRegion.includes(jobSearch.locationRegion) &&
        this.state.contractType.includes(jobSearch.contractType) &&
        this.state.contractLength.includes(jobSearch.contractLength) &&
        this.state.seniorityLevel.includes(jobSearch.seniorityLevel) &&
        this.state.salaryBracket.includes(jobSearch.salaryBracket)

    );

    const jobSearchGallery = jobSearchGalleryFiltered.map((jobSearch) => {
      return (

        <div
          onClick={() => this.viewJob(jobSearch)}
          key={jobSearch._id}
          className={styles.jobContainer}
        >
          <Image
            className={styles.companyLogo}
            cloudName="oijqec973f1nf"
            publicId={jobSearch.companyLogoResourceID}
            secure={true}
          >
            <Transformation
              height="100"
              width="300"
              crop="pad"
            />
          </Image>

          <div>
            <div className={styles.jobNameContainer}>
              <p className={styles.standNameText}>
                <strong>{jobSearch.company}</strong>
              </p>
              <p className={styles.jobSummaryText}>
                {jobSearch.jobTitle}
              </p>
            </div>
            <div className={styles.visitStandButton}><Trans>posters_view</Trans></div>
          </div>


        </div>
      );
    });

    return (
      <div>

        {this.state.showAdvancedSearchDiv ? (
          <div className={styles.advancedSearchBox}>
            <div className={styles.advancedSearchTitleBar}>
              <h2><Trans>jobs_advanced_job_search</Trans><button
                className={styles.advancedSearchModalClose}
                variant="success"
                onClick={() => this.setState({ showAdvancedSearchDiv: false })}
              >
                <img
                  className={styles.closeIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button></h2>

            </div>
            <div className={styles.searchArea}>
              <h4><Trans>jobs_location_region</Trans></h4>
              <ToggleButtonGroup
                type="checkbox"
                value={this.state.locationRegion}
                name="locationRegion"
                onChange={this.handleLocationRegionChange}
                size="sm"
                className={styles.multiRowButtonGroup}
              >
                <ToggleButton value={"jobs_apac"}><Trans>jobs_apac</Trans></ToggleButton>
                <ToggleButton value={"jobs_europe"}><Trans>jobs_europe</Trans></ToggleButton>
                <ToggleButton value={"jobs_south_america"}><Trans>jobs_south_america</Trans></ToggleButton>
                <ToggleButton value={"jobs_uk"}><Trans>jobs_uk</Trans></ToggleButton>
                <ToggleButton value={"jobs_us_canada"}><Trans>jobs_us_canada</Trans></ToggleButton>
                <ToggleButton value={"shared_other"}><Trans>shared_other</Trans></ToggleButton>
              </ToggleButtonGroup>
              <br /> <br />

              <h4><Trans>jobs_contract_type</Trans></h4>
              <ToggleButtonGroup
                type="checkbox"
                value={this.state.contractType}
                name="contractType"
                onChange={this.handleContractTypeChange}
                size="sm"
                className={styles.multiRowButtonGroup}
              >
                <ToggleButton value={"jobs_full_time"}><Trans>jobs_full_time</Trans></ToggleButton>
                {/**<ToggleButton value={"jobs_part_time"}><Trans>jobs_part_time</Trans></ToggleButton>*/}
              </ToggleButtonGroup>


              <br /><br />
              <h4><Trans>jobs_contract_length</Trans></h4>
              <ToggleButtonGroup
                type="checkbox"
                value={this.state.contractLength}
                name="contractType"
                onChange={this.handleContractLengthChange}
                size="sm"
                className={styles.multiRowButtonGroup}
              >
                <ToggleButton value={"jobs_permanent"}><Trans>jobs_permanent</Trans></ToggleButton>
                <ToggleButton value={"shared_internship"}><Trans>shared_internship</Trans></ToggleButton>
                {/**<ToggleButton value={"jobs_contract"}><Trans>jobs_contract</Trans></ToggleButton>
                <ToggleButton value={"jobs_temporary"}><Trans>jobs_temporary</Trans></ToggleButton>
                <ToggleButton value={"shared_other"}><Trans>shared_other</Trans></ToggleButton>*/}
              </ToggleButtonGroup>
              <br /> <br />

              <h4><Trans>jobs_seniority_level</Trans></h4>
              <ToggleButtonGroup
                type="checkbox"
                value={this.state.seniorityLevel}
                name="seniorityLevel"
                onChange={this.handleSeniorityLevelChange}
                size="sm"
                className={styles.multiRowButtonGroup}
              >
                <ToggleButton value={"shared_internship"}><Trans>shared_internship</Trans></ToggleButton>
                <ToggleButton value={"jobs_entry_level"}><Trans>jobs_entry_level</Trans></ToggleButton>
                <ToggleButton value={"jobs_associate"}><Trans>jobs_associate</Trans></ToggleButton>
                <ToggleButton value={"jobs_mid_senior"}><Trans>jobs_mid_senior</Trans></ToggleButton>
                <ToggleButton value={"jobs_director"}><Trans>jobs_director</Trans></ToggleButton>
                {/**<ToggleButton value={"jobs_executive"}><Trans>jobs_executive</Trans></ToggleButton>*/}
                <ToggleButton value={"shared_other"}><Trans>shared_other</Trans></ToggleButton>
              </ToggleButtonGroup>

              <br />
              <h4><Trans>jobs_salary_bracket</Trans></h4>
              <ToggleButtonGroup
                type="checkbox"
                value={this.state.salaryBracket}
                name="salary"
                onChange={this.handleSalaryBracketChange}
                size="sm"
                className={styles.multiRowButtonGroup}
              >
                <ToggleButton value={2}><Trans>jobs_at_least_two</Trans></ToggleButton>
                <ToggleButton value={3}><Trans>jobs_at_least_three</Trans></ToggleButton>
                <ToggleButton value={4}><Trans>jobs_at_least_four</Trans></ToggleButton>
                <ToggleButton value={5}><Trans>jobs_at_least_five</Trans></ToggleButton>
                <ToggleButton value={6}><Trans>jobs_at_least_six</Trans></ToggleButton>
                <ToggleButton value={7}><Trans>jobs_at_least_seven</Trans></ToggleButton>
                <ToggleButton value={8}><Trans>jobs_at_least_eight</Trans></ToggleButton>
              </ToggleButtonGroup>



              <br />

              <Button className="btn btn-success float-right" onClick={() => this.setState({ showAdvancedSearchDiv: false })}><Trans>jobs_apply</Trans></Button>
              <Button className="btn btn-primary float-right" onClick={() => this.resetAdvancedSearch()}><Trans>jobs_reset</Trans></Button>
              <br /><br />
            </div>
          </div>
        ) : null
        }

        <Modal
          isOpen={this.state.showJobModal}
          ariaHideApp={false}
          onRequestClose={this.handleCloseJobModal}
          contentLabel="Job"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
        >
          {this.state.selectedJob ? (
            <div className={styles.modalFlexboxColumn}>
              <h1 className={styles.titleArea}>
                {this.state.selectedJob &&
                  this.state.selectedJob.company}
                <Button
                  className={styles.modalClose}
                  variant="success"
                  onClick={this.handleCloseJobModal}
                >
                  <Trans>shared_close</Trans>
                </Button>
              </h1>
              <hr />

              <div className={styles.scrollableArea}>
                <div className={styles.columnContainer}>
                  <div className={styles.leftProfileColumn}>


                    {this.state.selectedJob &&
                      this.state.selectedJob.companyLogoResourceID ? (
                      <div>
                        <Image
                          cloudName="oijqec973f1nf"
                          publicId={
                            this.state.selectedJob.companyLogoResourceID
                          }
                          width="100%"
                          secure={true}
                        >
                          <Transformation
                            height="100"
                            width="300"
                            crop="pad"
                            gravity="west"
                          />
                        </Image>
                      </div>
                    ) : null}
                    <br />

                    {this.state.selectedJob.jobTitle &&
                      this.state.selectedJob.jobTitle.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/job_title_icon.png"}
                          alt="Job title"
                        />
                        <p className={styles.profileParameterTitles}><Trans>shared_job_title</Trans>:</p>
                        <p className={styles.profileParameterAnswer}>
                          {this.state.selectedJob.jobTitle}
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.selectedJob.company &&
                      this.state.selectedJob.company.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/expertise.png"}
                          alt="Location"
                        />
                        <p className={styles.profileParameterTitles}>
                          <Trans>presenters_organisation</Trans>:
                        </p>
                        <p className={styles.profileParameterAnswer}>
                          {this.state.selectedJob.company}
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.selectedJob.location &&
                      this.state.selectedJob.location.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/office_location.png"}
                          alt="Location"
                        />
                        <p className={styles.profileParameterTitles}>
                          <Trans>jobs_location_specific</Trans>:
                        </p>
                        <p className={styles.profileParameterAnswer}>
                          {this.state.selectedJob.location}
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.selectedJob.seniorityLevel &&
                      this.state.selectedJob.seniorityLevel.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/superpower.png"}
                          alt="Seniority"
                        />
                        <p className={styles.profileParameterTitles}>
                          <Trans>jobs_seniority_level</Trans>
                        </p>
                        <p className={styles.profileParameterAnswer}>
                          <Trans>{this.state.selectedJob.seniorityLevel}</Trans>
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.selectedJob.salary &&
                      this.state.selectedJob.salary.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/previously_worked.png"}
                          alt="Salary"
                        />
                        <p className={styles.profileParameterTitles}>
                          <Trans>jobs_salary_actual</Trans>
                        </p>
                        <p dangerouslySetInnerHTML={this.sanitizeCustomField(this.state.selectedJob.salary)} className={styles.profileParameterAnswer}>
                        </p>
                      </span>
                    ) : null}
                  </div>

                  <div className={styles.rightProfileColumn}>
                    {this.state.selectedJob.jobDetail &&
                      this.state.selectedJob.jobDetail.length > 1 ? (
                      <span>
                        <p className={styles.noIconProfileParameterAnswer} dangerouslySetInnerHTML={this.sanitizeCustomField(this.state.selectedJob.jobDetail)}>
                        </p>
                        <br />
                      </span>
                    ) : null}


                    {this.state.selectedJob.url &&
                      this.state.selectedJob.url.length > 1 ? (
                      <span>
                        <p className={styles.profileParameterTitles}><Trans>jobs_url</Trans>:</p>
                        <p className={styles.noIconProfileParameterAnswer}>
                          <a
                            href={this.state.selectedJob.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {this.state.selectedJob.url}
                          </a>
                          <br />
                        </p>
                      </span>
                    ) : null}



                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Modal>

        <div className={styles.outerBackgroundDiv}>
          {/**<img
            className="imageNavBackground"
            src={window.$videoPath + "background.png"}
            alt="Background"
          ></img>*/}
        </div>

        <div className={styles.hotlinkBar}>
          <Link to={"/lobby"} className={styles.backLink}>
            <div className={styles.backDiv}>
              <img
                className={styles.backArrow}
                src={window.$videoPath + "icons/backArrow.png"}
                alt="Back"
              />
              <Trans>attendees_back_to_eco_park</Trans>
            </div>
          </Link>
          <Link to={"/exhibition"} className={styles.forwardLink}>
            <div className={styles.forwardDiv}>
              <img
                className={styles.forwardArrow}
                src={window.$videoPath + "icons/forwardArrow.png"}
                alt="Back"
              />
              <Trans>shared_exhibition</Trans>
            </div>
          </Link>
        </div>

        <div className={styles.headerBar}>
          <h1 className={styles.pageTitle}>
            <strong><Trans>nav_green_jobs</Trans></strong>
          </h1>
          <div className={styles.searchTitleArea}>
            <p style={{ display: "inline" }}></p>
          </div>
          <input
            className={styles.searchBox}
            type="text"
            name="searchString"
            placeholder={this.props.t('shared_search')}
            value={this.state.searchString}
            onChange={this.handleChange}
          />
        </div>
        <div className={styles.advancedSearchButtonBar}>
          <Button className={styles.advancedSearchButton} onClick={() => this.setState({ showAdvancedSearchDiv: true })}><Trans>shared_advanced_search</Trans></Button><br />
        </div>

        <section className={styles.profileSegmentsArea}>{jobSearchGallery}</section>
      </div>
    );
  }
}

export default withTranslation()(JobSearch);