import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import VideoPlayer from "./../modules/videoPlayer/VideoPlayer"
import PlenaryMobile from "./PlenaryMobile";
import WidgetContainer from "./../modules/widgetContainer/WidgetContainer";
import { requestPoints } from "./../helpers/requestPoints.js";
import styles from "./Plenary.module.scss";

class Plenary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoStateForwards: true,
      previousPlenaryOpenStatus: "closed",
      showContent: false,
      muxSigningToken: "",
      muxPlayerURL: "",
      slidoEventID: "h9ipqour",
      interprefyEventID: "widgetdemo",
    };
    this.plenaryVideo = React.createRef();
    this.reversePlenaryVideo = React.createRef();
    this.plenaryVideoPlayer = React.createRef();
  }

  // Check open status on entry to area
  componentDidMount () {
    requestPoints("visitPlenary", "visitPlenary");

    if (this.props.plenaryOpenStatus === "open") {
      this.viewPresentation();
    } else {
      alert(this.props.t('notification_plenary_closed'));
    }
  }

  componentWillUnmount () {
    // Set corner menu to displayed again
    this.props.setCornerMenuStatus(1);
  }

  // Action zoom or unzoom if already in plenary and status changes
  componentDidUpdate (prevProps, prevState) {
    if (
      this.props.plenaryOpenStatus === "open" &&
      this.props.plenaryOpenStatus !== this.state.previousPlenaryOpenStatus
    ) {
      this.setState({
        previousPlenaryOpenStatus: this.props.plenaryOpenStatus,
      });
      this.viewPresentation();
    }
    if (
      this.props.plenaryOpenStatus === "closed" &&
      this.props.plenaryOpenStatus !== this.state.previousPlenaryOpenStatus
    ) {
      this.setState({
        previousPlenaryOpenStatus: this.props.plenaryOpenStatus,
      });
      this.leavePresentation();
    }
  }

  viewPresentation = () => {
    // Check video and play function are valid before issuing play command in case on mobile where not valid
    if (this.plenaryVideo && this.plenaryVideo.play) {
      this.plenaryVideo.play();
    }
    // Set corner menu to hidden
    this.props.setCornerMenuStatus(3);
    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.forwardsVideoEnded();
      }.bind(this),
      6000
    );
  };

  leavePresentation = () => {
    this.setState({
      showContent: false,
    });
    if (this.reversePlenaryVideo.play) {
      this.reversePlenaryVideo.play();
    }
    // Set timeout in case video does not load/finish
    setTimeout(
      function () {
        this.reverseVideoEnded();
      }.bind(this),
      6000
    );
  };

  forwardsVideoEnded = () => {
    this.setState({ videoStateForwards: false, showContent: true });
    // Repeat call to hide corner menu in case we transitioned from breakout and it tried to show it again on unmount!
    this.props.setCornerMenuStatus(3);
  };

  reverseVideoEnded = () => {
    if (this.plenaryVideo) {
      this.plenaryVideo.currentTime = 0;
    }
    this.setState({ videoStateForwards: true });
  };

  render () {
    // Set position preset of content window
    /**const iFrameDiv =
      this.props.plenaryPosition === 1
        ? styles.iFramePosition1
        : this.props.plenaryPosition === 2
        ? styles.iFramePosition2
        : styles.iFramePosition0; */
    //
    return (
      <div>
        {this.props.showMobile ?
          <PlenaryMobile
            videoContent={this.props.plenaryContent}
            slidoEventID={this.state.slidoEventID}
            interprefyEventID={this.state.interprefyEventID}
            userProfile={this.props.userProfile}
          />
          :
          <div className={styles.masterPageDiv}>
            <video
              className="imageNavBackground"
              id="introVideo"
              webkit-playsinline="true"
              playsInline
              loop={false}
              muted
              ref={(plenaryVideo) => (this.plenaryVideo = plenaryVideo)}
              onEnded={() => this.forwardsVideoEnded()}
            >
              <source src={window.$videoPath + "visuals/" + this.props.language + "/plenary.mp4"} type="video/mp4" />
              <source src={window.$videoPath + "visuals/" + this.props.language + "/plenary.webm"} type="video/webm" />
            </video>

            <CSSTransition
              in={!this.state.videoStateForwards}
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <video
                className="imageNavBackground"
                id="introVideoReverse"
                webkit-playsinline="true"
                playsInline
                muted
                loop={false}
                ref={(reversePlenaryVideo) =>
                  (this.reversePlenaryVideo = reversePlenaryVideo)
                }
                onEnded={() => this.reverseVideoEnded()}
              >
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/plenaryReverse.mp4"}
                  type="video/mp4"
                />
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/plenaryReverse.webm"}
                  type="video/webm"
                />
              </video>
            </CSSTransition>

            {
              // Change button areas based on zoomed in or out state
              this.state.videoStateForwards ? (
                <div>
                  <div
                    className={styles.goToPresentation}
                    onClick={this.viewPresentation}
                  ></div>
                  <Link to="/breakouts">
                    <img
                      className={`${styles.hoverHotspot} ${styles.breakoutsHoverHotspot}`}
                      src="/hoverShapes/hoverSquare.png"
                      alt="To breakouts"
                    ></img>
                  </Link>
                  <Link to="/visualOnDemand">
                    <img
                      className={`${styles.hoverHotspot} ${styles.onDemandHoverHotspot}`}
                      src="/hoverShapes/hoverSquare.png"
                      alt="On Demand"
                    ></img>
                  </Link>
                </div>
              ) : (
                <Link to={"/lobby"}>
                  <div
                    className={styles.leavePresentation}
                  /**onClick={this.leavePresentation}*/
                  ></div>
                </Link>
              )
              // End of button areas
            }

            <CSSTransition
              in={
                !this.state.videoStateForwards &&
                this.state.showContent
              }
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <img
                className="imageNavBackground"
                src={window.$videoPath + "visuals/" + this.props.language + "/plenary.jpg"}
                alt="Background"
              />
            </CSSTransition>

            <CSSTransition
              in={
                this.props.plenaryContent != null &&
                this.props.plenaryContent.length > 5 &&
                !this.state.videoStateForwards &&
                this.state.showContent
              }
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <div className={styles.iFrameDiv}>
                <VideoPlayer
                  resourceLocator={this.props.plenaryContent}
                  location={"plenary"}
                  userProfile={this.props.userProfile}
                  autoPlay={true}
                />
              </div>
            </CSSTransition>

            <CSSTransition
              in={
                !this.state.videoStateForwards &&
                this.state.showContent
              }
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <div className={styles.widgetContainerDiv}>
                <WidgetContainer
                  slidoEventID={this.state.slidoEventID}
                  interprefyEventID={this.state.interprefyEventID}
                  userProfile={this.props.userProfile}
                  simTransAvailable={true}
                />
              </div>
            </CSSTransition>
          </div>
        }
      </div>
    );
  }
}

export default withTranslation()(Plenary);