import React, { Component } from "react";
import ReactTooltip from 'react-tooltip';
import { Image, Video, Transformation } from "cloudinary-react";
import { Trans, withTranslation } from 'react-i18next';
import TimezoneSelect from 'react-timezone-select'
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import styles from "./UserProfile.module.scss";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      surname: "",
      jobTitle: "",
      country: "",
      company: "",
      areasOfInterest: "",
      email: "",

      userProfilePictureID: null,
      fileInputDisabled: false,

      userLanguage: "en",
      userTimezone: "Europe/London",
      userAgeBracket: 2,

      instagram: "",
      linkedIn: "",
      facebook: "",
      twitter: "",
      tiktok: "",
      weibo: "",
      snapchat: "",
      qq: "",
      qzone: "",

      page1Active: true,
      page1Complete: false,
      page2Active: false,
      page2Complete: false,
      page3Active: false,
      page3Complete: false,
      page4Active: false,
      page4Complete: false,
      page5Active: false,
    };

    this.profileScrollRef = React.createRef();
  }

  componentDidMount () {
    this.retrieveUserDetails();
  }

  retrieveUserDetails = () => {
    fetch("/api/getUserProfile")
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.status);
        } else {
          return res.json();
        }
      })
      .then((data) => this.loadResponseIntoState(data))
      .catch((error) => {
        console.log("Unable to load user profile");
      });
  };

  loadResponseIntoState (data) {
    this.setState({
      firstName: data.user.firstName,
      surname: data.user.surname,
      jobTitle: data.user.jobTitle,
      country: data.user.country,
      company: data.user.company,
      areasOfInterest: data.user.areasOfInterest,
      email: data.user.email,
      userProfilePictureID: data.user.userProfilePictureID,
      userLanguage: data.user.userLanguage,
      userTimezone: data.user.userTimezone,
      userAgeBracket: data.user.userAgeBracket,

      instagram: data.user.instagram,
      linkedIn: data.user.linkedIn,
      facebook: data.user.facebook,
      twitter: data.user.twitter,
      tiktok: data.user.tiktok,
      weibo: data.user.weibo,
      snapchat: data.user.snapchat,
      qq: data.user.qq,
      qzone: data.user.qzone,

      // Reset dialogue status too
      page1Active: true,
      page1Complete: false,
      page2Active: false,
      page2Complete: false,
      page3Active: false,
      page3Complete: false,
      page4Active: false,
      page4Complete: false,
      page5Active: false,
    });
  }

  handleProfilePictureUpload = (event) => {
    this.setState({ fileInputDisabled: true });
    const data = new FormData();
    data.append("file", event.target.files[0]);

    fetch("/api/uploadImage", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          userProfilePictureID: data.publicID,
        });
        console.log("Returned upload ID: " + this.state.userProfilePictureID);
        this.setState({ fileInputDisabled: false });
      });
  };

  handleProfileVideoUpload = (event) => {
    this.setState({ fileInputDisabled: true });
    const data = new FormData();
    data.append("file", event.target.files[0]);

    fetch("/api/uploadVideo", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          userProfileVideoID: data.publicID,
        });
        console.log("Returned upload ID: " + this.state.userProfilePictureID);
        this.setState({ fileInputDisabled: false });
      });
  };


  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleLanguageChange = (event) => {
    console.log("Language value " + event.target.value)
    this.props.setLanguage(event.target.value);
  }

  saveFormData = () => {
    fetch("/api/updateUserProfile", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log("Saved successfully");
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Error updating profile -  please try again");
      });
  };

  nextPage1 = () => {
    if (this.state.email && this.state.email.length > 0) {
      this.saveFormData();
      this.profileScrollRef.scrollTop = 0;
      this.setState({ page1Active: false, page2Active: true });
      if (
        this.state.firstName.length > 0 &&
        this.state.surname.length > 0 &&
        this.state.jobTitle.length > 0 &&
        this.state.country.length > 0 &&
        this.state.company.length > 0 &&
        this.state.areasOfInterest.length > 0
      ) {
        this.setState({ page1Complete: true });
      } else {
        this.setState({ page1Complete: false });
      }
    } else {
      alert("Email cannot be blank")
    }
  };

  nextPage2 = () => {
    this.saveFormData();
    this.profileScrollRef.scrollTop = 0;
    this.setState({ page2Active: false, page3Active: true });
    if (
      this.state.userProfilePictureID &&
      this.state.userProfilePictureID.length > 0 //&&
      //this.state.userProfileVideoID &&
      //this.state.userProfileVideoID.length > 0
    ) {
      this.setState({ page2Complete: true });
    } else {
      this.setState({ page2Complete: false });
    }
  };

  closeAndResetProfileModal = () => {
    this.setState({ page1Complete: false });
  };

  render () {
    const StatusIcon1 = () => {
      if (this.state.page1Active) {
        return (
          <img
            className={styles.statusIcon}
            src={window.$videoPath + "icons/profile/page_1_on.png"}
            alt="Page 1 visible"
          />
        );
      } else if (this.state.page1Complete) {
        return (
          <img
            className={styles.statusIcon}
            src={window.$videoPath + "icons/profile/tick_circle.png"}
            alt="Page 1 complete"
          />
        );
      } else {
        return (
          <img
            className={styles.statusIcon}
            src={window.$videoPath + "icons/profile/page_1_off.png"}
            alt="Page 1 not visible"
          />
        );
      }
    };

    const StatusIcon2 = () => {
      if (this.state.page2Active) {
        return (
          <img
            className={styles.statusIcon}
            src={window.$videoPath + "icons/profile/page_2_on.png"}
            alt="Page 2 visible"
          />
        );
      } else if (this.state.page2Complete) {
        return (
          <img
            className={styles.statusIcon}
            src={window.$videoPath + "icons/profile/tick_circle.png"}
            alt="Page 2 complete"
          />
        );
      } else {
        return (
          <img
            className={styles.statusIcon}
            src={window.$videoPath + "icons/profile/page_2_off.png"}
            alt="Page 2 not visible"
          />
        );
      }
    };

    const StatusIcon3 = () => {
      if (
        this.state.page3Active &&
        this.state.page1Complete &&
        this.state.page2Complete
      ) {
        return (
          <img
            className={styles.statusIcon}
            src={window.$videoPath + "icons/profile/tick_circle.png"}
            alt="All complete"
          />
        );
      } else {
        return (
          <img
            className={styles.statusIcon}
            src={window.$videoPath + "icons/profile/tick_circle_grey.png"}
            alt="All incomplete"
          />
        );
      }
    };

    return (
      <Modal
        onAfterOpen={this.retrieveUserDetails}
        isOpen={this.props.showProfileModal}
        ariaHideApp={false}
        contentLabel="User Profile"
        //contentRef={(contentDiv) => (this.profileScrollRef = contentDiv)}
        overlayClassName={styles.modalOverlay}
        className={styles.modalContent}
        style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
      >
        <div className={styles.modalFlexboxColumn}>
          <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
            <h1><Trans>shared_my_profile</Trans></h1>
          </div>

          <div className={styles.scrollableArea} ref={(contentDiv) => (this.profileScrollRef = contentDiv)}>

            {/**<div className={styles.pageNavColumn}>
              <StatusIcon1 />
              <StatusIcon2 />
              <StatusIcon3 />
            </div>*/}
            <div className={styles.profileEntryArea}>
              <br />
              {/*Page 1 */}
              {this.state.page1Active ? (
                <div>
                  <p className={styles.subheading} style={{ paddingLeft: "5px" }}><Trans>profile_your_information</Trans></p>
                  <div className={styles.gridContainer}>

                    <div className={styles.gridElement}>
                      <label htmlFor="firstName" className={styles.formLabel}>
                        <Trans>register_first_name</Trans>:
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={styles.gridElement}>
                      <label htmlFor="surname" className={styles.formLabel}>
                        <Trans>register_surname</Trans>:
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="surname"
                        placeholder="Surname"
                        value={this.state.surname}
                        onChange={this.handleChange}
                      />
                    </div>

                    {/**<div className={styles.gridElement}>
                      <label htmlFor="jobTitle" className={styles.formLabel}>
                        Job title:
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="jobTitle"
                        placeholder="Job title"
                        value={this.state.jobTitle}
                        onChange={this.handleChange}
                      />
                    </div>*/}

                    <div className={styles.gridElement}>
                      <label htmlFor="company" className={styles.formLabel}>
                        <Trans>presenters_organisation</Trans>:
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="company"
                        placeholder="Company/Affiliation"
                        value={this.state.company}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={styles.gridElement}>
                      <label htmlFor="areasOfInterest" className={styles.formLabel}>
                        <Trans>shared_attendee_type</Trans>:
                      </label>
                      <select
                        className={`${styles.formField} ${styles.dropDownEntry}`}
                        id="areasOfInterest"
                        name="areasOfInterest"
                        placeholder={this.props.t('shared_attendee_type')}
                        tabIndex="70"
                        value={this.state.areasOfInterest}
                        onChange={this.handleChange}
                      >
                        <option value="delegate">{this.props.t('shared_delegate')}</option>
                        <option value="exhibitor">{this.props.t('shared_exhibitor_partner')}</option>
                        <option value="speaker">{this.props.t('shared_speaker')}</option>
                      </select>
                    </div>

                    {/**<div className={styles.gridElement}>
                      <label htmlFor="areasOfInterest" className={styles.formLabel}>
                        Areas of interest:
                      </label>
                      <input
                        className={`${styles.longFormField} ${styles.textEntry}`}
                        type="text"
                        name="areasOfInterest"
                        placeholder="Areas of Interest"
                        value={this.state.areasOfInterest}
                        onChange={this.handleChange}
                      />
                    </div>*/}


                    <div className={styles.gridElement}>
                      <label htmlFor="country" className={styles.formLabel}>
                        <Trans>attendees_country</Trans>:
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="country"
                        placeholder="Country"
                        value={this.state.country}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={styles.gridElement}>
                      <label htmlFor="email" className={styles.formLabel}>
                        <Trans>register_email</Trans>:
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntryDisabled}`}
                        type="email"
                        name="email"
                        disabled={true}
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={styles.gridElement}>
                      <label htmlFor="userLanguage" className={styles.formLabel}>
                        <Trans>register_preferred_language</Trans>:
                      </label>
                      <select
                        className={`${styles.formField} ${styles.dropDownEntry}`}
                        id="userLanguage"
                        name="userLanguage"
                        placeholder="Preferred Language"
                        tabIndex="90"
                        value={this.state.userLanguage}
                        onChange={this.handleChange}
                      >
                        <option value="en">{this.props.t('shared_english')}</option>
                        <option value="es">{this.props.t('shared_spanish')}</option>
                        <option value="fr">{this.props.t('shared_french')}</option>
                        <option value="zh">{this.props.t('shared_mandarin')}</option>
                      </select>
                    </div>

                    <div className={styles.gridElement}>
                      <label htmlFor="userAgeBracket" className={styles.formLabel}>
                        <Trans>register_age</Trans>:
                      </label>
                      <select
                        className={`${styles.formField} ${styles.dropDownEntry}`}
                        id="userAgeBracket"
                        name="userAgeBracket"
                        placeholder="Age"
                        tabIndex="90"
                        disabled={true}
                        value={this.state.userAgeBracket}
                      >
                        <option value="1">16-17</option>
                        <option value="2">18-24</option>
                        <option value="3">25-30</option>
                        <option value="4">31-35</option>
                        <option value="5">36+</option>
                      </select>
                    </div>

                    {/** Wait for timezone to load before rendering*/}
                    {this.state.userTimezone.value ? <div className={styles.gridElement}>
                      <label htmlFor="userTimezone" className={styles.formLabel}>
                        <Trans>register_timezone</Trans>:
                      </label>
                      <TimezoneSelect
                        id="userTimezone"
                        value={this.state.userTimezone.value}
                        onChange={(data) => this.setState({ userTimezone: data })} />
                    </div> : null}


                  </div>


                  <div className={styles.buttonArea}>
                    <Button
                      onClick={this.nextPage1}
                      className={`${"btn btn-secondary"} ${styles.bottomRightButtons}`}
                    >
                      <Trans>shared_next</Trans>
                    </Button>
                    <Button
                      onClick={this.props.closeProfileModal}
                      className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                    >
                      <Trans>my_day_close_without_saving</Trans>
                    </Button>
                  </div>
                </div>
              ) : null}

              {/*Page 2 */}
              {this.state.page2Active ? (
                <div>
                  <Image
                    cloudName="oijqec973f1nf"
                    publicId={this.state.userProfilePictureID}
                    width="150"
                    secure={true}
                    style={{ float: "right" }}
                  >
                    <Transformation
                      height="150"
                      width="150"
                      crop="fill"
                      radius="20"
                    />
                  </Image>
                  <p className={styles.subheading}>
                    <Trans>profile_profile_picture</Trans>
                  </p>

                  <label
                    className={styles.formLabel}
                    htmlFor="profilePicture"
                  >
                    <Trans>profile_picture_upload</Trans>
                  </label>
                  <label className={styles.customFileUpload}>
                    <img
                      className={styles.uploadButton}
                      src={window.$videoPath + "icons/profile/upload.png"}
                      alt="Upload"
                    ></img>
                    <input
                      className={styles.fileInput}
                      name="profilePicture"
                      type="file"
                      disabled={this.state.fileInputDisabled}
                      accept="image/*"
                      onChange={this.handleProfilePictureUpload}
                    />
                  </label>

                  <div className={styles.profileVideoSelection}>
                    <label className={styles.formLabel} htmlFor="profileVideo">
                      Video upload
                    </label>
                    <p className={styles.introText}>
                      Please upload a video (up to 30 secs) telling people a bit
                      about yourself.
                      <br />
                      <br />
                      Feel free to be as creative as you like with this!
                      MP4/WMV/MOV video files are all good
                    </p>
                    <label className={styles.customFileUpload}>
                      <img
                        className={styles.uploadButton}
                        src={window.$videoPath + "icons/profile/upload.png"}
                        alt="Upload"
                      ></img>
                      <input
                        className={styles.fileInput}
                        name="profileVideo"
                        type="file"
                        disabled={this.state.fileInputDisabled}
                        accept="video/*"
                        onChange={this.handleProfileVideoUpload}
                      />
                    </label>
                    {this.state.userProfileVideoID != null ? (
                      <div>
                        <Video
                          controls={true}
                          cloudName="oijqec973f1nf"
                          publicId={this.state.userProfileVideoID}
                          width="200"
                        ></Video>
                      </div>
                    ) : null}
                  </div>
                  <br />


                  <p className={styles.subheading}><Trans>profile_social_media</Trans></p>
                  <div className={styles.gridContainer}>

                    <ReactTooltip className={styles.toolTip} style={{ zIndex: 5000000000 }} html={true} id={"socialMedia"} border={true} effect={"float"} />

                    <div className={styles.gridElement}
                      data-tip={"<h6><strong>" + this.props.t('profile_instagram') + "</strong></h6><br />"}
                      data-for={"socialMedia"}
                    >
                      <label htmlFor="instagram" className={styles.formLabel}>
                        <img src={window.$videoPath + "icons/socialMediaIcons/instagram.png"} className={styles.socialMediaIcon}

                        />
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="instagram"
                        placeholder="Instagram"
                        tabIndex="130"
                        value={this.state.instagram}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={styles.gridElement}
                      data-tip={"<h6><strong>" + this.props.t('profile_personalised_url') + "</strong></h6><br />"}
                      data-for={"socialMedia"}
                    >
                      <label htmlFor="linkedIn" className={styles.formLabel}>
                        <img src={window.$videoPath + "icons/socialMediaIcons/linkedin.png"} className={styles.socialMediaIcon} />
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="linkedIn"
                        placeholder="LinkedIn"
                        tabIndex="140"
                        value={this.state.linkedIn}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={styles.gridElement}
                      data-tip={"<h6><strong>" + this.props.t('profile_facebook') + "</strong></h6><br />"}
                      data-for={"socialMedia"}
                    >
                      <label htmlFor="facebook" className={styles.formLabel}>
                        <img src={window.$videoPath + "icons/socialMediaIcons/facebook.png"} className={styles.socialMediaIcon} />
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="facebook"
                        placeholder="Facebook"
                        tabIndex="150"
                        value={this.state.facebook}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={styles.gridElement}
                      data-tip={"<h6><strong>" + this.props.t('profile_twitter') + "</strong></h6><br />"}
                      data-for={"socialMedia"}
                    >
                      <label htmlFor="twitter" className={styles.formLabel}>
                        <img src={window.$videoPath + "icons/socialMediaIcons/twitter.png"} className={styles.socialMediaIcon} />
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="twitter"
                        placeholder="Twitter"
                        tabIndex="150"
                        value={this.state.twitter}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={styles.gridElement}
                      data-tip={"<h6><strong>" + this.props.t('profile_tiktok') + "</strong></h6><br />"}
                      data-for={"socialMedia"}
                    >
                      <label htmlFor="tiktok" className={styles.formLabel}>
                        <img src={window.$videoPath + "icons/socialMediaIcons/tiktok.png"} className={styles.socialMediaIcon} />
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="tiktok"
                        placeholder="TikTok"
                        tabIndex="160"
                        value={this.state.tiktok}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={styles.gridElement}
                      data-tip={"<h6><strong>" + this.props.t('profile_weibo') + "</strong></h6><br />"}
                      data-for={"socialMedia"}
                    >
                      <label htmlFor="weibo" className={styles.formLabel}>
                        <img src={window.$videoPath + "icons/socialMediaIcons/Weibo.png"} className={styles.socialMediaIcon} />
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="weibo"
                        placeholder="Weibo"
                        tabIndex="170"
                        value={this.state.weibo}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={styles.gridElement}
                      data-tip={"<h6><strong>" + this.props.t('profile_url') + "</strong></h6><br />"}
                      data-for={"socialMedia"}
                    >
                      <label htmlFor="snapchat" className={styles.formLabel}>
                        <img src={window.$videoPath + "icons/socialMediaIcons/snapchat.png"} className={styles.socialMediaIcon} />
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="snapchat"
                        placeholder="Snapchat"
                        tabIndex="180"
                        value={this.state.snapchat}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={styles.gridElement}
                      data-tip={"<h6><strong>" + this.props.t('profile_url') + "</strong></h6><br />"}
                      data-for={"socialMedia"}
                    >
                      <label htmlFor="qq" className={styles.formLabel}>
                        <img src={window.$videoPath + "icons/socialMediaIcons/qq.png"} className={styles.socialMediaIcon} />
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="qq"
                        placeholder="QQ"
                        tabIndex="190"
                        value={this.state.qq}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={styles.gridElement}
                      data-tip={"<h6><strong>" + this.props.t('profile_personalised_url') + "</strong></h6><br />"}
                      data-for={"socialMedia"}
                    >
                      <label htmlFor="qzone" className={styles.formLabel}>
                        <img src={window.$videoPath + "icons/socialMediaIcons/qzone-logo.png"} className={styles.socialMediaIcon} />
                      </label>
                      <input
                        className={`${styles.formField} ${styles.textEntry}`}
                        type="text"
                        name="qzone"
                        placeholder="Qzone"
                        tabIndex="200"
                        value={this.state.qzone}
                        onChange={this.handleChange}
                      />
                    </div>


                  </div>


                  <br />
                  <div className={styles.buttonArea}>
                    <Button
                      onClick={this.nextPage2}
                      disabled={this.state.fileInputDisabled}
                      className={`${"btn btn-secondary"} ${styles.bottomRightButtons}`}
                    >
                      <Trans>shared_next</Trans>
                    </Button>
                    <Button
                      onClick={this.props.closeProfileModal}
                      className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                    >
                      <Trans>my_day_close_without_saving</Trans>
                    </Button>
                  </div>
                </div>
              ) : null}

              {/*Page 3*/}
              {this.state.page3Active ? (
                <div>
                  <p className={styles.subheading}></p>

                  <img
                    className={styles.bigTick}
                    src={window.$videoPath + "icons/profile/big_tick.png"}
                    alt="Profile complete"
                  ></img>

                  <p className={styles.subheading}>
                    <Trans>profile_many_thanks</Trans>
                  </p>
                  <div className={styles.buttonArea}>
                    <Button
                      disabled={this.state.fileInputDisabled}
                      className={`${"btn btn-secondary"} ${styles.bottomRightButtons}`}
                      onClick={this.props.closeProfileModal}
                    >
                      <Trans>shared_submit</Trans>
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

export default withTranslation()(UserProfile);