import React, { Component } from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Trans } from 'react-i18next';
import NavDropdown from "react-bootstrap/NavDropdown";
import UserProfile from "./../userAccount/UserProfile";
import UserSessions from "./../userAccount/UserSessions";
import UserSessionBooking from "./../userAccount/UserSessionBooking";
import { Image, Transformation } from "cloudinary-react";
import CornerMenu from "./../cornerMenu/CornerMenu";
import styles from "./TopMenu.module.scss";

export default class TopMenu extends Component {

  // Keep function here as we do want two actions so as to reload user profile changes throughout site
  handleCloseProfileModal = () => {
    this.props.setShowProfileModal(false);
    this.props.reloadUserProfile();
  };

  handleCloseSessionSelectionModal = () => {
    this.props.setShowSessionSelectionModal(false);
    this.props.reloadUserProfile();
  };

  handleCloseSessionBookingModal = () => {
    this.props.setShowSessionBookingModal(false);
    this.props.reloadUserProfile();
  };

  openFeedbackSurvey = () => {
    window.open("https://www.surveymonkey.co.uk/r/GYECCpostevent", "_blank", "noopener");
  };

  render () {
    const navBarVisibilityControl =
      this.props.topMenuStatus !== 0
        ? styles.styledNavBarDiv
        : styles.styledNavBarDivHidden;
    return (
      <div>
        <UserProfile
          showProfileModal={this.props.showProfileModal}
          closeProfileModal={this.handleCloseProfileModal}
          setLanguage={this.props.setLanguage}
        />
        <UserSessions
          userProfile={this.props.userProfile}
          showSessionSelectionModal={this.props.showSessionSelectionModal}
          closeSessionSelectionModal={this.handleCloseSessionSelectionModal}
          showMobile={this.props.showMobile}
        />
        <UserSessionBooking
          showSessionBookingModal={this.props.showSessionBookingModal}
          closeSessionBookingModal={this.handleCloseSessionBookingModal}
        />
        <nav>
          {/* Display Corner Menu for logged in users */}
          {this.props.topMenuStatus !== 0 &&
            !window.location.href.includes("login") &&
            !window.location.href.includes("ssoLogin") &&
            !window.location.href.includes("userList") &&
            !window.location.href.includes("analytics") &&
            !window.location.href.includes("exhibitionAdminList") &&
            !window.location.href.includes("editExhibitionStand") &&
            !window.location.href.includes("liveStreamAdminList") &&
            !window.location.href.includes("viewLiveStream") &&
            !window.location.href.includes("createLiveStream") &&
            !window.location.href.includes("holding") &&
            !window.location.href.includes("zoomBreakout") &&
            !window.location.href.includes("register") ? (
            <CornerMenu
              userProfile={this.props.userProfile}
              reloadUserProfile={this.props.reloadUserProfile}
              setShowSessionSelectionModal={this.props.setShowSessionSelectionModal}
              showAgendaModal={this.props.showAgendaModal}
              setShowAgendaModal={this.props.setShowAgendaModal}
              setShowProfileModal={this.props.setShowProfileModal}
              showHelpModal={this.props.showHelpModal}
              setShowHelpModal={this.props.setShowHelpModal}
              showLeaderboardModal={this.props.showLeaderboardModal}
              setShowLeaderboardModal={this.props.setShowLeaderboardModal}
              cornerMenuStatus={this.props.cornerMenuStatus}
              language={this.props.language}
            />
          ) : null}
          {!window.location.href.includes("login") &&
            !window.location.href.includes("ssoLogin") &&
            !window.location.href.includes("holding") &&
            !window.location.href.includes("zoomBreakout") &&
            !window.location.href.includes("register") ? (
            <div className={navBarVisibilityControl}>
              <Navbar expand="xl" className={styles.styledNavbar}>
                <Link to="/lobby" disabled={this.props.topMenuStatus === 2}>
                  <img
                    className={styles.navBarTopLeftIcon}
                    src={window.$videoPath + "logoFromDark.png"}
                    alt="Logo"
                  />
                </Link>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className={styles.navBarToggle}
                />
                <Navbar.Collapse
                  id="basic-navbar-nav"
                  className={styles.navBarCollapse}
                >
                  <Nav className={styles.styledDropdown}>
                    <NavDropdown
                      className={styles.styledDropdownTitle}
                      title={<Trans>nav_navigate</Trans>}
                    >
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/plenary"
                      >
                        <Trans>shared_plenary</Trans>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/breakouts"
                      >
                        <Trans>shared_breakouts</Trans>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/networking"
                      >
                        <Trans>shared_networking_lounge</Trans>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/exhibitionIntro"
                      >
                        <Trans>shared_exhibition</Trans>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/posters"
                      >
                        <Trans>shared_poster_gallery</Trans>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/visualOnDemand"
                      >
                        <Trans>nav_gyecc_tv</Trans>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/lobby"
                      >
                        <Trans>nav_lobby</Trans>
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link
                      className={styles.styledNavItem}
                      disabled={this.props.topMenuStatus === 2}
                      as={Link}
                      to="/jobSearch"
                    >
                      <Trans>nav_green_jobs</Trans>
                    </Nav.Link>
                    <NavDropdown
                      className={styles.styledDropdownTitle}
                      title={<Trans>nav_get_connected</Trans>}
                    >
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/gallery"
                      >
                        <Trans>nav_attendees</Trans>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/networking"
                      >
                        <Trans>shared_networking_lounge</Trans>
                      </NavDropdown.Item>
                    </NavDropdown>

                    <Nav.Link
                      className={styles.styledNavItem}
                      disabled={this.props.topMenuStatus === 2}
                      as={Link}
                      to="/presentersGallery"
                    >
                      <Trans>nav_speakers</Trans>
                    </Nav.Link>

                    <NavDropdown
                      className={styles.styledDropdownTitle}
                      title={<Trans>my_day_plan_my_day</Trans>}
                    >

                      {/**<NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        onClick={() => this.props.setShowProfileModal(true)}
                      >
                        MY PROFILE
                      </NavDropdown.Item>*/}
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        onClick={() => this.props.setShowSessionSelectionModal(true)}
                      >
                        <Trans>nav_session_selection</Trans>
                      </NavDropdown.Item>
                      {this.props.userProfile && this.props.userProfile.userAgeBracket > 1 ?
                        <NavDropdown.Item
                          className={styles.styledDropdownNavItem}
                          disabled={this.props.topMenuStatus === 2}
                          as={Link}
                          to="/meetings"
                        >
                          <Trans>nav_my_meetings</Trans>
                        </NavDropdown.Item>
                        : null}
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        onClick={() => this.props.setShowAgendaModal(true)}
                        to="/meetings"
                      >
                        <Trans>nav_conference_agenda</Trans>
                      </NavDropdown.Item>
                      {/**<NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        onClick={() => this.props.setShowSessionBookingModal(true)}
                      >
                        MY ROUNDTABLE SELECTIONS
                      </NavDropdown.Item>*/}
                    </NavDropdown>
                    {/**<Nav.Link
                      className={styles.styledNavItem}
                      onClick={() => this.props.setShowAgendaModal(true)}
                    >
                      <Trans>nav_conference_agenda</Trans>
                    </Nav.Link>*/}
                    <Nav.Link
                      className={styles.styledNavItem}
                      disabled={this.props.topMenuStatus === 2}
                      as={Link}
                      to="/visualEventRewind"
                    >
                      <Trans>nav_on_demand</Trans>
                    </Nav.Link>
                    <Nav.Link
                      className={styles.styledNavItem}
                      disabled={this.props.topMenuStatus === 2}
                      onClick={this.openFeedbackSurvey}
                    >
                      <Trans>Feedback survey</Trans>
                    </Nav.Link>
                    <Nav.Link
                      className={styles.styledNavItem}
                      onClick={() => this.props.setShowHelpModal(true)}
                    >
                      <Trans>shared_help</Trans>
                    </Nav.Link>
                    {this.props.adminStatus ? (
                      <Nav.Link
                        className={styles.styledNavItem}
                        as={Link}
                        to="/adminMenu"
                      >
                        ADMIN MENU
                      </Nav.Link>
                    ) : null}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div className={styles.userProfileArea}>
                {this.props.userProfile &&
                  this.props.userProfile.userProfilePictureID ? (
                  <span
                    onClick={() => this.props.setShowProfileModal(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <Image
                      alt="User Profile Image"
                      className={styles.userProfilePicture}
                      cloudName="oijqec973f1nf"
                      publicId={
                        this.props.userProfile.userProfilePictureID + ".png"
                      }
                      width="50"
                      secure={true}
                    >
                      <Transformation
                        height="50"
                        width="50"
                        gravity="face"
                        radius="max"
                        crop="fill"
                      />
                    </Image>
                  </span>
                ) : null}
                <p className={styles.userName}>
                  {/**this.props.userProfile &&
                    this.props.userProfile.firstName.length < 12 ? (
                    <span>{this.props.userProfile.firstName + " (" + this.props.language + ")"}</span>
                  ) : null*/}
                  <span
                    onClick={() => this.props.setShowProfileModal(true)}
                    style={{ cursor: "pointer" }}
                  ><Trans>shared_my_profile</Trans></span>
                  <br />
                  <Link className={styles.logout} to="/logout">
                    <Trans>nav_log_out</Trans>
                  </Link>
                  {/**<Link className={styles.logout} to="/logout">
                      Se Déconnecter
                    </Link>*/}
                </p>
              </div>
            </div>
          ) : null
          }
        </nav>
      </div >
    );
  }
}
