import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import dompurify from "dompurify";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { dateFormatterTimeAndDay } from "../helpers/dateFormatter.js"
import Modal from "react-modal";
import { Image, Transformation } from "cloudinary-react";
import { requestPoints } from "./../helpers/requestPoints.js";
import styles from "./Gallery.module.scss";

class PresentersGallery extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      galleryContent: [],
      searchString: "",
      showMeetingRequestModal: false,
      meetingRequesteeUser: null,
      meetingRequesteeUserMeetings: [],
      meetingNote: "",
      meetingSlots: [],
      selectedMeetingSlot: null,
      existingUserMeetings: [],
    };
  }

  componentDidMount () {
    // Hide corner menu
    this.props.setCornerMenuStatus(3);

    this.loadCurrentDatabaseContent();

    // Scroll back to top
    window.scrollTo(0, 0);
  }

  componentWillUnmount () {
    // Set corner menu to displayed again
    this.props.setCornerMenuStatus(1);
  }

  componentDidUpdate () {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 3) {
      console.log(this.props.cornerMenuStatus);
      this.props.setCornerMenuStatus(3);
    }
  }

  loadCurrentDatabaseContent () {
    fetch("/api/getPresenterGalleryContent")
      .then((res) => res.json())
      .then((data) => this.setState({ galleryContent: data }));
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleMeetingRequestClose = () => {
    this.loadCurrentDatabaseContent();
    this.setState({ showMeetingRequestModal: false });
  };

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  // Called when user clicks request meeting button on another's profile
  viewPresenter = (user) => {
    this.loadCurrentDatabaseContent();
    this.setState({
      meetingRequesteeUser: user,
      showMeetingRequestModal: true,
    });
    requestPoints("viewPresenter", user._id);
  };

  lookupTalkLocation = (talkLocation) => {
    switch (talkLocation) {
      case "/plenary":
        return (
          <Link to="plenary"><Trans>shared_plenary</Trans></Link>
        )
      case "/breakout/6086abd759b00d4eefcc69b1":
        return (
          <Link to="/breakout/6086abd759b00d4eefcc69b1"><Trans>nav_workstream_one</Trans></Link>
        )
      case "/breakout/6086abd759b00d4eefcc69b2":
        return (
          <Link to="/breakout/6086abd759b00d4eefcc69b2"><Trans>nav_workstream_two</Trans></Link>
        )
      case "/breakout/6086abd759b00d4eefcc69b3":
        return (
          <Link to="/breakout/6086abd759b00d4eefcc69b3"><Trans>nav_workstream_three</Trans></Link>
        )
      case "/breakout/6086abd759b00d4eefcc69b4":
        return (
          <Link to="/breakout/6086abd759b00d4eefcc69b4"><Trans>nav_workstream_four</Trans></Link>
        )
      case "/roundtableSession/6121613d9f34e80ad6f9cccb":
        return (
          <Link to="/roundtableSession/6121613d9f34e80ad6f9cccb"><Trans>networking_tables_title</Trans></Link>
        )
      case "/roundtableSession/6121613d9f34e80ad6f9cccc":
        return (
          <Link to="/roundtableSession/6121613d9f34e80ad6f9cccc"><Trans>networking_tables_title</Trans></Link>
        )
      default:
        return (null)
    }
  }

  render () {
    const userGalleryFiltered = this.state.galleryContent.filter(
      (user) =>
        user.firstName
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.surname
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.company
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.bio
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.website
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.talkTitle
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.talkAbstract
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase())
    );

    const userGallery = userGalleryFiltered.map((user) => {
      return (
        <div
          key={user._id}
          className={styles.userContainer}
          onClick={() => this.viewPresenter(user)}
        >
          <Image
            cloudName="oijqec973f1nf"
            publicId={user.userProfilePictureID}
            width="170"
            height="200"
            secure={true}
          >
            <Transformation
              height="200"
              width="170"
              gravity="face"
              crop="fill"
            />
          </Image>
          {user.presenterType === 101 ? (
            <div>
              <div className={styles.userNameContainer}>
                <p className={styles.userNameText}>
                  {user.firstName + " " + user.surname}
                  <br />
                  <span style={{ color: "#1a81b7" }}>Speaker</span>
                </p>
              </div>
              <img
                className={styles.detailButton}
                src={window.$videoPath + "icons/detail.png"}
                alt="Detail"
              />
            </div>
          ) : null}
          {user.presenterType > 0 ? (
            <div>
              <div className={styles.userNameContainer}>
                <p className={styles.userNameText}>
                  {user.firstName + " " + user.surname}
                  <br />
                  <span style={{ color: "#009245" }}>{user.company}</span>
                </p>
              </div>
              <img
                className={styles.detailButton}
                src={window.$videoPath + "icons/detail.png"}
                alt="Detail"
              />
            </div>
          ) : null}
          {user.presenterType === 103 ? (
            <div>
              <div className={styles.userNameContainer}>
                <p className={styles.userNameText}>
                  {user.firstName + " " + user.surname}
                  <br />
                  <span style={{ color: "#f74500" }}>Panel Speaker</span>
                </p>
              </div>
              <img
                className={styles.detailButton}
                src="/icons/detail-red.png"
                alt="Detail"
              />
            </div>
          ) : null}
        </div>
      );
    });

    return (
      <div>
        <Modal
          isOpen={this.state.showMeetingRequestModal}
          ariaHideApp={false}
          onRequestClose={this.handleMeetingRequestModal}
          contentLabel="Presenter Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
        >
          {this.state.meetingRequesteeUser ? (
            <div className={styles.modalFlexboxColumn}>
              <h1 className={styles.titleArea}>
                {this.state.meetingRequesteeUser &&
                  this.state.meetingRequesteeUser.firstName}  {this.state.meetingRequesteeUser &&
                    this.state.meetingRequesteeUser.surname}
                <Button
                  className={styles.modalClose}
                  variant="success"
                  onClick={this.handleMeetingRequestClose}
                >
                  <Trans>shared_close</Trans>
                </Button>
              </h1>
              <hr />
              <div className={styles.scrollableArea}>
                <div className={styles.columnContainer}>
                  <div className={styles.leftProfileColumn}>
                    {this.state.meetingRequesteeUser &&
                      this.state.meetingRequesteeUser.userProfilePictureID ? (
                      <div>
                        <Image
                          cloudName="oijqec973f1nf"
                          publicId={
                            this.state.meetingRequesteeUser.userProfilePictureID
                          }
                          width="240"
                          height="300"
                          secure={true}
                        >
                          <Transformation
                            height="300"
                            width="240"
                            gravity="face"
                            crop="fill"
                          />
                        </Image>
                      </div>
                    ) : null}

                    <br />

                    {this.state.meetingRequesteeUser.jobTitle &&
                      this.state.meetingRequesteeUser.jobTitle.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/job_title_icon.png"}
                          alt="Job title"
                        />
                        <p className={styles.profileParameterTitles}><Trans>shared_job_title</Trans>:</p>
                        <p dangerouslySetInnerHTML={this.sanitizeCustomField(this.state.meetingRequesteeUser.jobTitle)} className={styles.profileParameterAnswer}>
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.company &&
                      this.state.meetingRequesteeUser.company.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/office_location.png"}
                          alt="Location"
                        />
                        <p className={styles.profileParameterTitles}>
                          <Trans>presenters_organisation</Trans>:
                        </p>
                        <p dangerouslySetInnerHTML={this.sanitizeCustomField(this.state.meetingRequesteeUser.company)} className={styles.profileParameterAnswer}>
                        </p>
                      </span>
                    ) : null}

                  </div>

                  <div className={styles.rightProfileColumn}>
                    {this.state.meetingRequesteeUser.bio &&
                      this.state.meetingRequesteeUser.bio.length > 1 ? (
                      <span>
                        <p className={styles.profileParameterTitles}>
                          <Trans>shared_about</Trans> {this.state.meetingRequesteeUser.firstName + " " + this.state.meetingRequesteeUser.surname}
                        </p>
                        <p dangerouslySetInnerHTML={this.sanitizeCustomField(this.state.meetingRequesteeUser.bio)} className={styles.noIconProfileParameterAnswer}>
                        </p>

                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.website &&
                      this.state.meetingRequesteeUser.website.length > 1 ? (
                      <span>
                        <p className={styles.profileParameterTitles}><Trans>jobs_url</Trans>:</p>
                        <p className={styles.noIconProfileParameterAnswer}>
                          <a
                            href={this.state.meetingRequesteeUser.website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {this.state.meetingRequesteeUser.website}
                          </a>
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.talkTitle &&
                      this.state.meetingRequesteeUser.talkTitle.length > 1 ? (
                      <span>
                        <p className={styles.profileParameterTitles}><Trans>speakers_session_name</Trans>:</p>
                        <p dangerouslySetInnerHTML={this.sanitizeCustomField(this.state.meetingRequesteeUser.talkTitle)} className={styles.noIconProfileParameterAnswer}>
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.talkTime &&
                      this.state.meetingRequesteeUser.talkTime.length > 1 ? (
                      <span>
                        <p className={styles.profileParameterTitles}><Trans>speakers_session_time</Trans>:</p>
                        <p dangerouslySetInnerHTML={this.sanitizeCustomField(this.state.meetingRequesteeUser.talkTime)} className={styles.noIconProfileParameterAnswer} className={styles.noIconProfileParameterAnswer}>
                          {/**dateFormatterTimeAndDay(this.state.meetingRequesteeUser.talkTime, this.props.userProfile)*/}
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.talkLocation &&
                      this.state.meetingRequesteeUser.talkLocation.length > 1 ? (
                      <span>
                        <p className={styles.profileParameterTitles}><Trans>speakers_session_location</Trans>:</p>
                        <p className={styles.noIconProfileParameterAnswer}>
                          {this.lookupTalkLocation(this.state.meetingRequesteeUser.talkLocation)}
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.talkAbstract &&
                      this.state.meetingRequesteeUser.talkAbstract.length > 1 ? (
                      <span>
                        <p className={styles.profileParameterTitles}>
                          <Trans>speakers_session_description</Trans>:
                        </p>
                        <p dangerouslySetInnerHTML={this.sanitizeCustomField(this.state.meetingRequesteeUser.talkAbstract)} className={styles.noIconProfileParameterAnswer}>
                        </p>
                      </span>
                    ) : null}
                  </div>
                </div>
                <br />
                <br />
              </div>
            </div>
          ) : null}
        </Modal>

        <div className={styles.outerBackgroundDiv}>
          {/**<img
            className="imageNavBackground"
            src={window.$videoPath + "background.png"}
            alt="Background"
          ></img>*/}
        </div>

        <div className={styles.hotlinkBar}>
          <Link to={"/lobby"} className={styles.backLink}>
            <div className={styles.backDiv}>
              <img
                className={styles.backArrow}
                src={window.$videoPath + "icons/backArrow.png"}
                alt="Back"
              />
              <Trans>attendees_back_to_eco_park</Trans>
            </div>
          </Link>
        </div>

        <div className={styles.headerBar}>
          <h1 className={styles.pageTitle}>
            <strong><Trans>nav_speakers</Trans></strong>
          </h1>
          <div className={styles.searchTitleArea}>
            <p><Trans>speakers_info_text</Trans></p>
          </div>
          <input
            className={styles.searchBox}
            type="text"
            name="searchString"
            placeholder={this.props.t('shared_search')}
            value={this.state.searchString}
            onChange={this.handleChange}
          />
        </div>

        <section className={styles.profileSegmentsArea}>{userGallery}</section>
      </div>
    );
  }
}

export default withTranslation()(PresentersGallery);