export const requestPoints = (action, reference) => {
  //alert("Requesting " + action + " points for " + reference);

  // Submit action for points
  fetch("/api/notifyUserActivity", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      eventCode: action,
      resourceID: action + ":" + reference,
    }),
  })
    .then((result) => result.text())
    .then((response) => {
      console.log(
        "Submitted " + action + " points for " + reference + " - " + response
      );
    });
};
