import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Trans, withTranslation } from 'react-i18next';
import styles from "./ExhibitionStandQuestions.module.scss";

class ExhibitionStandQuestions extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      newQuestionText: "",
      exhibitionStandUnansweredQuestions: [],
      exhibitionStandAnsweredQuestions: [],
    };
    this.unansweredScrollRef = React.createRef();
  }

  componentDidMount () {
    this.loadQuestions();
  }

  loadQuestions () {
    console.log("Selected exhibition stand: " + this.props.exhibitionStandID);

    // Fetch unanswered
    fetch(
      "/api/getExhibitionStandUnansweredQuestions?exhibitionStandID=" +
      this.props.exhibitionStandID
    )
      // Need to somehow request by received prop ID
      .then((res) => res.json())
      .then((data) => this.loadUnansweredIntoState(data));

    // Fetch answered
    fetch(
      "/api/getExhibitionStandAnsweredQuestions?exhibitionStandID=" +
      this.props.exhibitionStandID
    )
      // Need to somehow request by received prop ID
      .then((res) => res.json())
      .then((data) => this.loadAnsweredIntoState(data));
  }

  loadUnansweredIntoState (data) {
    this.setState({
      exhibitionStandUnansweredQuestions: data,
    });
  }

  loadAnsweredIntoState (data) {
    this.setState({
      exhibitionStandAnsweredQuestions: data,
    });
  }

  handleChange = (event) => {
    console.log("Change");
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  submitNewQuestion = () => {
    if (this.state.newQuestionText && this.state.newQuestionText.length > 0) {
      fetch("/api/submitExhibitionStandQuestion", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          exhibitionStandID: this.props.exhibitionStandID,
          questionText: this.state.newQuestionText,
        }),
      })
        .then((result) => result.text())
        .then((response) => {
          console.log("Submitted question request");
        });
      this.setState({ newQuestionText: "" });
      setTimeout(
        function () {
          this.loadQuestions();
        }.bind(this),
        2000
      );
      this.unansweredScrollRef.current.scrollTop = this.unansweredScrollRef.current.scrollHeight;
    } else {
      alert(this.props.t('exhibition_submit_question'));
    }
  };

  render () {
    const unansweredQuestions = this.state.exhibitionStandUnansweredQuestions.map(
      (question) => {
        return (
          <h3 className={styles.questionText} key={question._id}>
            {question.questionText}
          </h3>
        );
      }
    );

    const answeredQuestions = this.state.exhibitionStandAnsweredQuestions.map(
      (question) => {
        return (
          <div key={question._id}>
            <h3 className={styles.questionText}>{question.questionText}</h3>
            <h3 className={styles.answerText}>{question.answerText}</h3>
            <br />
            <br />
          </div>
        );
      }
    );

    return (

      <div className={styles.columnContainer}>
        <div className={styles.formColumn}>
          <div className={styles.answeredDiv}>
            <div className={styles.questionHeader}>
              <h2 className={styles.questionHeaderTitle}><Trans>shared_questions_answered</Trans></h2>
            </div>
            <div className={styles.answerScrollArea}>{answeredQuestions}</div>
          </div>
        </div>
        <div className={styles.formColumn}>
          <div className={styles.unansweredDiv}>
            <div className={styles.questionHeader}>
              <h2 className={styles.questionHeaderTitle}>
                <Trans>shared_questions_to_be_answered</Trans>
              </h2>
            </div>
            <div
              className={styles.answerScrollArea}
              ref={this.unansweredScrollRef}
            >
              {unansweredQuestions}
            </div>
          </div>
          <div className={styles.enterQuestionDiv}>
            <div className={styles.questionHeader}>
              <h2 className={styles.questionHeaderTitle}><Trans>shared_submit_a_question</Trans></h2>
            </div>
            <h4 className={styles.openingTimes}>
              Q & A boards are no longer being monitored for this event
            </h4>
            <input
              className={styles.newQuestion}
              type="text"
              name="newQuestionText"
              placeholder={this.props.t('shared_submit_a_question')}
              value={this.state.newQuestionText}
              onChange={this.handleChange}
            />
            <Button
              className={styles.submitButton}
              onClick={this.submitNewQuestion}
            >
              <Trans>shared_submit</Trans>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ExhibitionStandQuestions);