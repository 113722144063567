import React, { Component } from "react";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import styles from "./UserList.module.scss";

export default class UserList extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      userList: [],
    };
  }

  componentDidMount () {
    fetch("/api/userList")
      //.then((res) => res.text())
      .then((res) => res.json())
      .then((data) =>
        this.setState({
          userList: data,
        })
      );
  }

  setUserApprovalStatus (userID, userApproved) {
    fetch("/api/updateUserApprovalStatus", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userID: userID, userApproved: userApproved }),
    })
      .then((result) => result.text())
      .then((response) => {
        console.log("Set user approval status for user ID" + userID);
        this.componentDidMount();
      });
  }

  setUserEmailConfirmedStatus (userID, userEmailConfirmed) {
    fetch("/api/updateUserEmailConfirmedStatus", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userID: userID,
        userEmailConfirmed: userEmailConfirmed,
      }),
    })
      .then((result) => result.text())
      .then((response) => {
        console.log("Set email confirmedstatus for user ID" + userID);
        this.componentDidMount();
      });
  }

  setUserProfileVideoApprovalStatus (userID, userProfileVideoApproved) {
    fetch("/api/updateUserProfileVideoApprovalStatus", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userID: userID,
        userProfileVideoApproved: userProfileVideoApproved,
      }),
    })
      .then((result) => result.text())
      .then((response) => {
        console.log(
          "Set user profile video approval status for user ID" + userID
        );
        this.componentDidMount();
      });
  }

  render () {
    const userList = this.state.userList.map((user) => {
      return (
        <tr key={user._id}>
          <td>{user.firstName}</td>
          <td>{user.surname}</td>
          <td>{user.email}</td>
          <td>{user.jobTitle}</td>
          <td>{user.company}</td>
          <td>{user.country}</td>
          <td>{user.areasOfInterest}</td>
          <td>{user.createdAt}</td>
          <td>
            {user.userApproved ? (
              <Button
                variant="success"
                onClick={() => this.setUserApprovalStatus(user._id, false)}
              >
                Change to Disapproved
              </Button>
            ) : (
              <Button
                variant="danger"
                onClick={() => this.setUserApprovalStatus(user._id, true)}
              >
                Change to Approved
              </Button>
            )}
          </td>
          <td>
            {user.userEmailConfirmed ? (
              <Button
                variant="success"
                onClick={() =>
                  this.setUserEmailConfirmedStatus(user._id, false)
                }
              >
                Change to Unconfirmed
              </Button>
            ) : (
              <Button
                variant="danger"
                onClick={() => this.setUserEmailConfirmedStatus(user._id, true)}
              >
                Change to Confirmed
              </Button>
            )}
          </td>
        </tr>
      );
    });

    return (
      <div className={styles.background}>
        <br />
        <h2>User List</h2>
        <Table striped bordered hover className="userTable">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Surname</th>
              <th>Email</th>
              <th>Job Title</th>
              <th>Company</th>
              <th>Country</th>
              <th>Time Selection</th>
              <th>Registration Date/Time</th>
              <th>Approval Status</th>
              <th>Email Confirmed</th>
            </tr>
          </thead>
          <tbody>{userList}</tbody>
        </Table>
      </div>
    );
  }
}
