import React from "react";
import Button from "react-bootstrap/Button";
import { Trans, withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./NetworkingMobile.module.scss";

export default function Breakouts (props) {

  return (
    <div className={styles.mobileListView} >
      <div className={styles.mobileTitleArea}>
        <h1 className={styles.mobileTitleText}><Trans>shared_networking_lounge</Trans></h1>
        <Link to="/lobby"><Button><Trans>shared_back</Trans></Button></Link>
      </div>

      <Link to="/gallery">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}><Trans>nav_attendees</Trans></h3>
        </div>
      </Link>
      {/**<Link to="/visualRoundtables">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}><Trans>networking_tables_title</Trans></h3>
        </div>
      </Link>*/}

      <br /><br />
      <h2 style={{ color: "black", marginLeft: "3%" }}>Zoom in to view the pledges that were submitted during GYECC21</h2>
      <br />
      {/**<a href={"https://photo.virtualeventsbooth.com/nationalgridwall"} target="_blank" rel="noopener noreferrer">
        <Button
          style={{ marginLeft: "3%" }}
          onClick={() => requestPoints("addPledge", "addPledge")}
        >
          <Trans>agenda_make_your_pledge</Trans>
        </Button>
      </a>*/}
      <br /><br />

      <div className={styles.externalIframeAspectDiv}>
        <iframe
          title="External iFrame"
          className={styles.externalIframe}
          src={"https://events.virtual-tech.io/nationalgrid/"}
          allow="camera; autoplay; encrypted-media;">
        </iframe>
      </div>
      <br /><br />
      <h2 style={{ color: "black", marginLeft: "3%" }}><Trans>social_wall_join</Trans></h2>
      <br /><br />

      <div className={styles.externalIframeAspectDiv}>
        <iframe
          title="External iFrame"
          className={styles.externalIframe}
          src={"https://www.juicer.io/api/feeds/gyecc21/iframe"}
          allow="camera; autoplay; encrypted-media;">
        </iframe>
      </div>

    </div>
  );
}
