import React, { Component } from "react";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import styles from "./../UserProfile.module.scss";

export default class UserSessionsOptionsBof1 extends Component {

    showSessionFullMessageIfFull = (buttonDisabled) => {
        console.log("Fire!");
        if (buttonDisabled == true) {
            alert("This session is full. Please check alternative time slots for availability.");
        }
    }

    buttonOptionElement = (value, title, additionalInfo, disabledStatus) => {
        return (
            <ToggleButton
                value={value}
                disabled={disabledStatus}
                className={styles.sessionOption}
                //data-tip={"<h4>" + additionalInfo + "</h4 ><br /><br />"}
                //data-for={"sessionInfo"}
                onClick={() => this.showSessionFullMessageIfFull(disabledStatus)}
            >
                {title}
            </ToggleButton>
        )
    }

    render () {
        return (
            <div>
                <h4 className={styles.dayHeadings}>Time Slot 1</h4>
                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    {this.buttonOptionElement(1, "Roundtable 1", "Additional text", this.props.session1Disabled)}
                    {this.buttonOptionElement(2, "Roundtable 2", "Additional text", this.props.session2Disabled)}
                    {this.buttonOptionElement(3, "Roundtable 3", "Additional text", this.props.session3Disabled)}
                </ToggleButtonGroup>

                <br />
                <br />

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    {this.buttonOptionElement(4, "Roundtable 4", "Additional text", this.props.session4Disabled)}
                    {this.buttonOptionElement(5, "Roundtable 5", "Additional text", this.props.session5Disabled)}
                    {this.buttonOptionElement(0, "No Roundtable Selected", "No Roundtable Selected", this.props.session6Disabled)}
                </ToggleButtonGroup>

                <br />


            </div>
        );
    }
}