import React from "react";
import { Trans, withTranslation } from 'react-i18next';
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import styles from "./ExhibitionMobile.module.scss";

export default function Breakouts (props) {

  return (
    <div className={styles.mobileListView} >
      <div className={styles.mobileTitleArea}>
        <h1 className={styles.mobileTitleText}><Trans>shared_exhibition</Trans></h1>
        <Link to="/lobby"><Button><Trans>shared_back</Trans></Button></Link>
      </div>

      <Link to="/exhibitionHall/1">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}><Trans>exhibition_hall_one</Trans></h3>
        </div>
      </Link>

      <Link to="/exhibitionHall/2">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}><Trans>exhibition_hall_two</Trans></h3>
        </div>
      </Link>

      <Link to="/exhibitionHall/3">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}><Trans>exhibition_hall_three</Trans></h3>
        </div>
      </Link>

    </div>
  );
}
