import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import styles from "./ExhibitionAdminList.module.scss";

export default class UserList extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      exhibitionStandList: [],
    };
  }

  componentDidMount () {
    fetch("/api/getExhibitionStandList")
      //.then((res) => res.text())
      .then((res) => res.json())
      .then((data) =>
        this.setState({
          exhibitionStandList: data,
        })
      );
  }

  render () {
    const exhibitionStandList = this.state.exhibitionStandList.map((exhibitionStand) => {
      return (
        <tr key={exhibitionStand._id}>
          <td>{exhibitionStand.standName}</td>
          <td>{exhibitionStand.standSummary}</td>
          <td><Link to={"/editExhibitionStand/" + exhibitionStand._id}><Button>Edit</Button></Link></td>
        </tr>
      );
    });

    return (
      <div className={styles.background} >
        <br /><br /><br />
        <h2>Exhibition Stand List</h2>
        <Table striped bordered hover className="exhibitionStandTable">
          <thead>
            <tr>
              <th>Stand Name</th>
              <th>Stand Summary</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>{exhibitionStandList}</tbody>
        </Table>
      </div>
    );
  }
}
