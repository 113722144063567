import React, { Component } from "react";
import ReactTooltip from 'react-tooltip';
import { Trans, withTranslation } from 'react-i18next';
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import UserSessionOptionsBreakouts1 from "./userSessionOptions/UserSessionOptionsBreakouts1"
import UserSessionOptionsBreakouts2 from "./userSessionOptions/UserSessionOptionsBreakouts2"
import UserSessionOptionsBreakouts3 from "./userSessionOptions/UserSessionOptionsBreakouts3"
import { dateFormatterTimeAndFullDay } from "../helpers/dateFormatter.js"
import styles from "./UserSessions.module.scss";

class UserSessions extends Component {
    constructor(props) {
        super(props);
        this.state = {

            breakoutSession1Selection: 0,
            breakoutSession2Selection: 0,
            breakoutSession3Selection: 0,
            breakoutSession4Selection: 0,
            breakoutSession5Selection: 0,
            breakoutSession6Selection: 0,
            breakoutSession7Selection: 0,
            breakoutSession8Selection: 0,
            breakoutSession9Selection: 0,
            breakoutSession10Selection: 0,
            breakoutSession11Selection: 0,
            breakoutSession12Selection: 0,
            breakoutSession13Selection: 0,
            breakoutSession14Selection: 0,
            breakoutSession15Selection: 0,
            breakoutSession16Selection: 0,

            page0Active: true,
            page1Active: false,
            page1Complete: false,
            page2Active: false,
            page2Complete: false,
            page3Active: false,
            page3Complete: false,
            page4Active: false,
            page4Complete: false,
            page5Active: false,
            page5Complete: false,
            page6Active: false,
            page6Complete: false,
            page7Active: false,
        };

        this.handleChange = this.handleChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);

        this.profileScrollRef = React.createRef();
    }

    componentDidMount () {
        // Get user session selections
        this.retrieveUserDetails();
    }

    resetDialogueStatus () {
        this.setState({
            page0Active: true,
            page1Active: false,
            page1Complete: false,
            page2Active: false,
            page2Complete: false,
            page3Active: false,
            page3Complete: false,
            page4Active: false,
            page4Complete: false,
            page5Active: false,
            page5Complete: false,
            page6Active: false,
            page6Complete: false,
            page7Active: false,
        });
    }

    retrieveUserDetails = () => {
        fetch("/api/getUserProfile")
            .then((res) => {
                if (!res.ok) {
                    throw new Error(res.status);
                } else {
                    return res.json();
                }
            })
            .then((data) => this.loadResponseIntoState(data))
            .catch((error) => {
                console.log("Unable to load user profile");
            });
    };

    loadResponseIntoState (data) {
        this.setState({
            breakoutSession1Selection: data.user.breakoutSession1Selection,
            breakoutSession2Selection: data.user.breakoutSession2Selection,
            breakoutSession3Selection: data.user.breakoutSession3Selection,
            breakoutSession4Selection: data.user.breakoutSession4Selection,
            breakoutSession5Selection: data.user.breakoutSession5Selection,
            breakoutSession6Selection: data.user.breakoutSession6Selection,
            breakoutSession7Selection: data.user.breakoutSession7Selection,
            breakoutSession8Selection: data.user.breakoutSession8Selection,
            breakoutSession9Selection: data.user.breakoutSession9Selection,
            breakoutSession10Selection: data.user.breakoutSession10Selection,
            breakoutSession11Selection: data.user.breakoutSession11Selection,
            breakoutSession12Selection: data.user.breakoutSession12Selection,
            breakoutSession13Selection: data.user.breakoutSession13Selection,
            breakoutSession14Selection: data.user.breakoutSession14Selection,
            breakoutSession15Selection: data.user.breakoutSession15Selection,
            breakoutSession16Selection: data.user.breakoutSession16Selection,
        });
        this.resetDialogueStatus();
    }

    handleChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value,
        });
    };


    handleBreakoutSession1SelectionChange = (value) => {
        this.setState({
            breakoutSession1Selection: value,
        });
    };

    handleBreakoutSession2SelectionChange = (value) => {
        this.setState({
            breakoutSession2Selection: value,
        });
    };

    handleBreakoutSession3SelectionChange = (value) => {
        this.setState({
            breakoutSession3Selection: value,
        });
    };

    handleBreakoutSession4SelectionChange = (value) => {
        this.setState({
            breakoutSession4Selection: value,
        });
    };

    handleBreakoutSession5SelectionChange = (value) => {
        this.setState({
            breakoutSession5Selection: value,
        });
    };

    handleBreakoutSession6SelectionChange = (value) => {
        this.setState({
            breakoutSession6Selection: value,
        });
    };

    handleBreakoutSession7SelectionChange = (value) => {
        this.setState({
            breakoutSession7Selection: value,
        });
    };

    handleBreakoutSession8SelectionChange = (value) => {
        this.setState({
            breakoutSession8Selection: value,
        });
    };

    handleBreakoutSession9SelectionChange = (value) => {
        this.setState({
            breakoutSession9Selection: value,
        });
    };

    handleBreakoutSession10SelectionChange = (value) => {
        this.setState({
            breakoutSession10Selection: value,
        });
    };

    handleBreakoutSession11SelectionChange = (value) => {
        this.setState({
            breakoutSession11Selection: value,
        });
    };

    handleBreakoutSession12SelectionChange = (value) => {
        this.setState({
            breakoutSession12Selection: value,
        });
    };

    handleBreakoutSession13SelectionChange = (value) => {
        this.setState({
            breakoutSession13Selection: value,
        });
    };

    handleBreakoutSession14SelectionChange = (value) => {
        this.setState({
            breakoutSession14Selection: value,
        });
    };

    handleBreakoutSession15SelectionChange = (value) => {
        this.setState({
            breakoutSession15Selection: value,
        });
    };

    handleBreakoutSession16SelectionChange = (value) => {
        this.setState({
            breakoutSession16Selection: value,
        });
    };


    saveFormData = () => {
        fetch("/api/updateUserSessionSelection", {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    console.log("Saved track session selection")
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .catch((err) => {
                console.error(err);
                alert("Error saving session selection -  please try again");
            });
    };


    saveAndExit = () => {
        this.saveFormData();
        this.props.closeSessionSelectionModal();
        this.resetDialogueStatus();
    };

    closeWithoutSave = () => {
        this.props.closeSessionSelectionModal();
        this.resetDialogueStatus();
    }

    nextPage0 = () => {
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page0Active: false, page1Active: true });

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage1 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page1Active: false, page0Active: true });
        if (
            this.state.breakoutSession1Selection &&
            this.state.breakoutSession1Selection > 0 &&
            this.state.breakoutSession2Selection &&
            this.state.breakoutSession2Selection > 0 &&
            this.state.breakoutSession3Selection &&
            this.state.breakoutSession3Selection > 0 &&
            this.state.breakoutSession4Selection &&
            this.state.breakoutSession4Selection > 0
        ) {
            this.setState({ page1Complete: true });
        } else {
            this.setState({ page1Complete: false });
        }
    };

    nextPage1 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page1Active: false, page2Active: true });
        if (
            this.state.breakoutSession1Selection &&
            this.state.breakoutSession1Selection > 0 &&
            this.state.breakoutSession2Selection &&
            this.state.breakoutSession2Selection > 0 &&
            this.state.breakoutSession3Selection &&
            this.state.breakoutSession3Selection > 0 &&
            this.state.breakoutSession4Selection &&
            this.state.breakoutSession4Selection > 0
        ) {
            this.setState({ page1Complete: true });
        } else {
            this.setState({ page1Complete: false });
        }
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage2 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page2Active: false, page1Active: true });
        if (
            this.state.breakoutSession5Selection &&
            this.state.breakoutSession5Selection > 0 &&
            this.state.breakoutSession6Selection &&
            this.state.breakoutSession6Selection > 0 &&
            this.state.breakoutSession7Selection &&
            this.state.breakoutSession7Selection > 0 &&
            this.state.breakoutSession8Selection &&
            this.state.breakoutSession8Selection > 0
        ) {
            this.setState({ page2Complete: true });
        } else {
            this.setState({ page2Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage2 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page2Active: false, page7Active: true });
        if (
            this.state.breakoutSession5Selection &&
            this.state.breakoutSession5Selection > 0 &&
            this.state.breakoutSession6Selection &&
            this.state.breakoutSession6Selection > 0 &&
            this.state.breakoutSession7Selection &&
            this.state.breakoutSession7Selection > 0 &&
            this.state.breakoutSession8Selection &&
            this.state.breakoutSession8Selection > 0
        ) {
            this.setState({ page2Complete: true });
        } else {
            this.setState({ page2Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage3 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page3Active: false, page2Active: true });
        if (
            this.state.breakoutSession9Selection &&
            this.state.breakoutSession9Selection > 0 &&
            this.state.breakoutSession10Selection &&
            this.state.breakoutSession10Selection > 0 &&
            this.state.breakoutSession11Selection &&
            this.state.breakoutSession11Selection > 0 &&
            this.state.breakoutSession12Selection &&
            this.state.breakoutSession12Selection > 0
        ) {
            this.setState({ page3Complete: true });
        } else {
            this.setState({ page3Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage3 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page3Active: false, page4Active: true });
        if (
            this.state.breakoutSession9Selection &&
            this.state.breakoutSession9Selection > 0 &&
            this.state.breakoutSession10Selection &&
            this.state.breakoutSession10Selection > 0 &&
            this.state.breakoutSession11Selection &&
            this.state.breakoutSession11Selection > 0 &&
            this.state.breakoutSession12Selection &&
            this.state.breakoutSession12Selection > 0
        ) {
            this.setState({ page3Complete: true });
        } else {
            this.setState({ page3Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage4 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page4Active: false, page3Active: true });
        if (
            this.state.breakoutSession133Selection &&
            this.state.breakoutSession13Selection > 0 &&
            this.state.breakoutSession14Selection &&
            this.state.breakoutSession14Selection > 0 &&
            this.state.breakoutSession15Selection &&
            this.state.breakoutSession15Selection > 0 &&
            this.state.breakoutSession16Selection &&
            this.state.breakoutSession16Selection > 0
        ) {
            this.setState({ page4Complete: true });
        } else {
            this.setState({ page4Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage4 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page4Active: false, page7Active: true });
        if (
            this.state.breakoutSession133Selection &&
            this.state.breakoutSession13Selection > 0 &&
            this.state.breakoutSession14Selection &&
            this.state.breakoutSession14Selection > 0 &&
            this.state.breakoutSession15Selection &&
            this.state.breakoutSession15Selection > 0 &&
            this.state.breakoutSession16Selection &&
            this.state.breakoutSession16Selection > 0
        ) {
            this.setState({ page4Complete: true });
        } else {
            this.setState({ page4Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage5 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page5Active: false, page4Active: true });
        if (
            this.state.breakoutSession5ASelection &&
            this.state.breakoutSession5ASelection > 0
        ) {
            this.setState({ page5Complete: true });
        } else {
            this.setState({ page5Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage5 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page5Active: false, page6Active: true });
        if (
            this.state.breakoutSession5ASelection &&
            this.state.breakoutSession5ASelection > 0
        ) {
            this.setState({ page5Complete: true });
        } else {
            this.setState({ page5Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage6 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page6Active: false, page5Active: true });
        if (
            this.state.breakoutSession6ASelection &&
            this.state.breakoutSession6ASelection > 0
        ) {
            this.setState({ page6Complete: true });
        } else {
            this.setState({ page6Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage6 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page6Active: false, page7Active: true });
        if (
            this.state.breakoutSession6ASelection &&
            this.state.breakoutSession6ASelection > 0
        ) {
            this.setState({ page6Complete: true });
        } else {
            this.setState({ page6Complete: false });
        }
    };

    previousPage7 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page7Active: false, page6Active: true });

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };



    closeAndResetProfileModal = () => {
        this.setState({ page1Complete: false });
    };

    render () {
        const StatusIcon1 = () => {
            if (this.state.page1Active) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_1_on.png"}
                        alt="Page 1 visible"
                    />
                );
            } else if (this.state.page1Complete) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 1 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_1_off.png"}
                        alt="Page 1 not visible"
                    />
                );
            }
        };

        const StatusIcon2 = () => {
            if (this.state.page2Active) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_2_on.png"}
                        alt="Page 2 visible"
                    />
                );
            } else if (this.state.page2Complete) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 2 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_2_off.png"}
                        alt="Page 2 not visible"
                    />
                );
            }
        };

        const StatusIcon3 = () => {
            if (this.state.page3Active) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_3_on.png"}
                        alt="Page 3 visible"
                    />
                );
            } else if (this.state.page3Complete) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 3 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_3_off.png"}
                        alt="Page 3 not visible"
                    />
                );
            }
        };

        const StatusIcon4 = () => {
            if (this.state.page4Active) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_4_on.png"}
                        alt="Page 4 visible"
                    />
                );
            } else if (this.state.page4Complete) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 4 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_4_off.png"}
                        alt="Page 4 not visible"
                    />
                );
            }
        };

        const StatusIcon5 = () => {
            if (this.state.page5Active) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_5_on.png"}
                        alt="Page 5 visible"
                    />
                );
            } else if (this.state.page5Complete) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 5 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_5_off.png"}
                        alt="Page 5 not visible"
                    />
                );
            }
        };

        const StatusIcon6 = () => {
            if (this.state.page6Active) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_6_on.png"}
                        alt="Page 6 visible"
                    />
                );
            } else if (this.state.page6Complete) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 6 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/page_6_off.png"}
                        alt="Page 4 not visible"
                    />
                );
            }
        };

        const StatusIcon7 = () => {
            if (
                this.state.page7Active &&
                this.state.page1Complete &&
                this.state.page2Complete &&
                this.state.page3Complete &&
                this.state.page4Complete &&
                this.state.page5Complete &&
                this.state.page6Complete
            ) {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle.png"}
                        alt="Page 7 complete"
                    />
                );
            } else {
                return (
                    <img
                        className={styles.statusIcon}
                        src={window.$videoPath + "icons/profile/tick_circle_grey.png"}
                        alt="Page 7 incomplete"
                    />
                );
            }
        };

        return (
            <div>

                <Modal
                    onAfterOpen={this.retrieveUserDetails}
                    isOpen={this.props.showSessionSelectionModal}
                    ariaHideApp={false}
                    contentLabel="User Profile"
                    //contentRef={(contentDiv) => (this.profileScrollRef = contentDiv)}
                    overlayClassName={styles.modalOverlay}
                    className={styles.modalContent}
                    style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
                >
                    {this.props.showMobile ? null :
                        <ReactTooltip className={styles.toolTip} style={{ zIndex: 5000000000 }} html={true} id={"sessionInfo"} border={true} effect={"float"} />
                    }

                    <div className={styles.modalFlexboxColumn}>
                        <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
                            <h1 className={styles.modalPageTitle}><Trans>my_day_plan_my_day</Trans></h1>
                        </div>

                        <div className={styles.scrollableArea} ref={(contentDiv) => (this.profileScrollRef = contentDiv)}>

                            {/**<div className={styles.pageNavColumn}>
                                <StatusIcon1 />
                                <StatusIcon2 />
                                <StatusIcon3 />
                                {/**<StatusIcon4 />
                                <StatusIcon5 />
                                <StatusIcon6 />
                                <StatusIcon7 />
                            </div>*/}

                            <div className={styles.sessionSelectionArea}>
                                <br />
                                {/*Page 0 */}
                                {this.state.page0Active ? (
                                    <React.Fragment>
                                        <p className={styles.smallSubheading} >
                                            <Trans>plan_my_day_info_text</Trans>
                                        </p>
                                        <br /><br />
                                        <div className={styles.buttonArea}>
                                            <Button
                                                onClick={this.nextPage0}
                                                className={`${"btn btn-secondary"} ${styles.bottomRightButtons}`}
                                            >
                                                <Trans>shared_next</Trans>
                                            </Button>
                                            <Button
                                                onClick={this.closeWithoutSave}
                                                className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                                            >
                                                <Trans>shared_close</Trans>
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                ) : null}

                                {/*Page 1 */}
                                {this.state.page1Active ? (
                                    <div className={styles.pageContentPanel}>
                                        <p className={styles.subheading}><Trans>on_demand_day_one</Trans></p>
                                        <br />
                                        <h4 className={styles.dayHeadings}>{dateFormatterTimeAndFullDay(new Date("2021-09-07T15:00:00.000+01:00"), this.props.userProfile)}</h4>

                                        <ToggleButtonGroup
                                            type="radio"
                                            name={"breakoutSessionSelection"}
                                            className={styles.buttonGroupRow}
                                        >
                                            <ToggleButton
                                                value={1}
                                                disabled={this.props.session1Disabled}
                                                className={styles.sessionOptionShort}
                                                data-tip={"<h6>" + this.props.t('agenda_opening_remarks_info') + "</h6><br /><br />"}
                                                data-for={"sessionInfo"}
                                            >
                                                <Trans>agenda_opening_remarks</Trans>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                        <br />


                                        <br />
                                        <h4 className={styles.dayHeadings}>{dateFormatterTimeAndFullDay(new Date("2021-09-07T15:30:00.000+01:00"), this.props.userProfile)}</h4>
                                        <UserSessionOptionsBreakouts1
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession1SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession1Selection}
                                            userProfile={this.props.userProfile}
                                        />
                                        <br />
                                        <h4 className={styles.dayHeadings}>{dateFormatterTimeAndFullDay(new Date("2021-09-07T16:30:00.000+01:00"), this.props.userProfile)}</h4>
                                        <UserSessionOptionsBreakouts1
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession2SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession2Selection}
                                            userProfile={this.props.userProfile}
                                        />
                                        <br />
                                        <h4 className={styles.dayHeadings}>{dateFormatterTimeAndFullDay(new Date("2021-09-07T17:30:00.000+01:00"), this.props.userProfile)}</h4>
                                        <UserSessionOptionsBreakouts2
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession3SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession3Selection}
                                            userProfile={this.props.userProfile}
                                        />
                                        <br />
                                        <h4 className={styles.dayHeadings}>{dateFormatterTimeAndFullDay(new Date("2021-09-07T18:30:00.000+01:00"), this.props.userProfile)}</h4>
                                        <ToggleButtonGroup
                                            type="radio"
                                            name={"breakoutSessionSelection"}
                                            className={styles.buttonGroupRow}
                                        >
                                            <ToggleButton
                                                value={1}
                                                disabled={this.props.session1Disabled}
                                                className={styles.sessionOptionShort}
                                                data-tip={"<h6>" + this.props.t('agenda_plenary_qa_panel_info') + "</h6><br /><br />"}
                                                data-for={"sessionInfo"}
                                            >
                                                <Trans>agenda_plenary_qa_panel</Trans>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                        <br />


                                        <br />
                                        <h4 className={styles.dayHeadings}>{dateFormatterTimeAndFullDay(new Date("2021-09-07T19:10:00.000+01:00"), this.props.userProfile)}</h4>
                                        <ToggleButtonGroup
                                            type="radio"
                                            name={"breakoutSessionSelection"}
                                            className={styles.buttonGroupRow}
                                        >
                                            <ToggleButton
                                                value={1}
                                                disabled={this.props.session1Disabled}
                                                className={styles.sessionOptionShort}
                                                data-tip={"<h6>What’s your pledge to go #onestepgreener?</h6><br /><br />"}
                                                data-for={"sessionInfo"}
                                            >
                                                <Trans>agenda_make_your_pledge</Trans>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                        <br />


                                        <br />
                                        <br />

                                        <div className={styles.buttonArea}>
                                            <Button
                                                onClick={this.nextPage1}
                                                className={`${"btn btn-secondary"} ${styles.bottomRightButtons}`}
                                            >
                                                <Trans>shared_next</Trans>
                                            </Button>

                                            <Button
                                                onClick={this.saveAndExit}
                                                className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                                            >
                                                <Trans>my_day_save_exit</Trans>
                                            </Button>
                                            <Button
                                                onClick={this.closeWithoutSave}
                                                className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                                            >
                                                <Trans>my_day_close_without_saving</Trans>
                                            </Button>
                                            <Button
                                                onClick={this.previousPage1}
                                                className={`${"btn btn-success"} ${styles.bottomRightButtons}`}
                                            >
                                                <Trans>shared_previous</Trans>
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}

                                {/*Page 2 */}
                                {this.state.page2Active ? (
                                    <div className={styles.pageContentPanel}>
                                        <p className={styles.subheading}><Trans>on_demand_day_two</Trans></p>
                                        <br />
                                        <h4 className={styles.dayHeadings}>{dateFormatterTimeAndFullDay(new Date("2021-09-08T06:00:00.000+01:00"), this.props.userProfile)}</h4>
                                        <ToggleButtonGroup
                                            type="radio"
                                            name={"breakoutSessionSelection"}
                                            className={styles.buttonGroupRow}
                                        >
                                            <ToggleButton
                                                value={1}
                                                disabled={this.props.session1Disabled}
                                                className={styles.sessionOptionShort}
                                                data-tip={"<h6>" + this.props.t('agenda_opening_remarks_info') + "</h6><br /><br />"}
                                                data-for={"sessionInfo"}
                                            >
                                                <Trans>agenda_opening_remarks</Trans>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                        <br />

                                        <br />
                                        <h4 className={styles.dayHeadings}>{dateFormatterTimeAndFullDay(new Date("2021-09-08T06:30:00.000+01:00"), this.props.userProfile)}</h4>
                                        <UserSessionOptionsBreakouts1
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession4SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession4Selection}
                                            userProfile={this.props.userProfile}
                                        />
                                        <br />
                                        <h4 className={styles.dayHeadings}>{dateFormatterTimeAndFullDay(new Date("2021-09-08T07:30:00.000+01:00"), this.props.userProfile)}</h4>
                                        <UserSessionOptionsBreakouts1
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession5SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession5Selection}
                                            userProfile={this.props.userProfile}
                                        />
                                        <br />
                                        <h4 className={styles.dayHeadings}>{dateFormatterTimeAndFullDay(new Date("2021-09-08T08:30:00.000+01:00"), this.props.userProfile)}</h4>
                                        <UserSessionOptionsBreakouts3
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession6SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession6Selection}
                                            userProfile={this.props.userProfile}
                                        />
                                        <br />
                                        <h4 className={styles.dayHeadings}>{dateFormatterTimeAndFullDay(new Date("2021-09-08T09:30:00.000+01:00"), this.props.userProfile)}</h4>
                                        <ToggleButtonGroup
                                            type="radio"
                                            name={"breakoutSessionSelection"}
                                            className={styles.buttonGroupRow}
                                        >
                                            <ToggleButton
                                                value={1}
                                                disabled={this.props.session1Disabled}
                                                className={styles.sessionOptionShort}
                                                data-tip={"<h6>" + this.props.t('agenda_plenary_qa_panel_info_day2') + "</h6><br /><br />"}
                                                data-for={"sessionInfo"}
                                            >
                                                <Trans>agenda_plenary_qa_panel</Trans>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                        <br />

                                        <br />
                                        <h4 className={styles.dayHeadings}>{dateFormatterTimeAndFullDay(new Date("2021-09-08T10:10:00.000+01:00"), this.props.userProfile)}</h4>
                                        <ToggleButtonGroup
                                            type="radio"
                                            name={"breakoutSessionSelection"}
                                            className={styles.buttonGroupRow}
                                        >
                                            <ToggleButton
                                                value={1}
                                                disabled={this.props.session1Disabled}
                                                className={styles.sessionOptionShort}
                                                data-tip={"<h6>What’s your pledge to go #onestepgreener?</h6><br /><br />"}
                                                data-for={"sessionInfo"}
                                            >
                                                <Trans>agenda_make_your_pledge</Trans>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                        <br />

                                        <br />
                                        <br />
                                        <div className={styles.buttonArea}>
                                            <Button
                                                onClick={this.nextPage2}
                                                className={`${"btn btn-secondary"} ${styles.bottomRightButtons}`}
                                            >
                                                <Trans>shared_next</Trans>
                                            </Button>
                                            <Button
                                                onClick={this.saveAndExit}
                                                className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                                            >
                                                <Trans>my_day_save_exit</Trans>
                                            </Button>
                                            <Button
                                                onClick={this.closeWithoutSave}
                                                className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                                            >
                                                <Trans>my_day_close_without_saving</Trans>
                                            </Button>
                                            <Button
                                                onClick={this.previousPage2}
                                                className={`${"btn btn-success"} ${styles.bottomRightButtons}`}
                                            >
                                                <Trans>shared_previous</Trans>
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}



                                {/*Page 7*/}
                                {this.state.page7Active ? (
                                    <div className={styles.pageContentPanel}>
                                        <p className={styles.smallSubheading}><Trans>plan_my_day_completed</Trans></p>
                                        <br /><br /><br />
                                        <div className={styles.buttonArea}>
                                            <Button
                                                onClick={this.saveAndExit}
                                                className={`${"btn btn-secondary"} ${styles.bottomRightButtons}`}
                                            >
                                                <Trans>shared_exit</Trans>
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div >
        );
    }
}

export default withTranslation()(UserSessions);