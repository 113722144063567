import React, { Component } from "react";
import * as UpChunk from '@mux/upchunk';
import { Redirect } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import styles from "./CreateVideo.module.scss";

export default class CreateVideo extends Component {
  constructor() {
    super();
    this.state = {
      videoName: "",
      videoURL: "",
      redirectNow: false,
      uploadProgress: 0,
      uploadURL: "",
    };
    this.filePicker = React.createRef();
  }

  updateInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  saveSettings = (event) => {
    event.preventDefault();
    if (this.state.videoName.length > 0 && this.state.videoURL.length > 0) {
      fetch("/api/createVideo", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ redirectNow: true });
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          alert("Error updating settings -  please try again");
        });
    } else {
      alert("Please enter a video name and upload source URL");
    }
  };

  getDirectUploadURL = (event) => {
    event.preventDefault();
    if (this.state.videoName.length > 0) {
      fetch("/api/getVideoUploadURL", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({ uploadURL: res.response.url });
          console.log(res.response);
        })
        .catch((err) => {
          console.error(err);
          alert("Error updating settings -  please try again");
        });
    } else {
      alert("Please enter a video name");
    }
  };

  uploadFile = () => {
    console.log("Uploading file");
    const upload = UpChunk.createUpload({
      endpoint: this.state.uploadURL,
      file: this.filePicker.files[0],
      chunkSize: 5120, // Uploads the file in ~5mb chunks
    });
    upload.on('error', err => {
      console.error('💥 🙀', err.detail);
    });

    upload.on('progress', progress => {
      console.log(`So far we've uploaded ${progress.detail}% of this file.`);
      this.setState({ uploadProgress: progress.detail });
    });

    upload.on('success', () => {
      console.log("Wrap it up, we're done here. 👋");
      alert("Upload complete");
    });
  }

  render () {
    return (
      <div className={styles.adminContentArea}>
        {this.state.redirectNow ? <Redirect to={"/videoAdminList"} /> : null}
        <h1>Create Video</h1>
        <br />
        <h2>Import video from URL</h2>
        <br />
        <form onSubmit={this.saveSettings}>
          <h4>Video Name</h4>
          <input
            type="text"
            name="videoName"
            size={100}
            placeholder="Video Name"
            value={this.state.videoName}
            onChange={this.updateInput}
          />
          <br /> <br />
          <h4>Video URL</h4>
          <input
            type="text"
            name="videoURL"
            size={100}
            placeholder="Video URL"
            value={this.state.videoURL}
            onChange={this.updateInput}
          />
          <br /> <br />

          {<button type="submit">Submit</button>}

        </form>


        <br />
        <hr style={{ width: "90%", height: "5px", backgroundColor: "black" }} />
        <br />
        <h2>Direct Upload</h2>
        <form onSubmit={this.getDirectUploadURL}>
          <br />


          <h4>Video Name</h4>
          <input
            type="text"
            name="videoName"
            size={100}
            placeholder="Video Name"
            value={this.state.videoName}
            onChange={this.updateInput}
          />
          <br /> <br />
          {/**<h2>UploadURL: {this.state.uploadURL}</h2>
            <br /> <br />*/}

          {<button type="submit">Submit video name</button>}
        </form>

        <br />
        {this.state.uploadURL ? <div>
          <h4>Select File</h4>
          <input
            type="file"
            id="avatar" name="avatar"
            ref={(filePicker) => (this.filePicker = filePicker)}
            style={{ display: "block" }}
          />
          <br />
          <br />
          <button onClick={() => this.uploadFile()}>Upload</button>
          <br />
          <br />
          <ProgressBar label={"Upload Progress"} style={{ width: "50%" }} now={this.state.uploadProgress} />
        </div> : null}
        <br /> <br />
        <br /> <br />
      </div>
    );
  }
}