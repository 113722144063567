import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import "./MessageForm.scss";

class MessageForm extends Component {
  static propTypes = {
    onMessageSend: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.input.focus();
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    this.props.onMessageSend(this.input.value);
    this.input.value = "";
  };

  render () {
    return (
      <form className="MessageForm" onSubmit={this.handleFormSubmit}>
        <div className="input-container">
          <input
            type="text"
            ref={(node) => (this.input = node)}
            placeholder={this.props.t('exhibition_enter_your_message')}
          />
        </div>
        <div className="button-container">
          <button type="submit"><Trans>exhibition_send</Trans></button>
        </div>
      </form>
    );
  }
}

export default withTranslation()(MessageForm);
