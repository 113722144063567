import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Trans, withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
import { Image, Transformation } from "cloudinary-react";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./ExhibitionStandMobile.module.scss";

export default class ExhibitionStandMobile extends Component {

  render () {
    // Sets background colour for all panels
    let standThemeColorStyle = { backgroundColor: this.props.panelColor }
    let panelTextColorStyle = { color: this.props.panelTextColor }
    let deskFrontColorStyle = { backgroundColor: this.props.deskFrontColor }
    return (
      <div className={styles.mobileListView} >
        <div className={styles.mobileTitleArea}>
          <h1 className={styles.mobileTitleText}>{this.props.standName}</h1>
          <Link to={"/exhibitionHall/" + this.props.hallNumber}>
            <Button><Trans>shared_back</Trans></Button></Link>
        </div>

        {/** Either display custom desk front or stock colour */}
        {this.props.deskPanelAssetID ? <img className={styles.deskPanelGraphicMobile} src={window.$videoPath + "exhibition/deskFronts/" + this.props.deskPanelAssetID} alt="Desk panel" />
          :
          <Image
            className={styles.deskFrontLogoMobile}
            cloudName="oijqec973f1nf"
            publicId={this.props.logoAssetID}
            secure={true}
          >
            <Transformation
              width="500"
              height="130"
              crop="pad"
            />
          </Image>
        }

        {/** Hero Image */}
        {this.props.backPanelAssetID ?
          <img className={styles.backPanelMobile}
            src={window.$videoPath + "exhibition/backPanels/" + this.props.backPanelAssetID}
            alt="Back panel" />
          : null}

        {/** Video Screens */}
        {
          this.props.mainVideoID ?
            <div className={styles.videoScreen1AspectDiv}>
              <div className={styles.videoScreen1FrameMobile}>
                <Vimeo
                  className={styles.videoScreen1Mobile}
                  controls={true}
                  video={this.props.mainVideoID}
                  responsive={true}
                  allow="autoplay; fullscreen"
                  allowfullscreen
                  mozallowfullscreen
                  webkitallowfullscreen
                  onPlay={() => requestPoints(
                    "viewExhibitionMainVideo",
                    this.props.exhibitionStandID
                  )}
                ></Vimeo>
              </div>
            </div> : null
        }
        {/** Physical Elements */}


        {/** Resource Area */}
        {this.props.showResourceArea ?
          <div
            onClick={() => this.props.setShowResourceAreaModal(true)}
            className={styles.mobileListItem}
          >
            <h2 className={styles.mobileListTitle}><Trans>exhibition_find_out_more</Trans></h2>

          </div> : null
        }

        {/** Contact Area */}
        {this.props.showQAndAArea ?
          <div className={styles.mobileListItem}
            onClick={() => this.props.setShowQuestionsModal(true)}>
            <h2
              className={styles.mobileListTitle}
              style={panelTextColorStyle}>
              <img className={styles.contactOptionIconMobile} src={window.$videoPath + "exhibition/icons/question.png"} alt="Q&A" />
              <Trans>exhibition_qa</Trans></h2>
          </div> : null}
        {this.props.showTextChatArea ?
          <div className={styles.mobileListItem}
            onClick={() => this.props.setShowTextChatModal(true)}>
            <h2
              className={styles.mobileListTitle}
              style={panelTextColorStyle}>
              <img className={styles.contactOptionIconMobile} src={window.$videoPath + "exhibition/icons/people.png"} alt="Text Chat" />
              <Trans>exhibition_live_text_chat</Trans></h2>
          </div> : null}
        {this.props.showLeaveDetailsArea ?
          <div className={styles.mobileListItem}
            onClick={() => this.props.setShowLeaveDetailsAreaModal(true)}>
            <h2
              className={styles.mobileListTitle}
              style={panelTextColorStyle}>
              <img className={styles.contactOptionIconMobile} src={window.$videoPath + "exhibition/icons/details.png"} alt="Leave details" />
              <Trans>exhibition_leave_details_follow_up_request</Trans>
            </h2>
          </div> : null}
        {this.props.showVideoChatArea ?
          <Link to={"/roundtableSession/" + this.props.videoChatResourceID + "/exhibitionStand/" + this.props.exhibitionStandID}>
            <div className={styles.mobileListItem}>

              <h2
                className={styles.mobileListTitle}
                style={panelTextColorStyle}>
                <img className={styles.contactOptionIconMobile} src={window.$videoPath + "exhibition/icons/camera.png"} alt="Leave details" />
                <Trans>exhibition_meet_and_greet</Trans>
              </h2>
            </div>
          </Link> : null}




        {
          this.props.nextStandID ?
            <Link to={"/exhibitionStand/" + this.props.nextStandID}>
              <img
                className={`${styles.hoverHotspot} ${styles.nextStand}`}
                src="/hoverShapes/hoverSquare.png"
                alt="Navigate to next stand"
              ></img>
            </Link> : null
        }

        {
          this.props.previousStandID ?
            <Link to={"/exhibitionStand/" + this.props.previousStandID}>
              <img
                className={`${styles.hoverHotspot} ${styles.previousStand}`}
                src="/hoverShapes/hoverSquare.png"
                alt="Navigate to previous stand"
              ></img>
            </Link> : null
        }
      </div>

    );
  }
}
