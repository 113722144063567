import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_ES } from "./translations/es/translations";
import { TRANSLATIONS_EN } from "./translations/en/translations";
import { TRANSLATIONS_FR } from "./translations/fr/translations";
import { TRANSLATIONS_ZH } from "./translations/zh/translations";


// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
    en: {
        translation: TRANSLATIONS_EN
    },
    es: {
        translation: TRANSLATIONS_ES
    },
    fr: {
        translation: TRANSLATIONS_FR
    },
    zh: {
        translation: TRANSLATIONS_ZH
    },
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

//i18n.changeLanguage("es");

export default i18n;