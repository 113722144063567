import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import Button from "react-bootstrap/Button";
import styles from "./LoginHelp.module.scss";

export default class LoginHelp extends Component {
  render () {
    return (
      <div className={styles.loginHelpBox}>
        <div className={styles.loginHelpBoxContent}>
          <img
            className={styles.helpBoxLogo}
            src={window.$videoPath + "logo.png"}
            alt="Logo"
          />
          <br />
          {this.props.language === "en" ?
            <div className={styles.loginHelpArea}>
              <p>
                <strong>Event platform opening</strong>
                <br />
                The Global Youth Engineering Climate Conference 2021 takes place on Tuesday 8 and Wednesday 8 September 2021.  The platform is now open to set-up your profile, familiarise yourself with the virtual environment and plan your day.
                <br />
                <br />
                <strong>Account set-up</strong>
                <br />
                To access the virtual event you will need to set up an account. This is different to registering for the event. Click on <strong>‘Create your profile’</strong> on the previous page and enter your profile information before clicking <strong>‘Submit’</strong>. You will receive a verification email which you will need to authorise to gain access to the site.
                <br />
                <br />
                <strong>Forgot password?</strong>
                <br />
                Click the <strong>‘Forgot password’</strong> button on previous page. Enter your email address and select the <strong>‘Reset password’</strong> button - if you have set up an account, you will receive a password reset email. If you haven’t yet set up an account, please follow the steps above to create one.
                <br />
                <br />
                <strong>Trouble accessing the site?</strong>
                <br />
                Please check your email and/or password are correct. If you have not set up an account yet please set one up and follow the relevant steps for verification, as detailed above.
                <br />
                <br />
                <strong>Further support</strong>
                <br />
                If you require further support with your account set up or logging in please contact: <a href="mailto:box.COP26@nationalgrid.com">box.COP26@nationalgrid.com</a>
              </p>
              <br />

              <Button
                onClick={() => this.props.handleCloseLoginHelpDiv()}
                className="btn btn-secondary float-left"
              >
                <Trans>shared_back</Trans>
              </Button>
              <br />
            </div>
            : null}
          {this.props.language === "es" ?
            <div className={styles.loginHelpArea}>
              <p>
                <strong>Apertura de la plataforma del evento</strong>
                <br />
                El evento Global Youth Engineering Climate Conference 2021 se realizará los días martes 7 y miércoles 8 de septiembre de 2021. La plataforma está abierta ahora para que configure su perfil, se familiarice con el entorno virtual y planifique su día.
                <br />
                <br />
                <strong>Configuración de la cuenta</strong>
                <br />
                Para acceder al evento, deberá configurar una cuenta. Esto es un proceso distinto del registro para el evento. Haga clic en <strong>“¿Es un usuario nuevo? Cree una cuenta”</strong> a continuación e ingrese la información de su perfil antes de hacer clic en <strong>“Enviar”</strong>. Recibirá un correo electrónico de verificación en el que deberá autorizar la cuenta para tener acceso al sitio.
                <br />
                <br />
                <strong>¿Olvidó su contraseña?</strong>
                <br />
                Haga clic en el botón <strong>“Olvidé mi contraseña”</strong> que aparece a continuación. Ingrese su dirección de correo electrónico y seleccione el botón <strong>“Restablecer contraseña”</strong>. Si ya ha configurado una cuenta, recibirá un correo electrónico de restablecimiento de contraseña. Si aún no ha configurado una cuenta, siga los pasos que se indican más arriba para crear una.
                <br />
                <br />
                <strong>¿Tiene dificultades para acceder al sitio?</strong>
                <br />
                Verifique que haya ingresado el correo electrónico y la contraseña correctos. Si aún no ha configurado una cuenta, hágalo y siga los pasos relevantes de verificación que se indican más arriba.
                <br />
                <br />
                <strong>Asistencia adicional</strong>
                <br />
                Si necesita asistencia adicional con la configuración de su cuenta o para acceder al sitio, comuníquese con la siguiente dirección: <a href="mailto:box.COP26@nationalgrid.com">box.COP26@nationalgrid.com</a>
              </p>
              <br />

              <Button
                onClick={() => this.props.handleCloseLoginHelpDiv()}
                className="btn btn-secondary float-left"
              >
                <Trans>shared_back</Trans>
              </Button>
              <br />
            </div>
            : null}
          {this.props.language === "fr" ?
            <div className={styles.loginHelpArea}>
              <p>
                <strong>Ouverture de la plateforme de l’événement</strong>
                <br />
                La Global Youth Engineering Climate Conference 2021 se déroulera mardi 7 septembre et mercredi 8 septembre 2021. La plateforme est maintenant ouverte ; vous pouvez y créer votre profil, découvrir l’environnement virtuel et planifier votre journée.
                <br />
                <br />
                <strong>Création de compte</strong>
                <br />
                Pour accéder à l’événement virtuel, vous devez créer un compte. Il s’agit d’une procédure distincte de l’inscription à l’événement. Cliquez sur <strong>Vous êtes un nouvel utilisateur ? Créez un compte</strong> ci-dessous et saisissez vos informations de profil, puis cliquez sur <strong>Envoyer</strong>. Vous recevrez un courriel d’authentification dont vous aurez besoin pour pouvoir accéder au site.
                <br />
                <br />
                <strong>Vous avez oublié votre mot de passe ? </strong>
                <br />
                Cliquez sur le bouton <strong>Mot de passe oublié</strong> ci-dessous. Saisissez votre adresse électronique et sélectionnez le bouton <strong>Réinitialiser le mot de passe</strong> – si vous avez créé un compte, vous recevrez un courriel de réinitialisation du mot de passe. Si vous n’avez pas encore créé de compte, veuillez suivre les étapes ci-dessus pour en configurer un.
                <br />
                <br />
                <strong>Vous avez des difficultés pour accéder au site ?</strong>
                <br />
                Veuillez vérifier que votre adresse électronique et/ou votre mot de passe sont corrects. Si vous n’avez pas encore créé de compte, veuillez en configurer un et suivez les étapes d’authentification pertinentes décrites ci-dessus.
                <br />
                <br />
                <strong>Assistance complémentaire </strong>
                <br />
                Si vous avez besoin d’une assistance complémentaire pour créer votre compte ou pour vous connecter à la plateforme, veuillez envoyer un courriel à l’adresse <a href="mailto:box.COP26@nationalgrid.com">box.COP26@nationalgrid.com</a>
              </p>
              <br />

              <Button
                onClick={() => this.props.handleCloseLoginHelpDiv()}
                className="btn btn-secondary float-left"
              >
                <Trans>shared_back</Trans>
              </Button>
              <br />
            </div>
            : null}
          {this.props.language === "zh" ?
            <div className={styles.loginHelpArea}>
              <p>
                <strong>活动平台的开放 </strong>
                <br />
                Global Youth Engineering Climate Conference 2021 的召开时间是 2021 年 9 月 7 日（星期二）和 8 日（星期三）。该平台现已开放，您可设置个人资料、熟悉虚拟环境并规划您的日程。
                <br />
                <br />
                <strong>帐户的设置</strong>
                <br />
                如要访问虚拟活动，您需设置帐户。这与注册该活动不同。点击下面的<strong>‘新用户？创建帐户’</strong>，并输入您的资料信息，然后点击<strong>‘提交’</strong>。您将收到一封验证邮件，验证之后，您就可进入该网站。
                <br />
                <br />
                <strong>忘记密码？</strong>
                <br />
                点击下面的<strong>‘忘记密码’</strong>按钮。输入您的电子邮件地址，并选择<strong>‘重置密码’</strong>按钮——如果您已经设置了一个帐户，您将收到一封密码重置邮件。如果您尚未设置帐户，请按照上述步骤创建一个。
                <br />
                <br />
                <strong>无法访问网站？</strong>
                <br />
                请检查您的电子邮件和/或密码是否正确。如果您尚未设置帐户，请设置一个，并按照上面详细列出的相关步骤进行验证。
                <br />
                <br />
                <strong>更多支持 </strong>
                <br />
                如果您在设置帐户或登录网站时需要更多支持，请联系： <a href="mailto:box.COP26@nationalgrid.com">box.COP26@nationalgrid.com</a>
              </p>
              <br />

              <Button
                onClick={() => this.props.handleCloseLoginHelpDiv()}
                className="btn btn-secondary float-left"
              >
                <Trans>shared_back</Trans>
              </Button>
              <br />
            </div>
            : null}
        </div>
      </div>
    );
  }
}
