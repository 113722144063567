import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Trans, withTranslation } from 'react-i18next';
import Button from "react-bootstrap/Button";
import PreLoginLanguageSelection from "./PreLoginLanguageSelection"
import Register from "./Register";
import RequestPasswordReset from "./RequestPasswordReset";
import LoginHelp from "./LoginHelp";
import styles from "./Login.module.scss";
import preLoginStyles from "./Pre-Login.module.scss";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      redirectToHome: false,
      redirectToHoldingPage: false,
      showRequestPasswordResetDiv: false,
      showRegisterDiv: false,
      showLoginHelpDiv: false,
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.state.email.length < 1) {
      alert(this.props.t('register_error_enter_email'));
    } else if (this.state.password.length < 1) {
      alert(this.props.t('register_error_enter_password'));
    } else {
      fetch("/api/authenticate", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            // Note login priviledge
            res.json().then((data) => {
              const roleResponse = data.role;
              console.log("Login response: " + roleResponse);
              if (roleResponse.includes("awaitingConfirmation")) {
                alert(this.props.t('register_account_confirmation_email'));
              } else if (roleResponse.includes("awaitingApproval")) {
                alert(this.props.t('register_registration_request'));
              } else if (roleResponse.includes("standard")) {
                this.setState({ redirectToHome: true });
              } else if (roleResponse.includes("admin")) {
                this.setState({ redirectToHome: true });
              } else if (roleResponse.includes("holding")) {
                this.setState({ redirectToHoldingPage: true });
              }
              this.props.checkLoginStatus();
            });
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          alert(this.props.t('notification_error_loggin_in'));
        });
    } // End of else for no form validation errors found
  };

  handleCloseRegisterDiv = () => {
    this.setState({
      showRegisterDiv: false,
    });
  };

  handleCloseLoginHelpDiv = () => {
    this.setState({
      showLoginHelpDiv: false,
    });
  };
  handleCloseRequestPasswordResetDiv = () => {
    this.setState({
      showRequestPasswordResetDiv: false,
    });
  };

  render () {
    if (this.state.redirectToHome === true) {
      return (
        <div>
          <Redirect to="/lobby" />
        </div>
      );
    } else if (this.state.redirectToHoldingPage === true) {
      return (
        <div>
          <Redirect to="/holding" />
        </div>
      );
    } else {
      return (
        <div className={styles.masterPageDiv}>
          <img
            className={styles.fullScreenBackground}
            src={window.$videoPath + "loginBackground.jpg"}
            alt="Background"
          ></img>

          {this.state.showRequestPasswordResetDiv ? (
            <RequestPasswordReset
              handleCloseRequestPasswordResetDiv={
                this.handleCloseRequestPasswordResetDiv
              }
            />
          ) : null}

          {this.state.showRegisterDiv ? (
            <Register
              handleCloseRegisterDiv={this.handleCloseRegisterDiv}
              cookies={this.props.cookies}
            />
          ) : null}

          {this.state.showLoginHelpDiv ? (
            <LoginHelp
              handleCloseLoginHelpDiv={this.handleCloseLoginHelpDiv}
              language={this.props.language}
            />
          ) : null}

          <div className={styles.loginBox} >

            <img
              className={preLoginStyles.preLoginBoxLogo}
              src={window.$videoPath + "logo.png"}
              alt="Logo"
            />
            <h3
              className={styles.helpText}
              onClick={() => this.setState({ showLoginHelpDiv: true })}
            >    <Button
              className="btn btn-success float-left"
            >
                <Trans>shared_help</Trans>
              </Button>
            </h3>
            <h2 className={styles.welcomeText}><Trans>login_welcome</Trans></h2>
            <br />
            <p style={{ fontSize: "0.9em" }}>Our platform is still open for you to explore and watch the on-demand content, so don’t miss out and log in below or create your profile until Friday 12 November 2021.</p>
            <br />
            <Button
              onClick={() => this.setState({ showRegisterDiv: true })}
              className="btn btn-secondary float-left"
            >
              <Trans>profile_create</Trans>
            </Button>
            <br />
            <br />
            <br />
            <h2 className={styles.signInText}><Trans>profile_already_created</Trans></h2>
            <form onSubmit={this.onSubmit}>
              <input
                className={preLoginStyles.textEntry}
                type="email"
                name="email"
                size="50"
                placeholder={this.props.t('login_email_address')}
                tabIndex="1"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
              />
              <br />
              <br />
              <input
                className={preLoginStyles.textEntry}
                type="password"
                name="password"
                placeholder={this.props.t('shared_password')}
                tabIndex="2"
                value={this.state.password}
                onChange={this.handleInputChange}
                required
              />
              <br />
              <br />
              <Button
                className={styles.forgottenPasswordLink}
                onClick={() =>
                  this.setState({ showRequestPasswordResetDiv: true })
                }
              >
                <Trans>login_forgot_password</Trans>
              </Button>
              <Button
                type="submit"
                value="Login"
                className="btn btn-success float-right submitButton"
                tabIndex="3">
                <Trans>login_login</Trans>
              </Button>
            </form>

            <br />
            <br />
            {
              !this.state.showRequestPasswordResetDiv ? <PreLoginLanguageSelection
                setLanguage={this.props.setLanguage}
                cookies={this.props.cookies}
              /> : null
            }
            <img src={window.$videoPath + "visualAssets/en/loginBoxFooter.png"} className={styles.loginBoxFooter} alt="Login Footer" />
          </div >
        </div >
      );
    }
  }
}

export default withTranslation()(Login);
