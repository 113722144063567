import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import { Trans, withTranslation } from 'react-i18next';
import dompurify from "dompurify";
import { Link } from "react-router-dom";
import VideoPlayer from "./../modules/videoPlayer/VideoPlayer"
import { Image, Transformation } from "cloudinary-react";
import { Lightbox } from "react-modal-image";
import { requestPoints } from "./../helpers/requestPoints.js";
import ExhibitionStandQuestions from "./../questions/ExhibitionStandQuestions";
import styles from "./PosterModal.module.scss";

export default class PosterModal extends Component {
  constructor() {
    super();
    this.state = {
      showPrimaryTab: true,
      showPosterLightbox: false,
      posterVotedFor: false,
    };
  }

  posterVideoPlayed = () => {
    requestPoints("playPosterVideo", this.props.poster._id);
  };

  showPosterLightbox = () => {
    requestPoints("viewPosterGalleryPoster", this.props.poster._id);
    this.setState({ showPosterLightbox: true });
  };

  logOpenPDF = () => {
    requestPoints("viewPosterGalleryPosterPDF", this.props.poster._id);
  };

  upvotePoster = () => {
    this.setState({ posterVotedFor: true });
    console.log("Upvoting poster");
    // Submit action for points
    fetch("/api/upvotePoster", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        posterID: this.props.poster._id,
      }),
    })
      .then((result) => result.text())
      .then((response) => {
        console.log("Submitted poster upvote");
      });
    alert("Thank you for your vote!");
  };

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  defaultToPrimaryTab = () => {
    this.setState({ showPrimaryTab: true });
  };

  render () {
    return (
      <Modal
        onAfterOpen={this.defaultToPrimaryTab}
        isOpen={this.props.showPosterModal}
        ariaHideApp={false}
        onRequestClose={this.props.closePosterModal}
        contentLabel="Poster Modal"
        overlayClassName={styles.modalOverlay}
        className={styles.modalContent}
        style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
      >
        <div className={styles.modalFlexboxColumn}>
          <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
            <h1 className={styles.modalPageTitle}>
              {/**<Trans>shared_poster_gallery</Trans>*/}{this.props.poster && this.props.poster.posterName}{" "}
              <Button
                className={styles.posterModalClose}
                variant="success"
                onClick={this.props.closePosterModal}
              >
                <Trans>shared_close</Trans>
              </Button>
              {this.props.showMobile ? <>
                <Button
                  className={styles.tabSelectionButtonQuestion}
                  variant="success"
                  onClick={() => alert("If you have questions for any of the GYECC partners, please contact box.COP26@nationalgrid.com")}
                >
                  <Trans>plenary_ask_questions</Trans>
                </Button>
                <Button
                  className={styles.tabSelectionButtonPoster}
                  variant="success"
                  onClick={() => this.setState({ showPrimaryTab: true })}
                >
                  <Trans>posters_overview</Trans>
                </Button>
              </> : null}
            </h1>


          </div>
          {this.props.showMobile ? null :
            <div className={styles.posterFeatureArea} >
              <img className={styles.posterFeatureImage} src={window.$videoPath + "visualAssets/" + this.props.language + "/posterFeature.png"} />
              <div className={styles.featureBarOverviewDiv} onClick={() => this.setState({ showPrimaryTab: true })} />
              <div className={styles.featureBarQuestionDiv} onClick={() => alert("If you have questions for any of the GYECC partners, please contact box.COP26@nationalgrid.com")} />
            </div>}

          <div className={styles.scrollableArea}>
            {this.state.showPrimaryTab ? (
              <div className={styles.columnContainer}>
                <div className={styles.formColumn}>
                  {this.props.poster &&
                    this.props.poster.posterVideo != null ? (
                    <div>
                      <Image
                        cloudName="oijqec973f1nf"
                        publicId={
                          this.props.poster.associatedPosterSessionID
                        }
                        secure={true}
                        className={styles.heroImage}
                      >
                        <Transformation
                          height="450"
                          width="250"
                        />
                      </Image>
                      <div className={styles.posterVideoDiv}>
                        <VideoPlayer
                          resourceLocator={this.props.poster.posterVideo}
                          location={"poster"}
                          userProfile={this.props.userProfile}
                          autoplay={true}
                        />
                      </div>
                    </div>

                  ) : null}
                  <div className={styles.outerAboutTextDiv}>
                    <div className={styles.aboutTextDiv}>
                      <h2 className={styles.posterNameText}>
                        {/**<strong>Title: </strong>*/}
                        {this.props.poster && this.props.poster.posterName}
                      </h2>
                      {/**<h2 className={styles.posterPresenterNameText}>
                      <strong>Presenter: </strong>
                      {this.props.poster &&
                        this.props.poster.posterPresenterName}
                    </h2>*/}
                      <h2 dangerouslySetInnerHTML={this.sanitizeCustomField(this.props.poster && this.props.poster.aboutText)} className={styles.aboutText}>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className={styles.formColumn}>
                  {this.props.poster ?
                    <a
                      href={
                        window.$videoPath +
                        "postersPDF/" +
                        this.props.poster.posterFilename +
                        ".pdf"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.posterLink}
                      onClick={this.logOpenPDF}
                    >
                      <img
                        alt="Poster preview"
                        className={styles.posterPreview}
                        src={
                          this.props.poster &&
                          window.$videoPath +
                          "postersSmallJPEG/" +
                          this.props.poster.posterFilename +
                          ".jpg"
                        }
                      />
                    </a>
                    : null}
                  {this.state.showPosterLightbox && (
                    <Lightbox
                      onClose={() =>
                        this.setState({ showPosterLightbox: false })
                      }
                      className={styles.posterPreview}
                      large={
                        this.props.poster &&
                        window.$videoPath +
                        "postersLargeJPEG/" +
                        this.props.poster.posterFilename +
                        ".jpg"
                      }
                      alt={this.props.poster && this.props.poster.posterName}
                    />
                  )}
                  <br />
                  {this.props.poster ? (
                    <h3 className={styles.enlargeHint}>
                      <a
                        href={
                          window.$videoPath +
                          "postersPDF/" +
                          this.props.poster.posterFilename +
                          ".pdf"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.posterLink}
                        onClick={this.logOpenPDF}
                      >
                        <img src={window.$videoPath + "icons/posterSearch.png"}
                          className={styles.posterSearchIcon}
                        />
                        <Trans>posters_enlarge_poster</Trans>
                      </a>
                    </h3>
                  ) : null}
                </div>
              </div>
            ) : (
              <div>
                <ExhibitionStandQuestions
                  exhibitionStandID={this.props.poster && this.props.poster._id}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
