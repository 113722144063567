import React, { Component } from "react";
import Modal from "react-modal";
import Button from "react-bootstrap/Button";
import { Trans, withTranslation } from 'react-i18next';
import VideoPlayer from "./../modules/videoPlayer/VideoPlayer"
import { requestPoints } from "../helpers/requestPoints.js";
import ChatFunction from "./../twilioChat/ChatFunction";
import styles from "./Help.module.scss";

export default class Help extends Component {
  constructor() {
    super();
    this.state = {
      showChat: false,
      showFAQ: false,
      showWelcomeVideo: true,
    };
  }

  helpModalOnAfterOpen = () => {
    requestPoints("other", "viewHelp");
  }

  showChat = () => {
    this.setState({
      showChat: true,
      showFAQ: false,
      showWelcomeVideo: false,
    });
  };

  showFAQ = () => {
    this.setState({
      showChat: false,
      showFAQ: true,
      showWelcomeVideo: false,
    });
  };

  showWelcomeVideo = () => {
    this.setState({
      showChat: false,
      showFAQ: false,
      showWelcomeVideo: true,
    });
  };

  render () {
    return (
      <div>
        <Modal
          onAfterOpen={this.helpModalOnAfterOpen}
          ariaHideApp={false}
          isOpen={this.props.showHelpModal}
          onRequestClose={() => this.props.setShowHelpModal(false)}
          contentLabel="Help Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
          style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
        >
          <div className={styles.modalFlexboxColumn}>
            <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
              <h1 className={styles.modalPageTitle}><Trans>shared_help</Trans></h1>
              <button
                className={styles.modalClose}
                variant="success"
                onClick={() => this.props.setShowHelpModal(false)}
              >
                <Trans>shared_close</Trans>
                <img
                  className={styles.modalCloseIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button>
            </div>

            <div className={styles.sectionSelectionPanel}>
              {this.state.showChat ? (
                <Button
                  variant="success"
                  className={styles.sectionSelectionButton}
                  onClick={this.showChat}
                >
                  <Trans>help_request_help</Trans>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className={styles.sectionSelectionButton}
                  onClick={this.showChat}
                >
                  <Trans>help_request_help</Trans>
                </Button>
              )}
              {this.state.showFAQ ? (
                <Button
                  variant="success"
                  className={styles.sectionSelectionButton}
                  onClick={this.showFAQ}
                >
                  <Trans>help_faq</Trans>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className={styles.sectionSelectionButton}
                  onClick={this.showFAQ}
                >
                  <Trans>help_faq</Trans>
                </Button>
              )}
              {this.state.showWelcomeVideo ? (
                <Button
                  variant="success"
                  className={styles.sectionSelectionButton}
                  onClick={this.showWelcomeVideo}
                >
                  <Trans>help_welcome_video</Trans>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className={styles.sectionSelectionButton}
                  onClick={this.showWelcomeVideo}
                >
                  <Trans>help_welcome_video</Trans>
                </Button>
              )}
            </div>

            <div className={styles.scrollableArea}>
              {this.state.showChat ? (
                <div>
                  <h1><Trans>help_request_help</Trans></h1>
                  <p className={styles.helpMessage}>
                    <Trans>help_request_help_info_text</Trans>
                  </p>
                  <br />
                  <div className={styles.helpChatBoxArea}>
                    <ChatFunction
                      userProfile={this.props.userProfile}
                      chatChannel="help-ng-yec"
                    />
                  </div>
                  <br />
                </div>
              ) : null}
              {this.state.showFAQ && this.props.language === "en" ? (
                <div>
                  <h1>FAQ</h1>
                  <div className={styles.faqElements}>
                    <p>
                      <br />
                      <strong>I am having technical issues. Who can I contact?</strong>
                      <br />
                      Click on Request help, where our support team are available on the event live days; 13:30 – 20:00 BST on Tuesday 7 September 2021 and 04:30 – 11:00 BST on Wednesday 8 September 2021. For any queries outside of this time, please contact <a href="mailto:box.COP26@nationalgrid.com">box.COP26@nationalgrid.com</a>
                    </p>
                    <p>
                      <strong>I’ve registered for GYECC21. Is the program on Wednesday 8 September different from Tuesday 7 September?</strong>
                      <br />
                      The program is essentially the same both days with minor possible differences in presenters leading certain sessions and in the live plenary panel line-up. The conference takes place over two half days to maximise the opportunity for the widest possible breadth of nations to attend across different time zones.
                    </p>
                    <p>
                      <strong>Do I need to attend both days of the conference?</strong>
                      <br />
                      There is no need to attend both days of the conference. The program is essentially the same on both half days with minor possible differences in presenters leading certain sessions and in the live plenary panel line-up. Content will be available on demand for both days following the event.
                    </p>
                    <p>
                      <strong>Where can I find out what is happening during the event?</strong>
                      <br />
                      Click on Agenda to see which sessions are running in the plenary and head to Plan my day to select which workstreams you would like to attend.
                    </p>
                    <p>
                      <strong>Where do I go to watch plenary sessions? </strong>
                      <br />
                      These will take place in the Plenary ampitheatre. Check the Agenda for when these will be live.
                    </p>
                    <p>
                      <strong>Can I add the sessions I want to attend to my agenda? </strong>
                      <br />
                      All sessions in the Plenary are automatically added to your agenda. To pick which workstream you would like to join:
                      <ol>
                        <li>Go to ‘Plan my day’</li>
                        <li>Choose the sessions that you would like to join at each time slot</li>
                        <li>Click ‘Save and exit’</li>
                        <li>Your chosen workstream will be added to your agenda </li>
                      </ol>
                      Changed your mind? You can change your selection at any time – just follow the above steps.
                    </p>
                    <p>
                      <strong>Where do I go to join the workstream sessions?</strong>
                      <br />
                      All workstreams can be viewed in the workstream pods – accessible from the eco park or the navigation menu. Check the Agenda to see which session is in which pod.
                    </p>
                    <p>
                      <strong>I understand that there is a choice of four different workstreams, how many can I attend?</strong>
                      <br />
                      You will be able to attend two sessions each day, one after another. All workstreams will run simultaneously and then repeat, a few minutes after the preceding session has finished. If you miss a session, you will be able to watch this on-demand after the event.
                    </p>
                    <p>
                      <strong>Will there be translation facilities available within the virtual conference environment?</strong>
                      <br />
                      Yes, the plenary and workstreams will be simultaneously translated from English into:
                      <ul>
                        <li>French </li>
                        <li>Spanish</li>
                        <li>Mandarin </li>
                      </ul>
                      Once in the Plenary or Workstream area, you will be able to choose your preferred language from a drop-down list in the ‘Languages’ tab on the right hand side of the video. <br /><br />
                      We will do our best to answer any questions that are posted in the Q&A box in different languages but there might be a slight delay whilst these are being translated. Please note that we might not be able to answer all questions.
                    </p>
                    <p>
                      <strong>What type of people should I expect to meet and hear from at the event?</strong>
                      <br />
                      Along with a wide range of global organisations who will be hosting exhibition stands/booths, you can expect to hear from a wide range of international speakers working in engineering or related fields. They will either share short case study experiences on their work within the field of renewable energy via short films on GYECC TV, or you can watch them take part in a variety of forums including discussion panels and quizzes throughout the conference.
                    </p>
                    <p>
                      <strong>How do I network with other delegates? </strong>
                      <br />
                      There are number of ways you can Get Connected with fellow delegates. You can set up a 1:1 meeting in the Attendee Gallery or join a Networking Table.<br /><br />

                      <i>Please note that 1:1 meetings are only available to over 18’s in order to help safeguard our youth audience. All networking tables are available to all age groups.</i>
                    </p>
                    <p>
                      <strong>How do I set up a 1:1 meeting?</strong>
                      <br />
                      <ol>
                        <li>Click on ‘Attendees’</li>
                        <li>Use the search field, or scroll to find a delegate / exhibitor you would like to have a meeting with</li>
                        <li>Click on their profile</li>
                        <li>Choose a time, and click ‘Submit’</li>
                        <li>An email will be sent to notify them that you would like to meet</li>
                      </ol>
                      If your request is accepted, you will both receive a confirmation email and the meeting will be added to your agenda. If they are unable to attend the time you have suggested, but would still like to meet, you will receive an email to notify you of this. You can then go back to their profile to suggest a different time.<br /><br />

                      <i>Please note that 1:1 meetings are only available to over 18’s in order to help safeguard our youth audience. All networking tables are available to all age groups.</i>

                    </p>
                    <p>
                      <strong>How do I join a 1:1 meeting? </strong>
                      <br />
                      <ol>
                        <li>Click on ‘Plan my day’ and select ‘My meetings’ or visit ‘My agenda’</li>
                        <li>Click ‘Join’ at the time of your meeting</li>
                        <li>You will be taken to your video call</li>
                        <li>Click ‘Connect’</li>
                        <li>Once you are finished, click ‘Disconnect’</li>
                      </ol>
                    </p>
                    <p>
                      <strong>How do I join a Networking Table?</strong>
                      <br />
                      <ol>
                        <li>Head to the Networking lounge</li>
                        <li>Click on ‘Networking tables’</li>
                        <li>Scroll through the page to find the networking topic you would like to join</li>
                        <li>Click ‘Join meeting’</li>
                        <li>Allow your browser to use your camera and audio</li>
                        <li>You will then be taken to your video ‘Networking table’</li>
                      </ol>
                      Please note when you join, the ‘Networking table’ may already have started.
                    </p>
                    <p>
                      <strong>I missed one of the sessions. How can I catch up?</strong>
                      <br />
                      Recordings will be available to watch after the event in the Event Rewind area of GYECC TV.
                    </p>
                  </div>

                  <br />
                </div>
              ) : null}

              {this.state.showFAQ && this.props.language === "es" ? (
                <div>
                  <h1>Preguntas frecuentes</h1>
                  <div className={styles.faqElements}>
                    <p>
                      <br />
                      <strong>Tengo problemas técnicos. ¿Con quién debo comunicarme?</strong>
                      <br />
                      Haga clic en Ayuda para que nuestro equipo de asistencia, disponible en los días de actividad del evento, de 13:30 a 20:00 el martes 7 de septiembre de 2021 y de 04:30 a 11:00 BST el miércoles 8 de septiembre de 2021. Si tiene consultas fuera de ese horario, comuníquese con <a href="mailto:box.COP26@nationalgrid.com">box.COP26@nationalgrid.com</a>
                    </p>
                    <p>
                      <strong>Me he registrado para GYECC21. ¿El programa del miércoles 8 de septiembre es distinto del programa del martes 7 de septiembre?</strong>
                      <br />
                      El programa es básicamente el mismo los dos días, aunque es posible que haya pequeñas diferencias en los presentadores que llevarán a cabo determinadas sesiones, así como en la composición del panel plenario en vivo. La conferencia se realiza durante dos medios días para maximizar la oportunidad de llegar a la mayor cantidad posible de países, cuyos asistentes estarán distribuidos en distintos husos horarios.
                    </p>
                    <p>
                      <strong>¿Es necesario que asista a ambos días de la conferencia?</strong>
                      <br />
                      No hay necesidad de asistir a la conferencia los dos días. El programa es básicamente el mismo los dos medios días, aunque es posible que haya pequeñas diferencias en los presentadores que llevarán a cabo determinadas sesiones, así como en la composición del panel plenario en vivo. El contenido de los dos días estará disponible para mirar bajo demanda después del evento.
                    </p>
                    <p>
                      <strong>¿Dónde puedo enterarme de lo que sucederá durante el evento?</strong>
                      <br />
                      Haga clic en Agenda para ver las sesiones que se realizarán en la plenaria y vaya a Planificar el día para seleccionar los flujos de trabajo a los que desea asistir.
                    </p>
                    <p>
                      <strong>¿Dónde debo ir para mirar las sesiones plenarias?</strong>
                      <br />
                      Las sesiones plenarias se realizarán en el anfiteatro para plenarias. Haga clic en la Agenda para saber cuándo se realizarán estas sesiones.
                    </p>
                    <p>
                      <strong>¿Puedo agregar a mi agenda las sesiones a las que deseo asistir?</strong>
                      <br />
                      Todas las sesiones de la plenaria se agregarán automáticamente a su agenda. Para seleccionar los flujos de trabajo a las que desea asistir, haga lo siguiente:
                      <ol>
                        <li>Vaya a “¨Planificar el día”.</li>
                        <li>Seleccione las sesiones a las que desea asistir en cada horario.</li>
                        <li>Haga clic en “Guardar y salir”.</li>
                        <li>El flujo de trabajo seleccionado se agregará a su agenda. </li>
                      </ol>
                      ¿Cambió de opinión? Para cambiar la opción elegida, simplemente repita los pasos anteriores.
                    </p>
                    <p>
                      <strong>¿Dónde debo ir para unirme a las sesiones de los flujos de trabajo?</strong>
                      <br />
                      Todos los flujos de trabajo pueden verse en los grupos de flujo de trabajo, a los que puede acceder mediante Eco-Park o el menú de navegación. Revise la Agenda para ver qué sesión corresponde a qué grupo.
                    </p>
                    <p>
                      <strong>Entiendo que puedo elegir entre cuatro flujos de trabajo diferentes. ¿A cuántos puedo asistir?</strong>
                      <br />
                      Podrá asistir a dos sesiones cada día en forma consecutiva. Todos los flujos de trabajo se llevarán a cabo en forma simultánea y se repetirán unos pocos minutos después de que termine la sesión anterior. Si se pierde una sesión, podrá mirarla bajo demanda después del evento.
                    </p>
                    <p>
                      <strong>¿El entorno de la conferencia virtual contará con servicios de traducción?</strong>
                      <br />
                      Sí, la sesión plenaria y los flujos de trabajo se traducirán en forma simultánea del inglés a los siguientes idiomas:
                      <ul>
                        <li>Francés</li>
                        <li>Español</li>
                        <li>Mandarín</li>
                      </ul>
                      Una vez que se encuentre en el área de la plenaria o del flujo de trabajo, podrá elegir su idioma en una lista desplegable de la pestaña “Idiomas”, a la derecha del vídeo. <br /><br />
                      Haremos todo lo posible por responder las preguntas que se publiquen en el recuadro de preguntas y respuestas en distintos idiomas, pero es posible que haya alguna demora mientras se traducen los mensajes. Tenga en cuenta que es posible que lleguemos a responder todas las preguntas.
                    </p>
                    <p>
                      <strong>¿A qué tipos de personas podré conocer y escuchar en el evento?</strong>
                      <br />
                      Además de una amplia gama de organizaciones que tendrán puestos de exposición, podrá escuchar a una amplia gama de disertantes internacionales que trabajan en ingeniería y en campos relacionados. Estas personas compartirán experiencias de trabajo en el campo de la energía renovable en forma de breves estudios de caso mediante vídeos cortos en GYECC TV. También podrá verlas participar en diversos foros, incluidos paneles de discusión y cuestionarios durante la conferencia.
                    </p>
                    <p>
                      <strong>¿Cómo hago para formar redes con otros delegados?  </strong>
                      <br />
                      Hay diversas formas de conectarse con otros delegados. Puede programar una reunión individual en la Galería de asistentes o participar en una Mesa de formación de redes. <br /><br />

                      <i>Tenga en cuenta que, a fin de proteger al público más joven, las reuniones individuales solo están disponibles para los mayores de 18 años. Todas las mesas de formación de redes están disponibles para todos los grupos de edad.</i>
                    </p>
                    <p>
                      <strong>¿Cómo programo una reunión individual?</strong>
                      <br />
                      <ol>
                        <li>Haga clic en “Asistentes”.</li>
                        <li>Use el campo de búsqueda o explore la lista hasta encontrar un delegado o expositor con quien desee reunirse. </li>
                        <li>Haga clic en el perfil de esa persona.</li>
                        <li>Seleccione un horario y haga clic en “Enviar”.</li>
                        <li>Se le enviará un correo electrónico a la persona para informarle que usted desea tener una reunión.</li>
                      </ol>
                      Si se acepta la solicitud, ambas partes recibirán un correo electrónico de confirmación, y la reunión se agregará a su agenda. Si la persona no puede asistir en el horario que usted sugirió, pero desea reunirse, se lo avisaremos a usted mediante un correo electrónico. Después, usted podrá ir nuevamente al perfil de esta persona para sugerir otro horario.<br /><br />

                      <i>Tenga en cuenta que, a fin de proteger al público más joven, las reuniones individuales solo están disponibles para los mayores de 18 años. Todas las mesas de formación de redes están disponibles para todos los grupos de edad.  </i>

                    </p>
                    <p>
                      <strong>¿Cómo me uno a una sesión individual?</strong>
                      <br />
                      <ol>
                        <li>Haga clic en “Planificar el día” y seleccione “Mis reuniones” o visite “Mi agenda”.</li>
                        <li>Haga clic en “Unirse” cuando llegue el horario de su reunión.</li>
                        <li>El sistema lo llevará a un videollamada.</li>
                        <li>Haga clic en “Conectar”.</li>
                        <li>Cuando termine, haga clic en “Desconectar”.</li>
                      </ol>
                    </p>
                    <p>
                      <strong>¿Cómo me sumo a una mesa de formación de redes?</strong>
                      <br />
                      <ol>
                        <li>Vaya al Salón de formación de redes.</li>
                        <li>Haga clic en “Mesas de formación de redes”. </li>
                        <li>Desplácese por la página hasta encontrar el tema de formación de redes al que desea unirse.</li>
                        <li>Haga clic en “Unirme a esta reunión”.</li>
                        <li>Deje que el navegador use la cámara y el micrófono.</li>
                        <li>El sistema lo llevará a su “Mesa de formación de redes” en vídeo. </li>
                      </ol>
                      Tenga en cuenta que es posible que la mesa de formación de redes ya haya comenzado cuando usted se una.
                    </p>
                    <p>
                      <strong>Me he perdido una de las sesiones. ¿Cómo puedo verla? </strong>
                      <br />
                      Las grabaciones estarán disponibles para verlas después del evento en el área de Repetición del evento de GYECC TV.
                    </p>
                  </div>

                  <br />
                </div>
              ) : null}

              {this.state.showFAQ && this.props.language === "fr" ? (
                <div>
                  <h1>Foire aux questions</h1>
                  <div className={styles.faqElements}>
                    <p>
                      <br />
                      <strong>J’ai des problèmes techniques. Qui puis-je contacter ?</strong>
                      <br />
                      Cliquez sur « Aide » ; notre équipe d’assistance est disponible les jours de l’événement en direct ; mardi 7 septembre 2021 de 13 h 30 à 20 h 00 (UTC+1) et mercredi 8 septembre 2021 de 4 h 30 à 11 h 00 (UTC+1). S’il y a des questions que vous souhaiteriez poser en dehors de ces dates et heures, veuillez envoyer un courriel à l’adresse <a href="mailto:box.COP26@nationalgrid.com">box.COP26@nationalgrid.com</a>
                    </p>
                    <p>
                      <strong>Je me suis inscrit(e) à la GYECC21. Le programme du mercredi 8 septembre est-il différent de celui du mardi 7 septembre ?</strong>
                      <br />
                      Pour l’essentiel, le programme sera le même les deux jours, avec d’éventuelles différences mineures relatives aux présentateurs de certaines séances et aux participants des séances plénières en direct. La conférence se déroule sur deux demi-journées pour permettre à autant de pays que possible de participer sur différents fuseaux horaires.

                    </p>
                    <p>
                      <strong>Dois-je participer aux deux jours de la conférence ?</strong>
                      <br />
                      Il n’est pas nécessaire de participer aux deux jours de la conférence. Pour l’essentiel, le programme est le même les deux demi-journées, avec quelques différences possibles relatives aux présentateurs de certaines séances et aux participants des séances plénières en direct.

                    </p>
                    <p>
                      <strong>Comment puis-je savoir ce qui se passe pendant l’événement ?</strong>
                      <br />
                      Cliquez sur « Programme » pour voir quelles séances se déroulent en plénière et accédez à la section « Planifier ma journée » pour sélectionner les groupes de travail auxquels vous souhaitez participer.
                    </p>
                    <p>
                      <strong>Où puis-je regarder les séances plénières ? </strong>
                      <br />
                      Elles se dérouleront dans l’amphithéâtre réservé aux séances plénières. Vérifiez votre « Programme » pour déterminer quand elles auront lieu en direct.
                    </p>
                    <p>
                      <strong>Puis-je ajouter à mon programme les séances auxquelles je souhaite participer ? </strong>
                      <br />
                      Toutes les séances en plénière sont automatiquement ajoutées à votre programme. Pour choisir le groupe de travail auquel vous souhaitez participer :
                      <ol>
                        <li>Allez dans « Planifier ma journée »</li>
                        <li>Choisissez les séances auxquelles vous souhaitez participer pour chaque créneau horaire</li>
                        <li>Cliquez sur « Enregistrer et quitter »</li>
                        <li>Le groupe de travail que vous avez choisi sera ajouté à votre programme </li>
                      </ol>
                      Vous avez changé d’avis ? Vous pouvez modifier votre sélection à tout moment – suivez simplement les étapes ci-dessus.
                    </p>
                    <p>
                      <strong>Où puis-je rejoindre les séances de groupes de travail ?</strong>
                      <br />
                      Vous pouvez consulter tous les groupes de travail dans les cellules de groupes de travail – accessibles depuis le parc écologique ou dans le menu de navigation. Vérifiez le programme pour voir quelle séance se trouve dans quelle cellule.
                    </p>
                    <p>
                      <strong>Je crois comprendre qu’il est possible de choisir entre quatre groupes de travail différents – à combien d’entre eux puis-je participer ?</strong>
                      <br />
                      Vous pourrez participer à deux séances chaque jour, l’une après l’autre. Tous les groupes de travail se dérouleront simultanément et seront ensuite répétés quelques minutes après la fin de la séance précédente. Si vous manquez une séance, vous pourrez la regarder sur demande après l’événement.
                    </p>
                    <p>
                      <strong>Des services de traduction seront-ils disponibles dans l’environnement virtuel de la conférence ?</strong>
                      <br />
                      Oui, les séances plénières et les groupes de travail seront traduits simultanément de l’anglais dans les langues suivantes :
                      <ul>
                        <li>Français</li>
                        <li>Espagnol</li>
                        <li>Mandarin</li>
                      </ul>
                      Une fois dans la section des séances plénière ou des groupes de travail, vous aurez la possibilité de choisir la langue que vous préférez dans une liste déroulante, depuis l’onglet « Langues » à droite de la vidéo. <br /><br />
                      Nous ferons de notre mieux pour répondre à toutes les questions qui sont soumises dans l’encadré de questions-réponses dans différentes langues, mais il peut falloir patienter un peu le temps qu’elles soient traduites. Veuillez noter que nous ne serons pas nécessairement en mesure de répondre à toutes les questions.
                    </p>
                    <p>
                      <strong>Quels types de personnes vais-je rencontrer et entendre parler lors de l’événement ?</strong>
                      <br />
                      Outre le grand nombre d’organisations mondiales qui tiendront des stands lors de l’exposition, vous aurez l’occasion d’entendre une multitude d’intervenants internationaux qui travaillent dans l’ingénierie ou dans des domaines associés. Ils présenteront brièvement leurs expériences professionnelles d’études de cas dans le domaine des énergies renouvelables, par le biais de films courts diffusés sur GYECC TV, ou vous pouvez également regarder leurs interventions dans le cadre de forums divers, y compris lors de tables rondes et de séances de questions, organisés tout au long de la conférence.
                    </p>
                    <p>
                      <strong>Comment puis-je établir des liens avec les autres délégués ? </strong>
                      <br />
                      Vous pouvez prendre contact avec les autres délégués de plusieurs manières. Vous pouvez organiser une rencontre en tête à tête dans la galerie des participants ou participer à une séance de réseautage.<br /><br />

                      <i>Veuillez noter que, pour assurer la protection de notre jeune public, les rencontres en tête à tête sont exclusivement réservées aux plus de 18 ans. Toutes les séances de réseautage sont disponibles à tous les groupes d’âge.</i>
                    </p>
                    <p>
                      <strong>Comment puis-je organiser une rencontre en tête à tête ?</strong>
                      <br />
                      <ol>
                        <li>Cliquez sur « Participants »</li>
                        <li>Utilisez le champ de recherche ou parcourez la page pour trouver un délégué/exposant que vous souhaitez rencontrer </li>
                        <li>Cliquez sur son profil</li>
                        <li>Choisissez une heure et cliquez sur « Soumettre »</li>
                        <li>Un courriel sera envoyé à la personne pour l’informer que vous souhaitez la rencontrer</li>
                      </ol>
                      Si votre demande est acceptée, vous et cette personne recevrez un courriel de confirmation et la rencontre sera ajoutée à votre programme. Si la personne ne peut pas vous rencontrer à l’heure que vous avez proposée mais qu’elle souhaite pouvoir vous rencontrer, vous recevrez un courriel pour vous en informer. Vous pouvez ensuite retourner sur son profil pour proposer une heure différente.<br /><br />

                      <i>Veuillez noter que, pour assurer la protection de notre jeune public, les rencontres en tête à tête sont exclusivement réservées aux plus de 18 ans. Toutes les séances de réseautage sont disponibles à tous les groupes d’âge.  </i>

                    </p>
                    <p>
                      <strong>Comment puis-je participer à une rencontre en tête à tête ?</strong>
                      <br />
                      <ol>
                        <li>Cliquez sur « Planifier ma journée » et sélectionnez « Mes rencontres » ou accédez à « Mon programme »</li>
                        <li>À l’heure prévue pour votre rencontre, cliquez sur « Participer »</li>
                        <li>Le système vous renverra à votre appel vidéo</li>
                        <li>Cliquez sur « Se connecter »</li>
                        <li>Une fois que vous avez terminé, cliquez sur « Se déconnecter »</li>
                      </ol>
                    </p>
                    <p>
                      <strong>Comment puis-je participer à une séance de réseautage ?</strong>
                      <br />
                      <ol>
                        <li>Accédez au salon de réseautage</li>
                        <li>Cliquez sur « Séances de réseautage » </li>
                        <li>Parcourez la page pour trouver le sujet de séance de réseautage qui vous intéresse</li>
                        <li>Cliquez sur « Participer à la séance »</li>
                        <li>Autorisez votre navigateur à utiliser vos fonctions de caméra et d’audio</li>
                        <li>Le système vous redirige ensuite vers votre vidéo de « séance de réseautage » </li>
                      </ol>
                      Veuillez noter que, quand vous y accédez, la « séance de réseautage » peut déjà avoir commencé.
                    </p>
                    <p>
                      <strong>J’ai manqué l’une des séances. Comment puis-je la consulter ? </strong>
                      <br />
                      Il sera possible de regarder des enregistrements après l’événement dans la zone « Rediffusion de l’événement » de GYECC TV.
                    </p>
                  </div>

                  <br />
                </div>
              ) : null}

              {this.state.showFAQ && this.props.language === "zh" ? (
                <div>
                  <h1>常见问题</h1>
                  <div className={styles.faqElements}>
                    <p>
                      <br />
                      <strong>我遇到一些技术问题。我可以联系谁？</strong>
                      <br />
                      点击“帮助”，我们的支持团队将在活动当天提供帮助；英国夏令时间 2021 年 9 月 7 日星期二 13:30 – 20:00 和英国夏令时间 2021 年 9 月 8 日星期三 04:30 – 11:00。如果在此时段之外有任何疑问，请联系 <a href="mailto:box.COP26@nationalgrid.com">box.COP26@nationalgrid.com</a>
                    </p>
                    <p>
                      <strong>我已经注册了 GYECC21。9 月 8 日星期三的项目与 9 月 7 日星期二的项目有什么不同？</strong>
                      <br />
                      两天的项目基本相同，只在某些会议的主持人和现场小组成员阵容上存在细微差别。会议举行时间为两个半天，旨在最大限度地让不同时区里尽可能多的国家得以有机会参加。
                    </p>
                    <p>
                      <strong>对于这两天的会议，我需要全都参加吗？</strong>
                      <br />
                      没有必要两天会议全都参加。两个半天的项目基本相同，只在某些会议的主持人和现场小组成员阵容上存在细微差别。活动结束之后，这两天的项目内容均将按需提供。
                    </p>
                    <p>
                      <strong>我在哪里能找到活动期间的相关内容？</strong>
                      <br />
                      点击日程，看看有哪些会话需全体出席，前往‘规划我的日程’，以选择想要参加的工作流。
                    </p>
                    <p>
                      <strong>我应该去哪里查看全体会议？ </strong>
                      <br />
                      这些会议将在全体大会堂举行。查看日程，以了解其召开时间。
                    </p>
                    <p>
                      <strong>我能否将想要参加的会议加入到我的日程中？</strong>
                      <br />
                      需全体出席的所有会议都将被自动加入到您的日程中。选择您想要加入的工作流：
                      <ol>
                        <li>前往‘规划我的日程’</li>
                        <li>选择您想要在各时段参加的会议</li>
                        <li>点击‘保存并退出’</li>
                        <li>您选择的工作流将被添加到您的日程中 </li>
                      </ol>
                      是否改变了主意？您可随时更改选择——只需按上述步骤操作。
                    </p>
                    <p>
                      <strong>去哪里参加工作流会议？</strong>
                      <br />
                      所有工作流都将显示在工作流窗格中——可从生态园或导航菜单访问。检查日程，以查看哪些会议在哪个窗格中。
                    </p>
                    <p>
                      <strong>我知道有四种不同的工作流可供选择，但我可以参加多少种？</strong>
                      <br />
                      您可以每天依次参加两场会议。所有工作流都将同时运行，然后在上一场会议结束后的数分钟内重复。如果您错过了一场会议，可以在此活动结束之后要求观看录像。
                    </p>
                    <p>
                      <strong>虚拟会议环境中是否提供翻译服务？</strong>
                      <br />
                      是的，全体会议和工作流都将被同时从英文译为：
                      <ul>
                        <li>法语</li>
                        <li>西班牙语</li>
                        <li>中文 </li>
                      </ul>
                      进入全体会议或工作流栏目后，您可从视频右侧“语言”选项卡的下拉菜单中选择您的首选语言。 <br /><br />
                      我们将尽最大努力回答那些以不同语言发布于问答栏中的所有问题，但由于需要进行翻译，所以可能会有稍许延迟。请注意，我们可能无法将所有问题都一一作答。
                    </p>
                    <p>
                      <strong>我会在活动中遇到哪些类型的人？</strong>
                      <br />
                      除了将主持展台/展位的众多全球组织外，您还可以聆听来自工程或相关领域的众多国际嘉宾的演讲。他们将通过 GYECC 电视上的短片分享各自在可再生能源领域中通过工作总结的案例研究经验，或者您也可以在整场会议期间观看他们参加各大论坛，包括讨论小组和知识竞赛。
                    </p>
                    <p>
                      <strong>我该如何联系其他代表？</strong>
                      <br />
                      您可以通过多种方式与其他代表建立联系。您可以在与会者相册中安排 1:1 会议，也可以加入联网平台。<br /><br />

                      <i>请注意，1:1 会议仅适用于年满 18 岁的成员，以帮助保护我们的青少年观众。所有联网平台都将面向所有年龄段成员开放。 </i>
                    </p>
                    <p>
                      <strong>我该如何安排 1:1 会议？</strong>
                      <br />
                      <ol>
                        <li>点击‘与会者’</li>
                        <li>使用搜索字段，或滚动查找您想与之会面的代表/参展商 </li>
                        <li>点击对方的资料</li>
                        <li>选择时间，并点击‘提交’</li>
                        <li>我们将发送一封电子邮件，以通知他们您想与之会面</li>
                      </ol>
                      如果您的请求被接受，你们双方都会收到一封确认电子邮件，该会议也将被添加到你们的日程中。如果对方无法按您提议的时间出席，但仍愿参加会议，您将会收到一封注明详情的电子邮件。然后，您可以返回其资料页面，以提议其他时间。<br /><br />

                      <i>请注意，1:1 会议仅适用于年满 18 岁的成员，以帮助保护我们的青少年观众。所有联网平台都将面向所有年龄段成员开放。 </i>

                    </p>
                    <p>
                      <strong>我该如何加入 1:1 会议？</strong>
                      <br />
                      <ol>
                        <li>点击‘规划我的日程’，并选择‘我的会议’或访问‘我的日程’</li>
                        <li>在会议时间点击‘参加’</li>
                        <li>您将被带至视频通话</li>
                        <li>点击‘连通’</li>
                        <li>完成后，点击‘断开连接’</li>
                      </ol>
                    </p>
                    <p>
                      <strong>我该如何加入联网平台？</strong>
                      <br />
                      <ol>
                        <li>前往‘联网大厅’</li>
                        <li>点击‘联网平台’ </li>
                        <li>滚动页面，以查找您想要加入的联网主题</li>
                        <li>点击‘加入会议’</li>
                        <li>允许您的浏览器使用摄像头和音频</li>
                        <li>之后，您将跳转至视频版的‘联网平台’</li>
                      </ol>
                      请注意，当您加入时，该‘联网平台’可能已经开始。
                    </p>
                    <p>
                      <strong>我错过了一场会议。我该如何补救？</strong>
                      <br />
                      活动结束后，您可通过 GYECC 电视的‘事件回放’选项观看录制内容。
                    </p>
                  </div>

                  <br />
                </div>
              ) : null}
              {this.state.showWelcomeVideo ? (
                <div className={styles.welcomeVideoDiv}>
                  <VideoPlayer
                    resourceLocator={"mux://Pc3oGMqUoMa37JOx1K5ZRBwcf7h3201yLNfPJl9leLUk"}
                    location={"help-welcome-video"}
                    userProfile={this.props.userProfile}
                    autoPlay={true}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
