import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import SlidoEmbed from "./../modules/slidoEmbed/SlidoEmbed";
import { Redirect, Link } from "react-router-dom";
import { Jutsu } from "react-jutsu"; // Component
import ChatFunction from "./../twilioChat/ChatFunction";
import styles from "./RoundtableSession.module.scss";

export default class RoundtableSession extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      roundtableID: "",
      tableName: "",
      discussionTopic: "",
      roundtableURL: "",
      roundtableType: 10,
      displayContent: false,
      redirectToRoundtables: false,
      returnText: "Roundtables",
      returnPath: "/visualRoundtablesDirect",
      slidoEventID: "",
    };
  }

  componentDidMount () {
    // Hide corner menu
    this.props.setCornerMenuStatus(3);

    fetch(
      "/api/getRoundtable?roundtableID=" + this.props.match.params.roundtableID
    )
      .then((res) => res.json())
      .then((data) => this.loadResponseIntoState(data));

    if (this.props.match.params.returnType !== null) {
      if (this.props.match.params.returnType === "exhibitionStand") {
        this.setState({ returnText: "Exhibition", returnPath: "/exhibitionStand/" + this.props.match.params.returnID });
      } else if (this.props.match.params.returnType === "posters") {
        this.setState({ returnText: "Poster Gallery", returnPath: "/posters" });
      }
    }

    // Scroll to top
    window.scrollTo(0, 0);
  }

  componentWillUnmount () {
    // Set corner menu to displayed again
    this.props.setCornerMenuStatus(1);
  }

  componentDidUpdate () {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 3) {
      console.log(this.props.cornerMenuStatus);
      this.props.setCornerMenuStatus(3);
    }
  }


  loadResponseIntoState (data) {
    this.setState({
      roundtableID: data.roundtable._id,
      tableName: data.roundtable.tableName,
      discussionTopic: data.roundtable.discussionTopic,
      roundtableURL: data.roundtable.roundtableURL,
      roundtableType: data.roundtable.roundtableType,
      displayContent: true,
      slidoEventID: data.roundtable.slidoEventID,
    });
  }

  render () {
    const displayName = this.props.userProfile
      ? this.props.userProfile.firstName
      : "";

    return (
      <div>
        <div className={styles.outerBackgroundDiv}>
          {/**<img
            className="imageNavBackground"
            src={window.$videoPath + "background.png"}
            alt="Background"
          ></img>*/}
        </div>

        <div className={styles.hotlinkBar}>
          <Link to={this.state.returnPath} className={styles.backLink}>
            <div className={styles.backDiv}>
              <img
                className={styles.backArrow}
                src={window.$videoPath + "icons/backArrow.png"}
                alt="Back"
              />
              <Trans>networking_back_to_tables</Trans>{/**Back to {this.state.returnText}*/}
            </div>
          </Link>
        </div>

        <div className={styles.headerBar}>
          <h1 className={styles.pageTitle}>
            <strong>{this.state.tableName}</strong>
          </h1>
        </div>

        {
          this.state.displayContent &&
            this.props.userProfile &&
            this.state.roundtableType > 3 &&
            this.state.roundtableType < 10 ? (
            <div className={styles.chatDiv}>
              <ChatFunction
                userProfile={this.props.userProfile}
                chatChannel={this.props.match.params.roundtableID}
              />
            </div>
          ) : null
        }

        {
          this.state.displayContent && this.state.roundtableType > 100 ? (
            <div className={styles.jitsiDiv}>
              <Jutsu
                containerStyles={{ width: "100%", height: "100%" }}
                roomName={this.props.match.params.roundtableID}
                displayName={displayName}
                onMeetingEnd={() =>
                  this.setState({ redirectToRoundtables: true })
                }
                loadingComponent={<p>loading ...</p>}
              />
            </div>
          ) : null
        }

        {
          this.state.displayContent && this.state.roundtableType > 0 ? (
            <div className={styles.iFrameDiv}>
              <iframe
                title="Meeting Frame"
                src={this.state.roundtableURL}
                className={styles.iFrame}
                allow="camera; microphone; fullscreen; speaker; display-capture"
              />
            </div>
          ) : null
        }

        {
          this.state.slidoEventID && this.state.roundtableType > 0 ? (
            <div className={styles.slidoDiv}>
              <SlidoEmbed
                slidoEventID={this.state.slidoEventID}
                userProfile={this.props.userProfile}
              />
            </div>
          ) : null
        }



        {
          this.state.redirectToRoundtables ? (
            <Redirect to="/visualRoundtables" />
          ) : null
        }
      </div>
    );
  }
}
