export const TRANSLATIONS_ES = {
    agenda_join: "Unirse",
    attendees_back_to_eco_park: "Volver al ecoparque",
    attendees_country: "País",
    attendees_info_text_old: "Busque personas con cargos similares, cerca de su ubicación o con intereses compartidos y converse con ellas",
    breakouts_ask_your_questions: "Haga sus preguntas",
    breakouts_breakout_explained: "Explicación de los flujos de trabajo",
    exhibition_back_to_hall: "Volver a la sala",
    exhibition_click_to_view_and_download: "Haga clic para ver y descargar",
    exhibition_contact: "Contacto",
    exhibition_enter_your_message: "Ingrese su mensaje",
    exhibition_find_out_more: "Más información",
    exhibition_go: "Ir",
    exhibition_hall_one: "Sala 1",
    exhibition_learn_more: "Más información",
    exhibition_leave_your_details: "Deje sus datos",
    exhibition_live_text_chat: "Chat de texto en vivo",
    exhibition_meet_and_greet: "Acto de bienvenida",
    exhibition_message: "Mensaje",
    exhibition_qa: "Preguntas y respuestas",
    exhibition_send: "Enviar",
    exhibition_text_chat: "Chat de texto",
    help_faq: "Preguntas frecuentes",
    help_request_help: "Solicitar ayuda",
    help_request_help_info_text: "Si necesita ayuda durante el evento, envíe su solicitud a continuación. Un miembro del equipo de apoyo se comunicará con usted lo antes posible.",
    help_welcome_video: "Vídeo de bienvenida",
    jobs_apac: "APAC (Asia-Pacífico)",
    jobs_associate: "Asociado",
    jobs_at_least_eight: "Al menos GBP 80,000",
    jobs_at_least_five: "Al menos GBP 50,000",
    jobs_at_least_four: "Al menos GBP 40,000",
    jobs_at_least_seven: "Al menos GBP 70,000",
    jobs_at_least_six: "Al menos GBP 60,000",
    jobs_at_least_three: "Al menos GBP 30,000",
    jobs_at_least_two: "Al menos GBP 20,000",
    jobs_contract: "Contrato",
    jobs_contract_length: "Duración del contrato",
    jobs_contract_type: "Tipo de contrato",
    jobs_director: "Director",
    jobs_entry_level: "Nivel básico",
    jobs_europe: "Europa",
    jobs_executive: "Ejecutivo",
    jobs_full_time: "De tiempo completo",
    jobs_industry_sector: "Sector",
    jobs_job_detail: "Datos del trabajo",
    jobs_location_region: "Región de la ubicación",
    jobs_location_specific: "Ubicación específica",
    jobs_mid_senior: "Nivel sénior medio",
    jobs_over_eight: "Más de GBP 81,000",
    jobs_part_time: "De medio tiempo",
    jobs_permanent: "Permanente",
    jobs_salary_actual: "Salario actual:",
    jobs_salary_bracket: "Rango de salario:",
    jobs_seniority_level: "Nivel de antigüedad",
    jobs_south_america: "Sudamérica",
    jobs_temporary: "Temporal",
    jobs_uk: "Reino Unido",
    jobs_url: "Sitio web",
    jobs_us_canada: "EE. UU. y Canadá",
    login_email_address: "Dirección de correo electrónico",
    login_forgot_password: "¿Olvidó su contraseña?",
    login_login: "Acceder",
    login_register: "¿Es un usuario nuevo? Regístrese aquí",
    login_sign_in_if_registered: "¿Ya se ha registrado? Acceda a su cuenta:",
    login_welcome: "Le damos la bienvenida a la GYECC21",
    meetings_accept: "Aceptar",
    meetings_awaiting_responses: "Esperando respuestas",
    meetings_ignore: "Rechazar",
    meetings_info_text: "Para obtener más información sobre cómo programar una reunión, vaya a la Mesa de ayuda y lea las Preguntas frecuentes.",
    meetings_meetings_confirmed: "Reuniones confirmadas",
    meetings_other_time: "¿Sería mejor otro momento?",
    meetings_requests_recieved: "Solicitudes recibidas",
    my_day_close_without_saving: "Cerrar sin guardar",
    my_day_event_date: "Fecha del evento",
    my_day_no_session_selected: "No ha seleccionado ninguna sesión",
    my_day_plan_my_day: "Planificar el día",
    my_day_save_exit: "Guardar y salir",
    my_day_time_slot_one: "Bloque horario 1",
    nav_agenda: "Agenda",
    nav_attendees: "Asistentes",
    nav_conference_agenda: "Agenda de la conferencia",
    nav_green_jobs: "Trabajos verdes",
    nav_gyecc_tv: "GYECC TV",
    nav_lobby: "Ecoparque",
    nav_log_out: "Salir",
    nav_my_beehive: "Mi evento",
    nav_my_breakout_selection: "Mi selección de flujos de trabajo",
    nav_my_meetings: "Mis reuniones",
    nav_navigate: "Navegar",
    nav_on_demand: "Repetición del evento",
    nav_presenters: "Presentadores",
    nav_speakers: "Disertantes",
    nav_workstream_four: "Flujo de trabajo 4",
    nav_workstream_one: "Flujo de trabajo 1",
    nav_workstream_three: "Flujo de trabajo 3",
    nav_workstream_two: "Flujo de trabajo 2",
    network_connect: "Conectar",
    network_disconnect: "Desconectar",
    network_meeting: "Reunión individual",
    networking_pledge_wall: "Pared de compromisos",
    networking_social_wall: "Pared social",
    on_demand_day_one: "Día 1",
    on_demand_day_two: "Día 2",
    plenary_ask_questions: "Hacer una pregunta",
    plenary_interprefy: "Idiomas",
    posters_enlarge_poster: "Haga clic para ampliar o abrir el PDF aquí",
    posters_overview: "Resumen",
    posters_view: "Ver",
    presenters_info_text: "Buscar disertantes y sesiones",
    presenters_organisation: "Organización",
    presenters_talk_abstract: "Resumen de charla",
    presenters_talk_title: "Título de charla",
    profile_many_thanks: "Muchas gracias por completar su perfil",
    profile_picture_upload: "Cargar imagen (JPEG, PNG, GIF)",
    profile_profile_picture: "Cargar imagen de perfil y vídeo",
    profile_your_information: "Su información",
    register_age: "Edad",
    register_confirm_password: "Confirmar contraseña",
    register_email: "Correo electrónico",
    register_first_name: "Nombre",
    register_preferred_language: "Idioma preferido",
    register_profile_picture: "Cargar imagen de perfil",
    register_surname: "Apellido",
    register_timezone: "Huso horario",
    reset_password_info_text: "Si existe una cuenta de usuario válida para la dirección de correo electrónico ingresada, recibirá un correo electrónico a la brevedad con un vínculo para restablecer su contraseña.",
    reset_password_reset_password: "Restablecer contraseña",
    select_breakout_info_text_one: "Seleccione los flujos de trabajo a los que desea asistir",
    select_roundtable_no: "No ha seleccionado mesas de formación de redes",
    select_roundtables_title: "Seleccione mesas de formación de redes",
    shared_about: "Acerca de",
    shared_ask_a_question: "Hacer una pregunta",
    shared_attendee_type: "Tipo de asistente",
    shared_back: "Volver",
    shared_breakouts: "Flujos de trabajo",
    shared_close: "Cerrar",
    shared_delegate: "Delegado",
    shared_english: "Inglés",
    shared_event_rewind: "Repetición del evento",
    shared_exhibition: "Exposición",
    shared_exhibitor_partner: "Expositor/Socio",
    shared_exit: "Salir",
    shared_french: "Francés",
    shared_help: "Ayuda",
    shared_internship: "Pasantía",
    shared_job_title: "Cargo",
    shared_mandarin: "Mandarín",
    shared_my_profile: "Mi perfil",
    shared_networking_lounge: "Salón de formación de redes",
    shared_networking_lounge_short: "Sala de reunions",
    shared_next: "Siguiente",
    shared_organiser: "Organizador",
    shared_other: "Otro",
    shared_password: "Contraseña",
    shared_plenary: "Plenaria",
    shared_poster_gallery: "Galería",
    shared_previous: "Anterior",
    shared_questions_answered: "Preguntas respondidas",
    shared_questions_to_be_answered: "Preguntas por responder",
    shared_roundtable: "Mesa de formación de redes",
    shared_search: "Buscar",
    shared_spanish: "Español",
    shared_speaker: "Disertante",
    shared_submit: "Enviar",
    shared_submit_a_question: "Enviar una pregunta",
    shared_upload: "Subir",
    leaderboard_title: "Tabla de posiciones ",
    leaderboard_info_text: "Zambúllase por completo en la Global Youth Engineering Climate Conference para ver si puede obtener uno de los primeros lugares en la tabla de posiciones.<br />Para ganar puntos, explore nuestra exhibición y nuestros recursos, descubra a los disertantes, visite los tableros de presentación de los socios e interactúe con otros delegados y expositores. Cuanto más participe, más puntos obtendrá y mayores serán sus posibilidades de recibir premios.<br />Los ganadores se anunciarán antes de la sesión de preguntas y respuestas plenaria de la conferencia, que se realizará a las 09:30 BST, el miércoles 8 de septiembre.",
    speakers_info_text: "Puede buscar a los disertantes y panelistas de GYECC21 por nombre o por título de la sesión. ",
    attendees_info_text: "Conéctese. Puede buscar a los delegados y expositores por nombre, cargo u organización.<br />Si desea establecer contacto con alguien, haga clic en el perfil y vea su disponibilidad para solicitar una reunión.",
    event_rewind_info_text: "Las grabaciones del evento estarán disponibles para mirar bajo demanda durante dos meses luego del evento, en las fechas previas a COP26.",
    exhibition_qa_title: "Exposición: Preguntas y respuestas",
    exhibition_qa_info_text: "Los paneles de preguntas y respuestas se revisarán durante los siguientes horarios: <br />Martes 7 de septiembre, de 15:30 a 18:30 BST<br />Miércoles 8 de septiembre de 06:30 a 09:30 BST",
    exhibition_text_chat_title: "Exposición: Chat de texto",
    exhibition_text_chat_info_text: "El chat de texto es revisará durante los siguientes horarios: <br />Martes 7 de septiembre, de 15:30 a 18:30 BST<br />Miércoles 8 de septiembre de 06:30 a 09:30 BST",
    exhibition_leave_details_title: "Exposición: Dejar detalles",
    exhibition_leave_details_info_text: "Los detalles que ingrese aquí se compartirán con el expositor. Si desea continuar la comunicación por correo electrónico o teléfono, ingrese sus datos de contacto preferidos y un breve resumen del tema que desea discutir.",
    exhibition_group_video_title: "Exposición: Chat de vídeo grupal",
    exhibition_group_video_info_text: "Los representantes estarán disponibles durante los siguientes horarios: <br />Martes 7 de septiembre, de 15:30 a 18:30 BST<br />Miércoles 8 de septiembre de 06:30 a 09:30 BST",
    networking_tables_title: "Mesas de formación de redes",
    networking_tables_info_text: "Las mesas de formación de redes estarán disponibles en los siguientes horarios:<br />Martes 7 de septiembre de 17:30 a 18:30 BST<br />Miércoles 8 de septiembre de 08:30 a 09:30 BST<br /><br />Tenga en cuenta que los lugares se asignan por orden de llegada. <br />Seleccione la mesa de formación de redes a la que desea asistir. En caso de que la mesa elegida esté completa, puede sumarse a otra mesa antes de volver a revisar si algún lugar ha quedado vacante.",
    plan_my_day_info_text: "Use este sector para aprovechar el día al máximo seleccionando los flujos de trabajo y las mesas de formación de redes a los que desea participar. Las opciones elegidas se agregarán a su agenda personal. <br />Los cuatro flujos de trabajo comenzarán a la misma hora y se realizarán dos veces cada día. Puede optar por asistir a un máximo de dos flujos de trabajo sucesivos, seguidos de una mesa de formación de redes.<br />Pase a las páginas para seleccionar las sesiones que le interesa agregar a su agenda para cada horario. Haga clic en Siguiente a continuación y pase al siguiente horario. Luego, haga clic en Guardar y salir para confirmar las opciones elegidas. <br />Si no desea asistir a una sesión durante uno de los bloques horarios, seleccione Exploración y formación de redes, donde podrá recorrer la exhibición libremente o programar reuniones individuales con otros asistentes.<br />Tenga en cuenta que este área es solo para planificación y no se garantiza que usted tenga un lugar en las mesas de formación de redes, ya que dichos lugares se asignan por orden de llegada. Asegúrese de llegar temprano. ",
    notifications_plenary_closed_title: "Plenaria cerrada ",
    notification_plenary_closed: "La plenaria está cerrada actualmente. Consulte la agenda para ver cuándo comienza la próxima sesión. ",
    notification_verification_email_title: "Esté atento al correo electrónico de verificación ",
    notification_verification_email: "Gracias por configurar su cuenta de Global Youth Engineering Climate Conference 2021. Le enviamos un correo electrónico de verificación. Haga clic en el vínculo que aparece en ese correo electrónico para verificar su cuenta. ",
    notification_error_loggin_in_title: "Error de acceso ",
    notification_error_loggin_in: "Hubo un error en el acceso a su cuenta. Verifique que haya ingresado el correo electrónico y la contraseña correctos. Si aún no ha configurado su cuenta, haga clic debajo para configurar una. ",
    notification_exhibition_closed_title: "La exposición está cerrada ",
    notification_exhibition_closed: "La exposición estará abierta los días martes 7 y miércoles 8 de septiembre. Regrese en esas fechas para interactuar con el contenido y conectarse a nuestras exposiciones.",
    notifications_titles: "Notificaciones de la plataforma",
    agenda_opening_remarks: "Plenaria: Comentarios iniciales y disertantes principales",
    agenda_opening_remarks_info: "Disertantes:<br />Denise Lee, Environmental Policy Researcher and Student, University of Toronto<br />Alok Sharma, President of the 26th United Nations Climate Change Conference<br />John Pettigrew, Chief Executive, National Grid<br />Joseph Tsongo, Co-ordinator of the Amani-Institute ASBL<br />Chris Stark, Chief Executive, Climate Change Committee",
    agenda_select_workstream_session: "Seleccione una sesión de flujo de trabajo",
    agenda_select_networking_table: "Seleccione una mesa de formación de redes",
    agenda_plenary_qa_panel: "Plenaria: panel de preguntas y respuestas",
    agenda_plenary_qa_panel_info: "Disertantes:<br />Eva Saha, Conference MC <br /> Dr. Nick Wayth, Energy Institute <br />Carlotta Giacché, Nord/LB and My Meaningful Money<br />Dr. Alaa Al Khourdajie, IPCC Working Group III<br />Sam Johnson Transport for London<br />Caritta Seppa, Tespack<br />Natalie Edwards, National Grid",
    agenda_plenary_qa_panel_info_day2: "Disertantes:<br />Eva Saha, Conference MC <br />Dr. Nick Wayth, Energy Institute <br />Polly Osborne, Burns & McDonnell<br />Amy Weltvreden, National Grid Electricity System Operator<br />Dr. Alaa Al Khourdajie, IPCC Working Group III<br />Dr. Alex Wohnas, Australian Energy Market Operator (AEMO)",
    agenda_make_your_pledge: "Haga su compromiso",
    agenda_option_one: "Opción 1",
    agenda_option_two: "Opción 2",
    agenda_option_three: "Opción 3",
    agenda_option_four: "Opción 4",
    agenda_option_five: "Opción 5",
    agenda_workstream_one_title: "Flujo de trabajo 1: Habilidades ecológicas: cómo ser un agente de cambio",
    agenda_workstream_one_info: "¿Desea contribuir a un futuro verde, pero no sabe qué competencias necesita?<br />¿Su empresa está en el proceso de abandonar los combustibles fósiles, por lo que usted necesita nuevas habilidades?<br /><br /> ¿Está dando sus primeros pasos en el sector energético y desea contribuir a un futuro más verde?<br /><br />El flujo de trabajo “Habilidades ecológicas: cómo ser un agente de cambio” responderá estas y otras preguntas.<br /><br /> Un panel interactivo diverso compuesto por empresas del sector energético, emprendedores, financistas y naciones en desarrollo le ayudará a explorar competencias nuevas, redescubrir otras que ya tiene y examinar cómo ofrecer sus competencias a otros. Usted puede ser un agente del cambio si fomenta el desarrollo de competencias verdes en su vida profesional y personal.",
    agenda_workstream_two_title: "Flujo de trabajo 2: Acceso a la energía en comunidades de carbono cero",
    agenda_workstream_two_info: "Todos comprendemos que necesitamos un sistema energético más limpio, ¿verdad?<br /><br /> Además, hemos asumido el compromiso de hacer cambios en nuestras vidas cotidianas para lograr la sustentabilidad a largo plazo, ¿o no?<br /><br />Es imperativo que reduzcamos el carbono en nuestras comunidades. Tal vez considere que eso ya está sucediendo en su zona o que no se ha avanzado lo suficiente.<br /><br />Nuestro “Consejo comunitario de reducción del carbono” explorará cómo reducir el carbono en nuestras comunidades y cómo lograr el cambio sustentable, lo cual incluye opiniones de expertos de programas comunitarios locales y proyectos de energías renovables. También exploraremos cómo se puede impulsar el cambio, sin importar el punto en el que se encuentre su comunidad en el proceso de reducir el carbono.",
    agenda_workstream_three_title: "Flujo de trabajo 3: Participación de las generaciones: El cambio verde comienza con el individuo",
    agenda_workstream_three_info: "¿Cuál es el impacto a nivel global de los productos que compramos, los hábitos que formamos y las profesiones que desarrollamos?<br /><br />Acompáñenos para aprender sobre su impacto personal y cómo los cambios individuales pueden ayudar a la reducción de carbono.<br /><br />En este flujo de trabajo, se usará un emocionante juego de datos curiosos para ampliar su conocimiento sobre el cambiante panorama energético.<br /><br />Durante el juego, los expertos del sector ofrecerán información valiosa sobre las lecciones que han aprendido y sugerirán puntos de acción para aumentar su impacto individual. La participación en este juego de datos curiosos le proporcionará herramientas para tomar decisiones de mayor impacto que ayuden a crear un mundo más verde.",
    agenda_workstream_four_title: "Flujo de trabajo 4: Un sistema energético global de carbono cero para todos",
    agenda_workstream_four_info: "Los objetivos son claros. Debemos lograr un huella neta de cero para mediados de siglo y también hacer adaptaciones para proteger nuestras comunidades y hábitats naturales. Lo que no está claro es cómo trabajar juntos para lograr estos objetivos. ¿Cómo podemos unirnos para crear un ambiente inclusivo y diverso mientras trabajamos por un objetivo común sin perder de vista nuestros desafíos individuales y locales?<br /><br />La sesión “Un sistema energético global de carbono cero para todos” le llevará en una recorrida por el mundo para explorar la forma en que distintos países y culturas abordan el sistema de energía baja en carbono. Esperamos que esta conversación sirva como punto de partida para hacer preguntas de análisis más profundo y compartir ideas mientras trabajamos codo a codo con el propósito de lograr el objetivo primordial de nuestra generación: desarrollar sistemas energéticos con carbono cero.<br /><br />Durante este recorrido global, conoceremos las maneras innovadoras en que las personas de distintos lugares del mundo trabajan por los mismos objetivos. Esta vista global de nivel macro nos dará una perspectiva útil para crear juntos un futuro en el que la energía limpia sea asequible.",
    agenda_explore_network: "Explorar y formar redes",
    agenda_visit_attendee_gallery: "Visite la galería de asistentes para programar reuniones individuales con otros delegados o aproveche ese tiempo para explorar el entorno virtual. Visite la exposición, la galería, los trabajos verdes o GYECC TV.",
    account_confirmation_status: "Estado de confirmación de la cuenta",
    awaiting_confirmation: "En espera de confirmación",
    select_workstream: "Seleccionar flujo de trabajo",
    eco_park_streams: "Flujos",
    eco_park_networking: "Sala de reuniones",
    register_error_first_name: "Ingrese su nombre",
    register_error_surname: "Ingrese su apellido",
    register_error_age_range: "Seleccione su rango de edad",
    register_error_enter_email: "Ingrese su dirección de correo electrónico",
    register_error_enter_valid_email: "Ingrese una dirección de correo electrónico válida",
    register_error_enter_password: "Ingrese una contraseña",
    register_error_password_six_letters: "Utilice una contraseña de al menos 6 caracteres",
    register_error_password_match: "Asegúrese de que las contraseñas coincidan",
    register_error_account_already_exists: "Ya existe una cuenta para este correo electrónico. Restablezca su contraseña.",
    register_error_attendee_type: "Seleccione un tipo de asistente",
    register_error_language: "Seleccione un idioma",
    register_error_country: "Ingrese un país",
    register_error_ltimezone: "Seleccione un huso horario",
    register_registration_request: "Su solicitud de registro todavía no ha sido aprobada. Por cualquier consulta, póngase en contacto con box.COP26@nationalgrid.com ",
    register_account_confirmation_email: "Asegúrese de haber hecho clic en el enlace que aparece en el correo electrónico de confirmación de su cuenta.",
    register_password_reset: "Gracias. Si hay una cuenta vinculada a esta dirección de correo electrónico, en breve recibirá un correo electrónico con un enlace para restablecer su contraseña.",
    shared_livestream_error: "Estamos experimentando dificultades técnicas. No se vaya a ninguna parte, estaremos de vuelta pronto.",
    notification_welcome: "¡Bienvenido a GYECC21! Para seleccionar sus flujos de trabajo y mesas de formación de redes, visite Planificar el día",
    notification_first_session_five_minute_warning: "Acompáñenos en la primera sesión del día en el plenario. ¡Comienza en 5 minutos!",
    notification_automatic_movement_to_plenary: "Será dirigido automáticamente al plenario en 5 minutos. ¡Cierre sus conversaciones y únase a nosotros!",
    notification_plenary_live: "Ahora estamos en directo en el plenario. Diríjase hacia este espacio para unirse a la sesión.",
    notification_final_session: "La última sesión de GYECC21 está a punto de comenzar. ¡Acérquese al plenario!",
    notification_audio_volume: "¿No oye el audio? Haga clic en el botón del volumen en la parte inferior de la pantalla.",
    notification_slido_comms: "Hoy todo gira en torno a la comunicación bidireccional: envíe sus preguntas a través de Sli.do",
    notification_visit_exhbition_gallery: "No olvide visitar la exposición y la galería para explorar y ponerse en contacto con los socios de GYECC.",
    notification_last_workstream_session: "Se acerca su última oportunidad de explorar uno de los flujos de trabajo: diríjase a los grupos de flujo de trabajo en 5 minutos",
    notification_workstream_selection: "Existen cuatro flujos de trabajo de los que puede elegir. ¿Ya ha asistido a alguno?",
    notification_make_a_pledge: "Vaya al salón de formación y haga clic en la pared de compromiso para enviar su compromiso GYECC21.",
    notification_workstream_signup: "¿Ya se ha inscrito en un flujo de trabajo? ¡No se lo pierda! Vaya a Planificar el día.",
    notification_connect_with_attendees: "Solicite reuniones individuales en la galería de asistentes para ponerse en contacto con ellos",
    notification_social_networking_tables: "¿Desea conversar? Acérquese a una de las mesas de formación de redes durante la próxima hora",
    notification_social_wall: "¿Ya ha visto la pared social? Vea sus publicaciones en la galería de la pared social",
    notification_book_meetings: "¿Ya ha reservado una reunión individual hoy? Visite la galería de asistentes para solicitar una reunión con otro asistente.",
    notification_available_vacancies: "Consulte las vacantes disponibles de los socios de GYECC en el área de la bolsa de trabajo",
    notification_thank_you: "¡Gracias por acompañarnos en GYECC21! Esperamos que haya disfrutado del evento y haya hecho valiosas conexiones.",
    notification_on_demand: "La plataforma permanecerá abierta hasta COP26 en noviembre. Vea todos los contenidos bajo demanda en GYECC TV.",
    exhibition_leave_details_follow_up_request: "Solicitar comunicación de seguimiento",
    exhibition_leave_details_request_meeting: "Quiero solicitar una reunión individual",
    exhibition_leave_details_contact_me: "Quiero que alguien se ponga en contacto conmigo",
    meetings_meeting_with: "Reunión individual con",
    mobile_access_from_desktop: "Acceda a la plataforma desde un ordenador de sobremesa o portátil para participar en esta reunión",
    plan_my_day_selection_complete: "Selección de sesiones completa",
    exhibition_hall_two: "Sala 2",
    exhibition_hall_three: "Sala 3",
    exhibition_hall_four: "Sala 4",
    shared_roundtables: "Mesas redondas",
    jobs_job_search: "Búsqueda de trabajo",
    forgotten_password_new: "Nueva contraseña",
    forgotten_password_confirm_new: "Confirmar nueva contraseña",
    speakers_biography: "Biografía",
    speakers_bio: "Bio",
    speakers_session_name: "Nombre de la sesión",
    speakers_session_description: "Descripción de la sesión",
    speakers_session_time: "Horario de la sesión",
    speakers_session_location: "Ubicación de la sesión",
    register_please_select: "Seleccione una opción",
    nav_get_connected: "Conéctese",
    shared_advanced_search: "Búsqueda avanzada",
    jobs_advanced_job_search: "Búsqueda avanzada de empleo",
    leaderboard_my_points: "Mis puntos",
    nav_session_selection: "Selección de sesión",
    mobile_exhibition_hall: "Sala de exposiciones",
    exhibition_visit: "Visita",
    exhibition_back_to_exhibition: "Volver a la exposición",
    exhibition_search_exhibition: "Búsqueda de casetas de exposición",
    meetings_request_meeting: "Solicite una reunión con",
    meetings_meetings_info_text: "Para reservar una reunión 1:1, seleccione una de las siguientes franjas horarias y pulse enviar. Una vez aceptada, la reunión aparecerá en su agenda. Para ver el estado de sus reuniones, vaya a 'Planificar mi día' y seleccione 'Mis reuniones'.",
    meetings_connect_when_ready: "Por favor, pulse conectar tan pronto como esté listo para unirse",
    on_demand_like_video: "¡Gracias por poner que le gusta este vídeo!",
    networking_back_to_tables: "Volver",
    networking_social_tables: "Mesas sociales",
    netowrking_hosted_tables: "Mesas auspiciadas",
    exhibition_please_include_message: "Por favor, escriba un mensaje",
    exhibition_message_sent: "Mensaje enviado con éxito",
    meetings_submitted: "Se ha enviado su solicitud de reunión",
    meetings_another_time: "Se ha enviado su solicitud para otra hora",
    exhibition_submit_question: "Por favor, introduzca una pregunta para enviar",
    registration_photo_submitted: "La foto del perfil se ha cargado con éxito",
    plan_my_day_completed: "Gracias por seleccionar sus sesiones; éstas aparecerán en su agenda personalizada. Puede cambiar su selección de sesiones en cualquier momento en el área 'Planificar mi día'.",
    profile_create: "Cree su perfil",
    profile_social_media: "Redes sociales",
    profile_facebook: "El enlace de su perfil se encuentra en la configuración del perfil. Se trata de una URL que incluye su enlace personalizado de Facebook:. https://www.facebook.com/Username/",
    profile_tiktok: "Añada su usuario de TikTok. Este será el @nombredeusuario que otros usuarios de TikTok podrán utilizar para etiquetarle o encontrarle",
    profile_instagram: "Añada su usuario de Instagram. Este será el @nombredeusuario que otros usuarios de Instagram podrán utilizar para etiquetarle o encontrarle",
    profile_twitter: "Añada su usuario de Twitter Este será el @nombredeusuario que otros usuarios de Twitter podrán utilizar para etiquetarle o encontrarle",
    profile_weibo: "Para encontrar su URL personal, pulse el menú en la esquina superior derecha de la página principal de la cuenta Weibo. Aparecerá un menú con una opción para copiar su URL personal",
    profile_personalised_url: "Visite su perfil y copie su URL personalizada de la barra de direcciones",
    profile_url: "Visite su perfil y copie su URL personalizada",
    profile_already_created: "¿Ya ha creado su perfil?",
    jobs_reset: "Resetear",
    jobs_apply: "Aplicar",
    networking_lounge_submit_pledge: "Pulsa aquí para enviar tu compromiso",
    networking_lounge_social: "Pulsa aquí para ver las publicaciones de #GYECC21 en redes sociales",
    social_wall_join: "Incluye #GYECC21 en tus publicaciones en redes para añadirte a la pared social de GYECC",
    pledge_wall_info: "Pulsa el botón “haz un compromiso” para comentarnos cómo harás para ser #onestepgreener",
    notification_gyecctv_closed_title: "GYECC TV cerrado",
    notification_gyecctv_closed: "GYECC TV abrirá a partir del martes 7 de septiembre. Por favor, regresa ese día para disfrutar los más de 45 vídeos bajo demanda.",
    notification_gallery_closed_title: "Galería cerrada",
    notification_gallery_closed: "La galería abrirá el martes 7 y el miércoles 8 de septiembre. Por favor, regresa esos días para participar del contenido de nuestros socios comerciales.",
};