export const TRANSLATIONS_ZH = {
    agenda_join: "加入",
    attendees_back_to_eco_park: "返回生态园",
    attendees_country: "国家",
    attendees_info_text_old: "查找职务相似、地点相近或兴趣相同的成员，并与其交流",
    breakouts_ask_your_questions: "提出您的问题",
    breakouts_breakout_explained: "工作流说明",
    exhibition_back_to_hall: "返回大厅",
    exhibition_click_to_view_and_download: "点击即可查阅和下载",
    exhibition_contact: "联系",
    exhibition_enter_your_message: "输入您的信息",
    exhibition_find_out_more: "查找更多内容",
    exhibition_go: "前往",
    exhibition_hall_one: "大厅 1",
    exhibition_learn_more: "了解更多内容",
    exhibition_leave_your_details: "填写您的详细信息",
    exhibition_live_text_chat: "实时文字聊天",
    exhibition_meet_and_greet: "会面",
    exhibition_message: "信息",
    exhibition_qa: "问答",
    exhibition_send: "发送",
    exhibition_text_chat: "文字聊天",
    help_faq: "常见问题",
    help_request_help: "需要帮助",
    help_request_help_info_text: "在活动期间，如果您需要任何帮助，请在下面提交您的请求。我们的支持团队成员将会尽快与您联系！",
    help_welcome_video: "欢迎视频",
    jobs_apac: "APAC（亚太区）",
    jobs_associate: "参与",
    jobs_at_least_eight: "£80,000 起",
    jobs_at_least_five: "£50,000 起",
    jobs_at_least_four: "£40,000 起",
    jobs_at_least_seven: "£70,000 起",
    jobs_at_least_six: "£60,000 起",
    jobs_at_least_three: "£30,000 起",
    jobs_at_least_two: "£20,000 起",
    jobs_contract: "合同",
    jobs_contract_length: "合同期限",
    jobs_contract_type: "合同类型",
    jobs_director: "负责人",
    jobs_entry_level: "初级",
    jobs_europe: "欧洲",
    jobs_executive: "管理人员",
    jobs_full_time: "全职",
    jobs_industry_sector: "行业领域",
    jobs_job_detail: "职务详情",
    jobs_location_region: "位置区域",
    jobs_location_specific: "特定区域",
    jobs_mid_senior: "中级",
    jobs_over_eight: "£81,000 以上",
    jobs_part_time: "兼职",
    jobs_permanent: "永久",
    jobs_salary_actual: "实际薪资:",
    jobs_salary_bracket: "薪金等级:",
    jobs_seniority_level: "资历水平",
    jobs_south_america: "南美洲",
    jobs_temporary: "临时工",
    jobs_uk: "英国",
    jobs_url: "网站",
    jobs_us_canada: "美国和加拿大",
    login_email_address: "电子邮件地址",
    login_forgot_password: "忘记密码？",
    login_login: "登录",
    login_register: "新用户？在此注册",
    login_sign_in_if_registered: "已经注册？签到：",
    login_welcome: "欢迎来到 GYECC21",
    meetings_accept: "同意",
    meetings_awaiting_responses: "等待响应",
    meetings_ignore: "拒绝",
    meetings_info_text: "关于如何安排会议，如需了解更多信息，请前往“帮助中心”并查阅常见问题解答",
    meetings_meetings_confirmed: "会议已确认",
    meetings_other_time: "其他时间？",
    meetings_requests_recieved: "申请已被接受",
    my_day_close_without_saving: "不保存，直接关闭",
    my_day_event_date: "事件日期",
    my_day_no_session_selected: "未选择任何会议",
    my_day_plan_my_day: "规划我的日程",
    my_day_save_exit: "保存并退出",
    my_day_time_slot_one: "时段 1",
    nav_agenda: "日程",
    nav_attendees: "与会者",
    nav_conference_agenda: "会议日程",
    nav_green_jobs: "绿色职业",
    nav_gyecc_tv: "GYECC 电视",
    nav_lobby: "生态园",
    nav_log_out: "登出",
    nav_my_beehive: "我的事件",
    nav_my_breakout_selection: "我的工作流选择",
    nav_my_meetings: "我的会议",
    nav_navigate: "导航",
    nav_on_demand: "事件回放",
    nav_presenters: "主持人",
    nav_speakers: "演讲嘉宾",
    nav_workstream_four: "工作流 4",
    nav_workstream_one: "工作流 1",
    nav_workstream_three: "工作流 3",
    nav_workstream_two: "工作流 2",
    network_connect: "连接",
    network_disconnect: "断开",
    network_meeting: "1:1 会议",
    networking_pledge_wall: "誓言墙",
    networking_social_wall: "社交墙",
    on_demand_day_one: "第 1 天",
    on_demand_day_two: "第 2 天",
    plenary_ask_questions: "提出问题",
    plenary_interprefy: "语言",
    posters_enlarge_poster: "点击放大或打开 PDF 文件",
    posters_overview: "概述",
    posters_view: "查看",
    presenters_info_text: "搜索演讲嘉宾和会议",
    presenters_organisation: "组织",
    presenters_talk_abstract: "演讲摘要",
    presenters_talk_title: "演讲主题",
    profile_many_thanks: "非常感谢您完成个人资料",
    profile_picture_upload: "上传照片 (JPEG, PNG, GIF)",
    profile_profile_picture: "上传资料图片和视频",
    profile_your_information: "您的信息",
    register_age: "年龄",
    register_confirm_password: "确认密码",
    register_email: "电子邮件",
    register_first_name: "名字",
    register_preferred_language: "首选语言",
    register_profile_picture: "上传资料图片",
    register_surname: "姓氏",
    register_timezone: "时区",
    reset_password_info_text: "如果所输入的电子邮件地址已被现有的有效用户使用，您将很快收到一封电子邮件，其中包含了重置密码的链接。",
    reset_password_reset_password: "重置密码",
    select_breakout_info_text_one: "选择您想要参加的工作流",
    select_roundtable_no: "没有选择联网平台",
    select_roundtables_title: "选择联网平台",
    shared_about: "关于",
    shared_ask_a_question: "提出一个问题",
    shared_attendee_type: "与会者类别",
    shared_back: "返回",
    shared_breakouts: "工作流",
    shared_close: "关闭",
    shared_delegate: "代表",
    shared_english: "英语",
    shared_event_rewind: "事件回放",
    shared_exhibition: "展览",
    shared_exhibitor_partner: "参展商/合作伙伴",
    shared_exit: "退出",
    shared_french: "法语",
    shared_help: "帮助",
    shared_internship: "实习",
    shared_job_title: "职位",
    shared_mandarin: "中文",
    shared_my_profile: "我的资料",
    shared_networking_lounge: "联网大厅",
    shared_networking_lounge_short: "联网大厅",
    shared_next: "下一步",
    shared_organiser: "主办方",
    shared_other: "其他",
    shared_password: "密码",
    shared_plenary: "全体会议",
    shared_poster_gallery: "相册",
    shared_previous: "上一步",
    shared_questions_answered: "已解答的问题",
    shared_questions_to_be_answered: "待解答的问题",
    shared_roundtable: "联网平台",
    shared_search: "搜索",
    shared_spanish: "西班牙语",
    shared_speaker: "演讲嘉宾",
    shared_submit: "提交",
    shared_submit_a_question: "提交一个问题",
    shared_upload: "上传",
    leaderboard_title: "排行榜 ",
    leaderboard_info_text: "充分感受 Global Youth Engineering Climate Conference 的氛围，看看您能否在排行榜上跻身前列！<br />通过探索我们的展览和资源、了解演讲嘉宾、查看我们的合作伙伴展板以及与其他代表和参展商互动来赚取积分。您参与的项目越多，获得的积分就越高，赢取奖品的机会也就越大。<br />获奖名单将于 9 月 8 日（星期三）英国夏令时间 09:30 举行的大会上最后全体出席的问答环节前公布。",
    speakers_info_text: "按姓名或会议标题搜索 GYECC21 的演讲嘉宾和专题小组成员。",
    attendees_info_text: "接通连接！按照姓名、职务或组织搜索代表和参展商。通过点击其个人资料进行连接，以查看对方是否有空并申请举行会议。",
    event_rewind_info_text: "对于 COP26 的前期活动，可在两个月的期限内按需点播活动的录制内容。",
    exhibition_qa_title: "展览 – 常见问题",
    exhibition_qa_info_text: "我们将在以下时间段检查问答栏：<br />英国夏令时间 9 月 7 日 星期二，15:30 – 18:30<br />英国夏令时间 9 月 8 日 星期三，06:30 – 09:30",
    exhibition_text_chat_title: "展览 - 文字聊天",
    exhibition_text_chat_info_text: "我们将在以下时间段检查文字聊天：<br />英国夏令时间 9 月 7 日 星期二，15:30 – 18:30<br />英国夏令时间 9 月 8 日 星期三，06:30 – 09:30",
    exhibition_leave_details_title: "展览 - 资料保存",
    exhibition_leave_details_info_text: "您在此提交的详细资料将被分享给参展商。如果您希望通过电子邮件或电话跟进后续进展，请提供您首选的联系方式以及您想要探讨的内容提要。",
    exhibition_group_video_title: "展览 – 群组视频聊天",
    exhibition_group_video_info_text: "代表将在以下时间段出席：<br />英国夏令时间 9 月 7 日 星期二，15:30 – 18:30<br />英国夏令时间 9 月 8 日 星期三，06:30 – 09:30",
    networking_tables_title: "联网平台",
    networking_tables_info_text: "联网平台将在以下时间段运行：<br />英国夏令时间 9 月 7 日 星期二，17:30 – 18:30<br />英国夏令时间 9 月 8 日 星期三，08:30 – 09:30<br /><br />请注意，所有席位均按先到先得的原则分配。<br />请选择您想要参加的联网平台——如果所选平台已满员，您可以查看即将开放的其他席位，并申请加入。",
    plan_my_day_info_text: "使用此栏目，选择您想要参加的工作流和联网平台，将其加入您的个性化日程，从而充分利用时间。<br />四个工作流将同时开始，每天运行两次。您最多可以选择接连参加两个工作流，之后参加一个联网平台。<br />请继续浏览页面，选择您想要加入日程中各时段的会议——点击下面的“下个会议”进入下一个会议，然后点击“保存并退出”即可确认您的选择。<br />如果您不想在其中某个时段参加会议，请选择“探索和联网”，您可在此自由浏览展览或向其他与会者发起 1:1 会议。<br />请注意，该栏目仅供您计划用，并不能保证您获得联网平台的席位，平台席位按先到先得的原则分配，因此请确保尽早申请！",
    notifications_plenary_closed_title: "全体会议已结束 ",
    notification_plenary_closed: "全体会议现已结束，请查看日程，以了解下次会议的召开时间。",
    notification_verification_email_title: "留意验证邮件 ",
    notification_verification_email: "感谢您为 Global Youth Engineering Climate Conference 2021 设置帐户。我们已向您发送一封验证邮件——请点击该邮件中的链接，以验证您的帐户。",
    notification_error_loggin_in_title: "错误日志 ",
    notification_error_loggin_in: "您登录时出现一个错误。请检查您的电子邮件和/或密码是否正确。如果您尚未设置帐户，请点击以下选项并设置。",
    notification_exhibition_closed_title: "展览已结束 ",
    notification_exhibition_closed: "展览将于 9 月 7 日（星期二）和 8 日（星期三）开放。届时敬请参与，并与我们的参展商联系。",
    notifications_titles: "平台通知",
    agenda_opening_remarks: "全体会议 - 开幕辞和主讲嘉宾",
    agenda_opening_remarks_info: "演讲嘉宾<br />Denise Lee, Environmental Policy Researcher and Student, University of Toronto<br />Alok Sharma, President of the 26th United Nations Climate Change Conference<br />John Pettigrew, Chief Executive, National Grid<br />Joseph Tsongo, Co-ordinator of the Amani-Institute ASBL<br />Chris Stark, Chief Executive, Climate Change Committee",
    agenda_select_workstream_session: "选择一场工作流会议",
    agenda_select_networking_table: "选择一个联网平台",
    agenda_plenary_qa_panel: "全体会议 - 问答环节",
    agenda_plenary_qa_panel_info: "演讲嘉宾<br />Eva Saha, Conference MC <br /> Dr. Nick Wayth, Energy Institute <br />Carlotta Giacché, Nord/LB and My Meaningful Money<br />Dr. Alaa Al Khourdajie, IPCC Working Group III<br />Sam Johnson Transport for London<br />Caritta Seppa, Tespack<br />Natalie Edwards, National Grid",
    agenda_plenary_qa_panel_info_day2: "演讲嘉宾<br />Eva Saha, Conference MC <br />Dr. Nick Wayth, Energy Institute <br />Polly Osborne, Burns & McDonnell<br />Amy Weltvreden, National Grid Electricity System Operator<br />Dr. Alaa Al Khourdajie, IPCC Working Group III<br />Dr. Alex Wohnas, Australian Energy Market Operator (AEMO)",
    agenda_make_your_pledge: "制定您的誓言",
    agenda_option_one: "选项 1",
    agenda_option_two: "选项 2",
    agenda_option_three: "选项 3",
    agenda_option_four: "选项 4",
    agenda_option_five: "选项 5",
    agenda_workstream_one_title: "工作流 1 - 绿色技能：如何成为变革的推动者",
    agenda_workstream_one_info: "想为绿色未来贡献一份力量但又不知道需要什么技能？<br />也许贵公司正在从化石燃料转型，而您需要新技能？<br /><br /> 也许您刚接触能源行业，但想为更完美的绿色未来贡献力量？<br /><br /> ‘绿色技能、如何成为变革的推动者’工作流将解答所有此类问题，并介绍更多信息。<br /><br /> 一个由能源公司、企业家、金融家和发展中国家组成的互动型多元化团队将深入探索新技能，重新发现您已经拥有的技能，并研究如何向他人推销您的技能。通过在职业生涯和个人生活中推广绿色技能，您可以成为变革的推动者。",
    agenda_workstream_two_title: "工作流 2 - 为零碳社区提供能源",
    agenda_workstream_two_info: "我们都知道对更清洁能源系统的需求……不是吗？<br /><br /> 我们致力于改变日常生活，以实现长期的可持续发展……不是吗？<br /><br /> 我们需要让社区减排。您可能已经觉察到所在社区这方面的变化，或者您可能会觉得尚需改进。<br /><br /> 我们的“低碳社区委员会”将探讨如何使我们的社区减排，以及如何实施可持续变革，包括来自当地社区计划和绿色能源项目的专家们的意见。我们还将探讨如何推动变革，以及处于减排中各个阶段的社区需执行的举措。",
    agenda_workstream_three_title: "工作流 3 - 世代参与 - 绿色变革从个人开始",
    agenda_workstream_three_info: "您购买的产品、您养成的习惯以及您追求的职业如何融合并产生全球影响？<br /><br />加入我们，了解您的个人影响，并学习个人变化如何推动减排。<br /><br />该工作流将运用激动人心的小游戏来丰富您的知识，让您深入了解不断变化的能源格局。<br /><br />在整个游戏过程中，行业专家将提供他们从工作中积累的经验，并提出能够增加您个人影响的行动项目。通过参与这个小游戏，您将能够做出更具影响力的决定，从而为更完美的绿色世界贡献力量。",
    agenda_workstream_four_title: "工作流 4 - 面向所有人的全球零碳能源系统",
    agenda_workstream_four_info: "目标非常明确。我们必须在本世纪中叶之前，在全球范围内实现零耗能，并采取措施保护社区和自然栖息地。问题是，我们该如何携手实现这些目标？我们怎样才能共同创造一个包容性且多元化的环境，朝着共同的目标努力，同时仍然认识到我们的个人挑战和本地挑战？<br /><br /> 全球零碳能源系统将带您环游全球，探索不同国家/地区和文化如何打造低碳能源系统。在我们齐心协力实现当代最重要的目标——零碳能源系统之际，让这场对话开启深层次问题和理念的分享。<br /><br />在这次环球之旅中，我们将学习世界不同地区的人们为实现该共同目标所开发的创新方式。通过这一全球宏观视野，我们将深入了解如何共同创造一个适宜的清洁能源未来。",
    agenda_explore_network: "探索和联网",
    agenda_visit_attendee_gallery: "访问与会者相册，与其他代表建立 1:1 会议，或者尽情探索虚拟环境。访问展览、图库、绿色职业或 GYECC TV。",
    account_confirmation_status: "帐户确认状态",
    awaiting_confirmation: "等待确认",
    select_workstream: "选择工作流",
    eco_park_streams: "数据流",
    eco_park_networking: "联网中",
    register_error_first_name: "请输入您的名字",
    register_error_surname: "请输入您的姓氏",
    register_error_age_range: "请选择您的年龄段",
    register_error_enter_email: "请输入您的电子邮件地址",
    register_error_enter_valid_email: "请输入有效的电子邮件地址",
    register_error_enter_password: "请输入密码",
    register_error_password_six_letters: "请使用至少有 6 个字符的密码",
    register_error_password_match: "请确认密码",
    register_error_account_already_exists: "此电子邮件地址已被另一帐户使用。请重置您的密码。",
    register_error_attendee_type: "请选择参与类型",
    register_error_language: "请选择一门语言",
    register_error_country: "请输入国家",
    register_error_ltimezone: "请选择时区",
    register_registration_request: "您的注册申请仍在等待审批。 如有任何疑问，请联系 box.COP26@nationalgrid.com ",
    register_account_confirmation_email: "请确保您已点击帐户确认邮件中的链接。",
    register_password_reset: "谢谢！如果此电子邮件地址已被另一帐户使用，您将很快收到一封电子邮件，该邮件中包含有重置密码的链接。",
    shared_livestream_error: "我们遇到了技术难题。请别走开，我们很快就会回来。",
    notification_welcome: "欢迎来到 GYECC21！如需选择您的工作流和联网平台，请访问规划我的日程",
    notification_first_session_five_minute_warning: "加入我们今日在全体会议厅举办的第一场会议—— 5 分钟后开始！",
    notification_automatic_movement_to_plenary: "您将在 5 分钟内自动进入全体会议厅——结束您的对话并加入我们！",
    notification_plenary_live: "我们现在在全体会议厅的直播现场。请您进入此空间，以加入本场会议。",
    notification_final_session: "GYECC21 的最后一场会议即将开始——请您前往全体会议厅！",
    notification_audio_volume: "听不到声音？点击您屏幕底部的音量按钮。",
    notification_slido_comms: "今天采用的是双向交流——请使用 Sli.do 提交您的问题",
    notification_visit_exhbition_gallery: "别忘了参观展览和访问相册，以探索更多内容，并与 GYECC 合作伙伴建立联系！",
    notification_last_workstream_session: "探索工作流的最后机会即将来临—— 5 分钟内前往工作流平台",
    notification_workstream_selection: "有四种工作流供您选择。您是否有参加过其中某项？",
    notification_make_a_pledge: "前往联网休息室，并点击誓言墙，以提供您的 GYECC21 承诺！",
    notification_workstream_signup: "您是否有报名参加某个工作流？千万别错过——前往“规划我的日程”！",
    notification_connect_with_attendees: "通过与会者相册请求 1:1 会议，以便与其他成员联系",
    notification_social_networking_tables: "想聊天？在接下来的一个小时里，进入某中一个社交联网平台",
    notification_social_wall: "您是否有查阅过社交墙？在社交墙相册中查看您的帖子",
    notification_book_meetings: "您今天是否有预订 1:1 会议？访问与会者相册，申请与其他成员进行交流。",
    notification_available_vacancies: "在工作平台区查看 GYECC 合作伙伴的位置空缺",
    notification_thank_you: "感谢您和我们一起参加 GYECC21！我们希望您乐在其中，并结识到宝贵的人际关系。",
    notification_on_demand: "该平台将一直开放至 11 月的 COP26，您可在 GYECC TV 上点播所有内容。",
    exhibition_leave_details_follow_up_request: "要求跟进后续动态",
    exhibition_leave_details_request_meeting: "我想申请一次 1:1 会面",
    exhibition_leave_details_contact_me: "我希望有人联系我",
    meetings_meeting_with: "将与您进行 1:1 会面的人员：",
    mobile_access_from_desktop: "请使用台式机或笔记本电脑访问该平台，以便参与本次会面",
    plan_my_day_selection_complete: "会议选择已完成",
    exhibition_hall_two: "大厅 2",
    exhibition_hall_three: "大厅 3",
    exhibition_hall_four: "大厅 4",
    shared_roundtables: "圆桌会议",
    jobs_job_search: "职位搜索",
    forgotten_password_new: "新密码",
    forgotten_password_confirm_new: "确认新密码",
    speakers_biography: "个人简介",
    speakers_bio: "个人简历",
    speakers_session_name: "会议名称",
    speakers_session_description: "会议描述",
    speakers_session_time: "会议时间",
    speakers_session_location: "会议地点",
    register_please_select: "请选择",
    nav_get_connected: "接通连接!",
    shared_advanced_search: "高级搜索",
    jobs_advanced_job_search: "高级职位搜索",
    leaderboard_my_points: "我的积分",
    nav_session_selection: "会话选择",
    mobile_exhibition_hall: "展览厅",
    exhibition_visit: "访问",
    exhibition_back_to_exhibition: "返回展览",
    exhibition_search_exhibition: "搜索展位",
    meetings_request_meeting: "申请会议",
    meetings_meetings_info_text: "如要预约 1:1 会议，请从下面选择一个时间段，然后点击提交。对方接受后，该会议将会显示在您的日程中。如要查看会议状态，请前往“规划我的日程'，并选择“我的会议'。",
    meetings_connect_when_ready: "当您准备好加入时，请点击连接",
    on_demand_like_video: "感谢您对本视频的支持！",
    networking_back_to_tables: "返回",
    networking_social_tables: "社交平台",
    netowrking_hosted_tables: "托管平台",
    exhibition_please_include_message: "请附上信息",
    exhibition_message_sent: "信息已成功发送",
    meetings_submitted: "您的会议申请已被提交",
    meetings_another_time: "您的时间更改申请已被发送",
    exhibition_submit_question: "请输入要提交的问题",
    registration_photo_submitted: "资料图片已成功上传",
    plan_my_day_completed: "感谢您选择会议——这些内容将显示在您的个人日程中。您可在“规划我的日程”窗口中随时更改会议选项。",
    profile_create: "创建您的资料",
    profile_social_media: "社交媒体",
    profile_facebook: "您可在“配置设置”中找到配置链接。 这将是一个包含您个人 Facebook 链接的 URL：https://www.facebook.com/Username/",
    profile_tiktok: "添加您的抖音用户名其他抖音用户可通过“@用户名”来标记您或查找您",
    profile_instagram: "添加您的 Instagram 用户名。其他 Instagram 用户可通过“@用户名”来标记您或查找您",
    profile_twitter: "添加您的 Twitter 账号。其他 Twitter 用户可通过“@用户名”来标记您或查找您",
    profile_weibo: "点击微博账户中主页右上角的菜单，即可找到您的 URL。这里将会显示一个菜单，其中包含“复制您的个人 URL”选项",
    profile_personalised_url: "访问您的资料页面并从地址栏复制您的个人 URL",
    profile_url: "访问您的资料页面并复制您的个人 URL",
    profile_already_created: "是否已创建资料？",
    jobs_reset: "重置",
    jobs_apply: "应用",
    networking_lounge_submit_pledge: "点击此处即可提交您的誓言",
    networking_lounge_social: "点击此处即可查阅 #GYECC21 社交媒体贴文",
    social_wall_join: "在您的社交媒体贴文上引用 #GYECC21，以便将其添加至 GYECC 社交墙",
    pledge_wall_info: "点击‘宣誓’按钮，告诉我们您将怎样开展 #onestepgreener",
    notification_gyecctv_closed_title: "GYECC TV 已关闭",
    notification_gyecctv_closed: "GYECC TV 将于 9 月 7 日（星期二）开放——届时请再来欣赏 45+ 视频点播。",
    notification_gallery_closed_title: "相册已关闭",
    notification_gallery_closed: "相册将于 9 月 7 日（星期二）和 9 月 8 日（星期三）开放——届时请再来参与我们的企业合作伙伴所提供的各种活动。",
};