export const TRANSLATIONS_FR = {
    agenda_join: "Participer",
    attendees_back_to_eco_park: "Parc écologique",
    attendees_country: "Pays",
    attendees_info_text_old: "Rechercher des personnes dont la fonction professionnelle ou le lieu est similaire ou partageant les mêmes intérêts et discuter avec elles",
    breakouts_ask_your_questions: "Posez vos questions",
    breakouts_breakout_explained: "Présentation des groupes de travail",
    exhibition_back_to_hall: "Retour au hall",
    exhibition_click_to_view_and_download: "",
    exhibition_contact: "Contact",
    exhibition_enter_your_message: "Saisissez votre message",
    exhibition_find_out_more: "Informations complémentaires",
    exhibition_go: "Démarrer",
    exhibition_hall_one: "Hall 1",
    exhibition_learn_more: "En savoir plus",
    exhibition_leave_your_details: "Fournissez vos coordonnées",
    exhibition_live_text_chat: "Discussion en direct en ligne",
    exhibition_meet_and_greet: "Accueil et rencontres",
    exhibition_message: "Message",
    exhibition_qa: "Questions-réponses",
    exhibition_send: "Envoyer",
    exhibition_text_chat: "Discussion en ligne",
    help_faq: "Foire aux questions",
    help_request_help: "Demander de l’aide",
    help_request_help_info_text: "Si vous avez besoin d’aide lors de l’événement, veuillez soumettre votre demande ci-dessous. Un membre de notre équipe d’assistance vous répondra dès que possible !",
    help_welcome_video: "Vidéo de bienvenue",
    jobs_apac: "APAC (Asie-Pacifique)",
    jobs_associate: "Associé",
    jobs_at_least_eight: "Au moins 80 000 livres sterling",
    jobs_at_least_five: "Au moins 50 000 livres sterling",
    jobs_at_least_four: "Au moins 40 000 livres sterling",
    jobs_at_least_seven: "Au moins 70 000 livres sterling",
    jobs_at_least_six: "Au moins 60 000 livres sterling",
    jobs_at_least_three: "Au moins 30 000 livres sterling",
    jobs_at_least_two: "Au moins 20 000 livres sterling",
    jobs_contract: "Contrat",
    jobs_contract_length: "Durée du contrat",
    jobs_contract_type: "Type de contrat",
    jobs_director: "Directeur",
    jobs_entry_level: "Premier échelon",
    jobs_europe: "Europe",
    jobs_executive: "Cadre",
    jobs_full_time: "Plein temps",
    jobs_industry_sector: "Secteur industriel",
    jobs_job_detail: "Description du poste",
    jobs_location_region: "Région du lieu",
    jobs_location_specific: "Lieu de travail",
    jobs_mid_senior: "Échelons intermédiaire et supérieur",
    jobs_over_eight: "Plus de 81 000 livres sterling",
    jobs_part_time: "Temps partiel",
    jobs_permanent: "Contrat à durée indéterminé",
    jobs_salary_actual: "Salaire réel:",
    jobs_salary_bracket: "Fourchette salariale:",
    jobs_seniority_level: "Niveau d’ancienneté",
    jobs_south_america: "Amérique du Sud",
    jobs_temporary: "Contrat à durée déterminée",
    jobs_uk: "Royaume-Uni",
    jobs_url: "Site Web",
    jobs_us_canada: "États-Unis et Canada",
    login_email_address: "Adresse électronique",
    login_forgot_password: "Vous avez oublié votre mot de passe ?",
    login_login: "Connexion",
    login_register: "Vous êtes un nouvel utilisateur ? Enregistrez-vous ici",
    login_sign_in_if_registered: "Vous vous êtes déjà inscrit(e) ? Connexion :",
    login_welcome: "Bienvenue à la GYECC21",
    meetings_accept: "Accepter",
    meetings_awaiting_responses: "Réponses en attente",
    meetings_ignore: "Refuser",
    meetings_info_text: "Pour des informations complémentaires sur l’organisation d’une rencontre, accédez à la rubrique Aide et consultez la section FAQ",
    meetings_meetings_confirmed: "Rencontres confirmées",
    meetings_other_time: "Changer l’heure ?",
    meetings_requests_recieved: "Demandes reçues",
    my_day_close_without_saving: "Fermer sans enregistrer",
    my_day_event_date: "Date d’événement",
    my_day_no_session_selected: "Aucune séance n’est sélectionnée",
    my_day_plan_my_day: "Planifier ma journée",
    my_day_save_exit: "Enregistrer et quitter",
    my_day_time_slot_one: "Créneau horaire 1",
    nav_agenda: "Programme",
    nav_attendees: "Participants",
    nav_conference_agenda: "Programme de la conférence",
    nav_green_jobs: "Emplois verts",
    nav_gyecc_tv: "GYECC TV",
    nav_lobby: "Parc écologique",
    nav_log_out: "Déconnexion",
    nav_my_beehive: "Mon événement",
    nav_my_breakout_selection: "Ma sélection de groupes de travail",
    nav_my_meetings: "Mes rencontres",
    nav_navigate: "Parcourir",
    nav_on_demand: "Rediffusion de l’événement",
    nav_presenters: "Présentateurs",
    nav_speakers: "Intervenants",
    nav_workstream_four: "Groupe de travail 4",
    nav_workstream_one: "Groupe de travail 1",
    nav_workstream_three: "Groupe de travail 3",
    nav_workstream_two: "Groupe de travail 2",
    network_connect: "Se connecter",
    network_disconnect: "Se déconnecter",
    network_meeting: "Rencontre en tête à tête",
    networking_pledge_wall: "Mur des engagements",
    networking_social_wall: "Mur social",
    on_demand_day_one: "1er jour",
    on_demand_day_two: "2e jour",
    plenary_ask_questions: "Poser une question",
    plenary_interprefy: "Langues",
    posters_enlarge_poster: "Cliquer ici pour agrandir ou ouvrir le PDF",
    posters_overview: "Aperçu",
    posters_view: "Affichage",
    presenters_info_text: "Rechercher des intervenants et des séances",
    presenters_organisation: "Organisation",
    presenters_talk_abstract: "Extrait de la discussion",
    presenters_talk_title: "Titre de la discussion",
    profile_many_thanks: "Nous vous remercions d’avoir créé votre profil",
    profile_picture_upload: "Téléchargement d’une photo (JPEG, PNG, GIF)",
    profile_profile_picture: "Téléchargement d’une photo et d’une vidéo de profil",
    profile_your_information: "Vos informations",
    register_age: "Âge",
    register_confirm_password: "Confirmer le mot de passe",
    register_email: "Courriel",
    register_first_name: "Prénom",
    register_preferred_language: "Langue de préférence",
    register_profile_picture: "Téléchargement d’une photo de profil",
    register_surname: "Nom de famille",
    register_timezone: "Fuseau horaire",
    reset_password_info_text: "S’il existe un compte utilisateur valide correspondant à l’adresse électronique que vous avez saisie, vous recevrez rapidement un courriel contenant un lien qui vous permettra de réinitialiser votre mot de passe.",
    reset_password_reset_password: "Réinitialiser le mot de passe",
    select_breakout_info_text_one: "Choisissez les groupes de travail auxquels vous souhaitez participer",
    select_roundtable_no: "Aucune séance de réseautage n’a été sélectionnée",
    select_roundtables_title: "Sélectionner des séances de réseautage",
    shared_about: "À propos de",
    shared_ask_a_question: "Poser une question",
    shared_attendee_type: "Type de participant",
    shared_back: "Retour",
    shared_breakouts: "Groupes de travail",
    shared_close: "Fermer",
    shared_delegate: "Délégué",
    shared_english: "Anglais",
    shared_event_rewind: "Rediffusion de l’événement",
    shared_exhibition: "Exposition",
    shared_exhibitor_partner: "Exposant/partenaire",
    shared_exit: "Quitter",
    shared_french: "Français",
    shared_help: "Aide",
    shared_internship: "Stage",
    shared_job_title: "Titre de poste",
    shared_mandarin: "Mandarin",
    shared_my_profile: "Mon profil",
    shared_networking_lounge: "Salon de réseautage",
    shared_networking_lounge_short: "Salon de réseautage",
    shared_next: "Suivant",
    shared_organiser: "Organisateur",
    shared_other: "Autre",
    shared_password: "Mot de passe",
    shared_plenary: "Plénière",
    shared_poster_gallery: "Galerie",
    shared_previous: "Précédent",
    shared_questions_answered: "Questions ayant reçu une réponse",
    shared_questions_to_be_answered: "Questions nécessitant une réponse",
    shared_roundtable: "Séance de réseautage",
    shared_search: "Rechercher",
    shared_spanish: "Espagnol",
    shared_speaker: "Intervenant",
    shared_submit: "Soumettre",
    shared_submit_a_question: "Soumettre une question",
    shared_upload: "Télécharger",
    leaderboard_title: "Classement ",
    leaderboard_info_text: "Participez activement à la Global Youth Engineering Climate Conference pour voir si vous pouvez prendre la tête du classement!<br />Pour gagner des points, visitez notre exposition, examinez nos ressources, découvrez les différents intervenants, consultez les panneaux d’affichage de nos partenaires et interagissez avec d’autres délégués et exposants. Plus vous vous impliquez, plus vous gagnez de points, et plus grandes sont vos chances de remporter des prix.<br />Les gagnants seront annoncés avant la séance de questions-réponses en plénière à la fin de la Conférence, qui se déroulera mercredi 8 septembre à 9 h 30 (UTC+1).",
    speakers_info_text: "Recherchez des intervenants et panélistes qui participeront à la Conférence GYECC21, par nom ou par titre de session. ",
    attendees_info_text: "Connectez-vous ! Recherchez des délégués et des exposants par nom, titre professionnel ou organisation.<br />Connectez-vous en cliquant sur leur profil pour consulter leurs disponibilités et demander à les rencontrer.",
    event_rewind_info_text: "Des enregistrements de l’événement seront disponibles en consultation à la demande pendant les deux mois qui suivent l’événement, à l’approche de la COP26.",
    exhibition_qa_title: "Exposition – Questions-réponses",
    exhibition_qa_info_text: "Les séances de questions-réponses feront l’objet d’un suivi aux dates et heures suivantes:<br />Mardi 7 septembre, de 15 h 30 à 18 h 30 (UTC+1)<br />Mercredi 8 septembre, de 6 h 30 à 9 h 30 (UTC+1)",
    exhibition_text_chat_title: "Exposition – discussions en ligne",
    exhibition_text_chat_info_text: "Les discussions en ligne feront l’objet d’un suivi aux dates et heures suivantes :<br />Mardi 7 septembre, de 15 h 30 à 18 h 30 (UTC+1)<br />Mercredi 8 septembre, de 6 h 30 à 9 h 30 (UTC+1)",
    exhibition_leave_details_title: "Exposition – soumettez vos coordonnées",
    exhibition_leave_details_info_text: "Les informations que vous soumettez ici seront communiquées à l’exposant. Si vous souhaitez un suivi par courriel ou par téléphone, veuillez indiquer les informations de contact que vous préférez utiliser et présentez brièvement le(s) sujet(s) dont vous souhaitez discuter.",
    exhibition_group_video_title: "Exposition – Discussion vidéo en groupe",
    exhibition_group_video_info_text: "Les représentants seront disponibles aux dates et heures suivantes : <br />Mardi 7 septembre, de 15 h 30 à 18 h 30 (UTC+1)<br />Mercredi 8 septembre, de 6 h 30 à 9 h 30 (UTC+1)",
    networking_tables_title: "Séances de réseautage",
    networking_tables_info_text: "Les séances de réseautage se tiendront aux dates et heures suivantes :<br />Mardi 7 septembre, de 17 h 30 à 18 h 30 (UTC+1)<br />Mercredi 8 septembre, de 8 h 30 à 9 h 30 (UTC+1)<br /><br />Veuillez noter que toutes les places sont attribuées selon le principe « premier venu, premier servi ». <br />Veuillez sélectionner la séance de réseautage à laquelle vous souhaitez participer – s’il n’y a plus de places pour la séance que vous avez choisie, vous pouvez en rejoindre une autre avant de vérifier si de nouvelles places sont devenues disponibles.",
    plan_my_day_info_text: "Utilisez cette section pour profiter au mieux de votre journée en sélectionnant les groupes de travail et les séances de réseautage auxquels vous souhaitez participer – les sélections seront ajoutées à votre programme personnalisé.<br />Les quatre groupes de travail démarreront à la même heure et se tiendront deux fois par jour. Vous pouvez décider de participer à un maximum de deux groupes de travail successifs, suivis d’une séance de réseautage.<br />Veuillez parcourir les pages et sélectionner les séances que vous souhaitez ajouter à votre programme pour chaque créneau horaire – en cliquant sur Suivante ci-dessous pour passer au créneau suivant, puis sur Enregistrer et quitter pour confirmer vos choix. <br />Si vous ne souhaitez pas participer à une séance au cours de l’un des créneaux horaires, veuillez sélectionner Explorer et réseauter, où vous pouvez consulter librement l’exposition ou organiser des rencontres en tête à tête avec d’autres participants.<br />Veuillez noter que cette section est destinée à vous aider dans la planification de votre programme et qu’elle ne garantit pas que vous pourrez participer aux séances de réseautage, dont les places sont attribuées selon le principe « premier venu, premier servi » ; alors, tenez-vous prêt(e) à y participer de bonne heure et de bonne humeur ! <br />",
    notifications_plenary_closed_title: "Séance plénière fermée ",
    notification_plenary_closed: "La séance plénière est actuellement fermée ; veuillez consulter le programme pour savoir quand la prochaine séance démarrera. ",
    notification_verification_email_title: "Ne manquez pas le courriel d’authentification ",
    notification_verification_email: "Merci d’avoir créé votre compte Global Youth Engineering Climate Conference 2021. Nous vous avons envoyé un courriel d’authentification – veuillez cliquer sur le lien contenu dans le courriel pour authentifier votre compte. ",
    notification_error_loggin_in_title: "Erreur d’identifiants de connexion ",
    notification_error_loggin_in: "Une erreur liée à vos identifiants de connexion est survenue. Veuillez vérifier que votre adresse électronique et/ou votre mot de passe sont corrects. Si vous n’avez pas encore créé de compte, veuillez cliquer ci-dessous pour en configurer un. ",
    notification_exhibition_closed_title: "Exposition fermée ",
    notification_exhibition_closed: "L’exposition sera ouverte mardi 7 septembre et mercredi 8 septembre. Veuillez revenir à ces dates pour proposer du contenu et prendre contact avec nos exposants.",
    notifications_titles: "Notifications de la plateforme",
    agenda_opening_remarks: "Plénière – Discours d’ouverture et principaux intervenants",
    agenda_opening_remarks_info: "Intervenants:<br />Denise Lee, Environmental Policy Researcher and Student, University of Toronto<br />Alok Sharma, President of the 26th United Nations Climate Change Conference<br />John Pettigrew, Chief Executive, National Grid<br />Joseph Tsongo, Co-ordinator of the Amani-Institute ASBL<br />Chris Stark, Chief Executive, Climate Change Committee",
    agenda_select_workstream_session: "Sélectionner une séance de groupe de travail",
    agenda_select_networking_table: "Sélectionner une séance de réseautage",
    agenda_plenary_qa_panel: "Plénière – panel de questions-réponses",
    agenda_plenary_qa_panel_info: "Intervenants:<br />Eva Saha, Conference MC <br /> Dr. Nick Wayth, Energy Institute <br />Carlotta Giacché, Nord/LB and My Meaningful Money<br />Dr. Alaa Al Khourdajie, IPCC Working Group III<br />Sam Johnson Transport for London<br />Caritta Seppa, Tespack<br />Natalie Edwards, National Grid",
    agenda_plenary_qa_panel_info_day2: "Intervenants:<br />Eva Saha, Conference MC <br />Dr. Nick Wayth, Energy Institute <br />Polly Osborne, Burns & McDonnell<br />Amy Weltvreden, National Grid Electricity System Operator<br />Dr. Alaa Al Khourdajie, IPCC Working Group III<br />Dr. Alex Wohnas, Australian Energy Market Operator (AEMO)",
    agenda_make_your_pledge: "Prenez votre engagement",
    agenda_option_one: "Option 1",
    agenda_option_two: "Option 2",
    agenda_option_three: "Option 3",
    agenda_option_four: "Option 4",
    agenda_option_five: "Option 5",
    agenda_workstream_one_title: "Groupe de travail 1 –  Compétences vertes : Comment devenir un agent du changement",
    agenda_workstream_one_info: "Vous souhaitez contribuer à un avenir vert, mais vous ne savez pas de quelles compétences vous avez besoin ?<br /> Peut-être votre société amorce-t-elle une transition en vue d’abandonner les combustibles fossiles et vous avez besoin de nouvelles compétences ?<br /><br />Peut-être êtes-vous nouveau/nouvelle dans le secteur des énergies et vous souhaitez contribuer à un avenir plus vert ?<br /><br />Le groupe de travail « Compétences vertes : Comment devenir un agent du changement » répondra à ces questions et bien d’autres.<br /><br />Dans le cadre d’un panel interactif et diversifié de sociétés du secteur énergétique, d’entrepreneurs, de financiers et de nations en développement, vous aurez l’occasion d’examiner de nouvelles compétences, de redécouvrir celles dont vous disposez déjà et d’apprendre à faire valoir vos compétences auprès des autres. Vous pouvez être un agent du changement en promouvant les compétences vertes dans votre vie professionnelle et personnelle.",
    agenda_workstream_two_title: "Groupe de travail 2 – Accès à l’énergie pour les communautés à zéro carbone",
    agenda_workstream_two_info: "Nous comprenons la nécessité d’un système énergétique plus propre… N’est-ce pas ?<br /><br /> Nous sommes déterminés à transformer notre quotidien en vue d’assurer une durabilité à long terme… N’est-ce pas ?<br /><br />Nous devons décarboniser nos communautés. Il se peut que ce soit déjà le cas dans le lieu où vous vivez ou, au contraire, que vous ayez l’impression que les progrès sont insuffisants.<br /><br /> Notre « Conseil de communautés à faible carbone » discutera de solutions permettant de décarboniser nos communautés et d’instaurer un changement durable, notamment avec les avis d’experts spécialisés dans les programmes communautaires à l’échelle locale et dans les projets d’énergies vertes. Nous discuterons également des moyens par lesquels vous pouvez promouvoir le changement, quelle que soit la position de votre communauté dans sa transition vers la décarbonisation.",
    agenda_workstream_three_title: "Groupe de travail 3 – Mobilisation des générations – le changement vert commence par soi-même",
    agenda_workstream_three_info: "Comment les produits que vous achetez, les habitudes que vous développez et les carrières que vous poursuivez contribuent-ils à un impact mondial ?<br /><br />Joignez-vous à nous pour comprendre votre impact personnel et découvrez les contributions que des changements individuels peuvent apporter à la décarbonisation.<br /><br />Ce groupe de travail se déroulera dans le cadre d’un jeu divertissant de questions visant à enrichir vos connaissances sur le paysage énergétique en évolution constante.<br /><br />Tout au long du jeu, des experts industriels présenteront les enseignements qu’ils ont tirés sur leur parcours et proposeront des actions à mener pour renforcer votre propre impact individuel. Suite à votre participation à ce jeu de questions, vous serez mieux équipé(e) pour prendre des décisions plus déterminantes en faveur d’un monde plus vert.",
    agenda_workstream_four_title: "Groupe de travail 4 – Un système énergétique mondial à zéro carbone pour tous",
    agenda_workstream_four_info: "Les objectifs sont clairs. Nous devons atteindre un bilan zéro carbone à l’échelle mondiale d’ici le milieu du siècle et nous adapter pour protéger les communautés et les habitats naturels. La question est de savoir comment, ensemble, nous pouvons travailler à la réalisation de ces objectifs. Comment pouvons-nous unir nos forces afin de créer un environnement inclusif et diversifié en faveur d’un objectif commun tout en reconnaissant nos propres difficultés aux niveaux individuel et local ?<br /><br />La séance « Système énergétique mondial à zéro carbone » vous entraînera dans un tour du monde en examinant les approches de différents pays et cultures en matière de transition vers un système énergétique à faible carbone. Cette séance ouvrira la voie à des échanges de questions approfondies et d’idées dans un cadre collaboratif, en vue d’atteindre l’objectif le plus important de notre génération : l’instauration de systèmes énergétiques à zéro carbone.<br /><br />Lors de ce tour du monde, nous découvrirons les moyens innovants par lesquels des personnes dans différentes régions du monde œuvrent à la réalisation des mêmes objectifs communs. Au travers de cette perspective mondiale, nous serons mieux à même de trouver des solutions pour bâtir ensemble un avenir énergétique propre et abordable.",
    agenda_explore_network: "Explorer et réseauter",
    agenda_visit_attendee_gallery: "Rendez-vous à la galerie des participants pour organiser des rencontres en tête à tête avec d’autres délégués ou profitez de ce temps pour découvrir l’environnement virtuel. Accédez à l’exposition, à la galerie, à la section sur les emplois verts ou à GYECC TV.",
    account_confirmation_status: "Statut de confirmation de compte",
    awaiting_confirmation: "En attente de confirmation",
    select_workstream: "Sélectionnez un groupe de travail",
    eco_park_streams: "Groupes",
    eco_park_networking: "Réseautage",
    register_error_first_name: "Veuillez saisir votre prénom",
    register_error_surname: "Veuillez saisir votre nom de famille",
    register_error_age_range: "veuillez sélectionner votre catégorie d'âge",
    register_error_enter_email: "Veuillez saisir votre adresse email",
    register_error_enter_valid_email: "Veuillez saisir une adresse email valide",
    register_error_enter_password: "Veuillez saisir un mot de passe",
    register_error_password_six_letters: "Veuillez utiliser un mot de passe d'au moins 6 caractères",
    register_error_password_match: "Veuillez vous assurer que vos mots de passe sont identiques",
    register_error_account_already_exists: "Un compte existe déjà pour ette adresse email. Veuillez réinitialiser votre mot de passe.",
    register_error_attendee_type: "Veuillez sélectionner un type de participant",
    register_error_language: "Veuillez sélectionner une langue",
    register_error_country: "Veuillez saisir un pays",
    register_error_ltimezone: "Veuillez sélectionner un fuseau horaire",
    register_registration_request: "Votre demande d'inscription est en cours d'approbation. Pour toute demande, veuillez contacter box.COP26@nationalgrid.com ",
    register_account_confirmation_email: "Veuillez vous assurer que vous avez cliqué sur le lien dans l'email de confirmation de votre compte.",
    register_password_reset: "Merci. Si un compte est enregistré pour cette adresse email, vous recevrez rapidement un email contenant un lien pour réinitialiser votre mot de passe.",
    shared_livestream_error: "Nous rencontrons actuellement des problèmes techniques. Veuillez rester connectés, nous serons bientôt de retour.",
    notification_welcome: "Bienvenue au GYECC21 ! Pour sélectionner vos groupes de travail et vos séances de réseautage, veuillez consulter la page Planifier ma journée",
    notification_first_session_five_minute_warning: "Rejoignez-nous pour la première session de la journée en plénière - début dans 5 minutes !",
    notification_automatic_movement_to_plenary: "Vous serez automatiquement transféré vers la plénière dans 5 minutes - fermez vos conversations et venez nous rejoindre !",
    notification_plenary_live: "Nous sommes maintenant en direct de la plénière. Veuillez vous rendre dans cet espace pour rejoindre la session.",
    notification_final_session: "La dernière session du GYECC21 va commencer - veuillez vous diriger vers la plénière !",
    notification_audio_volume: "Vous n'entendez pas le son ? Cliquez sur le bouton de volume en bas de votre écran.",
    notification_slido_comms: "Aujourd'hui, tout tourne autour de la communication bilatérale - soumettez vos questions en utilisant Sli.do",
    notification_visit_exhbition_gallery: "N'oubliez pas de visiter l'exposition et la galerie pour explorer et vous connecter avec les partenaires du GYECC !",
    notification_last_workstream_session: "Votre dernière chance d'explorer l'un des groupes de travail approche - rendez-vous dans les pods des groupes de travail dans 5 minutes",
    notification_workstream_selection: "Vous avez le choix entre quatre groupes de travail. Avez-vous déjà participé à l'un d'entre eux ?",
    notification_make_a_pledge: "Rendez-vous dans le salon de réseautage et cliquez sur le mur des engagements pour soumettre votre engagement GYECC21 !",
    notification_workstream_signup: "Vous êtes-vous déjà inscrit à un groupe de travail ? Ne ratez pas cette opportunité - Allez sur Planifier ma journée !",
    notification_connect_with_attendees: "Connectez-vous avec les participants en demandant des réunions en 1:1 dans la galerie des participants",
    notification_social_networking_tables: "Envie d'une discussion ? Rendez-vous à l'une des séances de réseautage au cours de la prochaine heure.",
    notification_social_wall: "Avez-vous consulté le social wall ? Consultez vos messages dans la galerie du social wall",
    notification_book_meetings: "Avez-vous réservé une réunion en 1:1 aujourd'hui ? Visitez la galerie des participants pour demander un rendez-vous avec un autre participant.",
    notification_available_vacancies: "Consultez les offres d'emploi disponibles des partenaires du GYECC dans la zone réservée aux emplois",
    notification_thank_you: "Merci de nous avoir rejoints au GYECC21 ! Nous espérons que vous avez apprécié l'événement et que vous avez trouvé des contacts utiles.",
    notification_on_demand: "La plateforme restera ouverte jusqu'à la COP26 en novembre. Vous pouvez consulter tout le contenu à la demande sur GYECC TV.",
    exhibition_leave_details_follow_up_request: "Demander un suivi",
    exhibition_leave_details_request_meeting: "J'aimerais demander un rendez-vous en 1:1",
    exhibition_leave_details_contact_me: "J'aimerais être contacté",
    meetings_meeting_with: "Réunion en 1:1 avec",
    mobile_access_from_desktop: "Veuillez accéder à la plateforme depuis un ordinateur fixe ou portable pour participer à cette réunion",
    plan_my_day_selection_complete: "Sélection des sessions terminée",
    exhibition_hall_two: "Hall 2",
    exhibition_hall_three: "Hall 3",
    exhibition_hall_four: "Hall 4",
    shared_roundtables: "Tables rondes",
    jobs_job_search: "Recherche d'emploi",
    forgotten_password_new: "Nouveau mot de passe",
    forgotten_password_confirm_new: "Confirmer le nouveau mot de passe",
    speakers_biography: "Biographie",
    speakers_bio: "Bio",
    speakers_session_name: "Nom de la session",
    speakers_session_description: "Description de la session",
    speakers_session_time: "Horaire de la session",
    speakers_session_location: "Lieu de la session",
    register_please_select: "Veuillez choisir",
    nav_get_connected: "Connectez-vous !",
    shared_advanced_search: "Recherche avancée",
    jobs_advanced_job_search: "Recherche d'emploi avancée",
    leaderboard_my_points: "Mes résultats",
    nav_session_selection: "Sélection de la session",
    mobile_exhibition_hall: "Hall d'exhibition",
    exhibition_visit: "Visite",
    exhibition_back_to_exhibition: "Retour à l'exhibition",
    exhibition_search_exhibition: "Recherche de stands d'exhibition",
    meetings_request_meeting: "Demander une réunion avec",
    meetings_meetings_info_text: "Pour réserver une réunion en 1:1, veuillez sélectionner l'un des créneaux horaires ci-dessous et cliquer sur soumettre. Une fois acceptée, la réunion apparaîtra dans votre agenda.",
    meetings_connect_when_ready: "Veuillez cliquer sur connecter dès que vous êtes prêt à rejoindre la réunion",
    on_demand_like_video: "Merci d'avoir liké cette vidéo !",
    networking_back_to_tables: "Retour",
    networking_social_tables: "Séances de réseautage social",
    netowrking_hosted_tables: "Séances hébergées",
    exhibition_please_include_message: "Veuillez inclure un message",
    exhibition_message_sent: "Message envoyé avec succès",
    meetings_submitted: "Votre demande de réunion a été soumise",
    meetings_another_time: "Votre demande d'un nouvel horaire a été envoyée",
    exhibition_submit_question: "Veuillez saisir une question à soumettre",
    registration_photo_submitted: "Chargement réussi de la photo de votre profil",
    plan_my_day_completed: "Merci de sélectionner vos sessions - celles-ci apparaitront dans votre agenda personnalisé. Vous pouvez modifier votre sélection de sessions à tout moment dans la zone 'Planifier ma journée'.",
    profile_create: "Créer votre profil",
    profile_social_media: "Medias sociaux",
    profile_facebook: "Vous pouvez trouver le lien de votre porfil dansles paramètres de profil. Il s'agira d'un URL incluant votre lien Facebook personnalisé :. https://www.facebook.com/Nomd'utilisateur/",
    profile_tiktok: "Ajouter votre nom d'utilisateur TikTok. Il s'agit du @nom d'utilisateur que les autres utilisateurs de TikTok peuvent utiliser pour vous taguer ou vous trouver",
    profile_instagram: "Ajouter votre nom d'utilisateur Instagram. Il s'agit du @nom d'utilisateur que les autres utilisateurs d'Instagram peuvent utiliser pour vous taguer ou vous trouver",
    profile_twitter: "Ajouter votre nom d'utilisateur Twitter. Il s'agit du @nom d'utilisateur que les autres utilisateurs de Twitter peuvent utiliser pour vous taguer ou vous trouver",
    profile_weibo: "Pour trouver votre URL personnelle, cliquez sur le menu en haut à droite de la page principale de votre compte Weibo. Un menu s'affichera avec une option permettant de copier votre URL personnelle",
    profile_personalised_url: "Visiter votre profil et ajouter votre URL personnalisée depuis la barre d'adresse",
    profile_url: "Visiter votre profil et ajouter votre URL personnalisée",
    profile_already_created: "Profil déjà créé ?",
    jobs_reset: "Réinitialiser",
    jobs_apply: "Appliquer",
    networking_lounge_submit_pledge: "Cliquez ici pour soumettre votre engagement",
    networking_lounge_social: "Cliquez ici pour voir les messages des médias sociaux #GYECC21",
    social_wall_join: "Ajoutez #GYECC21 à votre message sur les médias sociaux pour qu'il soit ajouté au mur social du GYECC.",
    pledge_wall_info: "Cliquez sur le bouton 'Engagez- vous' pour nous dire comment vous allez devenir #onestepgreener",
    notification_gyecctv_closed_title: "GYECC TV n'est pas disponible",
    notification_gyecctv_closed: "GYECC TV sera disponible à partir du mardi 7 septembre - Veuillez revenir plus tard pour profiter de plus de 45 vidéos à la demande.",
    notification_gallery_closed_title: "Galerie fermée",
    notification_gallery_closed: "La galerie sera ouverte le mardi 7 et le mercredi 8 septembre - Veuillez revenir plus tard pour accéder au contenu de nos partenaires commerciaux.",
};