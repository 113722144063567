import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Trans, withTranslation } from 'react-i18next';
import dompurify from "dompurify";
import RoundtablesMobile from "./RoundtablesMobile";
import styles from "./Roundtables.module.scss";

export default class Roundtables extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      roundtables: [],
    };
  }

  componentDidMount () {
    this.getRoundtables();
  }

  getRoundtables () {
    fetch("/api/getRoundtablesList")
      .then((res) => res.json())
      .then((data) => this.setState({ roundtables: data }));
  }

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  render () {
    const roundtablesGrid = this.state.roundtables.map((roundtable, index) => {
      return (
        <div key={roundtable._id} className={styles.roundtableGridItem}>


          <div className={styles.roundtableItem}>
            <span>
              <div className={styles.innerContainer}>
                {/**<h1 className={styles.tableNumber}>Roundtable {index + 1}</h1>*/}
                <div className={styles.tableNameVerticalCentre}>
                  <h1 className={styles.tableName}>{roundtable.tableName}</h1>
                </div>

                <div className={styles.lowerRoundtableArea}>
                  {roundtable.roundtableType > 0 ? (
                    <Link to={"/roundtableSession/" + roundtable._id}>
                      <img
                        className={styles.joinHexButton}
                        src={window.$videoPath + "icons/joinCircle.png"}
                        alt="Detail"
                      />
                    </Link>
                  ) : null}
                  <br />
                </div>
              </div>
            </span>
          </div>
        </div>
      );
    });

    return (
      <div>
        {this.props.showMobile ?
          <RoundtablesMobile roundtables={this.state.roundtables} />
          :
          <>
            <div className={styles.outerBackgroundDiv}>
              {/**<img
              className="imageNavBackground"
              src={window.$videoPath + "background.png"}
              alt="Background"
            ></img>*/}
            </div>
            <div className={styles.hotlinkBar}>
              <Link to={"/lobby"} className={styles.backLink}>
                <div className={styles.backDiv}>
                  <img
                    className={styles.backArrow}
                    src={window.$videoPath + "icons/backArrow.png"}
                    alt="Back"
                  />
                  <Trans>attendees_back_to_eco_park</Trans>
                </div>
              </Link>
            </div>

            <div className={styles.headerBar}>
              <h1 className={styles.pageTitle}>
                <strong>ROUNDTABLES</strong>
              </h1>
            </div>
            <section className={styles.profileSegmentsArea}>
              {roundtablesGrid}
            </section>
          </>
        }
      </div>
    );
  }
}
