import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import styles from "./ExhibitionIntro.module.scss";

export default function ExhibitionIntro (props) {
  const [introVideoComplete, setIntroVideoComplete] = useState(false);

  useEffect(() => {
    // Start timeout in case video does not load
    setTimeout(function () {
      setIntroVideoComplete(true);
    }, 6000);
  }, []);

  return (
    <div className={styles.masterPageDiv}>

      <div>
        <video
          className="imageNavBackground"
          autoPlay
          webkit-playsinline="true"
          playsInline
          id="myVideo"
          muted
          onEnded={() => setIntroVideoComplete(true)}
        >
          <source
            src={window.$videoPath + "exhibition/fullScreenVisuals/" + props.language + "/exhibitionIntro.webm"}
            type="video/webm"
          />
          <source
            src={window.$videoPath + "exhibition/fullScreenVisuals/" + props.language + "/exhibitionIntro.mp4"}
            type="video/mp4"
          />
        </video>

        {/**<CSSTransition
          in={introVideoComplete}
          timeout={1500}
          classNames="imageNavBackground"
          unmountOnExit
        >
          <img
            className="imageNavBackground"
            src={window.$videoPath + "exhibition/exhibitionIntro.jpg"}
            alt="Background"
          ></img>
        </CSSTransition>*/}
        {introVideoComplete || props.showMobile ? <Redirect to={"/exhibition"} /> : null}

      </div>

      <Link to="/exhibition">
        <img
          className={`${styles.hoverHotspot} ${styles.exhibitionHoverHotspot}`}
          src="/hoverShapes/hoverSquare.png"
          alt="Navigate to Exhibition"
        ></img>
      </Link>

    </div>
  );
}
