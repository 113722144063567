import React, { Component } from "react";
import Button from "react-bootstrap/Button";

import styles from "./ExhibitionStandAnswers.module.scss";

export default class ExhibitionStandAnswers extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      exhibitionStandUnansweredQuestions: [],
      exhibitionStandAnsweredQuestions: [],
      selectedQuestionID: null,
      newAnswerText: "",
    };
  }

  componentDidMount () {
    this.loadQuestions();
  }

  loadQuestions () {
    //console.log("Selected exhibition stand: " + this.props.match.params.exhibitionStandID);

    // Fetch unanswered
    fetch(
      "/api/getExhibitionStandUnansweredQuestions?exhibitionStandID=" +
      this.props.match.params.exhibitionStandID
    )
      // Need to somehow request by received prop ID
      .then((res) => res.json())
      .then((data) => this.loadUnansweredIntoState(data));

    // Fetch answered
    fetch(
      "/api/getExhibitionStandAnsweredQuestions?exhibitionStandID=" +
      this.props.match.params.exhibitionStandID
    )
      // Need to somehow request by received prop ID
      .then((res) => res.json())
      .then((data) => this.loadAnsweredIntoState(data));
  }

  loadUnansweredIntoState (data) {
    this.setState({
      exhibitionStandUnansweredQuestions: data,
    });
  }

  loadAnsweredIntoState (data) {
    this.setState({
      exhibitionStandAnsweredQuestions: data,
    });
  }

  handleChange = (event) => {
    //console.log("Change");
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  hideQuestion = () => {
    fetch("/api/hideExhibitionStandQuestion", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        questionID: this.state.selectedQuestionID,
        answerToken: this.props.match.params.answerToken,
      }),
    })
      .then((result) => {
        if (result.status === 200) {
          //console.log("Submitted question request");
        } else {
          alert("Please confirm your answer page URL is correct and you have sufficient priviledge")
        }
        this.setState({ newAnswerText: "" });
        this.loadQuestions(this.props.match.params.exhibitionStandID);
      });
  };

  submitNewAnswer = () => {
    fetch("/api/submitExhibitionStandAnswer", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        questionID: this.state.selectedQuestionID,
        answerText: this.state.newAnswerText,
        answerToken: this.props.match.params.answerToken,
      }),
    })
      .then((result) => {
        if (result.status === 200) {
          //console.log("Submitted question request");
        } else {
          alert("Please confirm your answer page URL is correct and you have sufficient priviledge")
        }
        this.setState({ newAnswerText: "" });
        this.loadQuestions(this.props.match.params.exhibitionStandID);
      });

  };

  render () {
    const unansweredQuestions = this.state.exhibitionStandUnansweredQuestions.map(
      (question) => {
        return (
          <div key={question._id}>
            <label>
              <input
                type="radio"
                name="selectedQuestionID"
                value={question._id}
                onChange={this.handleChange}
              />
              {question.questionText}
            </label>
          </div>
        );
      }
    );

    const answeredQuestions = this.state.exhibitionStandAnsweredQuestions.map(
      (question) => {
        return (
          <div key={question._id}>
            <label>
              <input
                type="radio"
                name="selectedQuestionID"
                value={question._id}
                onChange={this.handleChange}
              />
              {question.questionText}
              <br />
              <span style={{ color: "red" }}>{question.answerText}</span>
            </label>
          </div>
        );
      }
    );

    return (
      <div className={styles.answerArea}>
        <br />
        <h1>Q&A Answer Area</h1>
        <br />
        <h3>
          Please select the question you wish to answer, enter your answer in
          the text field and then press Submit.
        </h3>
        <h3>
          To amend a previously submitted answer, simply select the question
          from the 'Answered Questions' list, enter a new answer in the Submit
          Answer area and then press Submit
        </h3>
        <br />
        <br />
        <form>
          <h2>Unanswered Questions:</h2>
          {unansweredQuestions}
          <br />
          <br />
          <h2>Answered Questions:</h2>
          {answeredQuestions}
          <br />
          <br />
          <h2>Submit Answer:</h2>
          <textarea
            cols="50"
            rows="5"
            className={styles.newAnswer}
            type="text"
            name="newAnswerText"
            placeholder="Enter answer here"
            value={this.state.newAnswerText}
            onChange={this.handleChange}
          />
          <br />
          <Button onClick={this.hideQuestion}>Hide Question</Button>
          <Button style={{ marginLeft: "20px" }} onClick={this.submitNewAnswer}>Submit</Button>
        </form>
      </div>
    );
  }
}