import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import styles from "./ExhibitionHallMobile.module.scss";

export default class ExhibitionHallMobile extends Component {

  render () {
    return (
      <div className={styles.mobileListView} >
        <div className={styles.mobileTitleArea}>
          <h1 className={styles.mobileTitleText}><Trans>mobile_exhibition_hall</Trans>{this.props.hallID}</h1>
          <Link to="/exhibition"><Button><Trans>shared_back</Trans></Button></Link>
        </div>

        {this.props.hallID === "1" ?
          <>
            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619c1">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>Aquatera Ltd</h3>
              </div>
            </Link>

            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619c2">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>Burns & McDonnell</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619c3">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>CIGRE</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619c4">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>Clarkson University</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619c5">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>EEI</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619c6">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>Energy Institute</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619c7">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>EY</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619c8">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>EPRI</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619c9">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>Hitachi</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619ca">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>IET</h3>
              </div>
            </Link>

            <Link to="/exhibitionSearch">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}><Trans>shared_search</Trans></h3>
              </div>
            </Link>
          </>
          : null}

        {this.props.hallID === "2" ?
          <>
            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619cb">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>Mott MacDonald</h3>
              </div>
            </Link>

            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619cc">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>National Grid Renewables</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619cd">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>National Grid Ventures</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619ce">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>National Grid ESO</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619cf">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>National Grid</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619d0">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>NatWest Group</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619d1">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>NYU Tandon School of Engineering</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619d2">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>One Young World</h3>
              </div>
            </Link>


            <Link to="/exhibitionSearch">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}><Trans>shared_search</Trans></h3>
              </div>
            </Link>
          </>
          : null}

        {this.props.hallID === "3" ?
          <>
            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619d4">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>SSE plc</h3>
              </div>
            </Link>

            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619d3">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>This is Engineering (RAEng)</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619d5">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>The Crown Estate</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619d6">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>Tufts University Fletcher School of Law and Diplomacy (Fletcher) and School of Engineering (SOE)</h3>
              </div>
            </Link>


            <Link to="/exhibitionStand/611d6f8b23b2afb0be1619d7">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}>WES</h3>
              </div>
            </Link>


            <Link to="/exhibitionSearch">
              <div
                className={styles.mobileListItem}
              >
                <h3 className={styles.mobileListTitle}><Trans>shared_search</Trans></h3>
              </div>
            </Link>
          </>
          : null}
      </div>
    );
  }
}
