import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import styles from "./ExhibitionSubmitDetails.module.scss";

class ExhibitionSubmitDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            requestMeeting: false,
            contactMe: false,
        };
    }

    componentDidMount = () => {

    };

    handleChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value,
        });
    };

    handleCheckboxChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    };


    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.message.length < 1) {
            alert(this.prop.t('exhibition_please_include_message'));
        } else {
            fetch("/api/sendExhibitorMessage", {
                method: "POST",
                body: JSON.stringify({ message: this.state.message, exhibitionStandID: this.props.exhibitionStandID, requestMeeting: this.state.requestMeeting, contactMe: this.state.contactMe }),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        alert(this.props.t('exhibition_message_sent'));
                        this.setState({ message: "", requestMeeting: false, contactMe: false, })
                    } else {
                        const error = new Error(res.error);
                        throw error;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    alert("Error sending message");
                });
        }
    };

    render () {
        return (
            <div>
                <div className={styles.formContainer}>
                    <Trans>exhibition_leave_details_info_text</Trans><br /><br />
                    <form onSubmit={this.handleSubmit} noValidate>
                        <input type="checkbox"
                            id="requestMeeting"
                            name="requestMeeting"
                            value={this.state.requestMeeting}
                            onChange={this.handleCheckboxChange}
                        />{" "}<Trans>exhibition_leave_details_request_meeting</Trans>
                        <br />
                        <br />

                        <input type="checkbox"
                            id="contactMe"
                            name="contactMe"
                            value={this.state.contactMe}
                            onChange={this.handleCheckboxChange}
                        />{" "}<Trans>exhibition_leave_details_contact_me</Trans>
                        <br /><br />

                        <label htmlFor="message"><Trans>exhibition_message</Trans>:</label>
                        <textarea
                            type="text"
                            name="message"
                            className={styles.textEntry}
                            rows="10"
                            placeholder={this.props.t('exhibition_message')}
                            value={this.state.message}
                            onChange={this.handleChange}
                            required
                        />
                        <br />
                        <br />

                        <input
                            type="submit"
                            value={this.props.t('shared_submit')}
                            className="btn btn-success float-right submitButton"
                        />
                    </form>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ExhibitionSubmitDetails);