import React, { Component } from "react";
import dompurify from "dompurify";
import { Trans, withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Image, Transformation } from "cloudinary-react";
import styles from "./ExhibitionSearch.module.scss";

export default class ExhibitionSearch extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      galleryContent: [],
      searchString: "",
    };
  }

  componentDidMount () {
    // Hide corner menu
    this.props.setCornerMenuStatus(3);
    // Load database content
    this.loadCurrentDatabaseContent();
  }

  componentDidUnmount () {
    // Set corner menu to displayed again
    this.props.setCornerMenuStatus(1);
  }

  componentDidUpdate () {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 3) {
      this.props.setCornerMenuStatus(3);
    }
  }

  loadCurrentDatabaseContent () {
    fetch("/api/getExhibitionStandList")
      .then((res) => res.json())
      .then((data) => this.setState({ galleryContent: data }));
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    //console.log(value + " - " + name)
    this.setState({
      [name]: value,
    });
  };


  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };



  render () {
    const exhibitionStandGalleryFiltered = this.state.galleryContent.filter(
      (exhibitionStand) =>
        exhibitionStand.standName
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        exhibitionStand.standSummary
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase())
    );

    const userGallery = exhibitionStandGalleryFiltered.map((exhibitionStand) => {
      return (
        <Link to={"/exhibitionStand/" + exhibitionStand._id} key={exhibitionStand._id} className={styles.exhibitionStandLink}>
          <div
            //style={{ background: exhibitionStand.panelColor }}

            className={styles.exhibitionStandContainer}
          >
            <Image
              className={styles.companyLogo}
              cloudName="oijqec973f1nf"
              publicId={exhibitionStand.logoAssetID + ".png"}
              secure={true}
            >
              <Transformation
                height="100"
                width="300"
                crop="pad"
              />
            </Image>

            <div>
              <div className={styles.exhibitionStandNameContainer}>
                <p className={styles.standNameText}>
                  <strong>{exhibitionStand.standName}</strong>
                </p>
                {/**<p dangerouslySetInnerHTML={this.sanitizeCustomField(exhibitionStand.standSummary)} className={styles.exhibitionStandSummaryText}>
                </p>*/}
              </div>
              <div className={styles.visitStandButton}><Trans>exhibition_visit</Trans></div>
            </div>


          </div>
        </Link>
      );
    });

    return (
      <div>
        <div className={styles.outerBackgroundDiv}>
          {/**<img
            className="imageNavBackground"
            src={window.$videoPath + "background.png"}
            alt="Background"
          ></img>*/}
        </div>

        <div className={styles.hotlinkBar}>
          <Link to={"/exhibition"} className={styles.backLink}>
            <div className={styles.backDiv}>
              <img
                className={styles.backArrow}
                src={window.$videoPath + "icons/backArrow.png"}
                alt="Back"
              />
              <Trans>exhibition_back_to_exhibition</Trans>
            </div>
          </Link>
        </div>

        <div className={styles.headerBar}>
          <h1 className={styles.pageTitle}>
            <strong><Trans>shared_search</Trans></strong>
          </h1>
          <div className={styles.searchTitleArea}>
            <p><Trans>exhibition_search_exhibition</Trans></p>
          </div>
          <input
            className={styles.searchBox}
            type="text"
            name="searchString"
            placeholder="Search"
            value={this.state.searchString}
            onChange={this.handleChange}
          />
        </div>

        <section className={styles.profileSegmentsArea}>{userGallery}</section>
      </div>
    );
  }
}
