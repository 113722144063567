import React, { Component } from "react";
import dompurify from "dompurify";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import BreakoutMobile from "./BreakoutMobile";
import VideoPlayer from "./../modules/videoPlayer/VideoPlayer";
import WidgetContainer from "./../modules/widgetContainer/WidgetContainer";
import styles from "./Breakout.module.scss";

var selectableiFrameDiv;

export default class Breakout extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      standName: "",
      breakoutLiveURL: "",
      breakoutPrerecordURL: "",
      isSmallBreakout: true,
      videoStateForwards: true,
      showContent: false,
      breakoutLiveControlChannel: 1,
      slidoEventID: "",
      interprefyEventID: "",
      breakoutArtID: "",
    };
    this.breakoutVideo = React.createRef();
    this.reverseBreakoutVideo = React.createRef();
  }

  componentDidMount () {
    // Only required where there is different artwork per breakout
    this.determineArtworkID();

    fetch(
      "/api/getBreakout?breakoutID=" +
      // Get selected stand ID from React Router path
      this.props.match.params.breakoutID
    )
      // Need to somehow request by received prop ID
      .then((res) => res.json())
      .then((data) => this.loadResponseIntoState(data));

    // Move straight to presentation state
    this.viewPresentation();
  }

  componentDidUpdate () { }

  componentWillUnmount () {
    // Restore corner menu
    this.props.setCornerMenuStatus(1);
  }

  loadResponseIntoState (data) {
    this.setState({
      standName: data.breakout.standName,
      breakoutLiveURL: data.breakout.breakoutLiveURL,
      breakoutPrerecordURL: data.breakout.breakoutPrerecordURL,
      breakoutLiveControlChannel: data.breakout.breakoutLiveControlChannel,
      slidoEventID: data.breakout.slidoEventID,
      interprefyEventID: data.breakout.interprefyEventID,
    });
  }

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };


  // Used where there is different artwork per breakout room and possible a different screen position
  determineArtworkID () {
    const breakoutID = this.props.match.params.breakoutID;
    const breakoutIDLastDigit = breakoutID.substring(breakoutID.length - 1, breakoutID.length);
    this.setState({ breakoutArtID: breakoutIDLastDigit })
    switch (breakoutIDLastDigit) {
      case "1":
        selectableiFrameDiv = styles.iFramePosition1;
        //selectableSlidoDiv = styles.slidoPosition1;
        break;
      case "2":
        selectableiFrameDiv = styles.iFramePosition2;
        //selectableSlidoDiv = styles.slidoPosition1;
        break;
      case "3":
        selectableiFrameDiv = styles.iFramePosition3;
        //selectableSlidoDiv = styles.slidoPosition3;
        break;
      case "4":
        selectableiFrameDiv = styles.iFramePosition4;
        //selectableSlidoDiv = styles.slidoPosition4;
        break;
    }
  }


  viewPresentation = () => {
    if (this.breakoutVideo != null && this.breakoutVideo.play) {
      this.breakoutVideo.play();
    }
    // Set timeout in case video does not load/finish
    setTimeout(
      function () {
        this.forwardsVideoEnded();
      }.bind(this),
      6000
    );
  };

  leavePresentation = () => {
    this.setState({ showContent: false });
    if (this.reverseBreakoutVideo) {
      this.reverseBreakoutVideo.play();
    }
    // Set timeout in case video does not load/finish
    setTimeout(
      function () {
        this.reverseVideoEnded();
      }.bind(this),
      6000
    );
  };



  forwardsVideoEnded = () => {
    this.setState({ videoStateForwards: false, showContent: true });
    // Hide corner menu
    this.props.setCornerMenuStatus(3);
    //this.registerVimeoPlayListener();
  };

  reverseVideoEnded = () => {
    // Need to reset breakout video back to start before transitioning to it
    if (this.breakoutVideo) {
      this.breakoutVideo.currentTime = 0;
    }
    this.setState({ videoStateForwards: true, showContent: false });
  };

  render () {
    // Select appropriate remote control channel to listen to
    var breakoutLive = 0;
    switch (this.state.breakoutLiveControlChannel) {
      case 1:
        breakoutLive = this.props.breakout1Live;
        break;
      case 2:
        breakoutLive = this.props.breakout2Live;
        break;
      case 3:
        breakoutLive = this.props.breakout3Live;
        break;
      case 4:
        breakoutLive = this.props.breakout4Live;
        break;
      default:
        breakoutLive = this.props.breakout1Live;
        break;
    }

    const videoContent =
      breakoutLive === "2"
        ? this.state.breakoutLiveURL
        : breakoutLive === "1"
          ? this.state.breakoutPrerecordURL
          : breakoutLive;

    return (
      <div>
        {this.props.showMobile ?
          <BreakoutMobile
            videoContent={videoContent}
            slidoEventID={this.state.slidoEventID}
            interprefyEventID={this.state.interprefyEventID}
            userProfile={this.props.userProfile}
          />
          :
          <div className={styles.masterPageDiv}>
            {this.state.videoStateForwards ? (
              <div>
                <div
                  className={styles.goToPresentation}
                  onClick={this.viewPresentation}
                ></div>
                <Link to="/breakoutsStill">
                  <img
                    className={`${styles.hoverHotspot} ${styles.exitHoverHotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="Exit"
                  ></img>
                </Link>
              </div>
            ) : null}
            {!this.state.videoStateForwards ? (
              <Link to={"/breakoutsStill"}>
                <div
                  className={styles.leavePresentation}
                /**onClick={this.leavePresentation}*/
                ></div>
              </Link>
            ) : null}

            <div>
              <video
                className="imageNavBackground"
                id="myVideo"
                webkit-playsinline="true"
                playsInline
                loop={false}
                muted
                ref={(breakoutVideo) => (this.breakoutVideo = breakoutVideo)}
                onEnded={() => this.forwardsVideoEnded()}
              >
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/reakout" + this.state.breakoutArtID + ".mp4"}
                  type="video/mp4"
                />
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/breakout" + this.state.breakoutArtID + ".webm"}
                  type="video/webm"
                />
              </video>
              <CSSTransition
                in={!this.state.videoStateForwards}
                timeout={1500}
                classNames="imageNavBackground"
                unmountOnExit
              >
                <video
                  className="imageNavBackground"
                  id="myVideo"
                  webkit-playsinline="true"
                  playsInline
                  loop={false}
                  muted
                  ref={(reverseBreakoutVideo) =>
                    (this.reverseBreakoutVideo = reverseBreakoutVideo)
                  }
                  onEnded={() => this.reverseVideoEnded()}
                >
                  <source
                    src={window.$videoPath + "visuals/" + this.props.language + "/breakoutReverse" + this.state.breakoutArtID + ".mp4"}
                    type="video/mp4"
                  />
                  <source
                    src={window.$videoPath + "visuals/" + this.props.language + "/breakoutReverse" + this.state.breakoutArtID + ".webm"}
                    type="video/webm"
                  />
                </video>
              </CSSTransition>
            </div>

            <CSSTransition
              in={this.state.showContent}
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <img
                className="imageNavBackground"
                src={window.$videoPath + "visuals/" + this.props.language + "/breakout" + this.state.breakoutArtID + ".jpg"}
                alt="Background"
              ></img>
            </CSSTransition>

            <CSSTransition
              in={this.state.showContent && videoContent.length > 0}
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              {/** Can use className=selectedableiFrameDiv here where different positions needed */}
              <div className={selectableiFrameDiv}>
                <VideoPlayer
                  resourceLocator={videoContent}
                  location={"breakout"}
                  userProfile={this.props.userProfile}
                  autoPlay={true}
                  locationID={this.props.match.params.breakoutID}
                />
              </div>
            </CSSTransition>

            <CSSTransition
              in={this.state.showContent && this.state.slidoEventID.length > 0}
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              {/** Can use className=selectedableSlidoDiv here where different positions needed */}
              <div className={styles.widgetContainerDiv}>
                <WidgetContainer
                  slidoEventID={this.state.slidoEventID}
                  interprefyEventID={this.state.interprefyEventID}
                  userProfile={this.props.userProfile}
                  simTransAvailable={true}
                />
              </div>
            </CSSTransition>
          </div>
        }
      </div>
    );
  }
}
