import React, { Component } from "react";
import { Trans } from 'react-i18next';
import NotificationMobile from "./NotificationMobile";
import styles from "./Notification.module.scss";

export default class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVisualNotification: false,
      bubble1Visible: styles.bubbleNotVisible,
      bubble2Visible: styles.bubbleNotVisible,
      bubble3Visible: styles.bubbleNotVisible,
      bubble4Visible: styles.bubbleNotVisible,
      bubbleMessageVisible: styles.bubbleMessageNotVisible,
      playAudioNotification: false,
    };
  }

  componentDidMount () {
    setTimeout(
      function () {
        this.setState({ showVisualNotification: true });
      }.bind(this),
      1
    );
    setTimeout(
      function () {
        this.setState({ bubble1Visible: styles.bubble1Visible });
      }.bind(this),
      500
    );
    setTimeout(
      function () {
        this.setState({ bubble2Visible: styles.bubble2Visible });
      }.bind(this),
      1000
    );
    setTimeout(
      function () {
        this.setState({
          bubble3Visible: styles.bubble3Visible,
        });
        if (
          this.props.notificationText.includes(
            "Visit the networking lounge to connect with other delegates or participate in roundtables"
          )
        ) {
          this.setState({
            playAudioNotification: true,
          });
        } else {
          this.setState({
            playAudioNotification: false,
          });
        }
      }.bind(this),
      1500
    );
    setTimeout(
      function () {
        this.setState({ bubbleMessageVisible: styles.bubbleMessageVisible });
      }.bind(this),
      2000
    );
  }
  render () {
    return (
      <div>
        {this.props.showMobile ?
          <NotificationMobile
            setNotificationText={this.props.setNotificationText}
            notificationText={this.props.notificationText}
          />
          :
          <div className="imageNavDiv">
            <div className={styles.visualNotification}>
              <img className={styles.notificationClose} src="/icons/close.png" alt="Close" onClick={() => this.props.setNotificationText("")} />
              <img
                className={styles.fullSize}
                src={window.$videoPath + "notification/notificationTemplate.png"}
                alt="Notification step 1"
              />
              <img
                className={this.state.bubble1Visible}
                src={window.$videoPath + "notification/notification1.png"}
                alt="Notification step 1"
              />
              <img
                className={this.state.bubble2Visible}
                src={window.$videoPath + "notification/notification2.png"}
                alt="Notification step 2"
              />
              <img
                className={this.state.bubble3Visible}
                src={window.$videoPath + "notification/notification3.png"}
                alt="Notification step 3"
              />
              <div className={this.state.bubbleMessageVisible}>
                {this.props.notificationText && (this.props.notificationText.includes("notification_") || this.props.notificationText.includes("shared_livestream_error")) ? <Trans>{this.props.notificationText}</Trans> : this.props.notificationText}
              </div>

            </div>
          </div>
        }
      </div>
    );
  }
}