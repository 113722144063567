import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Trans, withTranslation } from 'react-i18next';
import PreLoginLanguageSelection from "./PreLoginLanguageSelection"
import styles from "./ConfirmUserEmail.module.scss";
import preLoginStyles from "./Pre-Login.module.scss";
const queryString = require("query-string");

export default class ConfirmUserEmail extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      confirmationSuccessful: "pending",
    };
  }

  componentDidMount () {
    const queryParameters = this.props.location.search;
    const queryParamatersParsed = queryString.parse(queryParameters);
    fetch("/api/confirmUserEmail", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userID: queryParamatersParsed.userID,
        confirmationCode: queryParamatersParsed.confirmationCode,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Returned status: " + data.status);
        this.setState({
          confirmationSuccessful: data.status,
        });
      });
  }

  render () {
    return (
      <div>
        {/** Hide this to check for cookie upon landing direct to this page */}
        <div style={{ display: "none" }}>
          <PreLoginLanguageSelection
            setLanguage={this.props.setLanguage}
            cookies={this.props.cookies}
          />
        </div>

        <div className={styles.masterPageDiv}>
          <img
            className={styles.fullScreenBackground}
            src={window.$videoPath + "loginBackground.jpg"}
            alt="Background"
          ></img>
          <div className={styles.confirmUserEmailBox}>
            <div className={styles.confirmUserEmailContent}>
              <img
                className={preLoginStyles.preLoginBoxLogo}
                src={window.$videoPath + "logo.png"}
                alt="Logo"
              />
              <br />
              <br />
              <br />
              <br />
              <h2 className={styles.confirmationStatusText}>
                <Trans>account_confirmation_status</Trans>
                <br />
                <br />
                {this.state.confirmationSuccessful === "success"
                  ? null
                  : <Trans>awaiting_confirmation</Trans>}
              </h2>
            </div>
            {this.state.confirmationSuccessful === "success" ? (
              <Redirect to="/" />
            ) : null}

          </div>
        </div>
      </div>
    );
  }
}
