import { Component } from "react";

export default class Logout extends Component {
  componentDidMount() {
    fetch("/api/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.props.setShowProfileModal(false);
          this.props.history.push("/");
          this.props.checkLoginStatus();
        } else if (res.status === 401) {
          console.log("Error logging out");
          this.props.history.push("/");
          this.props.checkLoginStatus();
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Error logging out please try again");
      });
  }

  render() {
    return null;
  }
}
