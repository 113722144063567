import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { dateFormatterTimeOnly } from "../../helpers/dateFormatter.js"
import styles from "./AgendaRow.module.scss";

class AgendaRow extends Component {

    getBreakoutURL = (breakoutRoomNumber) => {
        switch (breakoutRoomNumber) {
            case 1:
                return "/breakout/6086abd759b00d4eefcc69b1";
            case 2:
                return "/breakout/6086abd759b00d4eefcc69b2";
            case 3:
                return "/breakout/6086abd759b00d4eefcc69b3";
            case 4:
                return "/breakout/6086abd759b00d4eefcc69b4";
            case 11:
                return "/roundtableSession/6121613d9f34e80ad6f9ccc6";
            case 12:
                return "/roundtableSession/6121613d9f34e80ad6f9ccc4";
            case 13:
                return "/roundtableSession/6121613d9f34e80ad6f9ccd4";
            case 14:
                return "/roundtableSession/6121613d9f34e80ad6f9ccc8";
            case 15:
                return "/roundtableSession/6121613d9f34e80ad6f9cccd";
            case 16:
                return "/roundtableSession/6121613d9f34e80ad6f9cccf";
            case 17:
                return "/roundtableSession/6121613d9f34e80ad6f9ccc2";
            case 18:
                return "/roundtableSession/6121613d9f34e80ad6f9ccca";
            case 19:
                return "/roundtableSession/6121613d9f34e80ad6f9cccb";
            case 20:
                return "/roundtableSession/6121613d9f34e80ad6f9ccd0";
            case 21:
                return "/roundtableSession/6121613d9f34e80ad6f9ccd2";
            case 22:
                return "/roundtableSession/6121613d9f34e80ad6f9ccd6";
            case 23:
                return "/roundtableSession/6121613d9f34e80ad6f9ccd7";
            case 24:
                return "/roundtableSession/6121613d9f34e80ad6f9ccd8";
            case 25:
                return "/roundtableSession/6121613d9f34e80ad6f9ccd9";
            case 31:
                return "/roundtableSession/6121613d9f34e80ad6f9ccc7";
            case 32:
                return "/roundtableSession/6121613d9f34e80ad6f9ccc5";
            case 33:
                return "/roundtableSession/6121613d9f34e80ad6f9ccd5";
            case 34:
                return "/roundtableSession/6121613d9f34e80ad6f9ccc9";
            case 35:
                return "/roundtableSession/6121613d9f34e80ad6f9ccce";
            case 36:
                return "/roundtableSession/6121613d9f34e80ad6f9ccc3";
            case 37:
                return "/roundtableSession/6121613d9f34e80ad6f9cccc";
            case 38:
                return "/roundtableSession/6121613d9f34e80ad6f9ccd1";
            case 39:
                return "/roundtableSession/6121613d9f34e80ad6f9ccd3";
            case 40:
                return "/roundtableSession/6121613d9f34e80ad6f9ccda";
            case 41:
                return "/roundtableSession/6121613d9f34e80ad6f9ccdb";
            case 42:
                return "/roundtableSession/6121613d9f34e80ad6f9ccdc";
            default:
                return "";
        }
    }

    getBreakoutLocationName = (breakoutRoomNumber) => {
        switch (breakoutRoomNumber) {
            case 1:
                return this.props.t('nav_workstream_one');
            case 2:
                return this.props.t('nav_workstream_two');
            case 3:
                return this.props.t('nav_workstream_three');
            case 4:
                return this.props.t('nav_workstream_four');
            case 5:
                return this.props.t('shared_roundtable');

            default:
                return "";
        }
    }

    getSelectedSessionDetails = (sessionSelection) => {
        switch (sessionSelection) {
            case 1:
                return {
                    sessionTitle: this.props.t('agenda_workstream_one_title'),
                    presenters: [],
                    locationName: this.getBreakoutLocationName(1),
                    location: this.getBreakoutURL(1)
                };
            case 2:
                return {
                    sessionTitle: this.props.t('agenda_workstream_two_title'),
                    presenters: [],
                    locationName: this.getBreakoutLocationName(2),
                    location: this.getBreakoutURL(2)
                };
            case 3:
                return {
                    sessionTitle: this.props.t('agenda_workstream_three_title'),
                    presenters: [],
                    locationName: this.getBreakoutLocationName(3),
                    location: this.getBreakoutURL(3)
                };
            case 4:
                return {
                    sessionTitle: this.props.t('agenda_workstream_four_title'),
                    presenters: [],
                    locationName: this.getBreakoutLocationName(4),
                    location: this.getBreakoutURL(4)
                };
            case 11:
                return {
                    sessionTitle: "Digital and data",
                    presenters: ["Hitachi"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(11)
                };
            case 12:
                return {
                    sessionTitle: "Energy and renewables",
                    presenters: ["Hitachi "],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(12)
                };
            case 13:
                return {
                    sessionTitle: "Energy transition",
                    presenters: ["EY"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(13)
                };
            case 14:
                return {
                    sessionTitle: "Energy-transport nexus",
                    presenters: ["Mott MacDonald"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(14)
                };
            case 15:
                return {
                    sessionTitle: "Meet the Engineers'",
                    presenters: ["IET"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(15)
                };
            case 16:
                return {
                    sessionTitle: "Renewable energy development",
                    presenters: ["NG renewables"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(16)
                };
            case 17:
                return {
                    sessionTitle: "Role of hydrogen in the journey to net zero",
                    presenters: ["Oonagh OGrady, SSE Thermal’s Head of Hydrogen Development"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(17)
                };
            case 18:
                return {
                    sessionTitle: "Sustainable energy transition",
                    presenters: ["EPRI"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(18)
                };
            case 19:
                return {
                    sessionTitle: "The importance of power grids on the path to decarbonation",
                    presenters: ["GO15"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(19)
                };
            case 20:
                return {
                    sessionTitle: "The nine priorities to a net zero built environment",
                    presenters: ["BEIS"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(20)
                };
            case 21:
                return {
                    sessionTitle: "The role of the infrastructure designer in addressing climate challenges",
                    presenters: ["Burns & Mcdonnell"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(21)
                };
            case 22:
                return {
                    sessionTitle: "Get Connected: Open topic",
                    presenters: [],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(22)
                };
            case 23:
                return {
                    sessionTitle: "Get Connected: Open topic",
                    presenters: [],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(23)
                };
            case 24:
                return {
                    sessionTitle: "Get Connected: Open topic",
                    presenters: [],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(24)
                };
            case 25:
                return {
                    sessionTitle: "Get Connected: Open topic",
                    presenters: [],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(25)
                };



            case 31:
                return {
                    sessionTitle: "Digital and data",
                    presenters: ["Hitachi"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(31)
                };
            case 32:
                return {
                    sessionTitle: "Energy and renewables",
                    presenters: ["Hitachi "],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(32)
                };
            case 33:
                return {
                    sessionTitle: "Energy transition",
                    presenters: ["EY"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(33)
                };
            case 34:
                return {
                    sessionTitle: "Energy-transport nexus",
                    presenters: ["Mott MacDonald"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(34)
                };
            case 35:
                return {
                    sessionTitle: "Meet the Engineers'",
                    presenters: ["IET"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(35)
                };
            case 36:
                return {
                    sessionTitle: "Role of hydrogen in the journey to net zero",
                    presenters: ["Oonagh OGrady, SSE Thermal’s Head of Hydrogen Development"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(36)
                };
            case 37:
                return {
                    sessionTitle: "The importance of power grids on the path to decarbonation",
                    presenters: ["GO15"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(37)
                };
            case 38:
                return {
                    sessionTitle: "The nine priorities to a net zero built environment",
                    presenters: ["BEIS"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(38)
                };
            case 39:
                return {
                    sessionTitle: "The role of the infrastructure designer in addressing climate challenges",
                    presenters: ["Burns & Mcdonnell"],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(39)
                };
            case 40:
                return {
                    sessionTitle: "Get Connected: Open topic",
                    presenters: [],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(40)
                };
            case 41:
                return {
                    sessionTitle: "Get Connected: Open topic",
                    presenters: [],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(41)
                };
            case 42:
                return {
                    sessionTitle: "Get Connected: Open topic",
                    presenters: [],
                    locationName: this.getBreakoutLocationName(5),
                    location: this.getBreakoutURL(42)
                };

            default:
                return {
                    sessionTitle: "Optional Session",
                    presenters: ["Presenter Name"],
                    locationName: this.getBreakoutLocationName(0),
                    location: this.getBreakoutURL(0)
                };
        }

    };

    render () {

        return (
            <React.Fragment>
                <tr className={styles.visibleOnlyOnMobile}>
                    <td colSpan="3" className={styles.mobileAgendaRow}>

                        {/** Case for fixed session */}
                        {this.props.agendaItemDetails.agendaItemTitle ?
                            <Link
                                to={this.props.agendaItemDetails.agendaItemLocation}
                                onClick={() => this.props.setShowAgendaModal(false)}
                            >
                                <div className={styles.agendaRowBlock}>
                                    <p className={styles.sessionTimeText}>{dateFormatterTimeOnly(this.props.agendaItemDetails.agendaItemTimestamp, this.props.userProfile)}</p>
                                    <p className={styles.sessionTitleText}>{this.props.agendaItemDetails.agendaItemTitle}</p>
                                    {
                                        this.props.agendaItemDetails.agendaItemPresenters.map((host) => {
                                            return (
                                                <p className={styles.sessionDetailText} key={host}>
                                                    - {host}
                                                </p>
                                            )
                                        }
                                        )
                                    }
                                    <p className={styles.sessionLocationText}>
                                        {this.props.agendaItemDetails.agendaItemLocationText}
                                    </p>
                                </div>
                            </Link> : null}

                        {/** Case for selectable session */}
                        {this.props.agendaItemDetails.agendaItemSessionSelector != null ?
                            <>
                                {this.props.agendaItemDetails.agendaItemSessionSelector === 0 ?
                                    /** Case for no session selected */
                                    <div
                                        className={styles.agendaRowBlock}
                                        onClick={() => this.props.switchFromAgendaToSessionSelection()}>
                                        <p className={styles.sessionTimeText}>{dateFormatterTimeOnly(this.props.agendaItemDetails.agendaItemTimestamp, this.props.userProfile)}</p>
                                        <p className={styles.sessionTitleText}>
                                            {/** Display message based upon type of session */}
                                            {this.props.agendaItemDetails.agendaItemType === "breakout" ? <Trans>agenda_select_workstream_session</Trans> : <Trans>agenda_select_networking_table</Trans>}
                                        </p>
                                    </div> :
                                    /** Case for session selected */
                                    <Link
                                        to={this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).location}
                                        onClick={() => this.props.setShowAgendaModal(false)}
                                    >
                                        <div className={styles.agendaRowBlock}>
                                            <p className={styles.sessionTimeText}>{dateFormatterTimeOnly(this.props.agendaItemDetails.agendaItemTimestamp, this.props.userProfile)}</p>
                                            <p className={styles.sessionTitleText}>
                                                {this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).sessionTitle}
                                            </p>
                                            {/** List presenters */}
                                            {
                                                this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).presenters.map((host) => {
                                                    return (
                                                        <p className={styles.sessionDetailText} key={host}>
                                                            {host}
                                                        </p>
                                                    )
                                                }
                                                )
                                            }
                                            {/** Location text */}
                                            <p className={styles.sessionLocationText}>{this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).locationName}</p>
                                        </div>
                                    </Link>
                                }</>
                            : null}

                        {/** Case for 1:1 meeting */}
                        {this.props.agendaItemDetails.meetingSlotID ?
                            <div className={styles.agendaRowBlock} style={{ cursor: "default" }}>
                                <p className={styles.sessionTimeText}>{dateFormatterTimeOnly(this.props.agendaItemDetails.meetingSlotID.meetingSlotTimestamp, this.props.userProfile)}</p>
                                <p className={styles.sessionTitleText}>
                                    <Trans>meetings_meeting_with</Trans> {this.props.agendaItemDetails.meetingRequesteeUser.firstName}{" "}
                                    {this.props.agendaItemDetails.meetingRequesteeUser.surname}
                                </p>
                                <p className={styles.sessionLocationText}><Trans>mobile_access_from_desktop</Trans></p>
                            </div>
                            : null}
                    </td>
                </tr>


                <tr className={styles.visibleOnlyOnDesktop}>
                    {/** Case for fixed session */}
                    {this.props.agendaItemDetails.agendaItemTitle ?
                        <React.Fragment>
                            <td className={styles.timeTableCell}>
                                <p className={styles.sessionTimeText}>{dateFormatterTimeOnly(this.props.agendaItemDetails.agendaItemTimestamp, this.props.userProfile)}</p>
                            </td>
                            <td className={styles.agendaItemTableCell}>

                                <Link
                                    className={styles.uncolouredLink}
                                    to={this.props.agendaItemDetails.agendaItemLocation}
                                    onClick={() => this.props.setShowAgendaModal(false)}
                                >
                                    <p className={styles.sessionTitleText}>{this.props.agendaItemDetails.agendaItemTitle}</p>
                                </Link>
                                {
                                    this.props.agendaItemDetails.agendaItemPresenters.map((host) => {
                                        return (
                                            <p className={styles.sessionDetailText} key={host}>
                                                - {host}
                                            </p>
                                        )
                                    }
                                    )
                                }
                            </td>
                            <td className={styles.locationTableCell}>
                                <p className={styles.sessionLocationText}>
                                    {this.props.agendaItemDetails.agendaItemLocationText && this.props.agendaItemDetails.agendaItemLocationText.length > 0 ?
                                        <Link
                                            className={styles.uncolouredLink}
                                            to={this.props.agendaItemDetails.agendaItemLocation}
                                            onClick={() => this.props.setShowAgendaModal(false)}
                                        >
                                            <Button variant="success" style={{ width: "140px", verticalAlign: "top" }}>
                                                {this.props.agendaItemDetails.agendaItemLocationText}
                                            </Button>
                                        </Link>
                                        : null}
                                </p>
                            </td>
                        </React.Fragment> : null}



                    {/** Case for selectable session */}
                    {this.props.agendaItemDetails.agendaItemSessionSelector != null ?
                        <>
                            <td className={styles.timeTableCell}>
                                <p className={styles.sessionTimeText}>{dateFormatterTimeOnly(this.props.agendaItemDetails.agendaItemTimestamp, this.props.userProfile)}</p>
                            </td>
                            <td className={styles.agendaItemTableCell}>
                                {/** Set link or open modal based upon response */}
                                {/** Check whether session has been selected as if not then open session booking */}

                                {this.props.agendaItemDetails.agendaItemSessionSelector === 0 ?
                                    <span style={{ cursor: "pointer" }} onClick={() => this.props.switchFromAgendaToSessionSelection()}>
                                        <p className={styles.sessionTitleText}>
                                            {/** Display message based upon type of session */}
                                            {this.props.agendaItemDetails.agendaItemType === "breakout" ? <Trans>agenda_select_workstream_session</Trans> : <Trans>agenda_select_networking_table</Trans>}
                                        </p>
                                    </span> :
                                    <span>
                                        <Link
                                            className={styles.uncolouredLink}
                                            to={this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).location}
                                            onClick={() => this.props.setShowAgendaModal(false)}
                                        >
                                            <p className={styles.sessionTitleText}>
                                                {this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).sessionTitle}
                                            </p>
                                        </Link>
                                        {
                                            this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).presenters.map((host) => {
                                                return (
                                                    <p className={styles.sessionDetailText} key={host}>
                                                        - {host}
                                                    </p>
                                                )
                                            }
                                            )
                                        }
                                    </span>
                                }
                            </td>
                            <td className={styles.locationTableCell}><p className={styles.sessionLocationText}>
                                <Link
                                    className={styles.uncolouredLink}
                                    to={this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).location}
                                    onClick={() => this.props.setShowAgendaModal(false)}
                                >
                                    {this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).locationName && this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).locationName.length > 0 ?
                                        <Button variant="success" style={{ width: "140px", verticalAlign: "top" }}>
                                            {this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).locationName}
                                        </Button>
                                        : null}
                                </Link>
                            </p>
                            </td>
                        </> : null /** Else for session selector set so must be selectable session */}




                    {/** Case for 1:1 session */}
                    {this.props.agendaItemDetails.meetingSlotID ?
                        <React.Fragment>
                            <td className={styles.timeTableCell}>
                                <p className={styles.sessionTimeText}>{dateFormatterTimeOnly(this.props.agendaItemDetails.meetingSlotID.meetingSlotTimestamp, this.props.userProfile)}</p>
                            </td>
                            <td className={styles.agendaItemTableCell}>
                                <p className={styles.sessionTitleText}>
                                    <Link
                                        className={styles.uncolouredLink}
                                        to={"/video/" + this.props.agendaItemDetails._id}
                                        onClick={() => this.props.setShowAgendaModal(false)}
                                    >
                                        <Trans>meetings_meeting_with</Trans> {this.props.agendaItemDetails.meetingRequesteeUser.firstName}{" "}
                                        {this.props.agendaItemDetails.meetingRequesteeUser.surname}
                                    </Link>
                                </p>
                            </td>
                            <td className={styles.locationTableCell}>
                                <p className={styles.sessionLocationText}>
                                    <Link
                                        className={styles.uncolouredLink}
                                        to={"/video/" + this.props.agendaItemDetails._id}
                                        onClick={() => this.props.setShowAgendaModal(false)}
                                    >
                                        <Button variant="success" style={{ width: "140px", verticalAlign: "top" }}><Trans>agenda_join</Trans></Button>
                                    </Link>
                                </p>
                            </td>
                        </React.Fragment> : null}












                </tr>

            </React.Fragment>
        );
    }
}

export default withTranslation()(AgendaRow);