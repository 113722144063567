import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Trans, withTranslation } from 'react-i18next';
import { CSSTransition } from "react-transition-group";
import dompurify from "dompurify";
import RoundtablesMobile from "./RoundtablesMobile";
import styles from "./VisualRoundtables.module.scss";

export default class Roundtables extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      introVideoComplete: false,
      roundtables: [],
    };
  }

  componentDidMount () {
    this.getRoundtables();

    if (this.props.bypassVideo == true) {
      this.setState({ introVideoComplete: true });
    }

    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      12000
    );

    // Scroll to top
    window.scrollTo(0, 0);

  }

  getRoundtables () {
    fetch("/api/getRoundtablesList")
      .then((res) => res.json())
      .then((data) => this.setState({ roundtables: data }));
  }

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  roundtablesGrid = (selectedType) => {
    const roundtablesFromCategory = this.state.roundtables.filter(
      (roundtable) => roundtable.roundtableType == selectedType
    );

    return roundtablesFromCategory.map((roundtable, index) => {
      return (
        <Link to={"/roundtableSession/" + roundtable._id}>
          <div className={styles.roundtableItem}>
            <img
              className={styles.roundtablesTableBackground}
              src={window.$videoPath + "visualAssets/" + this.props.language + "/roundtable.png"}
              alt="Background"
            ></img>

            <div className={styles.tableText}>
              <h1 className={styles.tableName}>{roundtable.tableName}</h1>
            </div>

            <div className={styles.lowerRoundtableArea}>

            </div>
          </div>
        </Link>
      );
    });
  };


  render () {
    return (
      <div>
        {this.props.showMobile ?
          <RoundtablesMobile roundtables={this.state.roundtables} />
          :
          <>
            <div className={styles.masterPageDiv}>
              <video
                className="imageNavBackground"
                autoPlay
                muted
                id="introVideo"
                onEnded={() => this.setState({ introVideoComplete: true })}
              >
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/roundtables.webm"}
                  type="video/webm"
                />
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/roundtables.mp4"}
                  type="video/mp4"
                />
              </video>

              <CSSTransition
                in={this.state.introVideoComplete}
                timeout={1500}
                classNames="imageNavBackground"
                unmountOnExit
              >
                <div>
                  <img
                    className="imageNavBackground"
                    src={window.$videoPath + "visuals/" + this.props.language + "/roundtables.jpg"}
                    alt="Background"
                  ></img>


                  <section className={styles.profileSegmentsArea}>

                    <h1 className={styles.roundtablesSectionInfo}>
                      <Trans>networking_tables_info_text</Trans>
                    </h1>

                    {/**<h1 className={styles.roundtablesSectionTitle}>
                      <Trans>netowrking_hosted_tables</Trans>
                    </h1>*/}
                    <section className={styles.profileSegmentsGroup}>
                      {this.roundtablesGrid(2)}
                    </section>
                    {/**<h1 className={styles.roundtablesSectionTitle}>
                      <Trans>networking_social_tables</Trans>
                    </h1>*/}
                    <section className={styles.profileSegmentsGroup}>
                      {this.roundtablesGrid(1)}
                    </section>
                  </section>
                </div>
              </CSSTransition>

              <Link to="/networkingFromTables">
                <img
                  className={styles.exitHoverHotspot}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Exit"
                ></img>
              </Link>

            </div>
          </>
        }
      </div>
    );
  }
}
