export const TRANSLATIONS_EN = {
    agenda_join: "Join",
    attendees_back_to_eco_park: "Back to Eco-Park",
    attendees_country: "Country",
    attendees_info_text_old: "Search for and chat with people in similar job roles, location or with shared interests",
    breakouts_ask_your_questions: "Ask your questions",
    breakouts_breakout_explained: "Workstreams explained",
    exhibition_back_to_hall: "Back to hall",
    exhibition_click_to_view_and_download: "Click to view and download",
    exhibition_contact: "Contact",
    exhibition_enter_your_message: "Enter your message",
    exhibition_find_out_more: "Find out more",
    exhibition_go: "Go",
    exhibition_hall_one: "Hall 1",
    exhibition_learn_more: "Learn more",
    exhibition_leave_your_details: "Leave your details",
    exhibition_live_text_chat: "Live text chat",
    exhibition_meet_and_greet: "Meet and greet",
    exhibition_message: "Message",
    exhibition_qa: "Q & A",
    exhibition_send: "Send",
    exhibition_text_chat: "Text chat",
    help_faq: "FAQ",
    help_request_help: "Request help",
    help_request_help_info_text: "If you require any help during the event, please submit your request below. A member of our support team will be with you as soon as possible! Please note, by closing this chat you will end the conversation and will not be able to see any previous messages.<br /><br />Help desk opening times:<br />Tuesday 7 September 13:30 - 20:00 BST<br />Wednesday 8 September 04:30 - 11:00 BST",
    help_welcome_video: "Welcome video",
    jobs_apac: "APAC (Asia-Pacific)",
    jobs_associate: "Associate",
    jobs_at_least_eight: "At least £80,000",
    jobs_at_least_five: "At least £50,000",
    jobs_at_least_four: "At least £40,000",
    jobs_at_least_seven: "At least £70,000",
    jobs_at_least_six: "At least £60,000",
    jobs_at_least_three: "At least £30,000",
    jobs_at_least_two: "At least £20,000",
    jobs_contract: "Contract",
    jobs_contract_length: "Contract length",
    jobs_contract_type: "Contract type",
    jobs_director: "Director",
    jobs_entry_level: "Entry level",
    jobs_europe: "Europe",
    jobs_executive: "Executive",
    jobs_full_time: "Full-time",
    jobs_industry_sector: "Industry sector",
    jobs_job_detail: "Job details",
    jobs_location_region: "Location region",
    jobs_location_specific: "Location specific",
    jobs_mid_senior: "Mid-senior level",
    jobs_over_eight: "Over £81,000",
    jobs_part_time: "Part-time",
    jobs_permanent: "Permanent",
    jobs_salary_actual: "Salary actual:",
    jobs_salary_bracket: "Salary bracket:",
    jobs_seniority_level: "Seniority level:",
    jobs_south_america: "South America",
    jobs_temporary: "Temporary",
    jobs_uk: "United Kingdom",
    jobs_url: "Website",
    jobs_us_canada: "USA & Canada",
    login_email_address: "Email address",
    login_forgot_password: "Forgot password?",
    login_login: "Login",
    login_register: "New user? Register here",
    login_sign_in_if_registered: "Already registered? Sign in:",
    login_welcome: "Welcome to GYECC21",
    meetings_accept: "Accept",
    meetings_awaiting_responses: "Awaiting responses",
    meetings_ignore: "Decline",
    meetings_info_text: "For more information on how to set up a meeting, head to Help page and view the FAQs.",
    meetings_meetings_confirmed: "Meetings confirmed",
    meetings_other_time: "Other time?",
    meetings_requests_recieved: "Requests received",
    my_day_close_without_saving: "Close without saving",
    my_day_event_date: "Event date",
    my_day_no_session_selected: "No session selected",
    my_day_plan_my_day: "Plan my day",
    my_day_save_exit: "Save & exit",
    my_day_time_slot_one: "Time slot 1",
    nav_agenda: "Agenda",
    nav_attendees: "Attendees",
    nav_conference_agenda: "Conference agenda",
    nav_green_jobs: "Green jobs",
    nav_gyecc_tv: "GYECC TV",
    nav_lobby: "Eco-Park",
    nav_log_out: "Log out",
    nav_my_beehive: "My event",
    nav_my_breakout_selection: "My workstream selection",
    nav_my_meetings: "My meetings",
    nav_navigate: "Navigate",
    nav_on_demand: "Event rewind",
    nav_presenters: "Presenters",
    nav_speakers: "Speakers",
    nav_workstream_four: "Workstream 4",
    nav_workstream_one: "Workstream 1",
    nav_workstream_three: "Workstream 3",
    nav_workstream_two: "Workstream 2",
    network_connect: "Connect",
    network_disconnect: "Disconnect",
    network_meeting: "1:1 Meeting",
    networking_pledge_wall: "Pledge wall",
    networking_social_wall: "Social wall",
    on_demand_day_one: "Day 1",
    on_demand_day_two: "Day 2",
    plenary_ask_questions: "Ask a question",
    plenary_interprefy: "Languages",
    posters_enlarge_poster: "Click to enlarge or open PDF here",
    posters_overview: "Overview",
    posters_view: "View",
    presenters_info_text: "Search for speakers and sessions",
    presenters_organisation: "Organisation",
    presenters_talk_abstract: "Talk abstract",
    presenters_talk_title: "Talk title",
    profile_many_thanks: "Many thanks for completing your profile!",
    profile_picture_upload: "Picture upload (JPEG, PNG, GIF)",
    profile_profile_picture: "Profile picture and video upload",
    profile_your_information: "Your information",
    register_age: "Age",
    register_confirm_password: "Confirm password",
    register_email: "Email",
    register_first_name: "First name",
    register_preferred_language: "Preferred language",
    register_profile_picture: "Profile picture upload",
    register_surname: "Surname",
    register_timezone: "Timezone",
    reset_password_info_text: "If a valid user account exists for the email address entered, you will shortly receive an email with a link to reset your password.",
    reset_password_reset_password: "Reset password",
    select_breakout_info_text_one: "Choose which workstreams you would like to attend",
    select_roundtable_no: "No networking tables selected",
    select_roundtables_title: "Select networking tables",
    shared_about: "About",
    shared_ask_a_question: "Ask a question",
    shared_attendee_type: "Attendee type",
    shared_back: "Back",
    shared_breakouts: "Workstreams",
    shared_close: "Close",
    shared_delegate: "Delegate",
    shared_english: "English",
    shared_event_rewind: "Event rewind",
    shared_exhibition: "Exhibition",
    shared_exhibitor_partner: "Exhibitor / Partner",
    shared_exit: "Exit",
    shared_french: "French",
    shared_help: "Help",
    shared_internship: "Internship",
    shared_job_title: "Job title",
    shared_mandarin: "Mandarin",
    shared_my_profile: "My profile",
    shared_networking_lounge: "Networking lounge",
    shared_networking_lounge_short: "Networking lounge",
    shared_next: "Next",
    shared_organiser: "Organiser",
    shared_other: "Other",
    shared_password: "Password",
    shared_plenary: "Plenary",
    shared_poster_gallery: "Gallery",
    shared_previous: "Previous",
    shared_questions_answered: "Questions answered",
    shared_questions_to_be_answered: "Questions to be answered",
    shared_roundtable: "Networking table",
    shared_search: "Search",
    shared_spanish: "Spanish",
    shared_speaker: "Speaker",
    shared_submit: "Submit",
    shared_submit_a_question: "Submit a question",
    shared_upload: "Upload",
    leaderboard_title: "Leaderboard",
    leaderboard_info_text: "Fully immerse yourself in the Global Youth Engineering Climate Conference to see if you can top the leaderboard! <br /><br />Earn points by exploring our exhibition and resources, discovering speakers, checking our partner display boards and interacting with other delegates and exhibitors. The more you get involved, the more points you earn, the bigger chances of winning some prizes.<br /><br />Winners will be announced before the final plenary Q&A panel of the conference which takes place at 09:30 BST on Wednesday 8 September.",
    speakers_info_text: "Search for GYECC21 speakers and panellists by name or session title. ",
    attendees_info_text: "Get connected! Search for delegates and exhibitors by name, job title or organisation.<br />Connect by clicking on their profile to view availability and request a meeting.",
    event_rewind_info_text: "Recordings from the event will be available to watch on-demand until Friday 12th November.",
    exhibition_qa_title: "Exhibition – Q&A",
    exhibition_qa_info_text: "Q&A boards will be monitored during the following times:<br />Tuesday 7 September, 15:30 – 18:30 BST<br />Wednesday 8 September, 06:30 – 09:30 BST",
    exhibition_text_chat_title: "Exhibition - Text chat",
    exhibition_text_chat_info_text: "Text chat will be monitored during the following times:<br />Tuesday 7 September, 15:30 – 18:30 BST<br />Wednesday 8 September, 06:30 – 09:30 BST",
    exhibition_leave_details_title: "Exhibition - Leave details",
    exhibition_leave_details_info_text: "The details you submit here will be shared with the exhibitor. If you would like a follow-up via email or telephone, please provide your preferred contact details and a brief summary of what you would like to discuss.",
    exhibition_group_video_title: "Exhibition – Group video chat",
    exhibition_group_video_info_text: "Representatives will be available during the following times: <br />Tuesday 7 September, 15:30 – 18:30 BST<br />Wednesday 8 September, 06:30 – 09:30 BST",
    networking_tables_title: "Networking Tables",
    networking_tables_info_text: "Networking Tables will run at the following times:<br />Tuesday 7 September, 17:30 – 18:30 BST<br />Wednesday 8 September, 08:30 – 09:30 BST<br /><br />Please note, all spots are allocated on a first come, first served basis. <br />Please select the networking table you would like to attend - should your chosen table be full, you can join a different one before checking back for any new spots that become available.",
    plan_my_day_info_text: "Use this area to make the most of your day by selecting which workstreams and networking tables you would like to attend – selections will be added to your personalised agenda.<br /><br />The four workstreams will commence at the same time and run twice on each day. You can choose to attend a maximum of two workstreams in succession, followed by one networking table.<br /><br />Please proceed through the pages to select which sessions you would like to add to your agenda for each time slot - clicking 'Next' below to proceed to the next slot and then 'Save & exit' to confirm your choices.<br /><br />If you don’t want to attend a session during one of the time slots, please select 'Explore & network' where you can freely browse the exhibition or set up 1:1 meetings with other attendees.<br />Please note, this area is for your planning only and it does not guarantee you a place on the networking tables which are allocated on a first come, first served basis so make sure you’re ready to go bright and early!",
    notifications_plenary_closed_title: "Plenary closed ",
    notification_plenary_closed: "The plenary is currently closed please check the agenda to see when the next session will be starting. ",
    notification_verification_email_title: "Look out for verification email",
    notification_verification_email: "Thank you for setting up your Global Youth Engineering Climate Conference 2021 Account. We have sent you a verification email - please click on the link in the email to verify your account. ",
    notification_error_loggin_in_title: "Error logging in ",
    notification_error_loggin_in: "There was an error with your log in. Please check your email and/or password are correct. If you have not set up an account yet please click below to set one up. ",
    notification_exhibition_closed_title: "Exhibition closed ",
    notification_exhibition_closed: "The exhibition will be open on Tuesday 7 & Wednesday 8 September. Please come back then to engage with content and connect with our exhibitiors.",
    notifications_titles: "Platform notifications",
    agenda_opening_remarks: "Plenary - Opening remarks and keynote speakers",
    agenda_opening_remarks_info: "Speakers:<br />Denise Lee, Environmental Policy Researcher and Student, University of Toronto<br />Alok Sharma, President of the 26th United Nations Climate Change Conference<br />John Pettigrew, Chief Executive, National Grid<br />Joseph Tsongo, Co-ordinator of the Amani-Institute ASBL<br />Chris Stark, Chief Executive, Climate Change Committee",
    agenda_select_workstream_session: "Select a workstream session",
    agenda_select_networking_table: "Select a networking table",
    agenda_plenary_qa_panel: "Plenary - Q&A panel",
    agenda_plenary_qa_panel_info: "Speakers: <br />Eva Saha, Conference MC <br /> Dr. Nick Wayth, Energy Institute <br />Carlotta Giacché, Nord/LB and My Meaningful Money<br />Dr. Alaa Al Khourdajie, IPCC Working Group III<br />Sam Johnson Transport for London<br />Caritta Seppa, Tespack<br />Natalie Edwards, National Grid",
    agenda_plenary_qa_panel_info_day2: "Speakers: <br />Eva Saha, Conference MC <br />Dr. Nick Wayth, Energy Institute <br />Polly Osborne, Burns & McDonnell<br />Amy Weltvreden, National Grid Electricity System Operator<br />Dr. Alaa Al Khourdajie, IPCC Working Group III<br />Dr. Alex Wohnas, Australian Energy Market Operator (AEMO)",
    agenda_make_your_pledge: "Make your pledge",
    agenda_option_one: "Option 1",
    agenda_option_two: "Option 2",
    agenda_option_three: "Option 3",
    agenda_option_four: "Option 4",
    agenda_option_five: "Option 5",
    agenda_workstream_one_title: "Workstream 1 - Green skills: How to be an agent of change",
    agenda_workstream_one_info: "Want to contribute to the green future, but don't know what skills you need?<br /> Perhaps your company is transitioning away from fossil fuels and you need new skills?<br /><br />Maybe you're new to the energy industry and want to contribute to a greener future?<br /><br />The ‘Green skills: How to be an agent of change’ workstream will answer these questions and more.<br /><br /> An interactive and diverse panel of energy companies, entrepreneurs, financiers, and developing nations will explore new skills re-discover ones you already have and look at how to market your skills to others. You can be an agent of change by promoting green skills in your professional and personal life.",
    agenda_workstream_two_title: "Workstream 2 - Access to energy for zero carbon communities",
    agenda_workstream_two_info: "We understand the need for a cleaner energy system… Don’t we?<br /><br />We are committed to making changes in our day-to-day lives to achieve long term sustainability… Aren’t we?<br /><br />We need to decarbonise our communities. You may already feel that happening where you live, or you may feel a lack of progress.<br /><br />Our ‘Low Carbon Community Council’ will explore how to decarbonise our communities, and how to make sustainable change, including views from experts in local community programmes and green energy projects. We will also explore how you can drive change, irrespective of where your community is on their decarbonisation journey.",
    agenda_workstream_three_title: "Workstream 3 - Engaging generations - green change starts with the individual",
    agenda_workstream_three_info: "How do products you buy, habits you form, and careers you pursue add up to make a global impact?<br /><br />Join us to learn about your personal impact and how individual changes can drive decarbonisation.<br /><br />This workstream will use an exciting game of trivia to expand your knowledge on the continuously changing energy landscape.<br /><br />Throughout the game, industry experts will offer insights about the lessons they've learned along the way and provide action items for increasing your own individual impacts. By participating in this game of trivia, you will be equipped to make more impactful decisions to contribute towards a greener world.",
    agenda_workstream_four_title: "Workstream 4 - A global zero carbon energy system for all",
    agenda_workstream_four_info: "The goals are clear. We must achieve net zero globally by mid-century and adapt to protect communities and natural habitats. The question is, how can we work together to achieve these goals? How can we come together to create an inclusive and diverse environment working towards a common goal while still being cognizant of our own individual and local challenges?<br /><br />The Global Zero Carbon Energy System session will tour you around the globe as we explore how different countries and cultures are approaching the low carbon energy system. Let this conversation act as a springboard to in-depth questions and idea sharing as we work in unison to achieve the most paramount goal of our generation, zero carbon energy systems.<br /><br />During this global tour we will learn the innovative ways that people in different areas of the world are working towards the same common goals. Through this global macro view, we will gain insight into creating an affordable clean energy future together.",
    agenda_explore_network: "Explore & network",
    agenda_visit_attendee_gallery: "Visit the attendee gallery to set-up 1:1 meetings with fellow delegates or take this time to explore the virtual environment. Visit the exhibition, gallery, green jobs or GYECC TV.",
    account_confirmation_status: "Account confirmation status",
    awaiting_confirmation: "Awaiting confirmation",
    select_workstream: "Select workstream",
    eco_park_streams: "Streams",
    eco_park_networking: "Networking",
    register_error_first_name: "Please enter your first name",
    register_error_surname: "Please enter your surname",
    register_error_age_range: "Please select your age range",
    register_error_enter_email: "Please enter your email address ",
    register_error_enter_valid_email: "Please enter a valid email address",
    register_error_enter_password: "Please enter a password",
    register_error_password_six_letters: "Please use a password of at least 6 characters",
    register_error_password_match: "Please ensure your passwords match",
    register_error_account_already_exists: "An account already exists for this email address. Please reset your password. ",
    register_error_attendee_type: "Please select attendee type",
    register_error_language: "Please select a language",
    register_error_country: "Please enter a country",
    register_error_ltimezone: "Please select a timezone",
    register_registration_request: "Your registration request is still pending approval. For any enquiries please contact box.COP26@nationalgrid.com ",
    register_account_confirmation_email: "Please ensure you have clicked the link in your account confirmation email.",
    register_password_reset: "Thank you. If there is an account registered to this email address, you will shortly receive an email with a link to reset your password.",
    shared_livestream_error: "We are experiencing a technical difficulty. Please don’t go anywhere, we will be back soon.",
    notification_welcome: "Welcome to GYECC21! To select your workstreams and networking tables, please visit 'Plan my day'",
    notification_first_session_five_minute_warning: "Join us for the first session of the day in the plenary - starting in 5 minutes! ",
    notification_automatic_movement_to_plenary: "You will be automatically moved through to the plenary in 5 minutes - wrap up your conversations and join us there!",
    notification_plenary_live: "We're now live in the plenary. Please make your way into this space to join the session. ",
    notification_final_session: "The final session of GYECC21 is about to start - please make your way to the plenary!",
    notification_audio_volume: "Can’t hear the audio? Click on the volume button at the bottom of your screen.",
    notification_slido_comms: "Today is all about two way communication - submit your questions using Sli.do",
    notification_visit_exhbition_gallery: "Don’t forget to visit the exhibition and gallery to explore and connect with GYECC partners!",
    notification_last_workstream_session: "Your last chance to explore one of the workstreams is approaching - head to the workstream pods in 5 minutes",
    notification_workstream_selection: "There are four workstreams for you to choose from. Have you attended one of them yet?",
    notification_make_a_pledge: "Head to the networking lounge and click on the pledge wall to submit your GYECC21 pledge! ",
    notification_workstream_signup: "Have you signed up for a workstream yet? Don’t miss out - head to plan my day!",
    notification_connect_with_attendees: "Connect with attendess by requesting 1:1 meetings in the attendee gallery",
    notification_social_networking_tables: "Fancy a chat? Drop into one of the social networking tables during the next hour",
    notification_social_wall: "Have you checked out the social wall? View your posts in the social wall gallery",
    notification_book_meetings: "Have you booked a 1:1 meeting today? Visit the attendee gallery to request a meeting with a fellow attendee. ",
    notification_available_vacancies: "Check out availabile vacancies from GYECC partners in the jobs board area",
    notification_thank_you: "Thank you for joining us at GYECC21! We hope you enjoyed the event and made valuable connections.",
    notification_on_demand: "The platform will remain open until COP26 in November, view all content on-demand in GYECC TV.",
    exhibition_leave_details_follow_up_request: "Request a follow-up",
    exhibition_leave_details_request_meeting: "I’d like to request a 1:1 meeting",
    exhibition_leave_details_contact_me: "I’d like someone to contact me",
    meetings_meeting_with: "1:1 Meeting with",
    mobile_access_from_desktop: "Please access the platform from a desktop or laptop to join this meeting",
    plan_my_day_selection_complete: "Session selection complete",
    exhibition_hall_two: "Hall 2",
    exhibition_hall_three: "Hall 3",
    exhibition_hall_four: "Hall 4",
    shared_roundtables: "Roundtables",
    jobs_job_search: "Job search",
    forgotten_password_new: "New password",
    forgotten_password_confirm_new: "Confirm new password",
    speakers_biography: "Biography",
    speakers_bio: "Bio",
    speakers_session_name: "Session name",
    speakers_session_description: "Session description",
    speakers_session_time: "Session time",
    speakers_session_location: "Session location",
    register_please_select: "Please select",
    nav_get_connected: "Get connected!",
    shared_advanced_search: "Advanced search",
    jobs_advanced_job_search: "Advanced job search",
    leaderboard_my_points: "My points",
    nav_session_selection: "Session selection",
    mobile_exhibition_hall: "Exhibition hall",
    exhibition_visit: "Visit",
    exhibition_back_to_exhibition: "Back to exhibition",
    exhibition_search_exhibition: "Search for exhibition stands",
    meetings_request_meeting: "Request a meeting with",
    meetings_meetings_info_text: "To book a 1:1 meeting, please select one of the time slots below and click 'Submit'. Once accepted, the meeting will show in your agenda. To see the status of your meetings, go to 'Plan my day' and select 'My meetings'.",
    meetings_connect_when_ready: "Please click connect as soon as you are ready to join",
    on_demand_like_video: "Thank you for liking this video!",
    networking_back_to_tables: "Back",
    networking_social_tables: "Social tables",
    netowrking_hosted_tables: "Hosted tables",
    exhibition_please_include_message: "Please include a message",
    exhibition_message_sent: "Message successfully sent",
    meetings_submitted: "Your meeting request has been submitted",
    meetings_another_time: "Your request for another time has been sent",
    exhibition_submit_question: "Please enter a question to submit",
    registration_photo_submitted: "Profile picture successfully uploaded",
    plan_my_day_completed: "Thank you for selecting your sessions – these will appear in your personalised agenda. You can change your session selections at any time in the plan my day area.",
    profile_create: "Create your profile",
    profile_social_media: "Social media",
    profile_facebook: "You can find your profile link in profile settings. This will be a URL including your personalised Facebook link:. https://www.facebook.com/Username/",
    profile_tiktok: "Add your TikTok username. This is the @username other TikTok users can use to tag or find you",
    profile_instagram: "Add your Instagram username. This is the @username other Instagram users can use to tag or find you",
    profile_twitter: "Add your Twitter handle. This is the @username other Twitter users can use to tag or find you",
    profile_weibo: "To find your personal URL, click the menu on the top right corner of the Weibo account main page. A menu will show up with an option to copy your personal URL",
    profile_personalised_url: "Visit your profile and copy your personalised URL from the address bar",
    profile_url: "Visit your profile and copy your personalised URL",
    profile_already_created: "Already created a profile?",
    jobs_reset: "Reset",
    jobs_apply: "Apply",
    networking_lounge_submit_pledge: "Click here to submit your pledge",
    networking_lounge_social: "Click here to view #GYECC21 social media posts",
    social_wall_join: "Include #GYECC21 in your social media post to be added to the GYECC social wall",
    pledge_wall_info: "Click 'Make a pledge' button to tell us how you will go #onestepgreener",
    notification_gyecctv_closed_title: "GYECC TV closed",
    notification_gyecctv_closed: "GYECC TV will be open from Tuesday 7 September - please come back then to enjoy 45+ videos on-demand.",
    notification_gallery_closed_title: "Gallery closed",
    notification_gallery_closed: "The gallery will be open on Tuesday 7 & Wednesday 8 September - please come back then to engage with content from our corporate partners.",
}
