import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import { CSSTransition } from "react-transition-group";
import VideoPlayer from "../modules/videoPlayer/VideoPlayer"
import { Link } from "react-router-dom";
import OnDemandModal from "./OnDemandModal";
import { Image, Transformation } from "cloudinary-react";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./VisualOnDemand.module.scss";

class VisualOnDemand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introVideoComplete: false,
      showContent: false,
      onDemandContent: [],
      selectedOnDemand: null,
      showOnDemandModal: false,
      searchString: "",
    };
    this.onDemandZoomVideo = React.createRef();
  }

  componentDidMount () {
    requestPoints("other", "visitGYECCTVArea");

    this.loadCurrentDatabaseContent();

    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      6000
    );
  }

  loadCurrentDatabaseContent () {
    fetch("/api/getOnDemandContent")
      .then((res) => res.json())
      .then((data) => this.setState({ onDemandContent: data }));
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onDemandFilteredByDay = (selectedDay) => {
    return this.state.onDemandContent.filter(
      (contentItem) => (contentItem.day === selectedDay && (
        contentItem.contentTitle.toLowerCase().includes(this.state.searchString.toLowerCase()) ||
        contentItem.speakerName.toLowerCase().includes(this.state.searchString.toLowerCase()) ||
        contentItem.aboutText.toLowerCase().includes(this.state.searchString.toLowerCase())
      ))
    );
  };

  showOnDemand (onDemand) {
    requestPoints("viewOnDemandItem", onDemand._id);
    this.setState({ selectedOnDemand: onDemand, showOnDemandModal: true });
  }

  onDemandZoom = () => {
    requestPoints("other", "visitOnDemandZoomArea");
    if (this.onDemandZoomVideo != null && this.onDemandZoomVideo.play) {
      this.onDemandZoomVideo.play();
      // Start timeout in case video does not load
      setTimeout(
        function () {
          this.setState({ showContent: true });
        }.bind(this),
        6000
      );
    }
  }

  onDemandGallery = (selectedDay) => {
    return this.onDemandFilteredByDay(selectedDay).map((contentItem) => {
      return (
        <div
          key={contentItem._id}
          className={styles.contentItem}
          onClick={() => this.showOnDemand(contentItem)}
        >
          <div className={styles.contentVideoArea}>
            {contentItem.pdfURL ?
              (
                contentItem.pdfThumbnailID ?
                  <Image
                    cloudName="oijqec973f1nf"
                    publicId={contentItem.pdfThumbnailID}
                    width="160"
                    height="90"
                    secure={true}
                  >
                    <Transformation
                      height="90"
                      width="160"
                      crop="pad"
                    />
                  </Image> :
                  <img src={window.$videoPath + "icons/webLink.png"} className={styles.pdfIcon} alt="Web Link Icon" />
              )
              : (contentItem.videoID ? (
                <div className={styles.onDemandVideoDiv}>
                  <VideoPlayer
                    resourceLocator={contentItem.videoID}
                    location={"onDemand"}
                    userProfile={this.props.userProfile}
                  />
                </div>
              ) : null
              )}

          </div>
          <div className={styles.contentInfoArea}>
            <h1 className={styles.contentTitle}>{contentItem.contentTitle}</h1>
            <p className={styles.speakerName}>{/**Presenter: contentItem.speakerName*/}<br /><strong><Trans>posters_view</Trans></strong></p>
          </div>
        </div>
      );
    });
  };

  render () {
    return (
      <div>
        <div className="imageNavDiv">
          {this.props.introType == "entry" ?
            <video
              className="imageNavBackground"
              autoPlay
              muted
              id="introVideo"
              onEnded={() => this.setState({ introVideoComplete: true })}
            >
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/tvIntro.webm"}
                type="video/webm"
              />
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/tvIntro.mp4"}
                type="video/mp4"
              />
            </video> : null}

          {this.props.introType == "fromRewind" ?
            <video
              className="imageNavBackground"
              autoPlay
              muted
              id="introVideo"
              onEnded={() => this.setState({ introVideoComplete: true })}
            >
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/eventRewindReverse.webm"}
                type="video/webm"
              />
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/eventRewindReverse.mp4"}
                type="video/mp4"
              />
            </video> : null}

          {this.props.introType == "fromOnDemand" ?
            <video
              className="imageNavBackground"
              autoPlay
              muted
              id="introVideo"
              onEnded={() => this.setState({ introVideoComplete: true })}
            >
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/onDemandReverse.webm"}
                type="video/webm"
              />
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/onDemandReverse.mp4"}
                type="video/mp4"
              />
            </video> : null}


          {/** Holding point before zooming in or going to event rewind */}
          <CSSTransition
            in={this.state.introVideoComplete}
            timeout={1500}
            classNames="imageNavBackground"
            unmountOnExit
          >
            <div>
              <video
                className="imageNavBackground"
                muted
                id="introVideo"
                ref={(onDemandZoomVideo) => (this.onDemandZoomVideo = onDemandZoomVideo)}
                onEnded={() => this.setState({ showContent: true })}
              >
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/onDemand.webm"}
                  type="video/webm"
                />
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/onDemand.mp4"}
                  type="video/mp4"
                />
              </video>
            </div>
          </CSSTransition>

          {/** Only show hotspots if we are in paused state at start of video */}
          {this.state.introVideoComplete && !this.state.showContent ?
            <>
              <img
                className={styles.onDemandZoomHoverHotspot}
                src="/hoverShapes/hoverSquare.png"
                alt="On Demand"
                onClick={this.onDemandZoom}
              ></img>

              <Link to="/visualEventRewind">
                <img
                  className={styles.eventRewindHoverHotspot}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Event Rewind"
                ></img>
              </Link>

              {/**<img
                className={styles.eventRewindHoverHotspot}
                src="/hoverShapes/hoverSquare.png"
                alt="Event Rewind"
                onClick={() => alert(this.props.t('event_rewind_info_text'))}
              ></img>*/}

              <Link to="/lobby">
                <img
                  className={styles.exitHoverHotspot}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Exit"
                ></img>
              </Link>
            </>
            : null}


          <CSSTransition
            in={this.state.introVideoComplete && this.state.showContent}
            timeout={1500}
            classNames="imageNavBackground"
            unmountOnExit
          >
            <div>
              <img
                className="imageNavBackground"
                src={window.$videoPath + "visuals/" + this.props.language + "/onDemand.jpg"}
                alt="Background"
              ></img>

              <Link to="/visualOnDemandFromOnDemand">
                <img
                  className={styles.zoomOutHoverHotspot}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Exit"
                ></img>
              </Link>

              <div className={styles.titleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
                <h1 className={styles.pageTitle}><Trans>nav_on_demand</Trans></h1>
                <input
                  className={styles.searchBox}
                  type="text"
                  name="searchString"
                  placeholder={this.props.t('shared_search')}
                  value={this.state.searchString}
                  onChange={this.handleChange}
                />
              </div>

              <section className={styles.onDemandItemsArea}>
                {this.onDemandGallery(1)}
              </section>
            </div>
          </CSSTransition>


          <OnDemandModal
            showOnDemandModal={this.state.showOnDemandModal}
            closeOnDemandModal={() => this.setState({ showOnDemandModal: false })}
            selectedOnDemand={this.state.selectedOnDemand}
            userProfile={this.props.userProfile}
          />

        </div>
      </div>
    );
  }
}

export default withTranslation()(VisualOnDemand);