import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import styles from "./VideoAdminList.module.scss";

export default class VideoAdminList extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      videoList: [],
    };
  }

  componentDidMount () {
    this.retrieveVideo();
  }

  retrieveVideo () {
    fetch("/api/getVideoList")
      //.then((res) => res.text())
      .then((res) => res.json())
      .then((data) =>
        this.setState({
          videoList: data,
        })
      );
  }

  archiveMediaItem = (videoID) => {
    console.log("Archiving " + videoID);
    fetch("/api/archiveMediaItem", {
      method: "POST",
      body: JSON.stringify({ videoID: videoID }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.retrieveVideo();
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Error archiving video -  please try again");
      });
  }

  render () {
    const videoList = this.state.videoList.map((videoList) => {
      return (
        <tr key={videoList._id}>
          <td>{videoList.videoName}</td>
          <td>{videoList.playbackID}</td>
          <td><Link to={"/viewVideo/" + videoList._id}><Button>View</Button></Link></td>
          <td><Button onClick={() => this.archiveMediaItem(videoList._id)}>Archive</Button></td>
        </tr>
      );
    });

    return (
      <div className={styles.adminContentArea} >
        <h1>Video Admin - Video Admin List</h1>
        <br />
        <Link to={"/createVideo"}>
          <Button>Create New Video</Button>
        </Link>
        <br />
        <br />

        <Table striped bordered hover style={{ width: "90%" }}>
          <thead>
            <tr>
              <th>Video Name</th>
              <th>Playback ID</th>
              <th>View</th>
              <th>Archive</th>
            </tr>
          </thead>
          <tbody>{videoList}</tbody>
        </Table>
      </div>
    );
  }
}