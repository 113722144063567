import React, { Component } from "react";

export default class SlidoEmbed extends Component {
    constructor() {
        super();
        //Set default message
        this.state = {
            slidoLanguageCode: "en_GB",
        }
    }

    componentDidMount () {
        this.updateSlidoLanguage();
    }

    componentDidUpdate (prevProps, prevState) {
        // Only trigger update if language changes
        if (prevProps.userProfile.userLanguage !== this.props.userProfile.userLanguage) {
            this.updateSlidoLanguage();
        }
    }

    updateSlidoLanguage = () => {
        console.log("Update Slido language " + this.props.userProfile.userLanguage)
        switch (this.props.userProfile.userLanguage) {
            case "en":
                this.setState({ slidoLanguageCode: "en_GB" });
                break;
            case "es":
                this.setState({ slidoLanguageCode: "es_ES" });
                break;
            case "fr":
                this.setState({ slidoLanguageCode: "fr_FR" });
                break;
            case "zh":
                this.setState({ slidoLanguageCode: "zh_hant" });
                break;
            default:
                this.setState({ slidoLanguageCode: "en_GB" });
                break;
        }
    }

    render () {
        return (
            <iframe
                title="Slido Iframe"
                src={"https://app.sli.do/event/" + this.props.slidoEventID + "?lang=" + this.state.slidoLanguageCode}
                height="100%"
                width="100%"
                style={{ minHeight: "560px" }}
            ></iframe>
        )
    }
}