import React from "react";
import { Trans, withTranslation } from 'react-i18next';
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import styles from "./BreakoutsMobile.module.scss";

export default function Breakouts (props) {

  return (
    <div className={styles.mobileListView} >
      <div className={styles.mobileTitleArea}>
        <h1 className={styles.mobileTitleText}><Trans>shared_breakouts</Trans></h1>
        <Link to="/lobby"><Button><Trans>shared_back</Trans></Button></Link>
      </div>

      <Link to="/breakout/6086abd759b00d4eefcc69b1">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}><Trans>nav_workstream_one</Trans></h3>
        </div>
      </Link>

      <Link to="/breakout/6086abd759b00d4eefcc69b2">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}><Trans>nav_workstream_two</Trans></h3>
        </div>
      </Link>

      <Link to="/breakout/6086abd759b00d4eefcc69b3">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}><Trans>nav_workstream_three</Trans></h3>
        </div>
      </Link>

      <Link to="/breakout/6086abd759b00d4eefcc69b4">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}><Trans>nav_workstream_four</Trans></h3>
        </div>
      </Link>

    </div>
  );
}
