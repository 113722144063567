import React, { Component } from "react";
import Modal from "react-modal";
import { Trans, withTranslation } from 'react-i18next';
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./Leaderboard.module.scss";

export default class Leaderboard extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      leaderboardContent: [],
      userPoints: 0,
    };
  }

  componentDidMount () {
    this.reloadData();
  }

  leaderboardModalOnAfterOpen = () => {
    requestPoints("other", "viewLeaderboard");
    this.reloadData();
  }

  reloadData = () => {
    // Get leaderboard
    fetch("/api/getLeaderboard")
      .then((res) => res.json())
      .then((data) => this.setState({ leaderboardContent: data }));
    // Get individual points
    fetch("/api/getUserProfile")
      .then((res) => res.json())
      .then((data) => this.setState({ userPoints: data.user.points }));
  };

  render () {
    const leaderboard = this.state.leaderboardContent.map((user, index) => {
      return (
        <tr key={user._id}>
          <td className={styles.nameCell}>
            {index + 1 + ")  " + user.firstName} {user.surname}
          </td>
          <td className={styles.pointsCell}>
            <div className={styles.numberCircle}> {user.points} </div>
          </td>
        </tr>
      );
    });

    return (
      <div>
        <Modal
          onAfterOpen={this.leaderboardModalOnAfterOpen}
          ariaHideApp={false}
          isOpen={this.props.showLeaderboardModal}
          onRequestClose={() => this.props.setShowLeaderboardModal(false)}
          contentLabel="Leaderboard Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
          style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
        >
          <div className={styles.modalFlexboxColumn}>
            <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
              <h1 className={styles.modalPageTitle}><Trans>leaderboard_title</Trans></h1>
              <button
                className={styles.modalClose}
                variant="success"
                onClick={() => this.props.setShowLeaderboardModal(false)}
              >
                <Trans>shared_close</Trans>
                <img
                  className={styles.modalCloseIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button>
            </div>

            <div className={styles.scrollableArea}>
              <h2 className={styles.leaderboardInfoText}>
                <Trans>leaderboard_info_text</Trans>
              </h2>

              <table className={styles.leaderboardTable}>
                <tbody>{leaderboard}</tbody>
              </table>

              <hr className={styles.horizRule} />
              <table className={styles.leaderTable}>
                <tbody>
                  <tr>
                    <td className={styles.myPoints}><Trans>leaderboard_my_points</Trans></td>
                    <td className={styles.pointsCell}>
                      <div className={styles.numberCircle}>
                        {this.state.userPoints}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr className={styles.horizRule} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
