import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import { Image, Transformation } from "cloudinary-react";
import { dateFormatterTimeAndDay } from "../helpers/dateFormatter.js"
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import styles from "./Meetings.module.scss";

export default class Meetings extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      requestsMade: [],
      requestsReceived: [],
    };
  }

  // Get sent and received meeting requests_
  componentDidMount () {
    // Hide corner menu
    this.props.setCornerMenuStatus(3);
    // Load database content
    fetch("/api/getUserMeetings")
      .then((res) => res.json())
      .then((data) =>
        this.setState({
          requestsMade: data.meetingRequestsMade,
          requestsReceived: data.meetingRequestsReceived,
        })
      );
    console.log(this.state.requestsMade);
  }

  componentDidUnmount () {
    // Set corner menu to displayed again
    this.props.setCornerMenuStatus(1);
  }

  componentDidUpdate () {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 3) {
      this.props.setCornerMenuStatus(3);
    }
  }

  // Called when user clicks request meeting button on another's profile
  acceptRequest = (request, response, requestAlternativeTime) => {
    fetch("/api/submitMeetingRequestResponse", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingID: request._id,
        response: response,
        requestAlternativeTime: requestAlternativeTime,
      }),
    });

    // Refresh page
    //window.location.reload(false);
    setTimeout(
      function () {
        this.componentDidMount();
      }.bind(this),
      2000
    );

    if (response === false && requestAlternativeTime === true) {
      alert(this.props.t('meetings_another_time'));
    }
  };

  // Function for sorting meeting records
  compare (a, b) {
    let comparison = 0;
    //console.log("A: " + a.meetingSlotID);
    //console.log("B: " + b.meetingSlotID);
    if (a.meetingSlotID.sequenceNumber > b.meetingSlotID.sequenceNumber) {
      comparison = 1;
    } else if (
      a.meetingSlotID.sequenceNumber < b.meetingSlotID.sequenceNumber
    ) {
      comparison = -1;
    }
    return comparison;
  }

  getCombinedConfirmedMeetings = () => {
    const confirmedRequestsMadeArray = this.state.requestsMade.filter(
      (request) => request.meetingStatus === "accepted"
    );
    const confirmedRequestsReceivedArray = this.state.requestsReceived.filter(
      (request) => request.meetingStatus === "accepted"
    );
    // For the requests received array where we are requestee, make the requestor the requestee to
    // allow the records to be combined to show the other party, regardless of who initiated
    confirmedRequestsReceivedArray.forEach(function (request) {
      request.meetingRequesteeUser = request.meetingRequestorUser;
      // Also amend the key to avoid the key clash of the same meeting ever occuring twice
      // if user invites themself!
      //request._id = "2" + request._id;
      // Does not work now we use meetingID as name for meeting
    });

    //console.log("A" + confirmedRequestsReceivedArray);
    const combinedRequestsArray = confirmedRequestsMadeArray.concat(
      confirmedRequestsReceivedArray
    );
    //console.log(combinedRequestsArray);
    return combinedRequestsArray.sort(this.compare);
  };

  render () {
    const requestsConfirmed = this.getCombinedConfirmedMeetings().map(
      (request) => {
        return (
          <tr key={request._id} className={styles.meetingRow}>
            <td className={styles.meetingTimeDiv}>
              <img
                src={window.$videoPath + "icons/time-dot.png"}
                alt="Time dot"
                className={styles.timeDot}
              />
              <div className={styles.timeOverlay}>
                {/**request.meetingSlotID.meetingSlotDescriptiveTitle*/}
                {dateFormatterTimeAndDay(request.meetingSlotID.meetingSlotTimestamp, this.props.userProfile)}
              </div>
            </td>
            <td className={styles.meetingDetailDiv}>
              <Trans>meetings_meeting_with</Trans> {request.meetingRequesteeUser.firstName}{" "}
              {request.meetingRequesteeUser.surname}
            </td>
            <td>
              {this.props.showMobile ? null :
                <Link to={"/video/" + request._id}>
                  <Button className={styles.joinButton} variant="success"><Trans>agenda_join</Trans></Button>
                </Link>
              }
            </td>
          </tr>
        );
      }
    );

    const requestsMade = this.state.requestsMade
      .filter((request) => request.meetingStatus === "pending")
      .map((request) => {
        return (
          <tr key={request._id}>
            <td>
              {request.meetingRequesteeUser ? (
                <Image
                  className={styles.profilePicture}
                  cloudName="oijqec973f1nf"
                  publicId={
                    request.meetingRequesteeUser.userProfilePictureID + ".png"
                  }
                  width="100"
                  secure={true}
                >
                  <Transformation
                    height="100"
                    width="100"
                    gravity="face"
                    crop="fill"
                    radius="max"
                  />
                </Image>
              ) : null}
            </td><td>
              <div className={styles.requestText}>
                {dateFormatterTimeAndDay(request.meetingSlotID.meetingSlotTimestamp, this.props.userProfile)}{" "}
                {request.meetingRequesteeUser.firstName}{" "}
                {request.meetingRequesteeUser.surname}
              </div>
            </td>
          </tr>
        );
      });

    const requestsReceived = this.state.requestsReceived
      .filter((request) => request.meetingStatus === "pending")
      .map((request) => {
        return (
          <tr key={request._id}>
            <td>
              <Image
                className={styles.profilePicture}
                cloudName="oijqec973f1nf"
                publicId={
                  request.meetingRequestorUser.userProfilePictureID + ".png"
                }
                width="100"
                secure={true}
              >
                <Transformation
                  height="100"
                  width="100"
                  gravity="face"
                  crop="fill"
                  radius="max"
                />
              </Image>
            </td>
            <td>
              <div className={styles.requestText}>
                {dateFormatterTimeAndDay(request.meetingSlotID.meetingSlotTimestamp, this.props.userProfile)} -{" "}
                {request.meetingRequestorUser.firstName}{" "}
                {request.meetingRequestorUser.surname}{" "}
              </div>
            </td>
            <td>
              <Button
                className={styles.responseButton}
                variant="primary"
                onClick={() => this.acceptRequest(request, true, false)}
              >
                <Trans>meetings_accept</Trans>
              </Button>
              <Button
                className={styles.responseButton}
                variant="success"
                onClick={() => this.acceptRequest(request, false, true)}
              >
                <Trans>meetings_other_time</Trans>
              </Button>
              <Button
                className={styles.responseButton}
                variant="danger"
                onClick={() => this.acceptRequest(request, false, false)}
              >
                <Trans>meetings_ignore</Trans>
              </Button>
            </td>
          </tr>
        );
      });

    return (
      <div>
        <div className={styles.outerBackgroundDiv}>
          {/**<img
            className="imageNavBackground"
            src={window.$videoPath + "background.png"}
            alt="Background"
          ></img>*/}
        </div>

        <div className={styles.hotlinkBar}>
          <Link to={"/lobby"} className={styles.backLink}>
            <div className={styles.backDiv}>
              <img
                className={styles.backArrow}
                src={window.$videoPath + "icons/backArrow.png"}
                alt="Back"
              />
              <Trans>attendees_back_to_eco_park</Trans>
            </div>
          </Link>
        </div>


        <div className={styles.headerBar}>
          <h1 className={styles.pageTitle}>
            <strong><Trans>nav_my_meetings</Trans></strong>
          </h1>
          <div className={styles.infoText}>
            <p>
              <Trans>meetings_info_text</Trans>
            </p>
          </div>
        </div>

        <div className={styles.contentContainer}>
          <br />

          <div className={styles.columnContainer}>
            <div className={styles.formColumn}>



              <div className={styles.confirmedContainer}>
                <div className={styles.meetingCategoryHeader}>
                  <h2><Trans>meetings_meetings_confirmed</Trans></h2>
                </div>
                <div className={styles.confirmedContainerGreyColumn}></div>
                <div className={styles.confirmedMeetingsScrollDiv}>
                  <table>
                    <tbody>{requestsConfirmed}</tbody>
                  </table>
                </div>
                <br />
                <br />
                <br />
              </div>



            </div>
            <div className={styles.formColumn}>
              <div className={styles.receivedDiv}>
                <div className={styles.meetingCategoryHeader}>
                  <h2><Trans>meetings_requests_recieved</Trans></h2>
                </div>
                <div className={styles.receivedScrollDiv}>
                  <table>
                    <tbody>{requestsReceived}</tbody>
                  </table>
                </div>
              </div>

              <div className={styles.awaitingDiv}>
                <div className={styles.meetingCategoryHeader}>
                  <h2><Trans>meetings_awaiting_responses</Trans></h2>
                </div>
                <div className={styles.awaitingScrollDiv}>
                  <table>
                    <tbody>{requestsMade}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/**<div className={styles.confirmedContainer}>
            <div className={styles.meetingCategoryHeader}>
              <h2>Meetings confirmed</h2>
            </div>
            <div className={styles.confirmedContainerGreyColumn}></div>
            <div className={styles.confirmedMeetingsScrollDiv}>
              <table>
                <tbody>{requestsConfirmed}</tbody>
              </table>
            </div>
            <br />
            <br />
            <br />
          </div>
          <div className={styles.rightColumn}>
            <div className={styles.receivedDiv}>
              <div className={styles.meetingCategoryHeader}>
                <h2>Requests received</h2>
              </div>
              <div className={styles.receivedScrollDiv}>
                <table>
                  <tbody>{requestsReceived}</tbody>
                </table>
              </div>
            </div>

            <div className={styles.awaitingDiv}>
              <div className={styles.meetingCategoryHeader}>
                <h2>Awaiting responses</h2>
              </div>
              <div className={styles.awaitingScrollDiv}>
                <table>
                  <tbody>{requestsMade}</tbody>
                </table>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
    );
  }
}
