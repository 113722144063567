import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import Button from "react-bootstrap/Button";
import styles from "./RequestPasswordReset.module.scss";
import preLoginStyles from "./Pre-Login.module.scss";

class RequestPasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.email.length < 1) {
      alert(this.props.t('register_error_enter_email'));
    } else {
      fetch("/api/requestPasswordReset", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.props.handleCloseRequestPasswordResetDiv();
            alert(this.props.t('reset_password_info_text'));
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          alert("Error resetting password please try again");
        });
    } // End of else for form validation
  };

  render () {
    return (
      <div className={styles.requestPasswordResetBox}>
        <div className={styles.forgottenPasswordContent}>
          <img
            className={preLoginStyles.preLoginBoxLogo}
            src={window.$videoPath + "logo.png"}
            alt="Logo"
          />
          <br />
          <br />
          <br />

          <h2><Trans>reset_password_reset_password</Trans></h2>
          <br />

          <form onSubmit={this.handleSubmit} noValidate>
            <input
              className={preLoginStyles.textEntry}
              type="email"
              name="email"
              placeholder={this.props.t('register_email')}
              tabIndex="200"
              value={this.state.email}
              onChange={this.handleChange}
            />
            <br />
            <br />
            <p>
              <Trans>reset_password_info_text</Trans>
            </p>
            <br />

            <input
              type="submit"
              value={this.props.t('reset_password_reset_password')}
              className="btn btn-success float-right submitButton"
            />
          </form>
          <Button
            onClick={() => this.props.handleCloseRequestPasswordResetDiv()}
            className="btn btn-secondary float-left"
          >
            <Trans>shared_back</Trans>
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(RequestPasswordReset);
