import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Trans, withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import OnDemandModal from "./OnDemandModal";
import dompurify from "dompurify";
import { requestPoints } from "./../helpers/requestPoints.js";
import styles from "./OnDemandMobile.module.scss";

export default class OnDemand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOnDemand: null,
      showOnDemandModal: false,
    };
    requestPoints("other", "visitMobileGYECCTV");
  }

  onDemandFilteredByDay = (selectedDay) => {
    return this.props.onDemandContent.filter(
      (contentItem) => contentItem.day === selectedDay
    );
  };

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  showOnDemand (onDemand) {
    requestPoints("viewOnDemandItem", onDemand._id);
    this.setState({ selectedOnDemand: onDemand, showOnDemandModal: true });
  }

  onDemandGallery = (selectedDay) => {
    return this.onDemandFilteredByDay(selectedDay).map((contentItem) => {
      return (
        <div
          key={contentItem._id}
          className={styles.mobileListItem}
          onClick={() => this.showOnDemand(contentItem)}
        >
          <h1 className={styles.mobileListTitle}>{contentItem.contentTitle}</h1>
          <hr className={styles.horizRule} />
          <h3 dangerouslySetInnerHTML={this.sanitizeCustomField(contentItem.speakerName)}></h3>
          <p dangerouslySetInnerHTML={this.sanitizeCustomField(contentItem.aboutText)} className={styles.aboutText}></p>
        </div>
      );
    });
  };

  render () {
    return (
      <div className={styles.mobileListView} >
        <div className={styles.mobileTitleArea}>
          <h1 className={styles.mobileTitleText}><Trans>nav_gyecc_tv</Trans></h1>
          <Link to="/lobby"><Button><Trans>shared_back</Trans></Button></Link>
        </div>
        {this.onDemandGallery(1)}
        {this.onDemandGallery(2).length > 0 ? <h3 className={styles.subHeadingTitle}><Trans>shared_event_rewind</Trans></h3> : null}
        {this.onDemandGallery(2)}

        <OnDemandModal
          showOnDemandModal={this.state.showOnDemandModal}
          closeOnDemandModal={() => this.setState({ showOnDemandModal: false })}
          selectedOnDemand={this.state.selectedOnDemand}
          userProfile={this.props.userProfile}
        />
      </div>
    );
  }
}
