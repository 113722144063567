import React, { Component } from "react";
import Vimeo from "@u-wave/react-vimeo";
import { Trans, withTranslation } from 'react-i18next';
import { requestPoints } from "./../helpers/requestPoints.js";
import styles from "./ExhibitionResourceArea.module.scss";

export default class ExhibitionResourceArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = () => {

    };

    logContentInteraction = (eventCode, contentID) => {
        // Submit action for points
        requestPoints(
            eventCode,
            eventCode +
            ":" +
            contentID +
            ":" +
            this.props.standName
        );
    };

    render () {
        let standThemeColorStyle = { backgroundColor: this.props.panelColor, color: this.props.panelTextColor }
        return (
            <div className={styles.columnContainer}>
                <div className={styles.formColumn}>
                    {this.props.resourceVideo1ID ? (
                        <Vimeo
                            controls={true}
                            responsive={true}
                            className={styles.boothVideo1}
                            video={this.props.resourceVideo1ID}
                            onPlay={() => this.logContentInteraction(
                                "viewExhibitionResourceVideo",
                                "viewExhibitionResourceVideo1"
                            )}
                            allow="autoplay; fullscreen"
                            allowfullscreen
                            mozallowfullscreen
                            webkitallowfullscreen

                        ></Vimeo>
                    ) : null}

                    {this.props.resourceVideo2ID ? (
                        <Vimeo
                            controls={true}
                            responsive={true}
                            className={styles.boothVideo2}
                            video={this.props.resourceVideo2ID}
                            onPlay={() => this.logContentInteraction(
                                "viewExhibitionResourceVideo",
                                "viewExhibitionResourceVideo2"
                            )}
                            allow="autoplay; fullscreen"
                            allowfullscreen
                            mozallowfullscreen
                            webkitallowfullscreen
                        ></Vimeo>
                    ) : null}
                </div>

                <div className={styles.formColumn}>
                    <div className={styles.learnMoreDiv}>
                        <h2 className={styles.learnMoreTitle}><strong><Trans>exhibition_learn_more</Trans></strong></h2>
                        <br />
                        <table className={styles.learnMoreTable}>
                            <tbody>
                                <tr className={styles.learnMoreTableRow}>
                                    <td className={styles.column1}>
                                        {this.props.resourceLink1Title &&
                                            this.props.resourceLink1Title.length > 0 ? (
                                            <a href={this.props.resourceLink1} target="_blank" rel="noopener noreferrer">
                                                <div
                                                    className={styles.learnMoreButton}
                                                    style={standThemeColorStyle}
                                                    variant="success"
                                                    onClick={() =>
                                                        this.logContentInteraction("viewExhibitionContent", this.props.standName + "-1" + this.props.resourceLink1Title)
                                                    }
                                                >
                                                    <h3 className={styles.learnMoreButtonText}>{this.props.resourceLink1Title}</h3>
                                                </div>
                                            </a>
                                        ) : null}
                                    </td>
                                    <td className={styles.column2}>
                                        {this.props.resourceLink2Title &&
                                            this.props.resourceLink2Title.length > 0 ? (
                                            <a href={this.props.resourceLink2} target="_blank" rel="noopener noreferrer">
                                                <div
                                                    className={styles.learnMoreButton}
                                                    style={standThemeColorStyle}
                                                    onClick={() =>
                                                        this.logContentInteraction("viewExhibitionContent", this.props.standName + "-2" + this.props.resourceLink2Title)
                                                    }
                                                >
                                                    <h3 className={styles.learnMoreButtonText}>{this.props.resourceLink2Title}</h3>
                                                </div>
                                            </a>
                                        ) : null}
                                    </td>
                                </tr>
                                <tr className={styles.learnMoreTableRow}>
                                    <td>
                                        {this.props.resourceLink3Title &&
                                            this.props.resourceLink3Title.length > 0 ? (
                                            <a href={this.props.resourceLink3} target="_blank" rel="noopener noreferrer">
                                                <div
                                                    className={styles.learnMoreButton}
                                                    style={standThemeColorStyle}
                                                    onClick={() =>
                                                        this.logContentInteraction("viewExhibitionContent", this.props.standName + "-3" + this.props.resourceLink3Title)
                                                    }
                                                >
                                                    <h3 className={styles.learnMoreButtonText}>{this.props.resourceLink3Title}</h3>
                                                </div>
                                            </a>
                                        ) : null}
                                    </td>
                                    <td>
                                        {this.props.resourceLink4Title &&
                                            this.props.resourceLink4Title.length > 0 ? (
                                            <a href={this.props.resourceLink4} target="_blank" rel="noopener noreferrer">
                                                <div
                                                    className={styles.learnMoreButton}
                                                    style={standThemeColorStyle}
                                                    onClick={() =>
                                                        this.logContentInteraction("viewExhibitionContent", this.props.standName + "-4" + this.props.resourceLink4Title)
                                                    }
                                                >
                                                    <h3 className={styles.learnMoreButtonText}>{this.props.resourceLink4Title}</h3>
                                                </div>
                                            </a>
                                        ) : null}
                                    </td>
                                </tr>
                                <tr className={styles.learnMoreTableRow}>
                                    <td className={styles.column1}>
                                        {this.props.resourceLink5Title &&
                                            this.props.resourceLink5Title.length > 0 ? (
                                            <a href={this.props.resourceLink5} target="_blank" rel="noopener noreferrer">
                                                <div
                                                    className={styles.learnMoreButton}
                                                    style={standThemeColorStyle}
                                                    onClick={() =>
                                                        this.logContentInteraction("viewExhibitionContent", this.props.standName + "-5" + this.props.resourceLink5Title)
                                                    }
                                                >
                                                    <h3 className={styles.learnMoreButtonText}>{this.props.resourceLink5Title}</h3>
                                                </div>
                                            </a>
                                        ) : null}
                                    </td>
                                    <td>
                                        {this.props.resourceLink6Title &&
                                            this.props.resourceLink6Title.length > 0 ? (
                                            <a href={this.props.resourceLink6} target="_blank" rel="noopener noreferrer">
                                                <div
                                                    className={styles.learnMoreButton}
                                                    style={standThemeColorStyle}
                                                    onClick={() =>
                                                        this.logContentInteraction("viewExhibitionContent", this.props.standName + "-6" + this.props.resourceLink6Title)
                                                    }
                                                >
                                                    <h3 className={styles.learnMoreButtonText}>{this.props.resourceLink6Title}</h3>
                                                </div>
                                            </a>
                                        ) : null}
                                    </td>
                                </tr>
                                <tr className={styles.learnMoreTableRow}>
                                    <td>
                                        {this.props.resourceLink7Title &&
                                            this.props.resourceLink7Title.length > 0 ? (
                                            <a href={this.props.resourceLink7} target="_blank" rel="noopener noreferrer">
                                                <div
                                                    className={styles.learnMoreButton}
                                                    style={standThemeColorStyle}
                                                    onClick={() =>
                                                        this.logContentInteraction("viewExhibitionContent", this.props.standName + "-7" + this.props.resourceLink7Title)
                                                    }
                                                >
                                                    <h3 className={styles.learnMoreButtonText}>{this.props.resourceLink7Title}</h3>
                                                </div>
                                            </a>
                                        ) : null}
                                    </td>
                                    <td>
                                        {this.props.resourceLink8Title &&
                                            this.props.resourceLink8Title.length > 0 ? (
                                            <a href={this.props.resourceLink8} target="_blank" rel="noopener noreferrer">
                                                <div
                                                    className={styles.learnMoreButton}
                                                    style={standThemeColorStyle}
                                                    onClick={() =>
                                                        this.logContentInteraction("viewExhibitionContent", this.props.standName + "-8" + this.props.resourceLink8Title)
                                                    }
                                                >
                                                    <h3 className={styles.learnMoreButtonText}>{this.props.resourceLink8Title}</h3>
                                                </div>
                                            </a>
                                        ) : null}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <br />
                </div>
            </div>
        );
    }
}