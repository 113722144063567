import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import { CSSTransition } from "react-transition-group";
import ReactTooltip from 'react-tooltip';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Button from "react-bootstrap/Button";
import NetworkingMobile from "./NetworkingMobile";
import { requestPoints } from "./../helpers/requestPoints.js";
import styles from "./Networking.module.scss";

class Networking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introVideoComplete: false,
      externalContentIframeSource: "",
    };
  }

  componentDidMount () {
    requestPoints("visitNetworkingLounge", "visitNetworkingLounge");

    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      12000
    );
  }

  showNoonahEmbed = () => {
    //this.setState({ externalContentIframeSource: "https://vpb.noonah.com/demobooth?ondemo=1" });
    this.setState({ externalContentIframeSource: "https://events.virtual-tech.io/nationalgrid/" });
    requestPoints("other", "viewNoonah");
  };

  showJuicerEmbed = () => {
    this.setState({ externalContentIframeSource: "https://www.juicer.io/api/feeds/gyecc21/iframe" });
    requestPoints("other", "viewJuicer");
  };

  render () {
    return (
      <div>
        {this.props.showMobile ?
          <NetworkingMobile />
          :
          <div className={styles.masterPageDiv}>
            <Modal
              ariaHideApp={false}
              isOpen={this.state.externalContentIframeSource.length > 0}
              contentLabel="External Content Embed"
              style={{
                overlay: {
                  position: "fixed",
                  top: 80,
                  left: "2%",
                  right: "2%",
                  bottom: "2%",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                  zIndex: 30000,
                },
                content: {
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                  right: "20px",
                  bottom: "20px",
                  border: "1px solid #ccc",
                  background: "#fff",
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  borderRadius: "4px",
                  outline: "none",
                  padding: 0,
                },
              }}
            >
              {this.state.externalContentIframeSource == "https://www.juicer.io/api/feeds/gyecc21/iframe" ?
                <h3 style={{ marginTop: "10px", marginLeft: "20px", width: "80%" }}><Trans>social_wall_join</Trans><br /><br /></h3>
                :
                <>
                  <h3 style={{ marginTop: "10px", marginLeft: "20px" }}>Zoom in to view the pledges that were submitted during GYECC21</h3>
                  {/**<a href={"https://photo.virtualeventsbooth.com/nationalgridwall"} target="_blank" rel="noopener noreferrer">
                    <Button
                      style={{ marginLeft: "20px" }}
                      onClick={() => requestPoints("addPledge", "addPledge")}
                    >
                      <Trans>agenda_make_your_pledge</Trans>
                    </Button>
                  </a>*/}
                  <br />
                </>
              }
              <div className={styles.iFrameContainer}>
                <iframe
                  title="External iFrame"
                  className={styles.noonahIframe}
                  src={this.state.externalContentIframeSource}
                  allow="camera; autoplay; encrypted-media;">
                </iframe>
              </div>

              <Button
                className={styles.iframeModalClose}
                variant="success"
                onClick={() =>
                  this.setState({
                    externalContentIframeSource: "",
                  })
                }
              >
                <Trans>shared_close</Trans>
              </Button>
            </Modal>
            {this.props.introType === "fromTables" ?
              <video
                className="imageNavBackground"
                autoPlay
                webkit-playsinline="true"
                playsInline
                muted
                id="introVideo"
                onEnded={() => this.setState({ introVideoComplete: true })}
              >
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/roundtablesReverse.webm"}
                  type="video/webm"
                />
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/roundtablesReverse.mp4"}
                  type="video/mp4"
                />
              </video>
              :
              <video
                className="imageNavBackground"
                autoPlay
                webkit-playsinline="true"
                playsInline
                muted
                id="introVideo"
                onEnded={() => this.setState({ introVideoComplete: true })}
              >
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/networking.webm"}
                  type="video/webm"
                />
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/networking.mp4"}
                  type="video/mp4"
                />
              </video>
            }

            <CSSTransition
              in={this.state.introVideoComplete}
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <img
                className="imageNavBackground"
                src={window.$videoPath + "visuals/" + this.props.language + "/networking.jpg"}
                alt="Background"
              ></img>
            </CSSTransition>


            {this.state.introVideoComplete ? (
              <div>
                <ReactTooltip className={styles.toolTip} style={{ zIndex: 5000000000 }} html={true} id={"networkingInfo"} border={true} place={"bottom"} effect={"float"} />
                <img
                  onClick={this.showNoonahEmbed}
                  className={`${styles.hoverHotspot} ${styles.pledgeHoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Pledge Wall"
                  data-tip={"<h4><strong>Click here to view the pledges that were submitted during GYECC21</strong></h4>"}
                  data-for={"networkingInfo"}
                ></img>
                <img
                  onClick={this.showJuicerEmbed}
                  className={`${styles.hoverHotspot} ${styles.socialHoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Social Wall"
                  data-tip={"<h4><strong>" + this.props.t('networking_lounge_social') + "</strong></h4>"}
                  data-for={"networkingInfo"}
                ></img>
                <Link to="/gallery">
                  <img
                    className={`${styles.hoverHotspot} ${styles.galleryHoverHotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="To Gallery"
                    onClick={() => requestPoints("other", "attendeeGalleryFromNetworking")}
                  ></img>
                </Link>
                {/**<Link to="/visualRoundtables">
                  <img
                    className={`${styles.hoverHotspot} ${styles.roundtablesHoverHotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="To Roundtables"
                  ></img>
                </Link>*/}
                <img
                  className={`${styles.hoverHotspot} ${styles.roundtablesHoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="To Roundtables"
                  onClick={() => alert("Networking tables are no longer available. The platform is open until 12th November – visit GYECC TV to view content from the event on-demand.")}
                ></img>
                <Link to="/lobby">
                  <img
                    className={`${styles.hoverHotspot} ${styles.lobbyHoverHotspot}`}
                    src="/hoverShapes/hoverSquare.png"
                    alt="To Lobby"
                  ></img>
                </Link>
              </div >

            ) : null
            }
          </div>
        }
      </div >
    );
  }
}

export default withTranslation()(Networking);