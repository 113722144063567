import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import PreLoginLanguageSelection from "./PreLoginLanguageSelection"
import styles from "./ResetPassword.module.scss";
import preLoginStyles from "./Pre-Login.module.scss";
const queryString = require("query-string");

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      resetToken: "",
      newPassword: "",
      confirmNewPassword: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount () {
    const queryParameters = this.props.location.search;
    console.log(queryParameters);
    const queryParamatersParsed = queryString.parse(queryParameters);
    console.log(queryParamatersParsed);

    this.setState({
      email: queryParamatersParsed.email,
      resetToken: queryParamatersParsed.resetToken,
    });
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.newPassword.length < 6) {
      alert(this.props.t('register_error_password_six_letters'));
    } else if (this.state.newPassword !== this.state.confirmNewPassword) {
      alert(this.props.t('register_error_password_match'));
    } else {
      fetch("/api/resetPassword", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.props.history.push("/");
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          alert("Error resetting password please try again");
        });
    }
  };

  render () {
    return (
      <div>
        {/** Hide this to check for cookie upon landing direct to this page */}
        <div style={{ display: "none" }}>
          <PreLoginLanguageSelection
            setLanguage={this.props.setLanguage}
            cookies={this.props.cookies}
          />
        </div>
        <div className={styles.masterPageDiv}>
          <img
            className={styles.fullScreenBackground}
            src={window.$videoPath + "loginBackground.jpg"}
            alt="Background"
          ></img>

          <div className={styles.resetPasswordBox}>
            <div className={styles.resetPasswordContent}>
              <img
                className={preLoginStyles.preLoginBoxLogo}
                src={window.$videoPath + "logo.png"}
                alt="Logo"
              />
              <br />
              <br />
              <h2><Trans>reset_password_reset_password</Trans></h2>

              <form onSubmit={this.handleSubmit} noValidate>
                <br />
                <label htmlFor="newPassword"><Trans>forgotten_password_new</Trans>:</label>
                <input
                  type="password"
                  name="newPassword"
                  className={preLoginStyles.textEntry}
                  size="50"
                  placeholder={this.props.t('forgotten_password_new')}
                  value={this.state.password}
                  onChange={this.handleChange}
                  required
                />
                <br />
                <br />
                <label htmlFor="confirmNewPassword">
                  <Trans>forgotten_password_confirm_new</Trans>:
                </label>
                <input
                  type="password"
                  name="confirmNewPassword"
                  className={preLoginStyles.textEntry}
                  size="50"
                  placeholder={this.props.t('forgotten_password_confirm_new')}
                  value={this.state.confirmPassword}
                  onChange={this.handleChange}
                  required
                />
                <br />
                <br />
                <input
                  type="submit"
                  value={this.props.t('shared_submit')}
                  className="btn btn-success float-right submitButton"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ResetPassword);