import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import styles from "./LiveStreamAdminList.module.scss";

export default class LiveStreamAdminList extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      liveStreamList: [],
    };
  }

  componentDidMount () {
    this.retrieveLiveStreams();
  }

  retrieveLiveStreams () {
    fetch("/api/getLiveStreamList")
      //.then((res) => res.text())
      .then((res) => res.json())
      .then((data) =>
        this.setState({
          liveStreamList: data,
        })
      );
  }

  archiveLiveStream = (liveStreamID) => {
    console.log("Archiving " + liveStreamID);
    fetch("/api/archiveLiveStream", {
      method: "POST",
      body: JSON.stringify({ liveStreamID: liveStreamID }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.retrieveLiveStreams();
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Error archiving live stream -  please try again");
      });
  }

  render () {
    const liveStreamList = this.state.liveStreamList.map((liveStream) => {
      return (
        <tr key={liveStream._id}>
          <td>{liveStream.streamName}</td>
          <td>{liveStream.streamKey}</td>
          <td><Link to={"/viewLiveStream/" + liveStream._id}><Button>View</Button></Link></td>
          <td><Button onClick={() => this.archiveLiveStream(liveStream._id)}>Archive</Button></td>
        </tr>
      );
    });

    return (
      <div className={styles.adminContentArea} >
        <h1>Live Stream Admin - Stream List</h1>
        <br />
        <Link to={"/createLiveStream"}>
          <Button>Create New Live Stream</Button>
        </Link>
        <br />
        <br />
        <h4>Server URL: rtmp://live.lu-virtual-cdn.co.uk:5222/app</h4>
        <br />
        <br />

        <Table striped bordered hover style={{ width: "90%" }}>
          <thead>
            <tr>
              <th>Stream Name</th>
              <th>Stream Key</th>
              <th>View</th>
              <th>Archive</th>
            </tr>
          </thead>
          <tbody>{liveStreamList}</tbody>
        </Table>
      </div>
    );
  }
}