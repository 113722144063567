import React, { Component } from "react";
import styles from "./Analytics.module.scss";

export default class Analytics extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      totalUsers: 0,
      awaitingConfirmation: 0,
      awaitingApproval: 0,
      approved: 0,

      requestedMeetings: 0,
      declinedMeetings: 0,
      pendingMeetings: 0,
      confirmedMeetings: 0,

      totalQuestions: 0,
      unansweredQuestions: 0,
      answeredQuestions: 0,

      plenaryVisits: 0,

      breakout1Visits: 0,
      breakout2Visits: 0,
      breakout3Visits: 0,
      breakout4Visits: 0,
      breakout5Visits: 0,
      breakout6Visits: 0,
      breakout7Visits: 0,

      exhibitionVisits: 0,
      posterGalleryVisits: 0,
      networkingLoungeVisits: 0,
    };
  }

  componentDidMount () {
    fetch("/api/getAnalyticsTotalUsers")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          totalUsers: data.totalUsers,
        })
      }
      );

    fetch("/api/getAnalyticsAwaitingConfirmation")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          awaitingConfirmation: data.awaitingConfirmation,
        })
      }
      );

    fetch("/api/getAnalyticsAwaitingApproval")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          awaitingApproval: data.awaitingApproval,
        })
      }
      );

    fetch("/api/getAnalyticsApproved")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          approved: data.approved,
        })
      }
      );

    fetch("/api/getAnalyticsRequestedMeetings")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          requestedMeetings: data.requestedMeetings,
        })
      }
      );


    fetch("/api/getAnalyticsDeclinedMeetings")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          declinedMeetings: data.declinedMeetings,
        })
      }
      );


    fetch("/api/getAnalyticsPendingMeetings")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          pendingMeetings: data.pendingMeetings,
        })
      }
      );

    fetch("/api/getAnalyticsConfirmedMeetings")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          confirmedMeetings: data.confirmedMeetings,
        })
      }
      );

    fetch("/api/getAnalyticsTotalQuestions")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          totalQuestions: data.totalQuestions,
        })
      }
      );

    fetch("/api/getAnalyticsUnansweredQuestions")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          unansweredQuestions: data.unansweredQuestions,
        })
      }
      );

    fetch("/api/getAnalyticsAnsweredQuestions")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          answeredQuestions: data.answeredQuestions,
        })
      }
      );

    fetch("/api/getAnalyticsPlenaryVisits")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          plenaryVisits: data.plenaryVisits,
        })
      }
      );

    fetch("/api/getAnalyticsBreakout1Visits")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          breakou1tVisits: data.breakoutVisits,
        })
      }
      );

    fetch("/api/getAnalyticsBreakout2Visits")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          breakout2Visits: data.breakoutVisits,
        })
      }
      );

    fetch("/api/getAnalyticsBreakout3Visits")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          breakout3Visits: data.breakoutVisits,
        })
      }
      );

    fetch("/api/getAnalyticsBreakout4Visits")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          breakout4Visits: data.breakoutVisits,
        })
      }
      );

    fetch("/api/getAnalyticsBreakout5Visits")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          breakout5Visits: data.breakoutVisits,
        })
      }
      );

    fetch("/api/getAnalyticsBreakout6Visits")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          breakout6Visits: data.breakoutVisits,
        })
      }
      );

    fetch("/api/getAnalyticsBreakout7Visits")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          breakout7Visits: data.breakoutVisits,
        })
      }
      );


    fetch("/api/getAnalyticsExhibitionVisits")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          exhibitionVisits: data.exhibitionVisits,
        })
      }
      );

    fetch("/api/getAnalyticsPosterGalleryVisits")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          posterGalleryVisits: data.posterGalleryVisits,
        })
      }
      );


    fetch("/api/getAnalyticsNetworkingLoungeVisits")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          networkingLoungeVisits: data.networkingLoungeVisits,
        })
      }
      );



  }


  render () {
    return (
      <div>
        <div className="imageNavDiv">

          <img
            className="imageNavBackground"
            src={window.$videoPath + "visualAssets/en/analyticsBackground.png"}
            alt="Background"
          ></img>

          <h3 className={`${styles.analyticsItem} ${styles.totalUsers}`}>{this.state.totalUsers}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.awaitingConfirmation}`}>{this.state.awaitingConfirmation}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.awaitingApproval}`}>{this.state.awaitingApproval}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.approved}`}>{this.state.approved}</h3>

          <h3 className={`${styles.analyticsItem} ${styles.requestedMeetings}`}> {this.state.requestedMeetings}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.declinedMeetings}`}>{this.state.declinedMeetings}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.pendingMeetings}`}>{this.state.pendingMeetings}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.confirmedMeetings}`}>{this.state.confirmedMeetings}</h3>

          <h3 className={`${styles.analyticsItem} ${styles.totalQuestions}`}>{this.state.totalQuestions}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.unansweredQuestions}`}>{this.state.unansweredQuestions}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.answeredQuestions}`}>{this.state.answeredQuestions}</h3>

          <h3 className={`${styles.analyticsItem} ${styles.totalVisits}`}>{this.state.plenaryVisits + this.state.breakout1Visits + this.state.breakout2Visits + this.state.breakout3Visits + this.state.breakout4Visits + this.state.breakout5Visits +
            this.state.breakout6Visits + this.state.breakout7Visits + this.state.exhibitionVisits + this.state.posterGalleryVisits + this.state.networkingLoungeVisits}</h3>

          <h3 className={`${styles.analyticsItem} ${styles.plenaryVisits}`}>{this.state.plenaryVisits}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.breakout1Visits}`}>{this.state.breakout1Visits}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.breakout2Visits}`}>{this.state.breakout2Visits}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.breakout3Visits}`}>{this.state.breakout3Visits}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.breakout4Visits}`}>{this.state.breakout4Visits}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.breakout5Visits}`}>{this.state.breakout5Visits}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.breakout6Visits}`}>{this.state.breakout6Visits}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.breakout7Visits}`}>{this.state.breakout7Visits}</h3>

          <h3 className={`${styles.analyticsItem} ${styles.exhibitionVisits}`}>{this.state.exhibitionVisits}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.posterVisits}`}>{this.state.posterGalleryVisits}</h3>
          <h3 className={`${styles.analyticsItem} ${styles.networkingLoungeVisits}`}>{this.state.networkingLoungeVisits}</h3>
        </div>
      </div>
    );
  }
}
