import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Trans, withTranslation } from 'react-i18next';
import dompurify from "dompurify";
import { requestPoints } from "./../helpers/requestPoints.js";
import VideoPlayer from "./../modules/videoPlayer/VideoPlayer"
import OnDemandMobile from "./OnDemandMobile"
import styles from "./OnDemand.module.scss";

export default class OnDemand extends Component {
  constructor() {
    super();
    this.state = {
      onDemandContent: [],
    };
  }

  componentDidMount () {
    requestPoints("other", "visitNonVisualGYECCTV");
    this.loadCurrentDatabaseContent();
  }

  loadCurrentDatabaseContent () {
    fetch("/api/getOnDemandContent")
      .then((res) => res.json())
      .then((data) => this.setState({ onDemandContent: data }));
  }

  onDemandFilteredByDay = (selectedDay) => {
    return this.state.onDemandContent.filter(
      (contentItem) => contentItem.day === selectedDay
    );
  };

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  onDemandGallery = (selectedDay) => {
    return this.onDemandFilteredByDay(selectedDay).map((contentItem) => {
      return (
        <div key={contentItem._id} className={styles.contentItem}>
          <div className={styles.contentVideoArea}>
            {contentItem.videoID ? (
              <div className={styles.onDemandVideoDiv}>
                <VideoPlayer
                  resourceLocator={contentItem.videoID}
                  location={"onDemand"}
                  userProfile={this.props.userProfile}
                />
              </div>
            ) : null}
          </div>
          <div className={styles.contentInfoArea}>
            <h1 className={styles.contentTitle}>{contentItem.contentTitle}</h1>
            <hr className={styles.horizRule} />
            {/**<p className={styles.speakerName}>{contentItem.speakerName}</p>*/}
            <p dangerouslySetInnerHTML={this.sanitizeCustomField(contentItem.aboutText)} className={styles.aboutText}></p>
          </div>
        </div>
      );
    });
  };

  render () {
    return (
      <div>
        {this.props.showMobile ?
          <OnDemandMobile
            onDemandContent={this.state.onDemandContent}
            userProfile={this.props.userProfile}
          />
          :
          <>
            <div className={styles.outerBackgroundDiv}>
              {/**<img
              className="imageNavBackground"
              src={window.$videoPath + "background.png"}
              alt="Background"
            ></img>*/}
            </div>

            <div className={styles.hotlinkBar}>
              <Link to={"/lobby"} className={styles.backLink}>
                <div className={styles.backDiv}>
                  <img
                    className={styles.backArrow}
                    src={window.$videoPath + "icons/backArrow.png"}
                    alt="Back"
                  />
                  <Trans>attendees_back_to_eco_park</Trans>
                </div>
              </Link>
            </div>

            <div className={styles.headerBar}>
              <h1 className={styles.pageTitle}>
                <strong><Trans>nav_gyecc_tv</Trans></strong>
              </h1>

              <div className={styles.searchTitleArea}>
                <p></p>
              </div>
            </div>
            <div className={styles.contentContainerDiv}>
              {this.onDemandGallery(1)}
              {this.onDemandGallery(2).length > 0 ? <h1 className={styles.dayTitle}><Trans>shared_event_rewind</Trans></h1> : null}
              {this.onDemandGallery(2)}
            </div>
          </>
        }
      </div>
    );
  }
}
