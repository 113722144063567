import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    console.log("Checking protected route");
    fetch("/api/checkToken")
      .then((res) => {
        if (res.status === 200) {
          console.log("Status here: " + res.status);
          setLoading(false);
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        //console.error(err);
        setRedirect(true);
        setLoading(false);
      });
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return null;
        }
        if (redirect) {
          return <Redirect to="/login" />;
        }
        return <Component {...rest} {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
