import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import dompurify from "dompurify";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { Image, Video, Transformation } from "cloudinary-react";
import { dateFormatterTimeAndDay } from "../helpers/dateFormatter.js"
import styles from "./Gallery.module.scss";

class Gallery extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      galleryContent: [],
      searchString: "",
      showMeetingRequestModal: false,
      meetingRequesteeUser: null,
      meetingRequesteeUserMeetings: [],
      meetingNote: "",
      meetingSlots: [],
      selectedMeetingSlot: null,
      existingUserMeetings: [],
      visualGallery: false,
    };
    this.handleSlotChange = this.handleSlotChange.bind(this);
    this.requestMeeting = this.requestMeeting.bind(this);
  }

  componentDidMount () {
    // Hide corner menu
    this.props.setCornerMenuStatus(3);

    if (this.props.visualGallery) {
      this.setState({ visualGallery: true })
    }
    this.loadCurrentDatabaseContent();

    // Scroll back to top
    window.scrollTo(0, 0);
  }

  componentWillUnmount () {
    // Set corner menu to displayed again
    this.props.setCornerMenuStatus(1);
  }

  componentDidUpdate () {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 3) {
      console.log(this.props.cornerMenuStatus);
      this.props.setCornerMenuStatus(3);
    }
  }

  loadCurrentDatabaseContent () {
    fetch("/api/getUserGalleryContent")
      .then((res) => res.json())
      .then((data) => this.setState({ galleryContent: data }));
    // Get meeting slots
    fetch("/api/getMeetingSlots")
      .then((res) => res.json())
      .then((data) => this.setState({ meetingSlots: data }));
    // Get taken meeting slots for signed in user
    fetch("/api/getUserMeetings")
      .then((res) => res.json())
      .then((data) => this.setState({ existingUserMeetings: data }));
  }

  getRequesteeAvailability (userID) {
    fetch("/api/getRequesteeUserMeetings?requesteeUserID=" + userID)
      .then((res) => res.json())
      .then((data) => this.setState({ meetingRequesteeUserMeetings: data }));
  }

  handleSlotChange = (value) => {
    // Update database here to check slot is still available
    this.loadCurrentDatabaseContent();
    this.setState({
      selectedMeetingSlot: value,
    });
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleMeetingRequestClose = () => {
    this.loadCurrentDatabaseContent();
    this.setState({ showMeetingRequestModal: false });
  };

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  // Called when user clicks request meeting button on another's profile
  requestMeeting = (user) => {
    this.loadCurrentDatabaseContent();
    this.getRequesteeAvailability(user._id);
    this.setState({
      meetingRequesteeUser: user,
      meetingNote: "",
      showMeetingRequestModal: true,
      selectedMeetingSlot: this.state.meetingSlots[0]._id,
    });
    console.log("Requesting meeting with " + user.firstName);
  };

  handleMeetingRequestSubmit = () => {
    console.log("Submitting meeting request: " + this.state.meetingNote);

    fetch("/api/submitMeetingRequest", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingRequesteeUser: this.state.meetingRequesteeUser,
        meetingNote: this.state.meetingNote,
        selectedMeetingSlot: this.state.selectedMeetingSlot,
      }),
    })
      .then((result) => result.text())
      .then((response) => {
        console.log("Submitted meeting request");
      });

    alert(this.props.t('meetings_submitted'));

    // Close modal
    //this.handleMeetingRequestClose();
  };

  lookupTransVersionOfDelegateType = (delegateType) => {
    switch (delegateType) {
      case "delegate":
        return (this.props.t('shared_delegate'));
      case "exhibitor":
        return (this.props.t('shared_exhibitor_partner'));
      case "speaker":
        return (this.props.t('shared_speaker'));
    }
  }

  checkForLeadingAt = (username) => {
    if (username.substring(0, 1) == "@") {
      return username.substring(1)
    } else {
      return username
    }
  }

  checkForLeadinghttps = (url) => {
    if (url.includes("http")) {
      return url
    } else {
      return ("https://" + url);
    }
  }

  render () {
    const userGalleryFiltered = this.state.galleryContent.filter(
      (user) =>
        user.firstName
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.surname
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.jobTitle
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.country
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.company
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
        user.areasOfInterest
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase())
    );

    const userGallery = userGalleryFiltered.map((user) => {
      return (
        <div
          key={user._id}
          className={styles.userContainer}
          onClick={() => this.requestMeeting(user)}
        >
          <Image
            cloudName="oijqec973f1nf"
            publicId={user.userProfilePictureID}
            width="170"
            height="200"
            secure={true}
          >
            <Transformation
              height="200"
              width="170"
              gravity="face"
              crop="fill"
            />
          </Image>
          <div className={styles.userNameContainer}>
            <p className={styles.userNameText}>
              <strong>{user.firstName} {user.surname}</strong><br />
              <span>{user.company}</span><br />
              <span style={{ color: "#009245" }}>{this.lookupTransVersionOfDelegateType(user.areasOfInterest)}</span>
            </p>
          </div>
          <img
            className={styles.detailButton}
            src={window.$videoPath + "icons/detail.png"}
            alt="Detail"
          />
        </div>
      );
    });

    const meetingSlotOptionsFiltered = this.state.meetingSlots.filter(
      (meetingSlot) => {
        var slotAvailable = true;
        const meetingSlotIDBeingChecked = meetingSlot._id;
        // Check through existing user meetings where user is requestor
        if (this.state.existingUserMeetings.meetingRequestsMade) {
          this.state.existingUserMeetings.meetingRequestsMade.forEach(
            (meetingRequest, index) => {
              if (
                meetingRequest.meetingStatus !== "declined" &&
                meetingRequest.meetingSlotID._id === meetingSlotIDBeingChecked
              ) {
                slotAvailable = false;
              }
            }
          );
        }
        // Check through existing user meetings already booked where user is requestee
        if (this.state.existingUserMeetings.meetingRequestsReceived) {
          this.state.existingUserMeetings.meetingRequestsReceived.forEach(
            (meetingRequest, index) => {
              if (
                meetingRequest.meetingStatus !== "declined" &&
                meetingRequest.meetingSlotID._id === meetingSlotIDBeingChecked
              ) {
                slotAvailable = false;
              }
            }
          );
        }
        // Check requestee availability
        // Check through requestee meetings where requestee is requestor
        if (this.state.meetingRequesteeUserMeetings.meetingRequestsMade) {
          this.state.meetingRequesteeUserMeetings.meetingRequestsMade.forEach(
            (meetingRequest, index) => {
              if (
                meetingRequest.meetingStatus !== "declined" &&
                meetingRequest.meetingSlotID._id === meetingSlotIDBeingChecked
              ) {
                slotAvailable = false;
              }
            }
          );
        }
        // Check through requestee meetings already booked where requetee is requestee
        if (this.state.meetingRequesteeUserMeetings.meetingRequestsReceived) {
          this.state.meetingRequesteeUserMeetings.meetingRequestsReceived.forEach(
            (meetingRequest, index) => {
              if (
                meetingRequest.meetingStatus !== "declined" &&
                meetingRequest.meetingSlotID._id === meetingSlotIDBeingChecked
              ) {
                slotAvailable = false;
              }
            }
          );
        }
        return slotAvailable;
      }
    );

    const meetingSlotOptions = meetingSlotOptionsFiltered.map((meetingSlot) => {
      return (
        <ToggleButton
          variant="primary"
          className={styles.meetingSlotsButton}
          value={meetingSlot._id}
        >
          {dateFormatterTimeAndDay(meetingSlot.meetingSlotTimestamp, this.props.userProfile)}
        </ToggleButton>
      );
    });

    return (
      <div>
        <Modal
          isOpen={this.state.showMeetingRequestModal}
          ariaHideApp={false}
          onRequestClose={this.handleMeetingRequestModal}
          contentLabel="Attendee Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
        >
          {this.state.meetingRequesteeUser ?
            <div className={styles.modalFlexboxColumn}>
              <h1 className={styles.titleArea}>
                {this.state.meetingRequesteeUser &&
                  this.state.meetingRequesteeUser.firstName}{" "}
                {this.state.meetingRequesteeUser &&
                  this.state.meetingRequesteeUser.surname}
                <Button
                  className={styles.modalClose}
                  variant="success"
                  onClick={this.handleMeetingRequestClose}
                >
                  <Trans>shared_close</Trans>
                </Button>
              </h1>
              <hr />
              <div className={styles.scrollableArea}>
                <div className={styles.columnContainer}>
                  <div className={styles.leftProfileColumn}>
                    {this.state.meetingRequesteeUser &&
                      this.state.meetingRequesteeUser.userProfilePictureID ? (
                      <div>
                        <Image
                          cloudName="oijqec973f1nf"
                          publicId={
                            this.state.meetingRequesteeUser.userProfilePictureID
                          }
                          width="170"
                          height="200"
                          secure={true}
                        >
                          <Transformation
                            height="200"
                            width="170"
                            gravity="face"
                            crop="fill"
                          />
                        </Image>
                      </div>
                    ) : null}

                    <br />

                    {this.state.meetingRequesteeUser.country &&
                      this.state.meetingRequesteeUser.country.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/office_location.png"}
                          alt="Location"
                        />
                        <p className={styles.profileParameterTitles}><Trans>attendees_country</Trans>:</p>
                        <p className={styles.profileParameterAnswer}>
                          {this.state.meetingRequesteeUser.country}
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.company &&
                      this.state.meetingRequesteeUser.company.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/expertise.png"}
                          alt="Organisation"
                        />
                        <p className={styles.profileParameterTitles}><Trans>presenters_organisation</Trans>:</p>
                        <p className={styles.profileParameterAnswer}>
                          {this.state.meetingRequesteeUser.company}
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.areasOfInterest &&
                      this.state.meetingRequesteeUser.areasOfInterest.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/job_title_icon.png"}
                          alt="Atendee type"
                        />
                        <p className={styles.profileParameterTitles}><Trans>shared_attendee_type</Trans>:</p>
                        <p className={styles.profileParameterAnswer}>
                          {this.lookupTransVersionOfDelegateType(this.state.meetingRequesteeUser.areasOfInterest)}
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.instagram &&
                      this.state.meetingRequesteeUser.instagram.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/socialMediaIcons/instagram.png"}
                          alt="Instagram"
                        />
                        <p className={styles.profileParameterTitles}>Instagram:</p>
                        <p className={styles.profileParameterAnswer}>
                          <a href={"https://www.instagram.com/" + this.checkForLeadingAt(this.state.meetingRequesteeUser.instagram)} target="_blank" rel="noopener noreferrer">
                            {this.state.meetingRequesteeUser.instagram}
                          </a>
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.linkedIn &&
                      this.state.meetingRequesteeUser.linkedIn.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/socialMediaIcons/linkedin.png"}
                          alt="LinkedIn"
                        />
                        <p className={styles.profileParameterTitles}>LinkedIn:</p>
                        <p className={styles.profileParameterAnswer}>
                          <a href={this.checkForLeadinghttps(this.state.meetingRequesteeUser.linkedIn)} target="_blank" rel="noopener noreferrer">
                            {this.state.meetingRequesteeUser.linkedIn}
                          </a>
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.facebook &&
                      this.state.meetingRequesteeUser.facebook.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/socialMediaIcons/facebook.png"}
                          alt="Facebook"
                        />
                        <p className={styles.profileParameterTitles}>Facebook:</p>
                        <p className={styles.profileParameterAnswer}>
                          <a href={this.checkForLeadinghttps(this.state.meetingRequesteeUser.facebook)} target="_blank" rel="noopener noreferrer">
                            {this.state.meetingRequesteeUser.facebook}
                          </a>
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.twitter &&
                      this.state.meetingRequesteeUser.twitter.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/socialMediaIcons/twitter.png"}
                          alt="Twitter"
                        />
                        <p className={styles.profileParameterTitles}>Twitter:</p>
                        <p className={styles.profileParameterAnswer}>
                          <a href={"https://twitter.com/" + this.checkForLeadingAt(this.state.meetingRequesteeUser.twitter)} target="_blank" rel="noopener noreferrer">
                            {this.state.meetingRequesteeUser.twitter}
                          </a>
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.tiktok &&
                      this.state.meetingRequesteeUser.tiktok.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/socialMediaIcons/tiktok.png"}
                          alt="Twitter"
                        />
                        <p className={styles.profileParameterTitles}>TikTok:</p>
                        <p className={styles.profileParameterAnswer}>
                          <a href={"https://www.tiktok.com/@" + this.checkForLeadingAt(this.state.meetingRequesteeUser.tiktok)} target="_blank" rel="noopener noreferrer">
                            {this.state.meetingRequesteeUser.tiktok}
                          </a>
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.weibo &&
                      this.state.meetingRequesteeUser.weibo.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/socialMediaIcons/Weibo.png"}
                          alt="Weibo"
                        />
                        <p className={styles.profileParameterTitles}>Weibo:</p>
                        <p className={styles.profileParameterAnswer}>
                          <a href={this.checkForLeadinghttps(this.state.meetingRequesteeUser.weibo)} target="_blank" rel="noopener noreferrer">
                            {this.state.meetingRequesteeUser.weibo}
                          </a>
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.snapchat &&
                      this.state.meetingRequesteeUser.snapchat.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/socialMediaIcons/snapchat.png"}
                          alt="Snapchat"
                        />
                        <p className={styles.profileParameterTitles}>Snapchat:</p>
                        <p className={styles.profileParameterAnswer}>
                          <a href={this.checkForLeadinghttps(this.state.meetingRequesteeUser.snapchat)} target="_blank" rel="noopener noreferrer">
                            {this.state.meetingRequesteeUser.snapchat}
                          </a>
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.meetingRequesteeUser.qq &&
                      this.state.meetingRequesteeUser.qq.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/socialMediaIcons/qq.png"}
                          alt="Snapchat"
                        />
                        <p className={styles.profileParameterTitles}>QQ:</p>
                        <p className={styles.profileParameterAnswer}>
                          <a href={this.checkForLeadinghttps(this.state.meetingRequesteeUser.qq)} target="_blank" rel="noopener noreferrer">
                            {this.state.meetingRequesteeUser.qq}
                          </a>
                          <br />
                        </p>
                      </span>
                    ) : null}


                    {this.state.meetingRequesteeUser.qzone &&
                      this.state.meetingRequesteeUser.qzone.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/socialMediaIcons/qzone-logo.png"}
                          alt="Snapchat"
                        />
                        <p className={styles.profileParameterTitles}>Qzone:</p>
                        <p className={styles.profileParameterAnswer}>
                          <a href={this.checkForLeadinghttps(this.state.meetingRequesteeUser.qzone)} target="_blank" rel="noopener noreferrer">
                            {this.state.meetingRequesteeUser.qzone}
                          </a>
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {/**this.state.meetingRequesteeUser.areasOfInterest.length > 1 ? (
                  <span>
                    <img
                      className={styles.profileIcon}
                      src={window.$videoPath + "icons/outside.png"}
                      alt="Expertise"
                    />
                    <p className={styles.profileParameterTitles}>
                      Areas of Interest:
                    </p>
                    <p className={styles.profileParameterAnswer}>
                      {this.state.meetingRequesteeUser.areasOfInterest}
                      <br />
                    </p>
                  </span>
                ) : null*/}
                  </div>
                  <div className={styles.rightProfileColumn}>
                    {this.state.meetingRequesteeUser && this.state.meetingRequesteeUser.areasOfInterest && this.state.meetingRequesteeUser.areasOfInterest.length > 0 && false ?
                      <>
                        <p className={styles.profileParameterTitles}>
                          <Trans>shared_about</Trans>{" "}
                          {this.state.meetingRequesteeUser &&
                            this.state.meetingRequesteeUser.firstName}{" "}
                          {this.state.meetingRequesteeUser &&
                            this.state.meetingRequesteeUser.surname}
                        </p>
                        <p className={styles.noIconProfileParameterAnswer}>
                          {this.state.meetingRequesteeUser.areasOfInterest}
                        </p>
                      </>
                      : null
                    }

                    {this.state.meetingRequesteeUser ? (
                      this.state.meetingRequesteeUser.userProfileVideoApproved &&
                        this.state.meetingRequesteeUser.userProfileVideoID ? (
                        <div>
                          <h3>Watch my video</h3>
                          <Video
                            controls={true}
                            cloudName="oijqec973f1nf"
                            publicId={
                              this.state.meetingRequesteeUser.userProfileVideoID
                            }
                            width="400"
                          ></Video>
                        </div>
                      ) : null
                    ) : null}
                    <br />
                    {(this.props.userProfile && this.props.userProfile.userAgeBracket > 1 && this.state.meetingRequesteeUser.userAgeBracket > 1) ?
                      <span>
                        <h4>
                          <Trans>meetings_request_meeting</Trans>{" "}
                          {this.state.meetingRequesteeUser &&
                            this.state.meetingRequesteeUser.firstName}{" "}
                          {this.state.meetingRequesteeUser &&
                            this.state.meetingRequesteeUser.surname}
                        </h4>
                        <br />
                        <div className={styles.meetingBookingDiv}>
                          <Trans>meetings_meetings_info_text</Trans>
                          <br />
                          <ToggleButtonGroup
                            type="radio"
                            value={this.state.selectedMeetingSlot}
                            onChange={this.handleSlotChange}
                            name="selectedMeetingSlot"
                            className={styles.meetingSlotsList}
                          >
                            {meetingSlotOptions}
                          </ToggleButtonGroup>
                          <br />
                          <br />
                          <Button variant="secondary"
                            onClick={this.handleMeetingRequestSubmit}>
                            <Trans>shared_submit</Trans>
                          </Button>
                        </div>
                      </span> : null}
                  </div>
                </div>
                <br />
                <br />
              </div>
            </div>
            : null}
        </Modal>

        {this.state.visualGallery ?
          <React.Fragment>
            <div className="imageNavDiv" >
              <img
                className="imageNavBackground"
                src={window.$videoPath + "demoVenues/warehouse/altNetworking4Gallery.jpg"}
                alt="Background"
              ></img>

              <div className={styles.searchTitleAreaVisual}>
                <p>
                  <Trans>attendees_info_text</Trans>
                </p>
                <input
                  className={styles.searchBox}
                  type="text"
                  name="searchString"
                  placeholder={this.props.t('shared_search')}
                  value={this.state.searchString}
                  onChange={this.handleChange}
                />
              </div>

              <section className={styles.profileSegmentsAreaVisual}>{userGallery}</section>
            </div>
          </React.Fragment>
          :
          <React.Fragment>
            <div className={styles.outerBackgroundDiv}>
              {/**<img
            className="imageNavBackground"
            src={window.$videoPath + "background.png"}
            alt="Background"
          ></img>*/}
            </div>

            <div className={styles.hotlinkBar}>
              <Link to={"/lobby"} className={styles.backLink}>
                <div className={styles.backDiv}>
                  <img
                    className={styles.backArrow}
                    src={window.$videoPath + "icons/backArrow.png"}
                    alt="Back"
                  />
                  <Trans>attendees_back_to_eco_park</Trans>
                </div>
              </Link>
              <Link to={"/networking"} className={styles.forwardLink}>
                <div className={styles.forwardDiv}>
                  <img
                    className={styles.forwardArrow}
                    src={window.$videoPath + "icons/forwardArrow.png"}
                    alt="Back"
                  />
                  <Trans>shared_networking_lounge_short</Trans>
                </div>
              </Link>
            </div>

            <div className={styles.headerBar}>
              <h1 className={styles.pageTitle}>
                <strong><Trans>nav_attendees</Trans></strong>
              </h1>

              <div className={styles.searchTitleArea}>
                <p><Trans>attendees_info_text</Trans>
                </p>

              </div>
              <input
                className={styles.searchBox}
                type="text"
                name="searchString"
                placeholder={this.props.t('shared_search')}
                value={this.state.searchString}
                onChange={this.handleChange}
              />
            </div>

            <section className={styles.profileSegmentsArea}>{userGallery}</section>
          </React.Fragment>}
      </div>
    );
  }
}

export default withTranslation()(Gallery);