import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Trans, withTranslation } from 'react-i18next';
import VideoPlayer from "./../modules/videoPlayer/VideoPlayer"
import { CSSTransition } from "react-transition-group";
import SlidoEmbed from "../modules/slidoEmbed/SlidoEmbed";
import styles from "./PlenaryMobile.module.scss";

export default class Breakout extends Component {

  render () {
    return (
      <div className={styles.mobileListView} >
        <div className={styles.mobileTitleArea}>
          <h1 className={styles.mobileTitleText}><Trans>shared_plenary</Trans></h1>
          <Link to="/lobby"><Button><Trans>shared_back</Trans></Button></Link>
        </div>

        <CSSTransition
          in={this.props.videoContent.length > 0}
          timeout={1500}
          classNames="imageNavBackground"
          unmountOnExit
        >
          <div className={styles.iFrameAspectDiv}>
            <div className={styles.iFrameDiv}>
              <VideoPlayer
                resourceLocator={this.props.videoContent}
                location={"plenary"}
                userProfile={this.props.userProfile}
                autoPlay={true}
              />
            </div>
          </div>
        </CSSTransition>


        <CSSTransition
          in={this.props.slidoEventID.length > 0}
          timeout={1500}
          classNames="imageNavBackground"
          unmountOnExit
        >
          <div className={styles.slidoDiv}>
            <SlidoEmbed
              slidoEventID={this.props.slidoEventID}
              userProfile={this.props.userProfile}
            />
          </div>
        </CSSTransition>

        <CSSTransition
          in={this.props.interprefyEventID.length > 0}
          timeout={1500}
          classNames="imageNavBackground"
          unmountOnExit
        >
          <div className={styles.iFrameDiv}>
            <iframe
              title="Interprefy Iframe"
              src={"https://interprefy.interpret.world/loginlink?token=" + this.props.interprefyEventID}
              height="100%"
              width="100%"
              style={{ minHeight: "560px" }}
            ></iframe>
          </div>
        </CSSTransition>

      </div>
    );
  }
}
