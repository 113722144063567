import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import dompurify from "dompurify";
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import VideoPlayer from "./../modules/videoPlayer/VideoPlayer"
import { Image, Transformation } from "cloudinary-react";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./OnDemandModal.module.scss";

class OnDemandModal extends Component {

  onDemandVideoPlayed = () => {
    requestPoints("viewOnDemandVideo", this.props.selectedOnDemand._id);
  };

  onDemandPDFViewed = (pdfURL) => {
    requestPoints("viewOnDemandPDF", pdfURL);
  };

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  upvotePoster = () => {
    console.log("Liking video");
    // Submit action for points
    fetch("/api/likeOnDemand", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        contentID: this.props.selectedOnDemand._id,
      }),
    })
      .then((result) => result.text())
      .then((response) => {
        console.log("Submitted poster upvote");
      });
    alert(this.props.t('on_demand_like_video'));
  };

  render () {
    return (
      <Modal
        isOpen={this.props.showOnDemandModal}
        ariaHideApp={false}
        onRequestClose={this.props.closeOnDemandModal}
        contentLabel="OnDemand Modal"
        overlayClassName={styles.modalOverlay}
        className={styles.modalContent}
      >
        <div className={styles.modalFlexboxColumn}>
          <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
            <h1 className={styles.modalPageTitle}>
              {this.props.selectedOnDemand && this.props.selectedOnDemand ? this.props.selectedOnDemand.contentTitle : "OnDemand Content"}
            </h1>
            <Button
              className={styles.modalClose}
              variant="success"
              onClick={this.props.closeOnDemandModal}
            >
              <Trans>shared_close</Trans>
            </Button>

          </div>

          <div className={styles.scrollableArea}>
            <div className={styles.columnContainer}>
              <div className={styles.formColumn}>
                {this.props.selectedOnDemand != null ? (

                  (this.props.selectedOnDemand.pdfURL ?
                    (
                      this.props.selectedOnDemand.pdfThumbnailID ?
                        <a href={this.props.selectedOnDemand.pdfURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => this.onDemandPDFViewed(this.props.selectedOnDemand.pdfURL)}
                        >
                          <Image
                            cloudName="oijqec973f1nf"
                            publicId={this.props.selectedOnDemand.pdfThumbnailID}
                            width="450"
                            height="250"
                            secure={true}
                          >
                            <Transformation
                              height="250"
                              width="450"
                              crop="pad"
                            />
                          </Image> </a> :
                        <a href={this.props.selectedOnDemand.pdfURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => this.onDemandPDFViewed(this.props.selectedOnDemand.pdfURL)}
                        >
                          <img src={window.$videoPath + "icons/webLink.png"} className={styles.pdfIcon} alt="Web Link Icon" />
                        </a>
                    )
                    : (this.props.selectedOnDemand.videoID ? (
                      <div className={styles.onDemandVideoAspectDiv}>
                        <div className={styles.onDemandVideoDiv}>
                          <VideoPlayer
                            resourceLocator={this.props.selectedOnDemand.videoID}
                            location={"onDemand"}
                            userProfile={this.props.userProfile}
                          />
                        </div>
                      </div>
                    ) : null
                    ))
                ) : null}
                {this.props.selectedOnDemand != null && this.props.selectedOnDemand.day == 1 ? <h2 className={styles.onDemandPresenterNameText}>
                  <br />To view subtitles in English, Spanish, French or Mandarin, please click on the three dots within the media player and choose a language from the captions dropdown.
                </h2> : null}
              </div>
              {
                this.props.selectedOnDemand ? <div className={styles.formColumn}>
                  <h2 className={styles.onDemandNameText}>
                    {this.props.selectedOnDemand && this.props.selectedOnDemand.contentTitle}
                  </h2>
                  <h2 className={styles.onDemandPresenterNameText}>
                    {this.props.selectedOnDemand && this.props.selectedOnDemand.speakerName ?
                      <div>{/**Presenter: */}<span dangerouslySetInnerHTML={this.sanitizeCustomField(this.props.selectedOnDemand.speakerName)}></span></div> : null}
                  </h2>
                  <div className={styles.aboutTextDiv}>
                    <h2 className={styles.aboutText}
                      dangerouslySetInnerHTML={this.sanitizeCustomField(
                        this.props.selectedOnDemand && this.props.selectedOnDemand.aboutText
                      )}>
                    </h2>
                    <img
                      src={window.$videoPath + "icons/likeVideo.png"}
                      className={styles.likeButton}
                      onClick={this.upvotePoster}
                    />
                  </div>
                </div> : null
              }
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

export default withTranslation()(OnDemandModal);