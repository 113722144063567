import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./RoundtablesMobile.module.scss";

export default class PostersMobile extends Component {

  componentDidMount () {
    requestPoints("visitRoundtablesMobile", "visitRoundtablesMobile");
  }

  render () {
    const roundtablesList = this.props.roundtables.map(
      (roundtable) => {
        return (
          <Link
            to={"/roundtableSession/" + roundtable._id}
          >
            <div
              key={roundtable._id}
              className={styles.mobileListItem}
            >
              <h3 className={styles.mobileListTitle}>{roundtable.tableName}</h3>
            </div>
          </Link>
        );
      }
    );

    return (
      <div className={styles.mobileListView} >
        <div className={styles.mobileTitleArea}>
          <h1 className={styles.mobileTitleText}><Trans>shared_roundtables</Trans></h1>
          <Link to="/lobby"><Button><Trans>shared_back</Trans></Button></Link>
        </div>
        <h3 className={styles.infoText}><Trans>networking_tables_info_text</Trans></h3>
        {roundtablesList}
      </div>
    );
  }
}
