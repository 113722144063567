import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import styles from "./../UserSessions.module.scss";

class UserSessionsOptionsBreakouts2 extends Component {


    render () {
        return (
            <div>
                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={11}
                        disabled={this.props.session1Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>Hitachi</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        Digital and data
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={12}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>Hitachi</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        Energy and renewables
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={13}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>EY</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        Energy transition
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={14}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>Mott MacDonald</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        Energy-transport nexus
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={15}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>IET</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        Meet the Engineers'
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={16}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>NG renewables</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        Renewable energy development
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={17}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>Oonagh OGrady, SSE Thermal’s Head of Hydrogen Development</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        Role of hydrogen in the journey to net zero
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={18}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>EPRI</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        Sustainable energy transition
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={19}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>GO15 gathers the world’s largest power grids operators representing more than half of the world’s electricity demand since 2004, GO15 is dedicated to high level exchanges.<br />It offers industry leaders the opportunity to address major strategic and technical issues affecting the safety, the reliability, and the sustainability of the power system.<br />Convinced that transmission grid is key to energy transition, GO15 aims to favour bilateral and multilateral international collaboration to prepare the future.</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        The importance of power grids on the path to decarbonation
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={20}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>BEIS</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        The nine priorities to a net zero built environment
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={21}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>Burns & Mcdonnell</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        The role of the infrastructure designer in addressing climate challenges
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={22}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>National Grid</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        Get Connected: Open topic
                    </ToggleButton>
                </ToggleButtonGroup>



                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={23}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>National Grid</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        Get Connected: Open topic
                    </ToggleButton>
                </ToggleButtonGroup>


                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={24}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>National Grid</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        Get Connected: Open topic
                    </ToggleButton>
                </ToggleButtonGroup>


                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={25}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>National Grid</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        Get Connected: Open topic
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={0}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>" + this.props.t('agenda_visit_attendee_gallery') + "</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        <Trans>agenda_explore_network</Trans>
                    </ToggleButton>
                </ToggleButtonGroup>


            </div >
        );
    }
}

export default withTranslation()(UserSessionsOptionsBreakouts2);
