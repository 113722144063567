import React, { Component } from "react";
import styles from "./PreLoginLanguageSelection.module.scss";


export default class PreLoginLanguageSelection extends Component {

  componentDidMount () {
    const { cookies } = this.props;
    const languageSetCookie = cookies.get("languageSet");
    if (languageSetCookie !== undefined) {
      this.props.setLanguage(languageSetCookie);
    }
  }

  selectLanguage = (selectedLanguage) => {
    this.props.setLanguage(selectedLanguage);
    const { cookies } = this.props;
    cookies.set("languageSet", selectedLanguage, { path: "/" });
  }

  render () {
    return (
      <div>
        <img
          onClick={() => this.selectLanguage("en")}
          className={styles.languageFlag}
          src={window.$videoPath + "icons/languageFlags/en.png"}
          alt="English"
        />
        <img
          onClick={() => this.selectLanguage("es")}
          className={styles.languageFlag}
          src={window.$videoPath + "icons/languageFlags/es.png"}
          alt="Spanish"
        />
        <img
          onClick={() => this.selectLanguage("fr")}
          className={styles.languageFlag}
          src={window.$videoPath + "icons/languageFlags/fr.png"}
          alt="French"
        />
        <img
          onClick={() => this.selectLanguage("zh")}
          className={styles.languageFlag}
          src={window.$videoPath + "icons/languageFlags/zh.png"}
          alt="Mandarin"
        />
      </div>
    );
  }
}
