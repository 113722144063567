import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import ReactTooltip from 'react-tooltip';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import BreakoutsMobile from "./BreakoutsMobile"
import styles from "./Breakouts.module.scss";
import { requestPoints } from "../helpers/requestPoints";

function Breakouts (props) {
  const [introVideoComplete, setIntroVideoComplete] = useState(false);

  useEffect(() => {
    requestPoints("other", "visitBreakoutLobby")
    // Start timeout in case video does not load
    setTimeout(function () {
      setIntroVideoComplete(true);
    }, 8000);

    // Set intro video complete immediately if not playing video so that we can access hovers
    if (!props.playVideo) {
      setIntroVideoComplete(true);
    }

  }, []);

  return (
    <div>
      {props.showMobile ?
        <BreakoutsMobile />
        :
        <div className="imageNavDiv">
          {props.playVideo ? (
            <div>
              <video
                className="imageNavBackground"
                autoPlay
                id="myVideo"
                webkit-playsinline="true"
                playsInline
                muted
                onEnded={() => setIntroVideoComplete(true)}
              >
                <source
                  src={window.$videoPath + "visuals/" + props.language + "/breakouts.webm"}
                  type="video/webm"
                />
                <source
                  src={window.$videoPath + "visuals/" + props.language + "/breakouts.mp4"}
                  type="video/mp4"
                />
              </video>
              <CSSTransition
                in={introVideoComplete}
                timeout={1500}
                classNames="imageNavBackground"
                unmountOnExit
              >
                <img
                  className="imageNavBackground"
                  src={window.$videoPath + "visuals/" + props.language + "/breakouts.jpg"}
                  alt="Background"
                ></img>
              </CSSTransition>
            </div>
          ) : (
            <img
              className="imageNavBackground"
              src={window.$videoPath + "visuals/" + props.language + "/breakouts.jpg"}
              alt="Background"
            ></img>
          )}

          {/**<Link to="/lobby">
            <img
              className={`${styles.hoverHotspot} ${styles.lobbyHoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="Navigate to Lobby"
            ></img>
          </Link>*/}


          {introVideoComplete ?
            <>
              <ReactTooltip className={styles.toolTip} style={{ zIndex: 5000000000 }} html={true} id={"breakoutInfo"} border={true} place={"bottom"} effect={"float"} />
              <Link to="/breakout/6086abd759b00d4eefcc69b1">
                <img
                  className={`${styles.hoverHotspot} ${styles.smallBreakout1HoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Navigate to small breakout 1"
                  data-tip={"<p><strong>" + props.t('agenda_workstream_one_title') + "</strong><br />" + props.t('agenda_workstream_one_info') + "</p>"}
                  data-for={"breakoutInfo"}
                ></img>
              </Link>

              <Link to="/breakout/6086abd759b00d4eefcc69b2">
                <img
                  className={`${styles.hoverHotspot} ${styles.smallBreakout2HoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Navigate to small breakout 2"
                  data-tip={"<p><strong>" + props.t('agenda_workstream_two_title') + "</strong><br />" + props.t('agenda_workstream_two_info') + "</p>"}
                  data-for={"breakoutInfo"}
                ></img>
              </Link>

              <Link to="/breakout/6086abd759b00d4eefcc69b3">
                <img
                  className={`${styles.hoverHotspot} ${styles.smallBreakout3HoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Navigate to small breakout 3"
                  data-tip={"<p><strong>" + props.t('agenda_workstream_three_title') + "</strong><br />" + props.t('agenda_workstream_three_info') + "</p>"}
                  data-for={"breakoutInfo"}
                ></img>
              </Link>

              <Link to="/breakout/6086abd759b00d4eefcc69b4">
                <img
                  className={`${styles.hoverHotspot} ${styles.smallBreakout4HoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Navigate to small breakout 4"
                  data-tip={"<p><strong>" + props.t('agenda_workstream_four_title') + "</strong><br />" + props.t('agenda_workstream_four_info') + "</p>"}
                  data-for={"breakoutInfo"}
                ></img>
              </Link>

              <Link to="/lobby">
                <img
                  className={`${styles.hoverHotspot} ${styles.lobbyHoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Navigate lobby"
                ></img>
              </Link>
            </> : null}
        </div>
      }
    </div>
  );
}


export default withTranslation()(Breakouts);
