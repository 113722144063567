import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import { CSSTransition, TransitionGroup } from "react-transition-group";

// Pre-login
import Login from "./registrationAndLogin/Login";
import ConfirmUserEmail from "./registrationAndLogin/ConfirmUserEmail";
import RequestPasswordReset from "./registrationAndLogin/RequestPasswordReset";
import ResetPassword from "./registrationAndLogin/ResetPassword";
import Logout from "./Logout";

// Standard spaces
import Home from "./Home";
import Lobby from "./imageNav/Lobby";
import Plenary from "./imageNav/Plenary";
import Posters from "./posters/Posters";
import Networking from "./imageNav/Networking";
import Breakouts from "./breakouts/Breakouts";
import Breakout from "./breakouts/Breakout";

// Gallery spaces
import Gallery from "./gallery/Gallery";
import PresentersGallery from "./gallery/PresentersGallery";
import OnDemand from "./gallery/OnDemand";
import VisualOnDemand from "./gallery/VisualOnDemand";
import VisualEventRewind from "./gallery/VisualEventRewind";
import JobSearch from "./gallery/JobSearch";

// 1:1 Meetings
import Meetings from "./meetings/Meetings";
import Video from "./video/Video";

// Exhibition
import ExhibitionIntro from "./exhibition/ExhibitionIntro";
import Exhibition from "./exhibition/Exhibition";
import ExhibitionHall from "./exhibition/ExhibitionHall";
import ExhibitionStand from "./exhibition/ExhibitionStand";
import ExhibitionStandAnswers from "./questions/ExhibitionStandAnswers";
import ExhibitionSearch from "./exhibition/ExhibitionSearch";

// Roundtables
import Roundtables from "./roundtables/Roundtables";
import VisualRoundtables from "./roundtables/VisualRoundtables";
import RoundtableSession from "./roundtables/RoundtableSession";


// Exhibition admin
import ExhibitionAdminList from "./exhibitionAdmin/ExhibitionAdminList";
import EditExhibitionStand from "./exhibitionAdmin/EditExhibitionStand";

// Live Stream admin
import LiveStreamAdminList from "./muxAdmin/liveStream/LiveStreamAdminList";
import ViewLiveStream from "./muxAdmin/liveStream/ViewLiveStream";
import CreateLiveStream from "./muxAdmin/liveStream/CreateLiveStream";

// Mux Video Admin
import VideoAdminList from "./muxAdmin/videoLibrary/VideoAdminList";
import ViewVideo from "./muxAdmin/videoLibrary/ViewVideo";
import CreateVideo from "./muxAdmin/videoLibrary/CreateVideo";

// Analytics
import Analytics from "./analytics/Analytics";

// Admin Menu
import AdminMenu from "./admin/AdminMenu";
import UserList from "./userManagement/UserList";
import RemoteControl from "./remoteControl/RemoteControl";

// Access control
import ProtectedRoute from "./ProtectedRoute";
import AdminRoute from "./AdminRoute";


class Routes extends React.Component {
  render () {
    const { location } = this.props;
    return (
      <main>
        <TransitionGroup className="transition-group">
          <CSSTransition
            key={location.key}
            timeout={{ enter: 1000, exit: 1000 }}
            classNames="fade"
          >
            <section className="route-section">
              <Switch location={location}>

                {/**Login and Registration Routes - not protected*/}
                <Route path="/" exact component={Home} />

                <Route
                  path="/login"
                  render={(props) => (
                    <Login
                      {...props}
                      cookies={this.props.cookies}
                      checkLoginStatus={this.props.checkLoginStatus}
                      setLanguage={this.props.setLanguage}
                      language={this.props.language}
                    />
                  )}
                />
                {/**<Route
                  path="/ssoLogin"
                  render={(props) => (
                    <SSOLogin
                      {...props}
                      checkLoginStatus={this.props.checkLoginStatus}
                    />
                  )}
                />*/}
                <Route
                  path="/confirmUserEmail"
                  render={(props) => (
                    <ConfirmUserEmail
                      {...props}
                      cookies={this.props.cookies}
                      setLanguage={this.props.setLanguage}
                    />
                  )}
                />
                <Route
                  path="/requestPasswordReset"
                  component={RequestPasswordReset}
                />
                <Route
                  path="/resetPassword"
                  render={(props) => (
                    <ResetPassword
                      {...props}
                      cookies={this.props.cookies}
                      setLanguage={this.props.setLanguage}
                    />
                  )}
                />
                <Route
                  path="/logout"
                  render={(props) => (
                    <Logout
                      {...props}
                      checkLoginStatus={this.props.checkLoginStatus}
                      setShowProfileModal={this.props.setShowProfileModal}
                    />
                  )}
                />

                {/**Image Navigation Area Routes*/}
                <ProtectedRoute
                  path="/lobby"
                  component={Lobby}
                  cookies={this.props.cookies}
                  setShowHelpModal={this.props.setShowHelpModal}
                  setShowAgendaModal={this.props.setShowAgendaModal}
                  setShowProfileModal={this.props.setShowProfileModal}
                  lobbyScreen={this.props.lobbyScreen}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                  userProfile={this.props.userProfile}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  setShowLeaderboardModal={this.props.setShowLeaderboardModal}
                  setShowSessionSelectionModal={this.props.setShowSessionSelectionModal}
                />

                <ProtectedRoute
                  path="/plenary"
                  component={Plenary}
                  plenaryContent={this.props.plenaryContent}
                  plenaryPosition={this.props.plenaryPosition}
                  plenaryOpenStatus={this.props.plenaryOpenStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  language={this.props.language}
                  userProfile={this.props.userProfile}
                  showMobile={this.props.showMobile}
                />

                <ProtectedRoute
                  path="/posters"
                  component={Posters}
                  setShowHelpModal={this.props.setShowHelpModal}
                  language={this.props.language}
                  userProfile={this.props.userProfile}
                  showMobile={this.props.showMobile}
                />

                <ProtectedRoute
                  path="/networking"
                  component={Networking}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                />

                <ProtectedRoute
                  path="/networkingFromTables"
                  component={Networking}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                  introType={"fromTables"}
                />

                <ProtectedRoute
                  path="/breakouts"
                  component={Breakouts}
                  playVideo={true}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                />

                <ProtectedRoute
                  path="/breakout/:breakoutID"
                  component={Breakout}
                  breakout1Live={this.props.breakout1Live}
                  breakout2Live={this.props.breakout2Live}
                  breakout3Live={this.props.breakout3Live}
                  breakout4Live={this.props.breakout4Live}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  userProfile={this.props.userProfile}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                />

                <ProtectedRoute
                  path="/breakoutsStill"
                  component={Breakouts}
                  playVideo={false}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                />

                {/** Galleries*/}
                <ProtectedRoute
                  path="/gallery"
                  component={Gallery}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  userProfile={this.props.userProfile}
                />

                <ProtectedRoute path="/visualGallery"
                  component={Gallery}
                  visualGallery={true}
                  userProfile={this.props.userProfile}
                />

                <ProtectedRoute
                  path="/presentersGallery"
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  userProfile={this.props.userProfile}
                  component={PresentersGallery}
                />
                <ProtectedRoute path="/ondemand"
                  component={OnDemand}
                  userProfile={this.props.userProfile}
                  showMobile={this.props.showMobile}
                />

                <ProtectedRoute path="/visualOndemand"
                  component={VisualOnDemand}
                  userProfile={this.props.userProfile}
                  language={this.props.language}
                  introType={"entry"}
                />

                <ProtectedRoute path="/visualOndemandFromEventRewind"
                  component={VisualOnDemand}
                  userProfile={this.props.userProfile}
                  language={this.props.language}
                  introType={"fromRewind"}
                />

                <ProtectedRoute path="/visualOndemandFromOnDemand"
                  component={VisualOnDemand}
                  userProfile={this.props.userProfile}
                  language={this.props.language}
                  introType={"fromOnDemand"}
                />

                <ProtectedRoute path="/visualEventRewind"
                  component={VisualEventRewind}
                  userProfile={this.props.userProfile}
                  language={this.props.language}
                />

                <ProtectedRoute
                  path="/jobSearch"
                  component={JobSearch}
                  showMobile={this.props.showMobile}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                />

                {/** 1:1 Meetings */}
                <ProtectedRoute
                  path="/meetings"
                  component={Meetings}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  userProfile={this.props.userProfile}
                  showMobile={this.props.showMobile}
                />
                <ProtectedRoute
                  path="/video/:meetingID"
                  component={Video}
                  userProfile={this.props.userProfile}
                />
                <ProtectedRoute path="/video" component={Video} />

                {/** Admin page for Q&A pages */}
                <ProtectedRoute
                  path="/exhibitionStandAnswers/:exhibitionStandID/:answerToken"
                  component={ExhibitionStandAnswers}
                />

                {/** Roundtables */}
                <ProtectedRoute
                  path="/roundtables"
                  component={Roundtables}
                  showMobile={this.props.showMobile}
                  language={this.props.language}
                />
                <ProtectedRoute
                  path="/visualRoundtables"
                  component={VisualRoundtables}
                  showMobile={this.props.showMobile}
                  language={this.props.language}
                  bypassVideo={false}
                />

                <ProtectedRoute
                  path="/visualRoundtablesDirect"
                  component={VisualRoundtables}
                  showMobile={this.props.showMobile}
                  language={this.props.language}
                  bypassVideo={true}
                />

                <ProtectedRoute
                  path="/roundtableSession/:roundtableID/:returnType/:returnID"
                  component={RoundtableSession}
                  userProfile={this.props.userProfile}
                  language={this.props.language}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                />
                <ProtectedRoute
                  path="/roundtableSession/:roundtableID"
                  component={RoundtableSession}
                  userProfile={this.props.userProfile}
                  language={this.props.language}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                />

                {/** Exhibition */}
                <ProtectedRoute
                  path="/exhibitionIntro"
                  component={ExhibitionIntro}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                />
                <ProtectedRoute
                  path="/exhibition"
                  component={Exhibition}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                />
                <ProtectedRoute
                  path="/exhibitionHall/:hallID"
                  component={ExhibitionHall}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                />
                <ProtectedRoute
                  path="/exhibitionStand/:exhibitionStandID"
                  component={ExhibitionStand}
                  userProfile={this.props.userProfile}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                />
                <ProtectedRoute
                  path="/exhibitionSearch"
                  component={ExhibitionSearch}
                  language={this.props.language}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                />

                {/** Exhibition Admin */}
                <AdminRoute
                  path="/exhibitionAdminList"
                  component={ExhibitionAdminList}
                />
                <AdminRoute
                  path="/editExhibitionStand/:exhibitionStandID"
                  component={EditExhibitionStand}
                />

                {/** Live stream admin */}
                <AdminRoute
                  path="/liveStreamAdminList"
                  component={LiveStreamAdminList}
                />
                <AdminRoute
                  path="/viewLiveStream/:liveStreamID"
                  component={ViewLiveStream}
                  userProfile={this.props.userProfile}
                />
                <AdminRoute
                  path="/createLiveStream"
                  component={CreateLiveStream}
                />

                {/** Mux video admin */}
                <AdminRoute
                  path="/videoAdminList"
                  component={VideoAdminList}
                />
                <AdminRoute
                  path="/viewVideo/:videoID"
                  component={ViewVideo}
                  userProfile={this.props.userProfile}
                />
                <AdminRoute
                  path="/createVideo"
                  component={CreateVideo}
                />

                {/** Analytics */}
                <AdminRoute path="/analytics" component={Analytics} />

                {/** Admin pages */}
                <AdminRoute path="/adminMenu" component={AdminMenu} />
                <AdminRoute path="/remoteControl" component={RemoteControl} />
                <AdminRoute path="/userList" component={UserList} />

              </Switch>
            </section>
          </CSSTransition>
        </TransitionGroup>
      </main>
    );
  }
}

// Higher order component withRouter is used to include location in props to Router for use by TransitionGroup
export default withRouter(Routes);
