import React, { Component } from "react";
import SlidoEmbed from "./../slidoEmbed/SlidoEmbed";
import styles from "./WidgetContainer.module.scss";

export default class WidgetContainer extends Component {
    constructor() {
        super();
        //Set default message
        this.state = {
            showSimTrans: false,
        }
    }

    render () {
        return (
            <>
                {this.props.simTransAvailable ?
                    <>
                        <img
                            className={styles.widgetContainerBackground}
                            src={this.state.showSimTrans ? (window.$videoPath + "visualAssets/" + this.props.userProfile.userLanguage + "/slidoContainer-interprefy.png") : (window.$videoPath + "visualAssets/" + this.props.userProfile.userLanguage + "/slidoContainer-slido.png")}
                            alt="Slido Container"
                        />
                        <img
                            className={`${styles.hoverHotspot} ${styles.switchToSimTransButton}`}
                            src="/hoverShapes/hoverSquare.png"
                            alt="Turn on Sim Trans"
                            onClick={() => this.setState({ showSimTrans: true })}
                        ></img>
                        <img
                            className={`${styles.hoverHotspot} ${styles.switchToSlidoButton}`}
                            src="/hoverShapes/hoverSquare.png"
                            alt="Turn on Slido"
                            onClick={() => this.setState({ showSimTrans: false })}
                        ></img>

                        <div className={styles.widgetDiv}
                            style={{ zIndex: this.state.showSimTrans ? 2 : 0 }}>
                            <iframe
                                title="Interprefy Iframe"
                                src={"https://interprefy.interpret.world/loginlink?token=" + this.props.interprefyEventID}
                                height="100%"
                                width="100%"
                                style={{ minHeight: "560px" }}
                            ></iframe>
                        </div>
                        <div className={styles.widgetDiv}>
                            <SlidoEmbed
                                slidoEventID={this.props.slidoEventID}
                                userProfile={this.props.userProfile}
                            />
                        </div>
                    </> :
                    <>
                        <div className={styles.fullWidgetArea}>
                            <SlidoEmbed
                                slidoEventID={this.props.slidoEventID}
                                userProfile={this.props.userProfile}
                            />
                        </div>
                    </>
                }
            </>
        )
    }
}