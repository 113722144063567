import firebase from "firebase/app";

const config = {
  apiKey: "AIzaSyBe5WL79zUXPkKBFsCn9sa-TM-JcNpnP3Q",
  authDomain: "lu-virtual.firebaseapp.com",
  databaseURL: "https://lu-virtual.firebaseio.com",
  projectId: "lu-virtual",
  storageBucket: "lu-virtual.appspot.com",
  messagingSenderId: "756119826111",
  appId: "1:756119826111:web:3fd67e90cc7d9e64df200a",
};

const Firebase = firebase.initializeApp(config);
export default Firebase;
