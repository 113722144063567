import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Firebase from "../helpers/firebase.js";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import styles from "./RemoteControl.module.scss";

const db = Firebase.firestore();
const auth = firebase.auth();

export default class RemoteControl extends Component {
  constructor() {
    super();
    this.state = {
      navigation: "",
      popUp: false,
      navBarStatus: 1,
      plenaryOpenStatus: "open",
      plenaryContent: "blank",
      plenaryPosition: 1,
      notificationText: "",
      circlesLive: "closed",
      breakout1Live: 0,
      breakout2Live: 0,
      breakout3Live: 0,
      breakout4Live: 0,
      breakout5Live: 0,
      breakout6Live: 0,
      breakout7Live: 0,
      breakout8Live: 0,
      breakout9Live: 0,
      breakout10Live: 0,
      lobbyScreen: "",
      zoomMeetingID: "",
      zoomPassword: "",
      webinarURL: "",
    };
    this.db = Firebase.firestore();
  }

  componentDidMount () {
    this.retrieveRemoteControlSettings();

    auth.signOut();
    this.retrieveRemoteControlWriteCredentials();
  }

  retrieveRemoteControlSettings () {
    fetch("/api/getRemoteControlSettings")
      .then((res) => res.json())
      .then((data) => this.loadResponseIntoState(data));
  }

  loadResponseIntoState (data) {
    console.log("Update: " + data.remoteControlSettings.navigation);
    this.setState({
      navigation: data.remoteControlSettings.navigation,
      popUp: data.remoteControlSettings.popUp,
      navBarStatus: data.remoteControlSettings.navBarStatus,
      plenaryOpenStatus: data.remoteControlSettings.plenaryOpenStatus,
      plenaryContent: data.remoteControlSettings.plenaryContent,
      plenaryPosition: data.remoteControlSettings.plenaryPosition,
      notificationText: data.remoteControlSettings.notificationText,
      circlesLive: data.remoteControlSettings.circlesLive,
      breakout1Live: data.remoteControlSettings.breakout1Live,
      breakout2Live: data.remoteControlSettings.breakout2Live,
      breakout3Live: data.remoteControlSettings.breakout3Live,
      breakout4Live: data.remoteControlSettings.breakout4Live,
      breakout5Live: data.remoteControlSettings.breakout5Live,
      breakout6Live: data.remoteControlSettings.breakout6Live,
      breakout7Live: data.remoteControlSettings.breakout7Live,
      breakout8Live: data.remoteControlSettings.breakout8Live,
      breakout9Live: data.remoteControlSettings.breakout9Live,
      breakout10Live: data.remoteControlSettings.breakout10Live,
      lobbyScreen: data.remoteControlSettings.lobbyScreen,
      zoomMeetingID: data.remoteControlSettings.zoomMeetingID,
      zoomPassword: data.remoteControlSettings.zoomPassword,
      webinarURL: data.remoteControlSettings.webinarURL,
    });
  }

  retrieveRemoteControlWriteCredentials = () => {
    fetch("/api/getRemoteControlWriteCredentials")
      .then((res) => res.json())
      .then((data) => this.authenticateRemoteControlWriteAccess(data));
  };

  authenticateRemoteControlWriteAccess = (data) => {
    console.log("Retrieved Firestore credentials");
    auth
      .signInWithEmailAndPassword(data.username, data.password)
      .catch((error) => {
        console.error("Error signing into remote control write access", error);
      });
  };

  updateInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleNavigationChange = (value) => {
    this.setState({
      navigation: value,
    });
  };

  handlePlenaryOpenStatusChange = (value) => {
    this.setState({
      plenaryOpenStatus: value,
    });
  };

  handleNavBarStatusChange = (value) => {
    this.setState({
      navBarStatus: value,
    });
  };

  handlePlenaryPositionChange = (value) => {
    this.setState({
      plenaryPosition: value,
    });
  };

  handlePlenaryContentChange = (value) => {
    this.setState({
      plenaryContent: value,
    });
  };

  handleNotificationTextChange = (value) => {
    this.setState({
      notificationText: value,
    });
  };

  handleCirclesLiveChange = (value) => {
    this.setState({
      circlesLive: value,
    });
  };

  handleBreakout1LiveChange = (value) => {
    this.setState({
      breakout1Live: value,
    });
  };

  handleBreakout2LiveChange = (value) => {
    this.setState({
      breakout2Live: value,
    });
  };

  handleBreakout3LiveChange = (value) => {
    this.setState({
      breakout3Live: value,
    });
  };

  handleBreakout4LiveChange = (value) => {
    this.setState({
      breakout4Live: value,
    });
  };

  handleBreakout5LiveChange = (value) => {
    this.setState({
      breakout5Live: value,
    });
  };

  handleBreakout6LiveChange = (value) => {
    this.setState({
      breakout6Live: value,
    });
  };

  handleBreakout7LiveChange = (value) => {
    this.setState({
      breakout7Live: value,
    });
  };

  handleBreakout8LiveChange = (value) => {
    this.setState({
      breakout8Live: value,
    });
  };

  handleBreakout9LiveChange = (value) => {
    this.setState({
      breakout9Live: value,
    });
  };

  handleBreakout10LiveChange = (value) => {
    this.setState({
      breakout10Live: value,
    });
  };

  handleLobbyScreenChange = (value) => {
    this.setState({
      lobbyScreen: value,
    });
  };

  saveSettings = () => {
    fetch("/api/updateRemoteControlSettings", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log("Saved successfully");
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Error updating settings -  please try again");
      });
  };

  sendToSite = (event) => {
    // Prevent page reload
    console.log("Submit remote control: " + this.state.notificationText);
    event.preventDefault();
    //db.collection("arm-research-summit")
    //.add({
    db.collection("ng-yec")
      .doc("QRSypBkVnYLxRnaeWmUS")
      .set({
        navigation: this.state.navigation,
        popUp: this.state.popUp,
        navBarStatus: this.state.navBarStatus,
        plenaryOpenStatus: this.state.plenaryOpenStatus,
        plenaryContent: this.state.plenaryContent,
        plenaryPosition: this.state.plenaryPosition,
        notificationText: this.state.notificationText,
        circlesLive: this.state.circlesLive,
        breakout1Live: this.state.breakout1Live,
        breakout2Live: this.state.breakout2Live,
        breakout3Live: this.state.breakout3Live,
        breakout4Live: this.state.breakout4Live,
        breakout5Live: this.state.breakout5Live,
        breakout6Live: this.state.breakout6Live,
        breakout7Live: this.state.breakout7Live,
        breakout8Live: this.state.breakout8Live,
        breakout9Live: this.state.breakout9Live,
        breakout10Live: this.state.breakout10Live,
        lobbyScreen: this.state.lobbyScreen,
        zoomMeetingID: this.state.zoomMeetingID,
        zoomPassword: this.state.zoomPassword,
        webinarURL: this.state.webinarURL,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(function (docRef) {
        console.log("Document written with ID");
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

    this.saveSettings();
  };

  render () {
    return (
      <div className={styles.background}>
        <div className={styles.remoteControlArea}>
          <h1>Remote Control</h1>
          <form onSubmit={this.sendToSite}>
            <br />
            <h3>Force Users to Space:</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.navigation}
              name="navigation"
              onChange={this.handleNavigationChange}
            >
              <ToggleButton className={styles.toggleButton} value={""}>
                Free Choice
              </ToggleButton>
              <ToggleButton value={"lobby"}>Lobby</ToggleButton>
              <ToggleButton value={"plenary"}>Plenary</ToggleButton>
              <ToggleButton value={"posters"}>Poster Gallery</ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Plenary Open</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.plenaryOpenStatus}
              name="plenaryOpenStatus"
              onChange={this.handlePlenaryOpenStatusChange}
            >
              <ToggleButton value={"open"}>Open</ToggleButton>
              <ToggleButton value={"closed"}>Closed</ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Nav Bar Status</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.navBarStatus}
              name="navBarStatus"
              onChange={this.handleNavBarStatusChange}
            >
              <ToggleButton value={0}>Hidden</ToggleButton>
              <ToggleButton value={1}>Visible</ToggleButton>
              <ToggleButton value={2}>Disabled (except Help)</ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />

            <h3>Plenary Content Source</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.plenaryContent}
              name="plenaryContent"
              onChange={this.handlePlenaryContentChange}
            >
              <ToggleButton value={""}>Blank</ToggleButton>
              <ToggleButton value={"vimeo://597787746"}>Holding Slide</ToggleButton>
              <ToggleButton value={"mux://rgi537jttHVtV01Z59e265mFmDTDAaATfGGdFjyn40244"}>
                Mux Demo
              </ToggleButton>
              <ToggleButton value={"mux://Nxa82HKcSmcQh4FPwzL1HVFBeXJ01Ufvf6ppSguqs9TQ"}>
                Plenary Mux Live Stream
              </ToggleButton>
              <ToggleButton value={"https://iframe.dacast.com/live/45590407-243c-77d3-e7a7-27e7b4ef11ce/ec1b8059-f715-4aef-01cf-273cc5415455"}>
                Plenary DACAST
              </ToggleButton>
              <ToggleButton value={"mux://OWnVMvTH7cJhXeLsJ9C025odtIcv7jFnLAAxKgnVz514"}>
                Technical Difficulties
              </ToggleButton>
            </ToggleButtonGroup>
            <br />
            <input
              type="text"
              name="plenaryContent"
              size={150}
              placeholder="Plenary Content Source"
              value={this.state.plenaryContent}
              onChange={this.updateInput}
            />
            <br /> <br />
            <h3>Breakout 1</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.breakout1Live}
              name="breakout1Live"
              onChange={this.handleBreakout1LiveChange}
            >
              <ToggleButton value={""}>No source</ToggleButton>
              <ToggleButton value={"vimeo://597783406"}>Holding Slide</ToggleButton>
              <ToggleButton value={"mux://rgi537jttHVtV01Z59e265mFmDTDAaATfGGdFjyn40244"}>
                Mux Demo
              </ToggleButton>
              <ToggleButton value={"mux://fzdfeBi01EbLjFilV8Qx02dINT6JbtC2siLGCWOEF283w"}>Mux Livestream</ToggleButton>
              <ToggleButton value={"https://iframe.dacast.com/live/45590407-243c-77d3-e7a7-27e7b4ef11ce/ec1b8059-f715-4aef-01cf-273cc5415455"}>
                DACAST Livestream
              </ToggleButton>
              <ToggleButton value={"mux://OWnVMvTH7cJhXeLsJ9C025odtIcv7jFnLAAxKgnVz514"}>
                Technical Difficulties
              </ToggleButton>
              <ToggleButton value={"mux://UPKm64eUkPE2DpUtJThu02ncMsngcW7reAnIlwrNkIEc"}>
                Holding Video
              </ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Breakout 2</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.breakout2Live}
              name="breakout2Live"
              onChange={this.handleBreakout2LiveChange}
            >
              <ToggleButton value={""}>No source</ToggleButton>
              <ToggleButton value={"vimeo://597783429"}>Holding Slide</ToggleButton>
              <ToggleButton value={"mux://rgi537jttHVtV01Z59e265mFmDTDAaATfGGdFjyn40244"}>
                Mux Demo
              </ToggleButton>
              <ToggleButton value={"mux://Gyjlaxp4fJRNzQsHXXgKfna014tcQD00VPHqqB02u4mK3E"}>Mux Livestream</ToggleButton>
              <ToggleButton value={"https://iframe.dacast.com/live/45590407-243c-77d3-e7a7-27e7b4ef11ce/f73390ea-8989-29c7-96fe-d11a44894162"}>
                DACAST Livestream
              </ToggleButton>
              <ToggleButton value={"mux://OWnVMvTH7cJhXeLsJ9C025odtIcv7jFnLAAxKgnVz514"}>
                Technical Difficulties
              </ToggleButton>
              <ToggleButton value={"mux://UPKm64eUkPE2DpUtJThu02ncMsngcW7reAnIlwrNkIEc"}>
                Holding Video
              </ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Breakout 3</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.breakout3Live}
              name="breakout3Live"
              onChange={this.handleBreakout3LiveChange}
            >
              <ToggleButton value={""}>No source</ToggleButton>
              <ToggleButton value={"vimeo://597783451"}>Holding Slide</ToggleButton>
              <ToggleButton value={"mux://rgi537jttHVtV01Z59e265mFmDTDAaATfGGdFjyn40244"}>
                Mux Demo
              </ToggleButton>
              <ToggleButton value={"mux://027QoMi019EkVYc58DUGDf015pFFoe6v3kp7xeKebDaZ2c"}>Livestream</ToggleButton>
              <ToggleButton value={"https://iframe.dacast.com/live/45590407-243c-77d3-e7a7-27e7b4ef11ce/4c1888bb-9425-efde-6666-8995f09aa81a"}>
                DACAST Livestream
              </ToggleButton>
              <ToggleButton value={"mux://OWnVMvTH7cJhXeLsJ9C025odtIcv7jFnLAAxKgnVz514"}>
                Technical Difficulties
              </ToggleButton>
              <ToggleButton value={"mux://UPKm64eUkPE2DpUtJThu02ncMsngcW7reAnIlwrNkIEc"}>
                Holding Video
              </ToggleButton>
            </ToggleButtonGroup>
            <br /> <br />
            <h3>Breakout 4</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.breakout4Live}
              name="breakout4Live"
              onChange={this.handleBreakout4LiveChange}
            >
              <ToggleButton value={""}>No source</ToggleButton>
              <ToggleButton value={"vimeo://597783467"}>Holding Slide</ToggleButton>
              <ToggleButton value={"mux://rgi537jttHVtV01Z59e265mFmDTDAaATfGGdFjyn40244"}>
                Mux Demo
              </ToggleButton>
              <ToggleButton value={"mux://Nndxe6aOlOSGfUYNvyw019NixI02wm02tdH3WOJ3AoTW3M"}>Livestream</ToggleButton>
              <ToggleButton value={"https://iframe.dacast.com/live/45590407-243c-77d3-e7a7-27e7b4ef11ce/a7d20288-de36-2d8f-5ee5-86255fa4467e"}>
                DACAST Livestream
              </ToggleButton>
              <ToggleButton value={"mux://OWnVMvTH7cJhXeLsJ9C025odtIcv7jFnLAAxKgnVz514"}>
                Technical Difficulties
              </ToggleButton>
              <ToggleButton value={"mux://UPKm64eUkPE2DpUtJThu02ncMsngcW7reAnIlwrNkIEc"}>
                Holding Video
              </ToggleButton>
            </ToggleButtonGroup>



            <br /> <br />
            <h3>Notifications</h3>
            <ToggleButtonGroup
              type="radio"
              value={this.state.notificationText}
              name="notificationText"
              onChange={this.handleNotificationTextChange}
            >
              <ToggleButton value={""}>Clear</ToggleButton>
              <ToggleButton
                value={"shared_livestream_error"}>shared_livestream_error
              </ToggleButton>
              <ToggleButton
                value={"notification_welcome"}>notification_welcome
              </ToggleButton>
              <ToggleButton
                value={"notification_first_session_five_minute_warning"}>notification_first_session_five_minute_warning
              </ToggleButton>
              <ToggleButton
                value={"notification_automatic_movement_to_plenary"}>notification_automatic_movement_to_plenary
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              type="radio"
              value={this.state.notificationText}
              name="notificationText"
              onChange={this.handleNotificationTextChange}
            >
              <ToggleButton
                value={"notification_plenary_live"}>notification_plenary_live
              </ToggleButton>
              <ToggleButton
                value={"notification_final_session"}>notification_final_session
              </ToggleButton>
              <ToggleButton
                value={"notification_audio_volume"}>notification_audio_volume
              </ToggleButton>
              <ToggleButton
                value={"notification_slido_comms"}>notification_slido_comms
              </ToggleButton>
              <ToggleButton
                value={"notification_visit_exhbition_gallery"}>notification_visit_exhbition_gallery
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              type="radio"
              value={this.state.notificationText}
              name="notificationText"
              onChange={this.handleNotificationTextChange}
            >
              <ToggleButton
                value={"notification_last_workstream_session"}>notification_last_workstream_session
              </ToggleButton>
              <ToggleButton
                value={"notification_workstream_selection"}> notification_workstream_selection
              </ToggleButton>
              <ToggleButton
                value={"notification_make_a_pledge"}> notification_make_a_pledge
              </ToggleButton>
              <ToggleButton
                value={"notification_workstream_signup"}>notification_workstream_signup
              </ToggleButton>
              <ToggleButton
                value={"notification_connect_with_attendees"}>notification_connect_with_attendees
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              type="radio"
              value={this.state.notificationText}
              name="notificationText"
              onChange={this.handleNotificationTextChange}
            >
              <ToggleButton
                value={"notification_social_networking_tables"}>notification_social_networking_tables
              </ToggleButton>
              <ToggleButton
                value={"notification_social_wall"}>notification_social_wall:
              </ToggleButton>
              <ToggleButton
                value={"notification_book_meetings"}>notification_book_meetings
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              type="radio"
              value={this.state.notificationText}
              name="notificationText"
              onChange={this.handleNotificationTextChange}
            >
              <ToggleButton
                value={"notification_available_vacancies"}>notification_available_vacancies
              </ToggleButton>
              <ToggleButton
                value={"notification_thank_you"}>notification_thank_you
              </ToggleButton>
              <ToggleButton
                value={"notification_on_demand"}>notification_on_demand
              </ToggleButton>
            </ToggleButtonGroup>
            <br />
            <input
              type="text"
              name="notificationText"
              size={100}
              placeholder="Notification text"
              value={this.state.notificationText}
              onChange={this.updateInput}
            />
            <br /> <br />
            {<button type="submit">Submit</button>}
          </form>
          <br />
          <br />
        </div>
      </div>
    );
  }
}
