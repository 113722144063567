import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { dateFormatterTimeAndDay } from "../../helpers/dateFormatter.js"
import styles from "./../UserSessions.module.scss";

class UserSessionsOptionsBreakouts1 extends Component {


    render () {
        return (
            <div>
                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={1}
                        disabled={this.props.session1Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>" + this.props.t('agenda_workstream_one_info') + "</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        <Trans>agenda_workstream_one_title</Trans>
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={2}
                        disabled={this.props.session1Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>" + this.props.t('agenda_workstream_two_info') + "</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        <Trans>agenda_workstream_two_title</Trans>
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={3}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>" + this.props.t('agenda_workstream_three_info') + "</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        <Trans>agenda_workstream_three_title</Trans>
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={4}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>" + this.props.t('agenda_workstream_four_info') + "</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        <Trans>agenda_workstream_four_title</Trans>
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={0}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                        data-tip={"<h6>" + this.props.t('agenda_visit_attendee_gallery') + "</h6><br /><br />"}
                        data-for={"sessionInfo"}
                    >
                        <Trans>agenda_explore_network</Trans>
                    </ToggleButton>
                </ToggleButtonGroup>

            </div>
        );
    }
}

export default withTranslation()(UserSessionsOptionsBreakouts1);
