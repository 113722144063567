import React, { Component } from "react";
import ReactTooltip from 'react-tooltip';
import Button from "react-bootstrap/Button";
import { Trans, withTranslation } from 'react-i18next';
import TimezoneSelect from 'react-timezone-select'
import styles from "./Register.module.scss";
import preLoginStyles from "./Pre-Login.module.scss";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      surname: "",
      jobTitle: "",
      country: "",
      company: "",
      areasOfInterest: "delegate",
      email: "",
      password: "",
      confirmPassword: "",
      userProfilePictureID: "placeholder_nhryv1",
      fileInputDisabled: false,
      userLanguage: "en",
      userTimezone: {
        value: "Europe/London",
        label: "Europe/London",
        abbrev: "BST",
        offset: 1,
        altName: ""
      },
      userAgeBracket: 0,

      instagram: "",
      linkedIn: "",
      facebook: "",
      twitter: "",
      tiktok: "",
      weibo: "",
      snapchat: "",
      qq: "",
      qzone: "",

      regCheckFailed: false,
      showSocialMediaFields: false,
      showDisclaimer: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount () {
    // Upon load, check for language cookie and pre-populate reg form to this
    const { cookies } = this.props;
    const languageSetCookie = cookies.get("languageSet");
    if (languageSetCookie !== undefined) {
      this.setState({ userLanguage: languageSetCookie })
    }
  }

  handleProfilePictureUpload = (event) => {
    this.setState({ fileInputDisabled: true });
    const data = new FormData();
    data.append("file", event.target.files[0]);

    fetch("/api/uploadImage", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          userProfilePictureID: data.publicID,
        });
        console.log("Returned upload ID: " + this.state.userProfilePictureID);
        this.setState({ fileInputDisabled: false });
        alert(this.props.t('registration_photo_submitted'));
      });
  };

  checkUserPreReg = (email) => {
    console.log("Pre reg check");
    fetch("/api/preRegStatus?email=" + encodeURIComponent(email), {
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.response.includes("successfullyMatched")) {
          console.log("matched");
          this.submitShortRegistration();
        } else {
          // Trigger full form request
          this.setState({ regCheckFailed: true });
          // Scroll back to top
          window.scrollTo(0, 0);
        }

      });
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  validateEmail = (email) => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  };

  submitShortRegistration = () => {
    if (this.state.email.length < 5) {
      alert(this.props.t('register_error_enter_email'));
    } else if (!this.validateEmail(this.state.email)) {
      alert(this.props.t('register_error_enter_valid_email'));
    } else if (this.state.password.length < 6) {
      alert(this.props.t('register_error_password_six_letters'));
    } else if (this.state.password !== this.state.confirmPassword) {
      alert(this.props.t('register_error_password_match'));
    } else {
      fetch("/api/register", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            alert(this.props.t('notification_verification_email'));
            this.props.handleCloseRegisterDiv();
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          alert(this.props.t('register_error_account_already_exists'));
        });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    // If pre reg check not failed then do pre-reg check
    // Assume not failed (so only email and password visible)
    if (this.state.regCheckFailed == false) {
      this.checkUserPreReg(this.state.email);
      // No further action until triggered by callack from email check response
    } else {

      if (this.state.firstName.length < 1) {
        alert(this.props.t('register_error_first_name'));
      } else if (this.state.surname.length < 1) {
        alert(this.props.t('register_error_surname'));
        /** } else if (this.state.jobTitle.length < 1) {
          alert("Please enter your job title");*/
      } else if (this.state.country.length < 1) {
        alert(this.props.t('register_error_country'));
        /** } else if (this.state.company.length < 1) {
          alert("Please enter your organisation");*/
      } else if (this.state.userAgeBracket == 0 || this.state.userAgeBracket == "0") {
        alert(this.props.t('register_error_age_range'));
      } else if (this.state.email.length < 5) {
        alert(this.props.t('register_error_enter_email'));
      } else if (!this.validateEmail(this.state.email)) {
        alert(this.props.t('register_error_enter_valid_email'));
      } else if (this.state.password.length < 6) {
        alert(this.props.t('register_error_password_six_letters'));
      } else if (this.state.password !== this.state.confirmPassword) {
        alert(this.props.t('register_error_password_match'));
      } else {
        fetch("/api/register", {
          method: "POST",
          body: JSON.stringify(this.state),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.status === 200) {
              alert(this.props.t('notification_verification_email'));
              this.props.handleCloseRegisterDiv();
            } else {
              const error = new Error(res.error);
              throw error;
            }
          })
          .catch((err) => {
            console.error(err);
            alert(this.props.t('register_error_account_already_exists'));
          });
      }
    }
  };

  showSocialMedia = () => {
    this.setState({ showSocialMediaFields: true });
    setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
  }

  render () {
    return (
      <div className={styles.registerBox}>
        <div className={styles.registerBoxContent}>
          <img
            className={styles.registrationBoxLogo}
            src={window.$videoPath + "logo.png"}
            alt="Logo"
          />

          <h2><Trans>profile_create</Trans></h2>
          <form onSubmit={this.handleSubmit} noValidate>

            <div className={styles.gridContainer}>
              {this.state.regCheckFailed ?
                <>
                  <div className={styles.gridElement}>
                    <label htmlFor="firstName" className={styles.formLabelBlue}>
                      <Trans>register_first_name</Trans>: *
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="firstName"
                      placeholder={this.props.t('register_first_name')}
                      tabIndex="10"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className={styles.gridElement}>
                    <label htmlFor="surname" className={styles.formLabelBlue}>
                      <Trans>register_surname</Trans>: *
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="surname"
                      placeholder={this.props.t('register_surname')}
                      tabIndex="20"
                      value={this.state.surname}
                      onChange={this.handleChange}
                    />
                  </div>

                  {/**<div className={styles.gridElement}>
                    <label htmlFor="jobTitle" className={styles.formLabel}>
                      Job title: *
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="jobTitle"
                      placeholder="Job title"
                      tabIndex="30"
                      value={this.state.jobTitle}
                      onChange={this.handleChange}
                    />
                  </div>*/}

                  <div className={styles.gridElement}>
                    <label htmlFor="company" className={styles.formLabelBlue}>
                      <Trans>presenters_organisation</Trans>:
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="company"
                      placeholder={this.props.t('presenters_organisation')}
                      tabIndex="30"
                      value={this.state.company}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className={styles.gridElement}>
                    <label htmlFor="userAgeBracket" className={styles.formLabelBlue}>
                      <Trans>register_age</Trans>: *
                    </label>
                    <select
                      className={`${styles.formField} ${styles.dropDownEntry}`}
                      id="userAgeBracket"
                      name="userAgeBracket"
                      placeholder="Age"
                      tabIndex="40"
                      value={this.state.userAgeBracket}
                      onChange={this.handleChange}
                    >
                      <option value="0"></option>
                      <option value="1">16-17</option>
                      <option value="2">18-24</option>
                      <option value="3">25-30</option>
                      <option value="4">31-35</option>
                      <option value="5">36+</option>
                    </select>
                  </div>
                </> : null}


              <div className={styles.gridElement}>
                <label htmlFor="areasOfInterest" className={styles.formLabel}>
                  <Trans>shared_attendee_type</Trans>: *
                </label>
                <select
                  className={`${styles.formField} ${styles.dropDownEntry}`}
                  id="areasOfInterest"
                  name="areasOfInterest"
                  placeholder={this.props.t('shared_attendee_type')}
                  tabIndex="50"
                  value={this.state.areasOfInterest}
                  onChange={this.handleChange}
                >
                  <option value="delegate">{this.props.t('shared_delegate')}</option>
                  <option value="exhibitor">{this.props.t('shared_exhibitor_partner')}</option>
                  <option value="speaker">{this.props.t('shared_speaker')}</option>
                </select>
              </div>

              <div className={styles.gridElement}>
                <label htmlFor="userLanguage" className={styles.formLabel}>
                  <Trans>register_preferred_language</Trans>: *
                </label>
                <select
                  className={`${styles.formField} ${styles.dropDownEntry}`}
                  id="userLanguage"
                  name="userLanguage"
                  placeholder={this.props.t('register_preferred_language')}
                  tabIndex="60"
                  value={this.state.userLanguage}
                  onChange={this.handleChange}
                >
                  <option value="en">{this.props.t('shared_english')}</option>
                  <option value="es">{this.props.t('shared_spanish')}</option>
                  <option value="fr">{this.props.t('shared_french')}</option>
                  <option value="zh">{this.props.t('shared_mandarin')}</option>
                </select>
              </div>

              {this.state.regCheckFailed ?
                <>
                  <div className={styles.gridElement}>
                    <label htmlFor="country" className={styles.formLabelBlue}>
                      <Trans>attendees_country</Trans>: *
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="country"
                      placeholder={this.props.t('attendees_country')}
                      tabIndex="70"
                      value={this.state.country}
                      onChange={this.handleChange}
                    />
                  </div>
                </> : null}

              <div className={styles.gridElement}>
                <label htmlFor="userTimezone" className={styles.formLabel}>
                  <Trans>register_timezone</Trans>: *
                </label>
                <TimezoneSelect
                  id="userTimezone"
                  tabIndex="80"
                  value={this.state.userTimezone}
                  onChange={(data) => this.setState({ userTimezone: data })} />
              </div>

              {this.state.regCheckFailed ? null :
                <div className={styles.gridElement}>
                  {/** Formatting spacer */}
                </div>
              }

              <div className={styles.gridElement}>
                <label htmlFor="email" className={styles.formLabel}>
                  <Trans>register_email</Trans>: *
                </label>
                <input
                  className={`${styles.formField} ${preLoginStyles.textEntry}`}
                  type="email"
                  name="email"
                  placeholder={this.props.t('register_email')}
                  tabIndex="90"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>


              <div className={styles.gridElement}>
                {/** Formatting spacer */}
              </div>

              <div className={styles.gridElement}>
                <label htmlFor="password" className={styles.formLabel}>
                  <Trans>shared_password</Trans>: *
                </label>
                <input
                  type="password"
                  name="password"
                  className={`${styles.formField} ${preLoginStyles.textEntry}`}
                  size="50"
                  placeholder={this.props.t('shared_password')}
                  tabIndex="100"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </div>

              <div className={styles.gridElement}>
                <label htmlFor="confirmPassword" className={styles.formLabel}>
                  <Trans>register_confirm_password</Trans>: *
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  className={`${styles.formField} ${preLoginStyles.textEntry}`}
                  size="50"
                  placeholder={this.props.t('register_confirm_password')}
                  tabIndex="110"
                  value={this.state.confirmPassword}
                  onChange={this.handleChange}
                />
              </div>


              <div className={styles.gridElement}>
                <label className={styles.formLabel} htmlFor="profilePicture">
                  <Trans>register_profile_picture</Trans>:
                </label>
                <label className={styles.customFileUpload}>
                  <img
                    className={styles.uploadButton}
                    src={window.$videoPath + "icons/profile/upload.png"}
                    alt="Upload"
                  ></img>
                  <input
                    className={styles.fileInput}
                    name="profilePicture"
                    type="file"
                    tabIndex="120"
                    disabled={this.state.fileInputDisabled}
                    accept="image/*"
                    onChange={this.handleProfilePictureUpload}
                  />
                </label>
              </div>

              <div className={styles.gridElement}>
                {/** Formatting spacer */}
              </div>

              {this.state.showSocialMediaFields ? null :
                <div className={styles.gridElement}>
                  <label htmlFor="instagram" className={styles.formLabel}>
                    <Trans>profile_social_media</Trans>:
                  </label>
                  <Button onClick={this.showSocialMedia}><Trans>profile_social_media</Trans></Button>
                </div>
              }

              <ReactTooltip className={styles.toolTip} style={{ zIndex: 5000000000 }} html={true} id={"socialMedia"} border={true} effect={"float"} />

              {this.state.showSocialMediaFields ?
                <>
                  <div className={styles.gridElement}
                    data-tip={"<h6><strong>" + this.props.t('profile_instagram') + "</strong></h6><br />"}
                    data-for={"socialMedia"}
                  >
                    <label htmlFor="instagram" className={styles.formLabel}>
                      <img src={window.$videoPath + "icons/socialMediaIcons/instagram.png"} className={styles.socialMediaIcon}

                      />
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="instagram"
                      placeholder="Instagram"
                      tabIndex="130"
                      value={this.state.instagram}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className={styles.gridElement}
                    data-tip={"<h6><strong>" + this.props.t('profile_personalised_url') + "</strong></h6><br />"}
                    data-for={"socialMedia"}
                  >
                    <label htmlFor="linkedIn" className={styles.formLabel}>
                      <img src={window.$videoPath + "icons/socialMediaIcons/linkedin.png"} className={styles.socialMediaIcon} />
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="linkedIn"
                      placeholder="LinkedIn"
                      tabIndex="140"
                      value={this.state.linkedIn}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className={styles.gridElement}
                    data-tip={"<h6><strong>" + this.props.t('profile_url') + "</strong></h6><br />"}
                    data-for={"socialMedia"}
                  >
                    <label htmlFor="facebook" className={styles.formLabel}>
                      <img src={window.$videoPath + "icons/socialMediaIcons/facebook.png"} className={styles.socialMediaIcon} />
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="facebook"
                      placeholder="Facebook"
                      tabIndex="150"
                      value={this.state.facebook}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className={styles.gridElement}
                    data-tip={"<h6><strong>" + this.props.t('profile_twitter') + "</strong></h6><br />"}
                    data-for={"socialMedia"}
                  >
                    <label htmlFor="twitter" className={styles.formLabel}>
                      <img src={window.$videoPath + "icons/socialMediaIcons/twitter.png"} className={styles.socialMediaIcon} />
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="twitter"
                      placeholder="Twitter"
                      tabIndex="150"
                      value={this.state.twitter}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className={styles.gridElement}
                    data-tip={"<h6><strong>" + this.props.t('profile_tiktok') + "</strong></h6><br />"}
                    data-for={"socialMedia"}
                  >
                    <label htmlFor="tiktok" className={styles.formLabel}>
                      <img src={window.$videoPath + "icons/socialMediaIcons/tiktok.png"} className={styles.socialMediaIcon} />
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="tiktok"
                      placeholder="TikTok"
                      tabIndex="160"
                      value={this.state.tiktok}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className={styles.gridElement}
                    data-tip={"<h6><strong>" + this.props.t('profile_weibo') + "</strong></h6><br />"}
                    data-for={"socialMedia"}
                  >
                    <label htmlFor="weibo" className={styles.formLabel}>
                      <img src={window.$videoPath + "icons/socialMediaIcons/Weibo.png"} className={styles.socialMediaIcon} />
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="weibo"
                      placeholder="Weibo"
                      tabIndex="170"
                      value={this.state.weibo}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className={styles.gridElement}
                    data-tip={"<h6><strong>" + this.props.t('profile_url') + "</strong></h6><br />"}
                    data-for={"socialMedia"}
                  >
                    <label htmlFor="snapchat" className={styles.formLabel}>
                      <img src={window.$videoPath + "icons/socialMediaIcons/snapchat.png"} className={styles.socialMediaIcon} />
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="snapchat"
                      placeholder="Snapchat"
                      tabIndex="180"
                      value={this.state.snapchat}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className={styles.gridElement}
                    data-tip={"<h6><strong>" + this.props.t('profile_url') + "</strong></h6><br />"}
                    data-for={"socialMedia"}
                  >
                    <label htmlFor="qq" className={styles.formLabel}>
                      <img src={window.$videoPath + "icons/socialMediaIcons/qq.png"} className={styles.socialMediaIcon} />
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="qq"
                      placeholder="QQ"
                      tabIndex="190"
                      value={this.state.qq}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className={styles.gridElement}
                    data-tip={"<h6><strong>" + this.props.t('profile_personalised_url') + "</strong></h6><br />"}
                    data-for={"socialMedia"}
                  >
                    <label htmlFor="qzone" className={styles.formLabel}>
                      <img src={window.$videoPath + "icons/socialMediaIcons/qzone-logo.png"} className={styles.socialMediaIcon} />
                    </label>
                    <input
                      className={`${styles.formField} ${preLoginStyles.textEntry}`}
                      type="text"
                      name="qzone"
                      placeholder="Qzone"
                      tabIndex="200"
                      value={this.state.qzone}
                      onChange={this.handleChange}
                    />
                  </div>

                </> : null}


            </div>
            <br />

            {this.state.showDisclaimer ?
              <div className={styles.disclaimer}>
                <strong>General Data Protection Regulation Disclaimer:</strong><br /><br />

                By providing your personal data, you are authorising National Grid to retain this information on its systems only, including any commissioned specifically for this event, designed and operated by a nominated third party acting as our principal and retained to do so. In both cases, data will be collected in the context of the National Grid COP26 Global Youth Engineering Climate Conference 2021 and not to be shared with any other third party organisations or for any other purpose, outside of the organisation and execution of this specific event as detailed on the event pages of www.nationalgrid.com.<br /><br />
                At the conference close, on-demand content will be available for a limited period during which interval, your data will be retained to provide continuing access to materials. After this point it will be erased from our systems.<br /><br />
                Only data pertinent to your conference experience will be collected, the accuracy of which will reflect the data submitted at the point of registration.<br /><br />
                National Grid systems and websites are subject to stringent security processes and safeguards with the protection of all data, a high priority.<br /><br />
                If at any time, you require changes to be made to the data held by National Grid or for it to be erased from our systems please contact: <a href="mailto:box.COP26@nationalgrid.com" target="_blank" rel="noopener noreferrer">box.COP26@nationalgrid.com</a><br /><br />
                Please note. This event is an open, bookable forum, the content of which will be available for a limited period on demand after the event. As such, any information you share, whether as an active audience member, speaker, or panellist, should be viewed as being in the public domain.
              </div>
              : null}

            <br />
            <input
              type="submit"
              value={this.props.t('shared_submit')}
              disabled={this.state.fileInputDisabled}
              className="btn btn-success float-right submitButton"
            />
          </form>
          <Button
            onClick={() => this.props.handleCloseRegisterDiv()}
            className="btn btn-secondary float-right"
            style={{ marginRight: 10 }}
          >
            <Trans>shared_back</Trans>
          </Button>
          <Button
            onClick={() => this.setState({ showDisclaimer: true })}
            className="btn btn-success float-right"
            style={{ marginRight: 10 }}
          >
            Disclaimer
          </Button>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default withTranslation()(Register);