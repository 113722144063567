import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import styles from "./CreateLiveStream.module.scss";

export default class CreateLiveStream extends Component {
  constructor() {
    super();
    this.state = {
      streamName: "",
      redirectNow: false,
    };
  }

  updateInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  saveSettings = (event) => {
    event.preventDefault();
    fetch("/api/createLiveStream", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ redirectNow: true });
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Error updating settings -  please try again");
      });
  };

  render () {
    return (
      <div className={styles.adminContentArea}>
        <h1>Create Live Stream</h1>
        {this.state.redirectNow ? <Redirect to={"/liveStreamAdminList"} /> : null}
        <form onSubmit={this.saveSettings}>
          <br />


          <h4>Stream Name</h4>
          <input
            type="text"
            name="streamName"
            size={100}
            placeholder="Stream Name"
            value={this.state.streamName}
            onChange={this.updateInput}
          />
          <br /> <br />

          {<button type="submit">Submit</button>}

          <br /> <br />
          <br /> <br />
        </form>
      </div>
    );
  }
}