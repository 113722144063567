import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next';
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import VideoPlayer from "./../modules/videoPlayer/VideoPlayer"
import LobbyMobile from "./LobbyMobile";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./Lobby.module.scss";

class Lobby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introVideoComplete: false,
      introMuxVersion: null,
      muteAudio: false,
    };
  }

  componentDidMount () {
    requestPoints("visitLobby", "visitLobby");
    const { cookies } = this.props;
    const lobbyVisitedCookie = cookies.get("lobbyVisited");
    if (lobbyVisitedCookie === undefined) {
      // Case for first visit
      //this.props.setShowHelpModal(true);this.props.setShowHelpModal(true);
      cookies.set("lobbyVisited", "yes", { path: "/" });

    } else {
      // If visited before them do not show intro sequence
      this.setState({ introVideoComplete: true })
    }

    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      15000
    );
    // Check which Mux intro to use
    this.getMuxIntroVersion();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.language != this.props.language) {
      // Language has changed
      this.getMuxIntroVersion();
    }

    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 1) {
      this.props.setCornerMenuStatus(1);
    }
  }

  introVideoComplete = () => {
    if (!this.state.introVideoComplete) {
      this.props.setShowHelpModal(true);
      this.setState({ muteAudio: true });
    }
    this.setState({ introVideoComplete: true })
  }

  getMuxIntroVersion () {
    switch (this.props.language) {
      case "en":
        this.setState({ introMuxVersion: "mux://JgOLazXJzegqv2S00omAZrjSwHbc701V7bUBqBUGjAAy8" })
        break;
      case "fr":
        this.setState({ introMuxVersion: "mux://UU01JJ3BWG8eztBLMJ3XKA7F8nZCnxvsvJcIIh3P8TOo" })
        break;
      case "es":
        this.setState({ introMuxVersion: "mux://Eaym2x1LG024mEuLNVm02S7bObHt6nV24LxKQeX5djAfQ" })
        break;
      case "zh":
        this.setState({ introMuxVersion: "mux://201dJaLtNUoM4oom4oL5dflTSZNyxo01jBERBy7QZ01M4I" })
        break;
      default:
        this.setState({ introMuxVersion: "mux://JgOLazXJzegqv2S00omAZrjSwHbc701V7bUBqBUGjAAy8" })
        break;
    }
  }

  muteAudio = () => {
    this.setState({ muteAudio: !this.state.muteAudio });
  };

  render () {
    return (
      <div>
        {this.props.showMobile ?
          <LobbyMobile
            setShowAgendaModal={this.props.setShowAgendaModal}
            setShowHelpModal={this.props.setShowHelpModal}
            setShowProfileModal={this.props.setShowProfileModal}
            setShowLeaderboardModal={this.props.setShowLeaderboardModal}
            setShowSessionSelectionModal={this.props.setShowSessionSelectionModal}
          />
          :
          <div className={styles.masterPageDiv}>
            {this.props.userProfile && this.state.introMuxVersion ?
              <div className="imageNavBackground">
                <VideoPlayer
                  resourceLocator={this.state.introMuxVersion}
                  location={"background"}
                  userProfile={this.props.userProfile}
                  autoPlay={true}
                  title={"Lobby Intro"}
                  showControls={false}
                  muted={true}
                  onEnded={this.introVideoComplete}
                /> </div> : null}

            <CSSTransition
              in={this.state.introVideoComplete}
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <video
                className="imageNavBackground"
                autoPlay
                webkit-playsinline="true"
                playsInline
                muted
                loop={true}
                id="introVideo"
              >
                <source src={window.$videoPath + "visuals/" + this.props.language + "/lobbyLoop.webm"} type="video/webm" />
                <source src={window.$videoPath + "visuals/" + this.props.language + "/lobbyLoop.mp4"} type="video/mp4" />
              </video>
            </CSSTransition>

            <img
              src={window.$videoPath + "icons/sound-on.png"}
              className="soundOn3DButton"
              onClick={this.muteAudio}
              alt="Audio mute"
            />

            <audio
              id="audioPlayer"
              src={window.$videoPath + "lobby.mp3"}
              autoPlay
              loop
              volume="0.3"
              muted={this.state.muteAudio}
            />

            <Link to="/plenary">
              <img
                className={`${styles.hoverHotspot} ${styles.plenaryHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To plenary"
              ></img>
            </Link>

            <Link to="/breakouts">
              <img
                className={`${styles.hoverHotspot} ${styles.breakoutsHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To breakouts"
              ></img>
            </Link>

            <Link to="/networking">
              <img
                className={`${styles.hoverHotspot} ${styles.networkingHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To networking"
              ></img>
            </Link>

            {/**<img
              className={`${styles.hoverHotspot} ${styles.postersHoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="To posters"
              onClick={() => alert(this.props.t('notification_gallery_closed'))}
            ></img>

            <img
              className={`${styles.hoverHotspot} ${styles.exhibitionHoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="To exhibition"
              onClick={() => alert(this.props.t('notification_exhibition_closed'))}
            ></img>

            <img
              className={`${styles.hoverHotspot} ${styles.onDemandHoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="To on demand"
              onClick={() => alert(this.props.t('notification_gyecctv_closed'))}
            ></img>*/}

            <Link to="/posters">
              <img
                className={`${styles.hoverHotspot} ${styles.postersHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To posters"
              ></img>
            </Link>

            <Link to="/exhibitionIntro">
              <img
                className={`${styles.hoverHotspot} ${styles.exhibitionHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To exhibition"
              ></img>
            </Link>

            <Link to="/visualOnDemand">
              <img
                className={`${styles.hoverHotspot} ${styles.onDemandHoverHotspot}`}
                src="/hoverShapes/hoverSquare.png"
                alt="To on demand"
              ></img>
            </Link>

            {/**<img
              className={`${styles.hoverHotspot} ${styles.helpDeskHotSpot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="Help Desk"
              onClick={() => this.props.setShowHelpModal(true)}
            ></img>*/}
          </div>
        }
      </div>
    );
  }
}

export default withTranslation()(Lobby);